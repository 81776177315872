// Importing react libs
import React, { useEffect, useState } from 'react';

// Importing antd libs
import {
  Form,
  Input,
  InputNumber,
  message,
  Select,
} from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/input-number/style/css';
import 'antd/es/message/style/css';
import 'antd/es/select/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { MChromosomeDictionary, chromosomes } from 'helix-modules/chromosome';

// Importing Helix components
import Label from 'helix/ui/label';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { BiomaterialGeneRegionList, DiseaseSelector } from 'components/gene';

// Importing component style
import './form.scss';
import { Notification } from 'helper/handler/notification';

// Defining constants
const { Item, useWatch } = Form;

const translateChrom = (chrom) => ({ '23': 'X', '24': 'Y' }[chrom] ?? chrom);
const getGeneOptions = (chrom) => chrom === 'X' ? AppAPI.GeneRegion.X_TYPE_VALUES : AppAPI.GeneRegion.NON_X_TYPE_VALUES;

// Exporting component
export const GeneForm = (props) => {
  const { case: caseObj, form: modalForm, gene, onUpdate } = props;
  const Language = useLanguage();
  const [form] = modalForm ? [modalForm] : Form.useForm();
  const [biomaterials, setBiomaterials] = useState([]);
  const [chromList, setChromList] = useState(chromosomes);
  const inheritanceType = useWatch('gene_type', form);
  const chromValue = useWatch('chromosome', form);
  const geneOptions = getGeneOptions(chromValue);

  const onFinish = async (values) => {
    // set the case id
    if (caseObj?.id) values['case'] = caseObj.id;
    // set id if gene is already defined
    if (gene?.id) values['id'] = gene.id;
    // set biomaterial status relationship
    values.biomaterials = biomaterials.map((biomaterial) => ({
      id: biomaterial.id,
      status: biomaterial.status,
    }));
    // calculate length
    values.length = values.end - values['start_at'];
    if (values.length <= 0) {
      return Notification.error(
        Language,
        { msg: 'NOT_VALID_GENE_REGION_START_END_POSITION' },
        'accessioning',
      );
    }
    const res = await AppAPI.GeneRegion.save(values);

    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);
    return onUpdate(res);
  };

  const _loadBiomaterials = async () => {
    const res = await AppAPI.Biomaterial.list({
      filters: {
        'case': [caseObj?.id],
        'designation__in': ['father', 'mother', 'reference'],
      },
      limit: 1000,
      order: 'designation',
    });
    if (res.error) return message.error(Language.get('common', res.msg));
    const biomaterials = res.results.map((biomaterial) => {
      let status;
      if (gene?.id) {
        const geneRegion = biomaterial['gene_regions'].find((item) => item['gene_region_id'] === gene.id);
        status = geneRegion?.status;
      }
      return {
        designation: biomaterial.designation,
        id: biomaterial.id,
        name: biomaterial.name,
        pedegree: biomaterial.pedegree,
        sex: biomaterial.sex,
        status,
      };
    }, {});

    // get values for gene statuses list
    const biomaterialStatuses = biomaterials.reduce((statuses, bio) => ({
      ...statuses,
      [`${bio.name}_status`]: bio.status,
    }), {});

    setBiomaterials(biomaterials);
    form.setFieldsValue(biomaterialStatuses);
  };

  const _loadChromosomeDictionary = async () => {
    // Define hg based on case is_v1_array
    if (caseObj) {
      const hg = caseObj.is_v1_array ? '19' : '38';
      setChromList(await MChromosomeDictionary.load({
        bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
        dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
      }));
    }
  };

  const _preFill = (id, disease) => {
    if (id) {
      if (disease) {
        form.setFieldsValue({
          ...disease,
          end: disease['start_at'] + disease.length,
          name: disease.name.split(':').shift(),
        });
      }
    } else {
      form.setFieldsValue({ disease_dictionary: null });
    }
  };

  const _updateBiomaterialRegionStatus = ({ index, value }) => {
    biomaterials[index].status = value;
    setBiomaterials(biomaterials);
  };

  useEffect(() => {
    _loadBiomaterials();
    _loadChromosomeDictionary();
  }, [caseObj, gene]);

  useEffect(() => {
    if (gene?.id) {
      form.setFieldsValue({
        ...gene,
        chromosome: translateChrom(gene.chromosome),
        end: gene['start_at'] + gene.length,
      });
    }
  }, []);

  useEffect(() => {
    if (chromValue && !geneOptions.includes(inheritanceType)) {
      form.setFieldValue('gene_type', null);
    }
  }, [chromValue]);

  const selectedChrom = chromList.find(chr => chr.label === chromValue);

  return (
    <div className="gene-form">
      <Form
        form={form}
        name="gene-form"
        onFinish={onFinish}
        validateMessages={{
          required: Language.get('gene', 'NOT_VALID_EMPTY_VALUE'),
        }}
      >
        <Item
          label={(<Label name={Language.get('gene', 'DISEASES')} title={Language.get('gene', 'DISEASES_INFO')} />)}
          name="disease_dictionary"
        >
          <DiseaseSelector
            value={form.getFieldValue('disease_dictionary')}
            onChange={_preFill}
          />
        </Item>
        <Item
          label={(<Label name={Language.get('gene', 'NAME')} title={Language.get('gene', 'NAME_INFO')} />)}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
        <Item
          label={(<Label name={Language.get('gene', 'CHROM')} title={Language.get('gene', 'CHROM_INFO')} />)}
          name="chromosome"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            options={chromList.map(chrom => ({ label: chrom.label, value: chrom.label }))}
          />
        </Item>
        <Item
          label={(<Label name={Language.get('gene', 'START')} title={Language.get('gene', 'START_INFO')} />)}
          name="start_at"
          rules={[{ required: true }]}
        >
          <InputNumber min={0} max={selectedChrom?.length - 1} />
        </Item>
        <Item
          label={(<Label name={Language.get('gene', 'END')} title={Language.get('gene', 'END_INFO')} />)}
          name="end"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} max={selectedChrom?.length} />
        </Item>
        <Item
          label={(<Label name={Language.get('gene', 'TYPE')} title={Language.get('gene', 'TYPE_INFO')} />)}
          name="gene_type"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            options={geneOptions.map((option) => ({ label: Language.get('gene', option), value: option }))}
          />
        </Item>
        <Item
          label={(<Label name={Language.get('gene', 'MUTATION_SITE')} title={Language.get('gene', 'MUTATION_SITE_INFO')} />)}
          name="mutation_site"
        >
          <InputNumber min={1} max={selectedChrom?.length - 1} />
        </Item>
        <h4>{Language.get('biomaterial', 'SAMPLES')}</h4>
        <BiomaterialGeneRegionList
          biomaterials={biomaterials}
          inheritanceType={inheritanceType}
          onUpdate={_updateBiomaterialRegionStatus}
        />
      </Form>
    </div>
  );
};
