// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Importing antd libs
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Upload,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/message/style/css';
import 'antd/es/upload/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app helper
import { Notification } from 'helper/handler/notification';

// Importing app component
import { CAImportConfirm, SAImportConfirm } from 'components/case/import';

// Importing component helper
import { Parser, Validator } from './helper/import';

// Importing component style
import './upload.scss';

// Defining constants
const ACCEPT_FILE_TYPE = 'text/plain, text/tsv';

// Exporting component
const ImportUpload = (props) => {
  const { case: caseObj, onImport } = props;
  const Language = useLanguage();
  const location = useLocation();
  const locationContent = location.state?.content;
  const navigate = useNavigate();
  const [biomaterials, setBiomaterials] = useState();
  const [importCAPayload, setImportCAPayload] = useState();
  const [loading, setLoading] = useState(false);
  const [showCAImportSummary, setCAShowImportSummary] = useState(false);
  const [showSAImportSummary, setSAShowImportSummary] = useState(false);

  const Import = {
    ca: async (content) => {
      // validate case accessioning file
      await Validator.caseAccessioningData(content, 'ca');

      // validate biomaterials
      await Validator.biomaterials([
        ...content.trio,
        ...content.data,
      ]);

      // validate combination of gene regions and trio
      Validator.geneTrio(content);

      // parse content to set proper payload for the API call
      Parser.trio(content);
      setImportCAPayload(Parser.mapForImport(content, 'ca'));
    },
    sa: async (content) => {
      const caseIdKey = 'case_id';
      if (!content?.header?.[caseIdKey]) {
        throw new Error('NOT_VALID_CASE_ID');
      }
      const res = await AppAPI.Case.list({
        filters: { 'external_id': [content.header[caseIdKey]] },
        limit: 1,
      });
      if (res.error || res.results.length === 0) throw new Error('NOT_VALID_CASE_ID_DOES_NOT_EXIST');
      const notSameCaseId = caseObj?.['external_id'] !== content.header[caseIdKey];
      if (notSameCaseId) {
        navigate(
          `/accessioning/${res.results[0].id}`,
          { state: { content } },
        );
        return false;
      }

      // validate biomaterial accessioning file
      Validator.baFileType(caseObj, content);

      // validate biomaterials
      await Validator.biomaterials(content.data, caseObj?.id);

      setBiomaterials(Parser.biomaterials(content.data, caseObj?.id));
    },
  };

  /**
   * On import ca confirm
   * @params {void}
   * @return {void} state change
  **/
  const _onConfirmCAImport = async () => {
    const res = await AppAPI.Case.import(importCAPayload);
    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);
    setImportCAPayload(undefined);
    navigate(`/accessioning/${res.id}`);
  };

  /**
   * On import sa confirm
   * @params {void}
   * @return {void} state change
  **/
  const _onConfirmSAImport = async () => {
    const res = await AppAPI.Biomaterial.save(biomaterials);
    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);
    setBiomaterials(undefined);
    return onImport();
  };

  /**
   * On import btn clicked
   * @params {object} file last added
   * @params {array} fileList list of file added
   * @return {bool} return false to prevent automatically upload files
  **/
  const _onImport = async (file) => {
    try {
      setLoading(true);
      let content = undefined;
      if (file) {
        const text = await file.text();

        // validate encode
        if (/�/gi.test(text)) {
          return message.error(Language.get('accessioning', 'NOT_VALID_ENCODE_FOR_IMPORT_FILE'));
        }

        // Parsing file content
        content = Parser.read(text);
      } else {
        content = { ...locationContent };
      }

      if (content) {
        if (!Import[content.header['file_type']]) throw new Error('NOT_VALID_FILE_TYPE');
        await Import[content.header['file_type'].toLowerCase()](content);
      }
    } catch (error) {
      message.error(
        <Fragment>
          {Language.get('accessioning', error.message.toUpperCase())}
          {error.ref && (<div>{Language.get('common', 'ROW')}: {error.ref}</div>)}
          {error.detail && (<pre>{error.detail}</pre>)}
        </Fragment>
      );
    } finally {
      setLoading(false);
    }
    return false;
  };

  useEffect(() => {
    setSAShowImportSummary(Boolean(biomaterials));
  }, [biomaterials]);

  useEffect(() => {
    setCAShowImportSummary(Boolean(importCAPayload));
  }, [importCAPayload]);

  useEffect(() => {
    if (locationContent && caseObj) {
      const caseExternalId = caseObj['external_id'];
      const locationCaseExternalId = locationContent.header['case_id'];
      if (caseExternalId === locationCaseExternalId) {
        _onImport();
        window.history.pushState({}, document.title);
      }
    }
  }, [locationContent, caseObj]);

  return (
    <div className="case-import">

      <Upload
        accept={ACCEPT_FILE_TYPE}
        multiple={false}
        showUploadList={false}
        beforeUpload={_onImport}
      >
        <Button disabled={loading}>
          {loading && <LoadingOutlined />}
          {Language.get('common', 'IMPORT')}
        </Button>
      </Upload>

      <CAImportConfirm
        data={importCAPayload}
        open={showCAImportSummary}
        onCancel={() => setImportCAPayload(undefined)}
        onConfirm={_onConfirmCAImport}
      />

      <SAImportConfirm
        data={biomaterials}
        open={showSAImportSummary}
        onCancel={() => {
          window.history.replaceState({}, document.title); // clear location state
          setBiomaterials(undefined);
        }}
        onConfirm={_onConfirmSAImport}
      />
    </div>
  );
};

ImportUpload.propTypes = {
  case: PropTypes.object,
  onImport: PropTypes.func,
};

ImportUpload.defaultProps = {
  case: undefined,
  onImport: () => { },
};

export { ImportUpload };
