// Importing react libs
const { useEffect, useState, useMemo } = require('react');

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { MChromosomeDictionary } from 'helix-modules/chromosome';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app handler
import { Notification } from 'helper/handler/notification';

// TODO: Replace it with call for a file that is implemented in https://github.com/basepair/kmap-frontend/pull/35/files
import { snps } from 'modules/pdf/mock/snps';

const useRegions = ({ caseId, isV1Array }) => {
  const Language = useLanguage();
  const [chromList, setChromList] = useState([]);
  const [geneRegions, setGeneRegions] = useState([]);

  useEffect(() => {
    const listGeneRegions = async () => {
      const res = await AppAPI.GeneRegion.list({
        filters: { case: caseId },
      });
      if (res.error) {
        Notification.error(Language, res);
        res.results = [];
      }
      setGeneRegions(res.results);
    };
    if (caseId) listGeneRegions();
    else setGeneRegions([]);
  }, [caseId, chromList]);

  useEffect(() => {
    const loadCytobands = async () => {
      const hg = isV1Array ? '19' : '38';
      setChromList(await MChromosomeDictionary.load({
        bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
        dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
      }));
    };
    loadCytobands();
  }, [isV1Array]);

  return useMemo(
    () => geneRegions.map((region) => ({
      ...region,
      band: MChromosomeDictionary.getCytoband({
        chromosome: chromList.find(({ value }) => `${value}` === region.chromosome),
        position: region['start_at'] + region.length / 2,
      }),
      snp: snps.find((snp) => snp.name === region.name),
    })),
    [geneRegions],
  );
};

export default useRegions;