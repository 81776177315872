// Importing react libs
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Importing antd components
import { Tooltip } from 'antd';
import 'antd/es/tooltip/style/css';

// importing barcode library
import SVGRenderer from 'jsbarcode/bin/renderers/svg.js';
import Barcode from 'react-barcode';

// Importing Helix components
import { DEFAULT_CFG, getPosition } from 'helix/chart/plate';

// Importing helper
import { beadchipCoord } from './helper/pdf';
import { isFailedBiomaterial } from './helper/data';

// Importing component style
import './beadchip.scss';

// Overriding Barcode SVGRenderer to remove not needed width in svg
SVGRenderer.prototype.setSvgAttributes = function (width, height) {
  if (this?.svg) {
    this.svg.setAttribute('height', `${height}px`);
    this.svg.setAttribute('x', '0px');
    this.svg.setAttribute('y', '0px');
    this.svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    this.svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.svg.setAttribute('version', '1.1');
    this.svg.setAttribute('style', 'transform: translate(0,0)');
  }
};

// Exporting component
export const Beadchip = ({
  barcode,
  data,
  slotAboveBox,
  slotBelowBox,
  getBeadText = (material) => material?.name ?? '',
  hasLink = true,
  hasTooltip = true,
}) => {
  const navigate = useNavigate();
  const dataByPosition = Array.isArray(data) ? data.reduce((byPosition, sample) => {
    const { y } = getPosition(sample['plate_position']);
    byPosition[y] = sample;
    return byPosition;
  }, {}) : data;

  return (
    <div className="beadchip-wrapper">
      <div className='above-box'>
        {slotAboveBox}
      </div>
      <div className="indexes">
        {Array(DEFAULT_CFG.plate.rows).fill(DEFAULT_CFG.plate.rows).map((_, index) => (
          <div className="index" key={index}>{(dataByPosition[index] && beadchipCoord(dataByPosition[index])) ?? ''}</div>
        ))}
      </div>
      <div className="beadchip-box">
        <div>
          {Array(DEFAULT_CFG.plate.rows).fill(DEFAULT_CFG.plate.rows).map((_, index) => {
            const alignedClass = [0, 1, 4, 5].includes(index) ? 'left' : 'right';
            let metaClassName = `meta ${alignedClass}`;

            const sample = dataByPosition[index];
            if (sample) {
              metaClassName += isFailedBiomaterial(sample) ? ' failed' : ' success';
            }
            const analysis = sample?.analyses?.[0];
            const {
              id,
              analysis_id: analysisId,
              case_id: caseId,
            } = analysis ?? {};
            const sampleLink = hasLink && analysis ? `/case/${caseId}/analysis/${analysisId}/sample/${id}` : '';
            const cursor = sampleLink ? 'pointer' : 'default';
            return (
              <div key={index}>
                <Tooltip title={hasTooltip ? sample?.name ?? sample?.id : null}>
                  <div
                    className={metaClassName}
                    style={{ cursor }}
                    onClick={() => sampleLink ? navigate(sampleLink) : null}
                  >
                    {sample ? getBeadText(sample) : null}
                  </div>
                </Tooltip>
              </div>
            );
          })}
          <Barcode displayValue={false} height={20} marginLeft={0} marginRight={0} value={barcode} width={1} />
        </div>
      </div>
      <div className='below-box'>
        {slotBelowBox}
      </div>
    </div>
  );
};
