// Importing react libs
import React, { useState } from 'react';

// Importing recharts components
import {
  Cell,
  Legend,
  PieChart,
  Pie as RechartsPie,
  Tooltip,
} from 'recharts';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component styles
import './pie.scss';

// Exporting component
export const Pie = (props) => {
  const {
    context,
    data,
    height,
    id,
    dataset,
    title,
    width = 400,
  } = props;

  const Language = useLanguage();
  const [selected, setSelected] = useState(null);
  const i18nStatistic = (key) => key && Language.get(context || 'pie', key);

  /**
   * Generate hint content for selected value
   * @param {Object} selected
   * @return {Object} div
  **/
  function _hintContent() {
    const { name, percentage, value } = selected || {};
    return (name &&
      <div className="pie-chart-tooltip">
        <div className="pie-chart-tooltip-label">{name}: {value} ({percentage})</div>
      </div>
    );
  }

  /** Helper to select value from series
   * @param {object} selected value
   * @return {void} set state change with selected value
  **/
  function _select(selected) {
    const { name, percent, value } = selected;
    setSelected({
      name: i18nStatistic(name),
      percentage: `${(percent).toFixed(1)}%`,
      value,
    });
  }

  return (
    <div
      className="pie-chart"
      id={id}
    >
      {title && <h2>{title}</h2>}
      <PieChart width={width} height={height}>
        <RechartsPie
          data={data}
          dataKey="value"
          cx={height / 2.5}
          cy={height / 2.5}
          outerRadius={height / 3}
          fill="#8884d8"
          onMouseOver={_select}
        >
          {(data || []).map((set) => (
            <Cell
              key={set.name}
              fill={dataset[set.name]?.color}
              strokeWidth={0}
            />
          ))}
        </RechartsPie>
        <Legend
          align='right'
          formatter={i18nStatistic}
          height={28}
          layout='vertical'
          verticalAlign="top"
        />
        <Tooltip content={_hintContent} />
      </PieChart>
    </div>
  );
};
