// Importing react libs
import React from 'react';
import { Navigate } from 'react-router-dom';

// Importing app modules
import AppAPI from 'modules/api';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing components
import { AnalysisFileList, RawFileList } from 'components/file';

// Importing container styles
import './index.scss';

export const File = () => {
  const Language = useLanguage();

  if (AppAPI.User.isDev) return <Navigate to="/dashboard" />;

  return (
    <div className="file-list">
      <RawFileList />
      <AnalysisFileList title={Language.get('file', 'RESULT_FILES')} type="data" />
      <AnalysisFileList title={Language.get('file', 'UI_FILES')} type="ui" />
    </div>
  );
};
