// Importing react libs
import React, { Fragment } from 'react';

// Importing antd libs
import { Col, Row, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app compoments
import { Disease } from 'components/analysis/region/disease';
import { Detail } from 'components/analysis/region/detail';
import { Snps } from 'components/analysis/region/snps';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Statistics = ({ analysis, geneRegions, isV1Array, snps }) => {
  const Language = useLanguage();

  return (
    <Fragment>
      {geneRegions.map((geneRegion) => (
        <Row key={geneRegion.name}>
          <Col span={24}>
            <Title level={2}>{Language.get('case', 'REGION_STATISTICS')}: {geneRegion.name}</Title>
          </Col>
          <Col offset={1} span={23}>
            <Row gutter={32}>
              <Col span={6}>
                <Detail
                  chromosome={geneRegion.chromosome}
                  isV1Array={isV1Array}
                  length={geneRegion.length}
                  start={geneRegion['start_at']}
                />
              </Col>
              <Col span={8}>
                <Disease analysis={analysis} geneRegion={geneRegion} />
              </Col>
              {Array.isArray(snps) ? <Col span={10}>
                <Snps snp={snps.find((snp) => snp.name === geneRegion.name)} />
              </Col> : null}
            </Row>
          </Col>
        </Row>
      ))}
    </Fragment>
  );
};
