// Importing react libs
import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Divider, Dropdown, Menu, message, Progress, Table, Tooltip } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/divider/style/css';
import 'antd/es/dropdown/style/css';
import 'antd/es/menu/style/css';
import 'antd/es/message/style/css';
import 'antd/es/progress/style/css';
import 'antd/es/table/style/css';
import 'antd/es/tooltip/style/css';

// Importing antd icons
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import BtnConfirm from 'helix/ui/btnConfirm';
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { ANALYSIS_STATUS_COLORS as COLORS } from 'styles/theme/colors';
import { Icon } from 'components/biomaterial';
import useDataExportFile from './useDataExportFile';

// Importing component style
import './list.scss';

const CaseMenu = ({ case: caseObj }) => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const accessioningLink = `/accessioning/${caseObj.id}`;
  const caseLink = `/case/${caseObj.id}`;
  const filesLink = `${caseLink}/files`;
  const handlePlannerClick = () => {
    return caseObj['plate_id']
      ? navigate(`/planner/${caseObj['plate_id']}`)
      : message.info(Language.get('case', 'NO_PLATE_FOUND_FOR_THE_SELECTED_CASE'));
  };
  const showCaseLink = caseObj.analyses?.length > 0;
  const { download, isLoading } = useDataExportFile({ caseIds: [caseObj.id] });

  return (
    <Menu
      items={[
        { key: 'accessioning', label: <Link to={accessioningLink}>{Language.get('accessioning', 'ACCESSIONING')}</Link> },
        { key: 'planner', label: Language.get('common', 'PLANNER'), onClick: handlePlannerClick },
        {
          disabled: !showCaseLink || isLoading,
          icon: isLoading ? <LoadingOutlined spin /> : null,
          key: 'export',
          label: Language.get('file', 'DATA_EXPORT_FILE'),
          onClick: download
        },
        ...(AppAPI.User.isDev ? [] : [{ key: 'files', label: <Link to={filesLink}>{Language.get('common', 'FILES')}</Link> }]),
        ...(showCaseLink ? [{ key: 'view', label: <Link to={caseLink}>{Language.get('common', 'VIEW')}</Link> }] : []),
      ]}
    />
  );
};
const AnalysisMenu = ({ analysisId, caseId }) => {
  const Language = useLanguage();
  const caseLink = `/case/${caseId}/analysis/${analysisId}`;
  const filesLink = `/case/${caseId}/files#analysis-${analysisId}`;
  return (
    <Menu
      items={[
        { key: 'view', label: <Link to={caseLink}>{Language.get('common', 'VIEW')}</Link> },
        ...(AppAPI.User.isDev ? [] : [{ key: 'files', label: <Link to={filesLink}>{Language.get('common', 'FILES')}</Link> }]),
      ]}
    />);
};

// Exporting component
const List = (props) => {
  const { name, limit: propsLimit, showMorePath: propsShowMorePath, isListPage } = props;
  const Language = useLanguage();
  const navigate = useNavigate();
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));

  const expandedRowRender = (record) => {
    const dataSource = record.analyses?.map((analysis) => {
      const {
        embryos,
        father,
        mother,
        reference,
      } = AppAPI.Biomaterial.byDesignation(analysis?.biomaterials);

      return {
        id: analysis.id,
        name: analysis.name,
        caseId: record['external_id'],
        refName: reference?.['biomaterial_name'],
        refType: reference?.['biomaterial_pedegree'],
        status: analysis.status,
        biomaterials: [
          father,
          mother,
          reference,
          ...embryos.sort((a, b) => a['biomaterial_id'] - b['biomaterial_id']),
        ]
      };
    });

    const columns = [{
      dataIndex: 'id',
      title: Language.get('accessioning', 'ANALYSIS_ID'),
      width: 120,
      render: (_value, row) => {
        return <Link to={`/case/${record.id}/analysis/${row.id}`}>{`${row.caseId}-${row.id}`}</Link>;
      },
    }, {
      dataIndex: 'refName',
      key: 'reference-name',
      title: Language.get('accessioning', 'REFERENCE_SAMPLE'),
      width: 190,
    }, {
      dataIndex: 'refType',
      key: 'reference-type',
      title: Language.get('accessioning', 'REFERENCE_TYPE'),
      render: (value) => value ? Language.get('biomaterial', value) : '',
      width: 180,
    }, {
      dataIndex: 'status',
      title: Language.get('common', 'STATUS'),
      render: (status) => {
        const ANALYSIS_STATUS = {
          'completed': { percent: 100, showIcon: true },
          'failed_embryos': { percent: 80, status: 'exception', showIcon: true },
          'failed_trio': { percent: 40, status: 'exception', showIcon: true },
          'processing_embryos': {
            percent: 80,
            success: { percent: 40, strokeColor: COLORS['completed_trio'] },
            status: 'active'
          },
          'processing_trio': { percent: 40, status: 'active' },
          'completed_trio': {
            percent: 40,
            success: { percent: 40, strokeColor: COLORS['completed_trio'] },
            showIcon: true
          },
          'waiting': { percent: 0 },
        };
        const progressProps = ANALYSIS_STATUS[status];
        return (
          <Tooltip title={Language.get('analysis', `ANALYSIS_STATUS_${status}`)}>
            <Progress
              showInfo={false}
              strokeColor={COLORS[status]}
              {...progressProps}
            />
          </Tooltip>
        );
      },
      width: 150,
    }, {
      dataIndex: 'biomaterials',
      title: Language.get('common', 'SAMPLES'),
      render: (biomaterials) => {
        return (
          <div className="icon-list">
            {biomaterials.map((biomaterial, index) => (
              <Fragment key={index}>
                {index === 3 && <Divider type="vertical" />}
                {biomaterial && <Icon tooltip biomaterial={biomaterial} />}
              </Fragment>
            ))}
          </div>
        );
      },
    }, {
      dataIndex: 'id',
      title: Language.get('common', 'ACTION'),
      width: 150,
      render: (id) => (
        <div className="icon-list">
          <Dropdown className="dropdown-label" overlay={<AnalysisMenu analysisId={id} caseId={record.id} />}>
            <span>Actions <DownOutlined /></span>
          </Dropdown>
          <BtnConfirm onConfirm={() => onDeleteAnalysis(id)} />
        </div>
      ),
    }];
    return <Table columns={columns} dataSource={dataSource} pagination={false} rowKey="id" />;
  };

  const fullListColumns = [
    {
      dataIndex: 'consultant',
      sorter: true,
      title: Language.get('case', 'CONSULTANT'),
      ...tableColumnFilterSearch(),
    },
    {
      dataIndex: 'centre',
      sorter: true,
      title: Language.get('case', 'REFERRING_CENTRE'),
      ...tableColumnFilterSearch(),
    }
  ];

  const columns = [{
    dataIndex: 'external_id',
    sorter: true,
    title: Language.get('case', 'CASE_ID'),
    render: (value, { id, analyses }) => analyses?.length ? <Link to={`/case/${id}`}>{value}</Link> : value,
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'mother_lastname',
    sorter: true,
    title: Language.get('case', 'LAST_NAME'),
  }, {
    dataIndex: 'mother_firstname',
    sorter: true,
    title: Language.get('case', 'FIRST_NAME'),
  }, ...(
    isListPage ? fullListColumns : []
  ), {
    dataIndex: 'created_on',
    sorter: true,
    title: Language.get('common', 'DATE_CREATED'),
    render: (value) => (new Date(value)).format(),
    ...tableColumnFilterDate()
  }, {
    dataIndex: 'updated_on',
    sorter: true,
    title: Language.get('common', 'DATE_MODIFIED'),
    render: (value) => (new Date(value)).format(),
    ...tableColumnFilterDate()
  }];

  const onDelete = async (item) => {
    const res = await AppAPI.Case.delete(item.id);
    if (res?.error) res.msg = Language.get('plate', res.msg);
    return res;
  };
  const onDeleteAnalysis = async (id) => {
    const res = await AppAPI.Analysis.delete(id);
    if (res.error) return message.error(Language.get('accessioning', res.msg));
    setListKey((Math.random() + 1).toString(36).substring(2));
  };
  const onEdit = async (item) => navigate(`/accessioning/${item.id}?edit=true`);
  const reader = async ({ filters, limit, offset, order }) => {
    const mapper = {};
    filters = Object.keys(filters).reduce((newFilters, field) => {
      newFilters[mapper[field] || field] = filters[field];
      return newFilters;
    }, {});
    limit = propsLimit || limit;
    order = order || '-updated_on';
    return await AppAPI.Case.list({ filters, limit, offset, order });
  };

  return (
    <div className="case-list-wrapper">
      <HelixList
        columns={columns}
        context="common"
        actions={[
          (record) => (
            <Dropdown className="dropdown-label" overlay={<CaseMenu case={record} />}>
              <span>Actions <DownOutlined /></span>
            </Dropdown>
          )
        ]}
        dataField="results"
        dataCountField="count"
        defaultSort={{ field: 'updated_on', order: 'descend' }}
        expandable={{ expandedRowRender }}
        key={listKey}
        name={name}
        pagination={false}
        reader={reader}
        rowKey="id"
        showMorePath={propsShowMorePath}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </div>
  );
};

List.propTypes = {
  name: PropTypes.string,
  showMorePath: PropTypes.string,
};

export { List };
