// Importing React and external libs
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing Antd
import { message } from 'antd';
import 'antd/es/message/style/css';

// Importing modules
import AppAPI from 'modules/api';
import { EVENT_SESSION_STARTED } from 'modules/session';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

export const ForgotPassword = () => {
  const Language = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const _validateToken = async () => {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const sesameToken = params.get('sesame');
      if (sesameToken) {
        const res = await AppAPI.User.validateToken({
          token: sesameToken,
        });
        if (res.error) {
          message.error(Language.get('user', res.msg));
          await AppAPI.User.logout();
          return navigate('/user/login');
        }
        window.app = { ...window.app, user: res };
        dispatchEvent(new CustomEvent(EVENT_SESSION_STARTED));
        window.location.href = '/user/profile';
        return;
      } else {
        await AppAPI.User.logout();
        return navigate('/user/login');
      }
    };
    _validateToken();
  }, []);

  return (
    <div className="password-reset">
      Reset password
    </div>
  );
};
