import Abstract from './abstract';

class Plate extends Abstract {
  constructor() {
    super();
    this.resource = 'plate';
    this._updateEndpoint();
  }
}

export default new Plate();
