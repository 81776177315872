// Importing react libs
import React from 'react';

// Importing antd libs
import { Typography } from 'antd';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing theme styles
import { RESULT_COLORS } from 'styles/theme/colors';

// Importing component style
import './snps.scss';

// Defining constants
const { Title } = Typography;

const AntTable = ({ children, className, ...restProps }) => (
  <div {...restProps} className={`${className} ant-table ant-table-bordered`}>
    <div className='ant-table-container'>
      <div className='ant-table-content'>
        <table>
          {children}
        </table>
      </div>
    </div>
  </div>
);
const AntHeadCell = (props) => <th {...props} className='ant-table-cell' />;
const AntTableCell = (props) => <td {...props} className='ant-table-cell' />;

// Exporting component
export const Snps = ({ kind, snps }) => {
  const Language = useLanguage();

  const tableTitle = Language.get('snps', kind);
  const tableTitleFirst = tableTitle[0];
  const columnTitle = (num) => `${tableTitle}-${tableTitleFirst}${num}`;

  const [snps1, snps2] = snps;

  return (
    <div className='snps-table-wrapper'>
      <Title level={3}>{tableTitle} SNPs</Title>
      <AntTable>
        <thead className='ant-table-thead'>
          <tr>
            <AntHeadCell></AntHeadCell>
            {['1', '2'].map(key => (
              <AntHeadCell
                key={key}
                colSpan={2}
                style={{
                  backgroundColor: RESULT_COLORS[`${kind}_${key}`]?.fill,
                  color: 'white',
                }}
              >{columnTitle(key)}
              </AntHeadCell>
            ))}
          </tr>
          <tr>
            <AntHeadCell>{Language.get('case', 'REGION')}</AntHeadCell>
            <AntHeadCell>{Language.get('snps', 'KEY')}</AntHeadCell>
            <AntHeadCell>{Language.get('snps', 'NON_KEY')}</AntHeadCell>
            <AntHeadCell>{Language.get('snps', 'Key')}</AntHeadCell>
            <AntHeadCell>{Language.get('snps', 'Non_Key')}</AntHeadCell>
          </tr>
        </thead>
        <tbody className='ant-table-tbody'>
          {['left', 'main', 'right'].map(field => (
            <tr key={field}>
              <AntTableCell>{Language.get('report', `${field}_FLANKING`)}</AntTableCell>
              <AntTableCell>{snps1.key[field]}</AntTableCell>
              <AntTableCell>{snps1.nonKey[field]}</AntTableCell>
              <AntTableCell>{snps2.key[field]}</AntTableCell>
              <AntTableCell>{snps2.nonKey[field]}</AntTableCell>
            </tr>
          ))}
        </tbody>

      </AntTable>
    </div>
  );
};
