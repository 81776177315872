// Importing React and external libs
import React from 'react';
import { NavLink } from 'react-router-dom';

// Importing antd libs
import { Tag } from 'antd';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix modules
import { chromosomes } from 'helix-modules/chromosome';

// Importing component style
import './chromNav.scss';

const CHROMS = chromosomes.map((chromosome) => chromosome.label);

export default function ChromNav({ path, isFullGenome, onClick, onClickFullGenome }) {
  const Language = useLanguage();
  const chrompath = `${(path || window.location.pathname).replace(/\/$/, '')}/chromosome`;
  return (
    <nav className="chromnav">
      <Tag
        className='full-genome-toggle'
        color="red"
        role="button"
        onClick={onClickFullGenome}
      >
        {Language.get('report', 'FULL_GENOME')}
      </Tag>
      <ul>
        {CHROMS.map((chrom) => (
          <li key={chrom}>
            <NavLink replace to={`${chrompath}/${chrom}`} onClick={() => onClick(`${chrompath}/${chrom}`)}>
              {({ isActive }) => (<Tag color={isActive && !isFullGenome ? 'blue' : null}>{chrom}</Tag>)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
