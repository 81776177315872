// Importing React
import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing Antd
import {
  Avatar,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Upload,
} from 'antd';
import 'antd/es/avatar/style/css';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/icon/style/css';
import 'antd/es/input/style/css';
import 'antd/es/input-number/style/css';
import 'antd/es/message/style/css';
import 'antd/es/select/style/css';
import 'antd/es/spin/style/css';
import 'antd/es/upload/style/css';

// Importing antd icons
import {
  SaveOutlined,
  UndoOutlined,
  UserOutlined
} from '@ant-design/icons';

// Importing helix-hooks
import useLanguage from 'helix-hooks/language';

// Importing helix-modules
import 'helix-modules/sString';

// Importing helix components
import Label from 'helix/ui/label';

// Importing modules
import AppAPI from 'modules/api';
import Permission from 'modules/permission';

// Importing app components
import {
  Analysis,
  BEAD_ARRAY_VERSION,
  INTENSITY_REFERENCE,
  SCANNING_HARDWARE,
  valueToKey
} from 'components/organization';

// Importing style
import './form.scss';

const DEFAULT_IDLE_TIME_SECONDS = 5 * 60;
const VALID_LOGO_TYPE = ['image/jpeg', 'image/png'];
const PDF_KEY_OPTIONS = {
  'CASE_ID': 'case_external_id',
  'SAMPLE_ID': 'name',
  'TUBE_ID': 'tube_id',
  'CYCLE_ID': 'cycle_id',
  'REQUISITION': 'requisition',
  'BLANK': 'blank',
};
export const LOG_LEVEL = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
};

// Component for save/reset/cancel button
const DisplayFormActions = (props) => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const { onReset } = props;
  return (
    <Fragment>
      <div className="form-container">
        <div className="form-action-buttons">
          <Button type="primary" onClick={onReset}>
            <UndoOutlined /> {Language.get('organization', 'RESTORE_DEFAULTS')}
          </Button>
        </div>
      </div>
      <div className="form-container">
        <div className="form-action-buttons">
          <Button onClick={() => navigate(-1)}>{Language.get('common', 'CANCEL')}</Button>
          <Button type="primary" htmlType="submit">
            <SaveOutlined /> {Language.get('common', 'SAVE')}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

// Exporting component
export const SettingForm = (props) => {
  const [form] = Form.useForm();
  const Language = useLanguage();
  const [logoLoading, setLogoLoading] = useState(false);
  const [organization, setOrganization] = useState(props.organization);
  const isAdmin = Permission.isHostAdmin();
  const canEdit = isAdmin || Permission.isLevel2();
  const { Item } = Form;
  const { TextArea } = Input;
  const isDigit = (str) => /^\d+$/.test(str);

  const OAUTH = JSON.parse(window.localStorage.getItem('oauth'));
  const ACCESS_TOKEN = OAUTH['access_token'];

  const CHARTS_OPTIONS = AppAPI.Organization.getSupportedCharts().map(chartKey => ({
    label: Language.get('chart', chartKey),
    value: chartKey,
  }));

  const { ANALYSIS, REPORT, MISCELLANEOUS } = organization?.setting ?? {};
  const defaultFlanking = 2;

  const initialValues = {
    address: organization?.address,
    beadArrayVersion: ANALYSIS?.BEAD_ARRAY_VERSION ?? BEAD_ARRAY_VERSION.KARYOMAP_V2,
    cnCallingOptions: ANALYSIS?.CN_CALLING_OPTIONS,
    disclaimer: REPORT?.DISCLAIMER,
    flankingRegion: ANALYSIS?.FLANKING_REGION ?? defaultFlanking,
    includedCharts: REPORT?.INCLUDED_CHARTS,
    intensityReference: ANALYSIS?.INTENSITY_REFERENCE ?? INTENSITY_REFERENCE.VERSION_1,
    logLevel: MISCELLANEOUS?.LOG_LEVEL ?? LOG_LEVEL.INFO,
    name: organization?.name,
    scanningHardware: ANALYSIS?.SCANNING_HARDWARE ?? SCANNING_HARDWARE.I_SCAN,
    sampleSummary: REPORT?.SAMPLE_SUMMARY,
    pdfKey1: REPORT?.PDF_KEY_1 ?? PDF_KEY_OPTIONS.CASE_ID,
    pdfKey2: REPORT?.PDF_KEY_2 ?? PDF_KEY_OPTIONS.SAMPLE_ID,
    pdfKey3: REPORT?.PDF_KEY_3 ?? PDF_KEY_OPTIONS.TUBE_ID,
    sessionTimeout: MISCELLANEOUS?.sessionTimeout ?? DEFAULT_IDLE_TIME_SECONDS,
  };

  const _beforeUploadLogo = async (file) => {
    if (VALID_LOGO_TYPE.indexOf(file.type) === -1) {
      message.error(Language.get('common', 'LOGO_NOT_VALID_FILE_TYPE'));
      return false;
    }

    if (file.size / 1024 / 1024 > 2) {
      message.error(Language.get('common', 'LOGO_FILE_TOO_LARGE'));
      return false;
    }

    // Initiate the FileReader object.
    const reader = new FileReader();
    // Read the contents of Image File.
    reader.readAsDataURL(file);
    const { height, width } = await new Promise((resolve) => {
      reader.onload = (event) => {
        const image = new Image();
        // Set the Base64 string return from FileReader as source.
        image.src = event.target.result;
        image.onload = () => {
          resolve({ height: image.height, width: image.width });
        };
      };
    });
    if (height > 570 || width > 1024) {
      message.error(Language.get('common', 'LOGO_FILE_DIMENSION_TOO_LARGE'));
      return false;
    }

    setLogoLoading(true);
    return true;
  };

  const _displayLogoSection = () => {
    const spinOrLogo = logoLoading
      ? (<Spin size="large" />)
      : (
        <Avatar
          icon={<UserOutlined />}
          size={100}
          src={organization?.logo ? `${organization.logo}?id=${(Math.random() + 1).toString(36).substring(2)}` : null}
        />
      );
    return canEdit
      ? <Upload
        accept={VALID_LOGO_TYPE.join(',')}
        action={`${AppAPI.Organization.constants.LOGO}?id=${organization.id}`}
        headers={{ Authorization: `Bearer ${ACCESS_TOKEN}` }}
        className="logo-uploader"
        listType="picture-card"
        name="logo"
        showUploadList={false}
        beforeUpload={_beforeUploadLogo}
        onChange={_onLogoChange}
      >
        {spinOrLogo}
      </Upload>
      : spinOrLogo;
  };

  const _onLogoChange = async (info) => {
    const { file } = info;
    if (file?.status === 'done' && file?.response?.logo) {
      setOrganization({ ...organization, logo: file.response.logo });
      setLogoLoading(false);
    }
  };

  const _reset = async () => {
    const defaultSettings = AppAPI.Organization.getHostSetting() ?? {};

    const { ANALYSIS, LANGUAGES } = defaultSettings;

    form.setFieldsValue({
      // ANALYSIS
      beadArrayVersion: BEAD_ARRAY_VERSION.KARYOMAP_V2,
      cnCallingOptions: ANALYSIS?.CN_CALLING_OPTIONS,
      flankingRegion: defaultFlanking,
      intensityReference: ANALYSIS?.INTENSITY_REFERENCE,
      scanningHardware: SCANNING_HARDWARE.I_SCAN,

      // REPORT
      address: '',
      disclaimer: '',
      includedCharts: [],
      sampleSummary: '',
      logo: null,
      pdfKey1: PDF_KEY_OPTIONS.CASE_ID,
      pdfKey2: PDF_KEY_OPTIONS.SAMPLE_ID,
      pdfKey3: PDF_KEY_OPTIONS.TUBE_ID,

      // LANGUAGES
      languages: LANGUAGES,

      // MISCELLANEOUS
      logLevel: LOG_LEVEL.INFO,
      sessionTimeout: DEFAULT_IDLE_TIME_SECONDS,
    });

    setOrganization({ ...organization, logo: null });
  };

  const onFinish = async (values) => {
    const { organization: propsOrg, onAfterSave } = props;
    const newSetting = {
      ANALYSIS: {
        'BEAD_ARRAY_VERSION': values.beadArrayVersion,
        'CN_CALLING_OPTIONS': values.cnCallingOptions,
        'FLANKING_REGION': values.flankingRegion,
        'INTENSITY_REFERENCE': values.intensityReference,
        'SCANNING_HARDWARE': values.scanningHardware,
      },
      REPORT: {
        'DISCLAIMER': values.disclaimer,
        'INCLUDED_CHARTS': values.includedCharts,
        'SAMPLE_SUMMARY': values.sampleSummary,
        'PDF_KEY_1': values.pdfKey1,
        'PDF_KEY_2': values.pdfKey2,
        'PDF_KEY_3': values.pdfKey3,
      },
      LANGUAGES: values.languages,
      MISCELLANEOUS: {
        'LOG_LEVEL': values.logLevel,
        sessionTimeout: values.sessionTimeout,
      }
    };
    const res = await AppAPI.Organization.save({
      address: values.address,
      disclaimer: values.disclaimer,
      id: propsOrg.id,
      ...(isAdmin && { name: values.name || propsOrg.name }),
      setting: newSetting,
      logo: organization.logo
    });
    if (res.error) {
      return message.error(`${res.field ? res.field?.toUpperCase() + ': ' : ''} ${Language.get('common', res.msg)}`);
    }
    message.success(Language.get('common', 'DATA_SAVED_SUCCESFULLY'));
    return onAfterSave && typeof onAfterSave === 'function' && onAfterSave(res);
  };

  return (
    <div className="organization-setting">
      <Form
        className="organization-setting-form"
        form={form}
        initialValues={initialValues}
        name="organization-form"
        validateMessages={{
          required: Language.get('common', 'NOT_VALID_EMPTY_VALUE'),
        }}
        onFinish={onFinish}
      >
        <div className="row">
          <div className="col">
            <div className="organization-setting-header">
              <h1>{Language.get('organization', 'ORGANIZATION')}</h1>
            </div>
            <div className="form-container">
              <Item
                colon={false}
                label={<Label name={Language.get('common', 'NAME')} />}
                name="name"
                rules={[{ required: true }]}
              >
                {isAdmin
                  ? <Input />
                  : initialValues.name}
              </Item>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="organization-setting-header">
              <h1>{Language.get('organization', 'REPORT_SETTINGS')}</h1>
            </div>
            <div className="form-container">
              {organization?.id && isDigit(organization.id) && (
                <Item colon={false} label={
                  <Label name={Language.get('common', 'LOGO')} title={Language.get('common', 'LOGO_INFO')} />
                }>
                  {_displayLogoSection()}
                </Item>
              )}
              <Item
                colon={false}
                label={<Label name={Language.get('common', 'ADDRESS')} title={Language.get('common', 'ADDRESS_INFO')} />}
                name="address"
              >
                {canEdit
                  ? <TextArea rows={6} />
                  : initialValues.address}
              </Item>
              <Item
                colon={false}
                label={Language.get('organization', 'INCLUDED_CHARTS')}
                name="includedCharts"
              >
                <Checkbox.Group options={CHARTS_OPTIONS} disabled={!canEdit} />
              </Item>
            </div>
          </div>
          <div className="col">
            <div className="form-container">
              <Item
                colon={false}
                label={<Label name={Language.get('organization', 'DISCLAIMER')} title={Language.get('organization', 'DISCLAIMER_INFO')} />}
                name="disclaimer"
              >
                {canEdit
                  ? <Input />
                  : initialValues.disclaimer}
              </Item>
              <Item
                colon={false}
                label={<Label name={Language.get('organization', 'REPORT_INTRODUCTION')} title={Language.get('organization', 'REPORT_INTRODUCTION_INFO')} />}
                name="sampleSummary"
              >
                {canEdit
                  ? <TextArea rows={10} />
                  : initialValues.sampleSummary
                }
              </Item>
              {[1, 2, 3].map(num => (
                <Item
                  key={num}
                  colon={false}
                  label={<Label name={Language.get('organization', `PDF_GUIDE_KEY_${num}`)} title={Language.get('organization', `PDF_GUIDE_KEY_${num}_INFO`)} />}
                  name={`pdfKey${num}`}
                  rules={[{ required: true }]}
                >
                  <Select
                    disabled={!canEdit}
                    options={Object.entries(PDF_KEY_OPTIONS).map(
                      ([labelKey, value]) => ({ label: Language.get('biomaterial', labelKey), value }))
                    }
                  />
                </Item>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Analysis canEdit={canEdit} initialValues={initialValues} />
          </div>
          <div className="col">
            <div className="organization-setting-header">
              <h1>{Language.get('organization', 'MISCELLANEOUS')}</h1>
            </div>
            <div className='form-container'>
              <Item
                colon={false}
                label={
                  <Label
                    name={Language.get('organization', 'SESSION_TIMEOUT')}
                    title={Language.get('organization', 'SESSION_TIMEOUT_INFO')}
                  />
                }
                name="sessionTimeout"
                rules={[
                  { required: true },
                  { max: 3600, type: 'number' },
                  { min: 60, type: 'number' }
                ]}
              >
                {canEdit
                  ? <InputNumber />
                  : initialValues.sessionTimeout}
              </Item>
              <Item
                colon={false}
                label={<Label name={Language.get('organization', 'LOG_LEVEL')} title={Language.get('organization', 'LOG_LEVEL_INFO')} />}
                name="logLevel"
                rules={[{ required: true }]}
              >
                {canEdit
                  ? (
                    <Select>
                      {Object.entries(LOG_LEVEL).map(([languageKey, item]) => (
                        <Select.Option key={item} value={item}>{Language.get('organization', languageKey)}</Select.Option>
                      ))}
                    </Select>
                  )
                  : Language.get('organization', valueToKey(LOG_LEVEL, initialValues.logLevel) || 'NA')
                }
              </Item>
            </div>
          </div>
        </div>
        <div>
          {canEdit && (<DisplayFormActions onReset={_reset} />)}
        </div>
      </Form>
    </div>
  );
};
