// Importing react libs
import React from 'react';

// Importing antd libs
import { Button, Popconfirm, Tooltip } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/popconfirm/style/css';
import 'antd/es/tooltip/style/css';

// Importing antd icons
import Icon from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app helpers
import { Notification } from 'helper/handler/notification';

export const ReAnalyzeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" version="1.0" y="0" x="0" fill="currentColor" width="1em" height="1em">
    <g transform="matrix(.96875 0 0 .98751 1.2501 -.25079)" fillRule="evenodd">
      <path d="m54.962 37.281l17.138-10.262 7.678 14.835c1.855 8.105-8.309 11.079-16.47 10.596l-8.346-15.169z" />
      <path d="m51.067 47.877l-9.013 15.95 9.013 16.173 0.223-6.469h8.235c3.005 0.26 6.899-1.711 8.346-4.573l10.572-19.408c-3.487 3.458-7.971 4.35-13.131 4.35h-13.911l-0.334-6.023z" />
      <path d="m30.928 28.212l-17.269-10.037 9.213-13.93c6.17-5.5589 13.698 1.9041 17.237 9.29l-9.181 14.677z" />
      <path d="m42.062 26.482l18.288 0.157 9.732-15.749-5.751 2.945-3.997-7.2167c-1.231-2.7593-4.84-5.216-8.04-5.0942l-22.06 0.156c4.708 1.3773 7.663 4.8732 10.168 9.3959l6.752 12.19-5.092 3.216z" />
      <path d="m0.44401 27.381l5.3508 4.101-5.036 9.464c-2.5181 4.365 1.8077 8.616 4.5639 10.096 2.7133 1.457 6.9243 1.63 10.859 1.577l7.082-11.357 5.35 2.839-9.285-16.878-18.885 0.158z" />
      <path d="m1.2309 49.307l11.488 20.821c2.309 2.892 6.663 3.576 11.174 3.471h12.118v-19.718l-22.977-0.158c-3.5671 0.211-8.2359-0.525-11.803-4.416z" />
    </g>
  </svg>
);

export default function ReAnalyze({
  analysis,
  showIcon = true,
  showText = true,
  onSuccess,
  onError,
  ...restProps
}) {
  const Language = useLanguage();

  const handleReanalyze = async () => {
    const res = await AppAPI.Analysis.reanalyze(analysis.id);
    if (res.error) {
      Notification.error(Language, res);
      onError?.(res);
    } else {
      Notification.success(Language, 'analysis', 'REANALYSIS_TRIGGERED');
      onSuccess?.(res);
    }
  };

  return (
    <Popconfirm
      cancelText={Language.get('common', 'NO')}
      okText={Language.get('common', 'Yes')}
      title={Language.get('analysis', 'CONFIRM_REANALYZE')}
      onConfirm={handleReanalyze}
    >
      <Tooltip placement="bottom" title={showText ? null : Language.get('analysis', 'REANALYZE')}>
        <Button
          type="primary"
          danger={analysis['is_stale']}
          icon={showIcon ? <Icon component={ReAnalyzeIcon} /> : null}
          {...restProps}
        >
          {showText ? Language.get('analysis', 'REANALYZE') : null}
        </Button>
      </Tooltip>
    </Popconfirm>
  );
}