// Importing React
import React from 'react';

// Importing Antd
import {
  Form,
  InputNumber,
  Select,
} from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/input-number/style/css';
import 'antd/es/select/style/css';

// Importing helix-hooks
import useLanguage from 'helix-hooks/language';

// Importing helix-modules
import 'helix-modules/sString';

// Importing helix components
import Label from 'helix/ui/label';

// Importing style
import './analysis.scss';

export const SCANNING_HARDWARE = {
  NEXT_SEQ: 'NextSeq',
  I_SCAN: 'iScan',
};
export const INTENSITY_REFERENCE = {
  VERSION_1: 'Version-1',
};
export const BEAD_ARRAY_VERSION = {
  KARYOMAP_V2: 'KaryoMap-v2-1_A1.bpm',
};

export const valueToKey = (obj, value) => Object.keys(obj).find((key) => obj[key] === value);

// Analysis Settings Component
export const Analysis = (props) => {
  const Language = useLanguage();
  const { canEdit, initialValues } = props;
  const { Item } = Form;
  const { Option } = Select;

  return (
    <div className="analysis-setting">
      <div className="organization-setting-header">
        <h1>{Language.get('organization', 'ANALYSES_SETTINGS')}</h1>
      </div>
      <div className="form-container">
        <Item
          colon={false}
          label={<Label name={Language.get('organization', 'FLANKING_REGION')} title={Language.get('organization', 'FLANKING_REGION_INFO')} />}
          name="flankingRegion"
          rules={[
            { message: Language.get('login', 'NOT_VALID_EMPTY_VALUE'), required: true },
            { message: Language.get('login', 'INPUT_IS_NOT_VALID_NUMBER'), type: 'number' },
          ]}
        >
          {canEdit
            ? <InputNumber max={5} min={0.5} step={0.5} />
            : initialValues.flankingRegion
          }
        </Item>
        <Item
          colon={false}
          label={<Label name={Language.get('organization', 'INTENSITY_REFERENCE')} title={Language.get('organization', 'INTENSITY_REFERENCE_INFO')} />}
          name="intensityReference"
          style={{ display: 'none' }} // TODO: delete line in version 2
          rules={[{ required: false }]} // TODO: make required-true in verison 2
        >
          {canEdit
            ? (
              <Select>
                <Option value="Version-1">{Language.get('organization', 'VERSION_1')}</Option>
              </Select>
            )
            : Language.get('organization', valueToKey(INTENSITY_REFERENCE, initialValues.intensityReference))
          }
        </Item>
        <Item
          colon={false}
          label={<Label name={Language.get('organization', 'SCANNING_HARDWARE')} title={Language.get('organization', 'SCANNING_HARDWARE_INFO')} />}
          name="scanningHardware"
          rules={[{ required: true }]}
        >
          {canEdit
            ? (
              <Select>
                {Object.entries(SCANNING_HARDWARE).map(([languageKey, item]) => (
                  <Option key={item} value={item}>{Language.get('organization', languageKey)}</Option>
                ))}
              </Select>
            )
            : Language.get('organization', valueToKey(SCANNING_HARDWARE, initialValues.scanningHardware) || 'NA')
          }
        </Item>
        <Item
          className="extra-left-margin"
          colon={false}
          label={<Label name={Language.get('organization', 'CN_CALLING_OPTIONS')} title={Language.get('common', 'CN_CALLING_OPTIONS_INFO')} />}
          name="cnCallingOptions"
          style={{ display: 'none' }} // TODO: delete line in version 2
          rules={[{ required: false }]} // TODO: make required-true in verison 2
        >
          {canEdit
            ? <InputNumber max={4} min={2.1} step={0.1} />
            : initialValues.cnCallingOptions
          }
        </Item>
        <Item
          colon={false}
          label={<Label name={Language.get('organization', 'BEAD_ARRAY_VERSION')} title={Language.get('organization', 'BEAD_ARRAY_VERSION_INFO')} />}
          name="beadArrayVersion"
          rules={[{ required: true }]}
        >
          {canEdit
            ? (
              <Select>
                {Object.entries(BEAD_ARRAY_VERSION).map(([languageKey, item]) => (
                  <Option key={item} value={item}>{Language.get('organization', languageKey)}</Option>
                ))}
              </Select>
            )
            : Language.get('organization', valueToKey(BEAD_ARRAY_VERSION, initialValues.beadArrayVersion) || 'NA')
          }
        </Item>
      </div>
    </div>
  );
};
