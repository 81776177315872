import React from 'react';
import { useParams } from 'react-router-dom';
import { message, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import 'antd/es/message/style/css';
import 'antd/es/spin/style/css';

// Importing helix
import HelixError from 'helix/ui/error';
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import {
  ANALYSIS_IN_PROGRESS_STATUSES,
  CASE_REFRESH_INCOMPLETE_MS,
  CASE_REFRESH_COMPLETE_MS,
  View
} from 'components/case';

// Importing container styles
import './index.scss';

export const CaseView = () => {
  const { id } = useParams();
  const Language = useLanguage();

  const _loadData = async (caseId) => {
    const [res, geneRegionsRes] = await Promise.all([
      AppAPI.Case.get(caseId),
      AppAPI.GeneRegion.list({ filters: { 'case': caseId } })
    ]);
    res.geneRegions = (Array.isArray(geneRegionsRes.results) ? geneRegionsRes.results : []).map(
      region => ({ ...region, chromosome: { '23': 'X', '24': 'Y' }[region.chromosome] ?? region.chromosome })
    );
    res.analyses = (Array.isArray(res.analyses) ? res.analyses : []).map(AppAPI.Analysis.parseAnalysisForReport);
    if (res.error) {
      return message.error(Language.get('common', res.msg));
    } else if (res.analyses.length === 0) {
      return message.error(Language.get('common', 'ANALYSIS_NOT_AVAILABLE'));
    }
    return res;
  };

  const { data: caseObj, error, isLoading, isError } = useQuery({
    queryKey: ['case', id],
    queryFn: ({ queryKey }) => _loadData(queryKey[1]),
    enabled: Boolean(id),
    refetchInterval: (data) => {
      if (data) {
        const isInProgress = data.analyses.some(analysis => ANALYSIS_IN_PROGRESS_STATUSES.includes(analysis.status));
        return isInProgress ? CASE_REFRESH_INCOMPLETE_MS : CASE_REFRESH_COMPLETE_MS;
      } else {
        return false;
      }
    },
  });


  return isLoading
    ? <Spin />
    : (
      <div className="caseView">
        {isError
          ? <HelixError message={error?.message ?? error} />
          : (<View caseObj={caseObj} loadCaseData={() => _loadData(id)} />)
        }
      </div>
    );
};
