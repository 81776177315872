// Importing app helper
import { preventUndefined, tableHeader } from './common';

export const dnaTable = ({ biomaterials, i18n }) => {
  const data = biomaterials.map((biomaterial) =>
    preventUndefined([
      biomaterial['biomaterial_name'],
      i18n(biomaterial['biomaterial_designation'] || 'SAMPLE', 'biomaterial'),
      i18n(biomaterial['biomaterial_bio_type'], 'biomaterial'),
      biomaterial['biomaterial_metadata']?.['volume_ul'],
      biomaterial['biomaterial_metadata']?.['concentration_ngul'],
      biomaterial['biomaterial_metadata']?.['260_280'],
      biomaterial['biomaterial_metadata']?.['260_230'],
    ])
  );

  const headerColumns = [
    { title: i18n('SAMPLE_ID'), width: 'auto' },
    { title: i18n('DESIGNATION'), width: 'auto' },
    { title: i18n('SAMPLE_TYPE'), width: '*' },
    { title: i18n('SAMPLE_VOLUME'), width: '*' },
    { title: i18n('CONCENTRATION'), width: '*' },
    { title: i18n('260_280'), width: 'auto' },
    { title: i18n('260_230'), width: 'auto' },
  ];

  return {
    fontSize: 7,
    table: {
      body: [
        tableHeader(headerColumns.map(({ title }) => title)),
        ...data,
      ],
      widths: headerColumns.map(({ width }) => width),
      headerRows: 1,
    },
  };
};
