// Importing react libs
import React, { useState } from 'react';

// Importing antd libs
import { Form, Modal } from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/modal/style/css';

// Importing app components
import { SignoffForm } from 'components/analysis/signoff/form';
import { Notification } from 'helper/handler/notification';

// Importing app components
import AppAPI from 'modules/api';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Exporting component
export const SignoffModal = ({ caseObj, open, onSignoffSent, onClose }) => {
  const Language = useLanguage();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSignoff = () => {
    setConfirmLoading(true);
    form.validateFields().then(async (values) => {
      const res = await AppAPI.Case.signoff(caseObj.id, {
        notes: values.notes,
        'is_active': true,
        password: values.password,
        username: values.username,
      });
      if (res.error) {
        Notification.error(Language, res, ['login', 'common']);
      } else {
        Notification.success(Language);
        onSignoffSent?.();
        onClose?.();
      }
    }).finally(
      () => setConfirmLoading(false)
    );
  };

  return (
    <Modal
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      open={open}
      title={`${caseObj['external_id']} - ${Language.get('signoff', 'MODAL_TITLE')}`}
      width={600}
      onCancel={onClose}
      onOk={handleSignoff}
    >
      <SignoffForm caseObj={caseObj} form={form} />
    </Modal>
  );
};
