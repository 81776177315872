// Importing app helper
import { tableHeader } from './common';

export const beadArray = ({ biomaterials, i18n }) => {
  const data = biomaterials.map(({ stats, ...biomaterial }) => [
    biomaterial['beadchip_barcode'],
    biomaterial['biomaterial_subarray'],
    biomaterial['biomaterial_name'],
    i18n(biomaterial['biomaterial_pedegree'], 'biomaterial'),
    i18n(biomaterial['biomaterial_bio_type'], 'biomaterial'),
    stats?.qc ?? '',
    stats?.callRate ?? '',
    stats?.aaRate ?? '',
    stats?.abRate ?? '',
    stats?.bbRate ?? '',
    stats?.adoRate ?? '',
    stats?.misCallRate ?? '',
    stats?.xHetRate ?? '',
    stats?.yCallRate ?? '',
  ]);
  return {
    fontSize: 7,
    table: {
      body: [
        tableHeader([
          i18n('BEADCHIP_BARCODE'),
          i18n('SUBARRAY'),
          i18n('SAMPLE_ID'),
          i18n('PEDIGREE'),
          i18n('SAMPLE_TYPE'),
          i18n('QC_STATUS'),
          i18n('CALL_RATE'),
          i18n('AA'),
          i18n('AB'),
          i18n('BB'),
          i18n('ADO'),
          i18n('MIS_CALL'),
          i18n('X_HET_RATE'),
          i18n('Y_CALL_RATE'),
        ]),
        ...data,
      ],
      headerRows: 1,
    },
  };
};
