// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing antd libs
import { Button, Form, Input, Modal, Popconfirm } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/popconfirm/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { Guide } from './guide';

// Importing component style
import './summary.scss';

// Defining constants
const { Item } = Form;

// Exporting component
export const Summary = (props) => {
  const { beadchips, form: formProps, plate, onUpdateBarcode, onUpdateLotBox, wells } = props;
  const Language = useLanguage();
  const navigate = useNavigate();
  const [form] = formProps ? [formProps] : Form.useForm();
  const [barcodes, setBarcodes] = useState();
  const [metadata, setMetadata] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    if (beadchips && plate) {
      // set barcodes
      const cols = Object.keys(beadchips);
      const newBarcodes = cols.reduce((newBarcodes, x) => {
        const { barcode, col } = beadchips[x];
        newBarcodes[`barcode_${col}`] = barcode;
        return newBarcodes;
      }, {});
      form.setFieldsValue(newBarcodes);
      setBarcodes(newBarcodes);

      // set lot_box [should be the same for all the beadchips]
      const newMetadata = beadchips[cols[0]]?.metadata || {};
      form.setFieldsValue(newMetadata);
      setMetadata(newMetadata);
    }
  }, [beadchips, plate]);

  const _onGeneratePlanner = () => {
    if (!plate?.pooled) {
      form.submit();
    }
    setShowPreviewModal(true);
  };

  const disabled = !beadchips || Object.keys(beadchips).length === 0;

  const itemColumns = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  return (
    <div className="plate-summary">
      <Item hidden name="pooled"><Input /></Item>
      {Object.keys(beadchips).map((x) => {
        const { col } = beadchips[x];
        const fieldName = `barcode_${col}`;
        return (
          <Item
            {...itemColumns}
            key={col}
            label={`${Language.get('plate', 'BEADCHIP_BARCODE')} ${col}`}
            name={fieldName}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  return /^\d{12}$/.test(value)
                    ? Promise.resolve()
                    : Promise.reject(Language.get('plate', 'NOT_VALID_BEADCHIP_BARCODE_LENGTH'));
                }
              },
              {
                validator: (_, value) => value && Object.values(barcodes).filter((item) => item === value).length >= 2
                  ? Promise.reject(new Error(Language.get('plate', 'DUPLICATE_BARCODE_NOT_ALLOWED')))
                  : Promise.resolve()
              }
            ]}
            onChange={onUpdateBarcode}
            validateTrigger="onSubmit"
          >
            {!plate?.pooled
              ? <Input maxLength={12} />
              : barcodes?.[fieldName]
            }
          </Item>
        );
      })}

      {[...Array(5)].map((_, index) => {
        const fieldName = `lot_box_${index}`;
        return (
          <Item
            {...itemColumns}
            key={index}
            label={Language.get('plate', `LOT_BOX_${index + 1}`)}
            name={fieldName}
            onChange={onUpdateLotBox}
          >
            {!plate?.pooled
              ? <Input onChange={onUpdateLotBox} />
              : metadata?.[fieldName]
            }
          </Item>
        );
      })}

      <div className="summary-btns">
        {!plate?.pooled && (
          <Fragment>
            <Button disabled={disabled} type="primary" onClick={form.submit}>{Language.get('common', 'SAVE')}</Button>
            <Popconfirm
              cancelText={Language.get('common', 'NO')}
              disabled={disabled}
              okText={Language.get('common', 'YES')}
              title={Language.get('plate', 'CONFIRM_FINALIZE')}
              onConfirm={() => {
                form.setFieldsValue({ pooled: true });
                form.submit();
              }}
            >
              <Button disabled={disabled} type="primary">{Language.get('plate', 'FINALIZE')}</Button>
            </Popconfirm>
          </Fragment>
        )}
        <Button
          disabled={disabled}
          type="primary"
          onClick={_onGeneratePlanner}
        >
          {Language.get('plate', 'GENERATE_PLANNER')}
        </Button>
        {!plate?.pooled && (
          <Popconfirm
            cancelText={Language.get('common', 'NO')}
            okText={Language.get('common', 'YES')}
            title={Language.get('plate', 'CONFIRM_CANCEL')}
            onConfirm={() => navigate('/planner')}
          >
            <Button>{Language.get('common', 'CANCEL')}</Button>
          </Popconfirm>
        )}
      </div>
      <Modal
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={() => {
            setShowPreviewModal(false);
          }}>
            {Language.get('common', 'OK')}
          </Button>,
        ]}
        height="100"
        style={{ top: 0 }}
        open={showPreviewModal}
        width="100"
        onCancel={() => {
          setShowPreviewModal(false);
        }}
      >
        <Guide beadchips={beadchips} inModal={true} plate={plate} wells={wells} />
      </Modal>
    </div>
  );
};
