// Importing react libs
import React from 'react';

// Importing antd libs
import { Divider, Space, Typography } from 'antd';
import 'antd/es/divider/style/css';
import 'antd/es/space/style/css';
import 'antd/es/typography/style/css';

// Importing app components
import { BeadArrays } from 'components/analysis/beadArrays';
import { DNA } from 'components/analysis/dna';
import { Settings } from 'components/analysis/settings';
import { Statistics } from 'components/analysis/region/statistics';
import { Summary } from 'components/case/summary';
import { Warning } from 'components/analysis/warning';
import useFlanking from 'hooks/useFlanking';

// Importing helix components
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Preview = ({ caseObj, analysis }) => {
  const { biomaterialsByDesignation, warningsBySample } = analysis;
  const referenceStats = biomaterialsByDesignation.reference?.stats ?? { trioSnps: [] };
  const Language = useLanguage();
  const flanking = useFlanking({ analysisSetting: analysis.setting });

  return (
    <Space direction="vertical" size={32}>
      <div>
        <Title level={1}>{Language.get('report', 'ANALYSIS_REPORT')}</Title>
        <Title level={2}>{Language.get('report', 'WARNINGS')}</Title>
        {Object.entries(warningsBySample).map(([title, warnings]) => (
          <Warning
            key={title}
            title={title}
            warnings={warnings}
          />
        ))}
      </div>
      <Summary
        analysis={analysis}
        biomaterials={biomaterialsByDesignation}
        caseObj={caseObj}
        regions={caseObj.geneRegions}
      />
      <Settings analysis={analysis} flanking={flanking} isV1Array={caseObj['is_v1_array']} />
      <Divider />
      <BeadArrays biomaterials={biomaterialsByDesignation} />
      <DNA biomaterials={biomaterialsByDesignation} />
      <Divider />
      <Statistics
        analysis={analysis}
        geneRegions={caseObj.geneRegions}
        isV1Array={caseObj['is_v1_array']}
        snps={referenceStats.trioSnps}
      />
      <Divider />
    </Space>
  );
};
