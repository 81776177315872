// Importing react libs
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import moment from 'moment';

// Importing React DND
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Importing antd libs
import {
  Card,
  Col,
  Descriptions,
  Form,
  message,
  Row,
} from 'antd';
import 'antd/es/card/style/css';
import 'antd/es/col/style/css';
import 'antd/es/descriptions/style/css';
import 'antd/es/form/style/css';
import 'antd/es/message/style/css';
import 'antd/es/row/style/css';

// Import Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import { getCoord, getPosition } from 'helix/chart/plate';
import Error from 'helix/ui/error';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { Beadchip, Builder, Summary } from 'components/plate';
import { Selector } from 'components/case';

// Importing container styles
import './builder.scss';

// Defining constants
const { Item } = Descriptions;

const MIN_PREPARED_ON_DATE = '2023-01-01';

// Exporting component
export const PlannerBuilder = () => {
  const [form] = Form.useForm();
  const Language = useLanguage();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [error, setError] = useState(undefined);
  const [beadchips, setBeadchips] = useState({});
  const [plate, setPlate] = useState(undefined);
  const [startingColumn, setStartingColumn] = useState(1);
  const [wells, setWells] = useState([]);

  const onFinish = async (values) => {
    // validate plate['prepared_on'] to be today or greater
    if (!plate.id && moment(`${plate['prepared_on']} 23:59:59`) < moment(MIN_PREPARED_ON_DATE)) {
      return message.error(Language.get('plate', 'NOT_VALID_PREPARED_ON_DATE'));
    }
    // prepare the payload
    const payload = {
      id,
      beadchips: Object.keys(beadchips).reduce((chips, x) => {
        const { barcode, metadata } = beadchips[x];
        chips.push({
          barcode,
          metadata,
          biomaterials: Object.keys(beadchips[x])
            .filter((key) => ['barcode', 'col', 'metadata'].indexOf(key) === -1)
            .map((y) => ({
              id: beadchips[x][y].id,
              'plate_position': getCoord({ x: Number(x), y: Number(y) }),
            })),
        });
        return chips;
      }, []),
      'external_id': plate['external_id'],
    };
    if (!id) {
      payload['prepared_on'] = plate['prepared_on'];
    }
    if (!plate.pooled) {
      payload.pooled = values.pooled;
    }
    const res = await AppAPI.Plate.save(payload);
    if (res.error) return message.error(Language.get('plate', res.msg));
    if (payload.pooled) return navigate('/planner');
    navigate(`/planner/${res.id}`);
    message.success(Language.get('common', 'DATA_SAVED_SUCCESFULLY'));
  };

  const _getMetadataFromFields = () => {
    return [...Array(5)].reduce((metadata, _, index) => {
      metadata[`lot_box_${index}`] = form.getFieldValue(`lot_box_${index}`);
      return metadata;
    }, {});
  };

  const _loadPlate = async () => {
    const res = await AppAPI.Plate.get(id);
    if (res.error) return setError(Language.get('common', res.msg));
    setPlate(res);
  };

  const _onUpdateBarcode = () => {
    const newSetOfBeadchips = Object.keys(beadchips).reduce((newSetOfBeadchips, x) => {
      const { col } = beadchips[x];
      beadchips[x].barcode = form.getFieldValue(`barcode_${col}`);
      newSetOfBeadchips[x] = beadchips[x];
      return newSetOfBeadchips;
    }, {});
    const newWells = wells.map((well) => {
      well['beadchip_barcode'] = newSetOfBeadchips[well.x]?.barcode;
      return well;
    });
    setBeadchips(newSetOfBeadchips);
    _setWells(newWells);
  };

  const _onUpdateLotBox = () => {
    const newSetOfBeadchips = Object.keys(beadchips).reduce((newSetOfBeadchips, x) => {
      beadchips[x].metadata = _getMetadataFromFields();
      newSetOfBeadchips[x] = beadchips[x];
      return newSetOfBeadchips;
    }, {});
    setBeadchips(newSetOfBeadchips);
  };

  const _onUpdatePlate = (wells) => {
    // set biomaterial in beadchips
    const newSetOfBeadchips = wells.reduce((newSetOfBeadchips, well) => {
      const col = well.x + 1;
      const barcode = beadchips[well.x]?.barcode || `0000000000${col < 9 ? '0' : ''}${col}`;
      const metadata = _getMetadataFromFields();
      well['beadchip_barcode'] = barcode;
      newSetOfBeadchips[well.x] = newSetOfBeadchips[well.x] || { barcode, col, metadata };
      newSetOfBeadchips[well.x][well.y] = {
        id: well.id,
        name: well.name,
        x: well.x,
        y: well.y,
      };
      return newSetOfBeadchips;
    }, {});
    setBeadchips(newSetOfBeadchips);
    _setWells(wells);
  };

  const _setWells = (wells) => {
    wells.sort((a, b) => a['plate_position'].localeCompare(b['plate_position']));
    setWells(wells);
  };

  useEffect(() => {
    if (id) _loadPlate();
    else setPlate({
      'external_id': searchParams.get('external_id'),
      'prepared_on': searchParams.get('prepared_on'),
    });
  }, []);

  useEffect(() => {
    if (plate?.beadchips) {
      const beadchips = {};
      const wells = plate?.beadchips.reduce((wells, beadchip) => {
        const newWells = beadchip.biomaterials.map((biomaterial) => {
          const { x, y } = getPosition(biomaterial['plate_position']);
          beadchips[x] = beadchips[x] || {};
          beadchips[x].barcode = beadchip.barcode;
          beadchips[x].col = x + 1;
          beadchips[x].metadata = beadchip.metadata;
          beadchips[x][y] = {
            id: biomaterial.id,
            name: biomaterial.name,
            x,
            y,
          };
          return {
            ...biomaterial,
            ['beadchip_barcode']: beadchip.barcode,
            ...{ x, y },
          };
        });
        return wells.concat(newWells);
      }, []);
      setBeadchips(beadchips);
      _setWells(wells);
    }
  }, [plate]);

  return (
    <div className="plate-planner">
      <h1>{Language.get('planner', 'PLATE_DEFINITION')}</h1>
      {error
        ? <Error message={error} />
        : (
          <Form form={form} name="plate-form" onFinish={onFinish}>
            <Descriptions column={2}>
              <Item label={Language.get('plate', 'PLATE_ID').toUpperCase()}>{plate?.['external_id']}</Item>
              <Item label={Language.get('plate', 'OPERATOR').toUpperCase()}>{plate?.author}</Item>
              <Item label={Language.get('plate', 'PREPARATION_DATE').toUpperCase()}>{(new Date(plate?.['prepared_on'])).format('date')}</Item>
            </Descriptions>
            <DndProvider backend={HTML5Backend}>
              <Row>
                {!plate?.pooled && (
                  <Col span={6}>
                    <Card
                      bordered={false}
                      className="card-title-align"
                      title={<h2>{Language.get('planner', 'AVAILABLE_SAMPLES')}</h2>}
                    >
                      <Selector plate={plate} startingColumn={startingColumn} wells={wells} onUpdate={_onUpdatePlate} />
                    </Card>
                  </Col>
                )}
                <Col span={!plate?.pooled ? 12 : 18}>
                  <Card
                    bordered={false}
                    className="card-title-align"
                    title={<h2>{Language.get('planner', 'PLATE_BUILDER')}</h2>}
                  >
                    <div className="plate-builder-wrapper">
                      <Builder
                        plate={plate}
                        wells={wells}
                        startingColumn={startingColumn}
                        onSetStartingColumn={(value) => setStartingColumn(Number(value))}
                        onUpdate={_onUpdatePlate}
                      />
                      <h3>{Language.get('plate', 'BEADCHIP_DEFINITION')}</h3>
                      <div className="beadchip-list">
                        {Object.keys(beadchips).map((x) => (
                          <Beadchip
                            barcode={beadchips[x].barcode}
                            data={beadchips[x]}
                            key={beadchips[x].barcode}
                            slotBelowBox={
                              <div className='beadchip-barcode'>{beadchips[x].barcode}</div>
                            }
                            getBeadText={sample => `${getCoord({ x: Number(x), y: sample.y })} (${sample.name})`}
                            hasLink={false}
                            hasTooltip={false}
                          />
                        ))}
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card
                    bordered={false}
                    className="card-title-align"
                    title={<h2>{Language.get('planner', 'SUMMARY')}</h2>}
                  >
                    <Summary
                      beadchips={beadchips}
                      form={form}
                      plate={plate}
                      wells={wells}
                      onUpdateBarcode={_onUpdateBarcode}
                      onUpdateLotBox={_onUpdateLotBox}
                    />
                  </Card>
                </Col>
              </Row>
            </DndProvider>
          </Form>
        )
      }
    </div>
  );
};
