// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';

// Importing antd libs
import { Button, Input, message, Tree } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/input/style/css';
import 'antd/es/message/style/css';
import 'antd/es/tree/style/css';

// Importing antd icons
import { ExportOutlined } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import 'helix-modules/sString';

// Importing Helix components
import { DEFAULT_CFG, Source, getCoord } from 'helix/chart/plate';

// Importing app modules
import AppAPI from 'modules/api';

// Importing component style
import './selector.scss';

// Defining constants
const { DirectoryTree } = Tree;

const CaseTitle = (props) => {
  const Language = useLanguage();
  const { case: caseProp, onPush } = props;
  return (
    <div className="case-title">
      <div title={caseProp?.['external_id']}>
        {Language.get('case', 'CASE_ID')}: {caseProp?.['external_id']?.compact(10, '...')}
      </div>
      <div>
        <Button
          className="icon"
          icon={<ExportOutlined />}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            onPush();
          }}
        />
      </div>
    </div>
  );
};

// Exporting component
const Selector = (props) => {
  const { plate, startingColumn, wells, onUpdate } = props;
  const Language = useLanguage();
  const [cases, setCases] = useState();

  let searchTO = undefined;

  const _inUse = (item) => (item?.['plate_position'] && plate.id !== item?.['plate_id'])
    || wells.some((well) => well.id === item.id);

  const _parse = (objects) => {
    const _getByDesignation = (designation, obj) => {
      return (obj.biomaterials || []).reduce((items, biomaterial) => {
        if (biomaterial.designation === designation) {
          const used = _inUse(biomaterial);
          biomaterial['case_external_id'] = obj?.['external_id'];
          items.push({
            biomaterial,
            disabled: used,
            icon: <Fragment />,
            isLeaf: true,
            key: `case_${obj.id}_bio_${biomaterial.id}`,
            selectable: false,
            title: (
              <Source
                disabled={used}
                item={biomaterial}
                key={biomaterial.id}
                title={biomaterial.name.compact(15, '...')}
              />
            ),
          });
        }
        return items;
      }, []);
    };
    return objects.map((obj) => {
      let children = [];
      if (obj.biomaterials) {
        // find mothers
        children = children.concat(_getByDesignation('mother', obj));
        // find fathers
        children = children.concat(_getByDesignation('father', obj));
        // find references
        children = children.concat(_getByDesignation('reference', obj));
        // group by cycles
        const cycles = obj.biomaterials.reduce((cycles, biomaterial) => {
          if (!biomaterial.designation) {
            const used = _inUse(biomaterial);
            biomaterial['case_external_id'] = obj?.['external_id'];
            const cycleID = biomaterial?.['cycle_id'] || 'NA';
            if (!cycles[cycleID]) {
              cycles[cycleID] = {
                children: [],
                key: `case_${obj.id}_cycle_${cycleID}`,
                title: <div>{Language.get('case', 'CYCLE_ID')}: {cycleID.compact(8, '...')}</div>
              };
            }
            cycles[cycleID].children.push({
              biomaterial,
              disabled: used,
              icon: <Fragment />,
              isLeaf: true,
              key: `case_${obj.id}_cycle_${cycleID}_bio_${biomaterial.id}`,
              selectable: false,
              title: (
                <Source
                  disabled={used}
                  item={biomaterial}
                  key={biomaterial.id}
                  title={biomaterial.name.compact(15, '...')}
                />
              ),
            });
          }
          return cycles;
        }, {});
        // injecting embryo to children
        Object.keys(cycles).forEach((cycleID) => children.push(cycles[cycleID]));
      }
      return {
        children,
        key: `case_${obj.id}`,
        title: <CaseTitle case={obj} onPush={() => _pushToPlate(children)} />,
      };
    });
  };

  const _pushToPlate = (children) => {
    // find next available place in plate
    const list = children
      .reduce((list, child) => {
        if (child.isLeaf) list.push(child);
        else child.children.forEach((superChild) => list.push(superChild));
        return list;
      }, [])
      .filter((child) => !child.disabled);
    const { cols, rows } = DEFAULT_CFG.plate;
    const toAdd = [];
    for (let x = (startingColumn - 1); x < cols; x++) {
      for (let y = 0; y < rows; y++) {
        const inPlate = wells.some((well) => well.x === x && well.y === y);
        if (inPlate) continue;
        if (list.length === 0) break;
        const child = list.shift();
        const biomaterial = child.biomaterial;
        biomaterial['plate_position'] = getCoord({ x, y });
        toAdd.push({
          ...biomaterial,
          x,
          y,
        });
      }
    }
    onUpdate([...wells, ...toAdd]);
  };

  const _search = (value) => {
    clearTimeout(searchTO);
    searchTO = setTimeout(async () => {
      const res = await AppAPI.Case.biomaterials({
        filters: { 'external_id__icontains': [value], 'is_v1_array': '0' },
        limit: 100,
        order: '-updated_on',
      });
      if (res.error) return message.error(Language.get('common', res.msg));
      setCases(_parse(res.results));
    }, 1000);
  };

  useEffect(() => {
    if (plate) _search();
  }, [plate, startingColumn, wells]);

  return (
    <div className="case-selector">
      <Input onChange={(event) => _search(event.target.value)} />
      {cases && (
        <DirectoryTree
          autoExpandParent={true}
          defaultExpandedKeys={[cases[0]?.key]}
          defaultExpandParent={true}
          treeData={cases}
        />
      )}
    </div>
  );
};

export { Selector };
