// Importing momentjs
import moment from 'moment';

// Importing Helix modules
import { MChromosomeDictionary } from 'helix-modules/chromosome';
import { BACKEND_DATE_FORMAT } from 'helix-modules/sDate';

// Importing helix components
import { getPosition } from 'helix/chart/plate';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { validateStatusForInheritanceType } from 'components/gene/rules';

// Importing validators
import { MAX_LENGTH_NAME, MAX_LENGTH_NOTES, isIdValid } from 'components/common/validators';

const ARRAY_VERSION = {
  KARYOMAP_V2: 'karyomap-v2',
  KARYOMAP_V1: 'humankaryomap-12v1.0',
};

const replaceDashAndSpace = (str = '', replacement = '_') => str.replace(/[- ]/g, replacement).toLowerCase();

export class ImportError {
  constructor(message, detail, ref, context) {
    this.message = message;
    this.detail = detail;
    this.ref = ref;
    this.context = context;
  }
}

export class Parser {
  /**
   * parse biomaterials from file content
   * @param {string} file content
   * @return {object}
  **/
  static biomaterials(biomaterials, caseId, importType) {
    return biomaterials.map((row) => {
      let biomaterial = {
        barcode: row['sample_barcode'],
        metadata: {
          '260_230': Number(row['260/230']),
          '260_280': Number(row['260/280']),
          'concentration_ngul': Number(row['concentration_ngul']),
          'volume_ul': Number(row['volume_ul']),
        },
        name: row['sample_id'],
        requisition: row.requisition,
        'bio_type': replaceDashAndSpace(row['type']),
        'cycle_id': row['cycle_id'],
        'tube_id': row['tube_id'],
      };
      if (importType === 'fa') {
        const wellPosition = row['well_position'];
        const wellRow = wellPosition.substring(0, 1);
        const wellColumn = wellPosition.substring(1);
        const paddedWellPosition = `${wellRow}${wellColumn.padStart(2, '0')}`;
        biomaterial = {
          ...biomaterial,
          // plate data
          'beadchip_barcode': row['beadchip_serial'],
          'plate_id': row['plate_id'],
          'plate_position': paddedWellPosition,
          'sub_array_id': row['subarray'],

          // lot data
          'lot_box_0': row['quad_wg_pre_lot'],
          'lot_box_1': row['wg_post_1_lv1_lot'],
          'lot_box_2': row['single_post_3_lv_lot'],
          'lot_box_3': row['post_2_lmv_lot'],
          'lot_box_4': row['post_4_lv_lot'],
        };
      }
      if (row.firstname) biomaterial.firstname = row.firstname;
      if (row.lastname) biomaterial.lastname = row.lastname;
      if (row.dob) biomaterial.dob = row.dob;
      if (row.reference) biomaterial.designation = row.reference.toLowerCase().replace(/\d|sample/, '');
      if (row.pedegree) biomaterial.pedegree = replaceDashAndSpace(row.pedegree);
      if (row.sex) biomaterial.sex = row.sex.toLowerCase()[0];
      if (caseId) biomaterial.case = caseId;
      return biomaterial;
    });
  }

  /**
   * parse ca import file content
   * @param {object}
   * data {array} list of embryio biomaterials
   * generegion {array} list of gene regions
   * header {object} case detail
   * trio {array} list of mother, father and reference biomaterials
   * @return {object} payload ready for API
  **/
  static mapForImport({ data, generegion, header, trio }, importType) {
    const { DESIGNATIONS } = AppAPI.Biomaterial;
    const _getGeneRegionStatus = (field, list, row) => {
      const [ref] = field.split('_');
      if (row[field]) {
        list.push({
          designation: DESIGNATIONS[ref.toUpperCase()] || DESIGNATIONS.REFERENCE,
          field, // to be able to link it in the backend
          status: replaceDashAndSpace(row[field]),
        });
      }
      return list;
    };
    return {
      biomaterials: Parser.biomaterials([...trio, ...data,], undefined, importType),
      case: {
        centre: header?.['referring_centre'],
        consultant: header?.consultant,
        notes: header?.notes,
        'external_id': header?.['case_id'],
      },
      genes: generegion.map((row) => {
        const startAt = Number(row['start']);
        const stopAt = Number(row['end']);
        return {
          biomaterials: ['mother_status', 'father_status', 'reference1_status', 'reference2_status'].reduce(
            (list, field) => _getGeneRegionStatus(field, list, row),
            [],
          ),
          chromosome: row.chromosome,
          length: stopAt - startAt,
          name: row['region_name'],
          'gene_type': replaceDashAndSpace(row['inheritance_type']),
          'mutation_site': row['mutation_site'] && Number(row['mutation_site']),
          'start_at': startAt,
        };
      }),
    };
  }

  /**
   * Read accessioning import tsv files
   * @param {string} file content
   * @return {object}
  **/
  static read(content) {
    let dataFields = [];
    let geneFields = [];
    let trioFields = [];

    const getFields = (line) => line.split('\t').map(
      (field, index) => !field && index === 0 ? 'reference' : (field || '').toLowerCase().sTrim()
    );

    const getValues = (line, fields) => {
      let values = line.split('\t');
      if (fields.length !== values.length) {
        throw new Error('NOT_VALID_DATA');
      }
      return values;
    };

    const buildObj = (fields, values) => {
      return fields.reduce((item, key, index) => ({
        ...item,
        [key]: values[index].sTrim(),
      }), {});
    };

    const obj = content && content.split('\n').reduce((obj, line, lineIndex) => {
      const isSectionLine = /\[.*\]/ig.test(line);
      if (isSectionLine) {
        obj[line.replace(/(\[|\]|,| )/ig, '').toLowerCase().sTrim()] = {};
      } else if (line) {
        const section = (obj.data && 'data')
          || (obj.trio && 'trio')
          || (obj.generegion && 'generegion')
          || (obj.header && 'header')
          || '';
        if (section === 'header') {
          const [key, value] = line.split('\t').map((chain) => chain?.sTrim());
          if (key) obj[section][replaceDashAndSpace(key)] = value;
        } else if (section === 'generegion') {
          if (Array.isArray(obj.generegion)) {
            const values = getValues(line, geneFields);
            obj.generegion.push(buildObj(geneFields, values));
          } else {
            obj.generegion = [];
            geneFields = getFields(line);
          }
        } else if (section === 'trio') {
          if (Array.isArray(obj.trio)) {
            const values = getValues(line, trioFields);
            obj.trio.push(buildObj(trioFields, values));
          } else {
            obj.trio = [];
            trioFields = getFields(line);
          }
        } else if (section === 'data') {
          if (Array.isArray(obj.data)) {
            const values = getValues(line, dataFields);
            obj.data.push({
              ...buildObj(dataFields, values),
              'sample_order': lineIndex + 1,
            });
          } else {
            obj.data = [];
            dataFields = getFields(line);
          }
        }
      }
      return obj;
    }, {});

    // removing row that seems empty
    if (obj.generegion) obj.generegion = obj.generegion.filter((gene) => Boolean(gene['region_name']));
    if (obj.trio) obj.trio = obj.trio.filter((biomaterial) => Boolean(biomaterial['sample_id']));
    if (obj.data) obj.data = obj.data.filter((biomaterial) => Boolean(biomaterial['sample_id']));

    return obj;
  }

  /**
   * parse trio from content
   * @param {object} content
   * @return {void} mutate content.trio
  **/
  static trio({ header, trio }) {
    // injecting names and dob
    ['mother', 'father'].forEach((field) => {
      const biomaterial = trio.find((biomaterial) => biomaterial.reference.toLowerCase() === field);
      if (biomaterial) {
        if (header[`${field}_dob`]) {
          biomaterial.dob = moment(header[`${field}_dob`], BACKEND_DATE_FORMAT).format(BACKEND_DATE_FORMAT);
        }
        biomaterial.firstname = header[`${field}_first_name`];
        biomaterial.lastname = header[`${field}_last_name`];
      }
    });
  }
}

export class Validator {
  /**
   * Validate biomaterial accessioning file type
   * @param {object} caseObj
   * @param {object} parsed file content
   * @returns {void}
   */
  static baFileType(caseObj, content) {
    // validate file type, version and date
    if ((content.header['file_type'] || '').toLowerCase() !== 'sa') {
      throw new Error('NOT_VALID_FILE_TYPE');
    }
    if (content.header['file_version'] !== '1') {
      throw new Error('NOT_VALID_FILE_VERSION');
    }
    if (!content.header['file_date'] || (new Date(content.header['file_date'])).isInvalid()) {
      throw new Error('NOT_VALID_FILE_DATE');
    }

    // validate case is_v1_array
    const mapArrayNames = {
      [ARRAY_VERSION.KARYOMAP_V1]: 1,
      [ARRAY_VERSION.KARYOMAP_V2]: 2,
    };
    content.header['array_type'] = (content.header['array_type'] || '').toLowerCase().replace(/ /ig, '-');
    const notSameArrayVersion = (caseObj?.['is_v1_array'] && mapArrayNames[content.header['array_type']] !== 1)
      || mapArrayNames[content.header['array_type']] !== 2;
    if (notSameArrayVersion) {
      throw new Error('NOT_VALID_ARRAY_TYPE_MISMATCH');
    }

    // validate required fields
    const required = ['sample_id', 'type', 'tube_id'];
    if (content.data.some((row) => required.some((field) => !row[field]))) {
      throw new Error('NOT_VALID_EMPTY_SAMPLE_FIELDS');
    }

    // validate allowed characters in fields
    const ids = ['cycle_id', 'requisition', 'sample_barcode', 'sample_id', 'tube_id'];
    content.data.forEach((row, index) => ids.forEach(field => {
      if (row[field] && !isIdValid(row[field])) {
        throw new ImportError('NOT_VALID_CHARACTERS_IN_SAMPLE_FIELDS', `${field} in row ${index + 1}`);
      }
    }));

    const numbers = ['260/230', '280', 'concentration_ngul', 'volume_ul'];
    if (content.data.some((row) => numbers.some((field) => row[field] && !Number(row[field])))) {
      throw new Error('NOT_VALID_NUMBERS_IN_SAMPLE_FIELDS');
    }
  }

  /**
   * Helper to validate barcodes
   * @param {array} barcodes
   * @returns {void}
   */
  static async barcodes(barcodes) {
    const setBarcodes = new Set(barcodes);
    if (barcodes.length !== setBarcodes.size) throw new ImportError('NOT_VALID_DUPLICATED_BARCODES');

    if (barcodes.length > 0) {
      const res = await AppAPI.Biomaterial.list({
        filters: { 'barcode__in': barcodes },
        limit: 100,
      });

      ((res && res.results) || []).forEach((biomaterial) => {
        const { barcode, name } = biomaterial;
        const row = barcodes.findIndex((value) => value === barcode);
        if (row !== -1) throw new ImportError('NOT_VALID_DUPLICATED_BARCODES', `${name}: ${barcode}`, row + 1);
      });
    }
  }

  /**
   *
   * @param {Array<Object>} biomaterials
   */
  static async beadchipsAndPlates(biomaterials, i18n) {
    const keys = {
      plateId: 'plate_id',
      wellPosition: 'well_position',
      beadchipBarcode: 'beadchip_serial',
      subArray: 'subarray'
    };
    const rows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    const cols = Array.from(new Array(12), (_, idx) => idx + 1);

    const validateSubarray = (str) => typeof str === 'string' &&
      str.length === 6 &&
      str.endsWith('C01') &&
      rows.some((_, idx) => str.startsWith(`R0${idx + 1}`));

    const validateWellPosition = (str) => {
      const wellPositions = rows.reduce((permutations, row) => {
        cols.forEach(col => {
          permutations.push(`${row}${col.toString().padStart(2, '0')}`);
          permutations.push(`${row}${col}`);
        });
        return permutations;
      }, []);
      return wellPositions.includes(str);
    };

    const validatedBiomaterials = biomaterials.map((biomaterial, index) => {
      const beadchipBarcode = biomaterial[keys.beadchipBarcode];
      const plateId = biomaterial[keys.plateId];
      const subArray = biomaterial[keys.subArray];
      const wellPosition = biomaterial[keys.wellPosition];

      if (!isIdValid(plateId)) {
        throw new ImportError('NOT_VALID_ID', `${biomaterial['sample_id']}: ${keys.plateId}`,);
      }
      if (!(beadchipBarcode?.length > 0 && beadchipBarcode?.length <= 50)) {
        throw new ImportError('NOT_VALID_BEADCHIP_BARCODE', beadchipBarcode, index + 1);
      }
      if (!validateSubarray(subArray)) {
        throw new ImportError('NOT_VALID_SUBARRAY', subArray, index + 1);
      }
      if (!validateWellPosition(wellPosition)) {
        throw new ImportError('NOT_VALID_WELL_POSITION', wellPosition, index + 1);
      }

      const { x: columnIndex, y: rowIndex } = getPosition(wellPosition);
      const isSubArrayWellCombinationValid = Number(subArray.substring(1, 3)) === rowIndex + 1;

      if (!isSubArrayWellCombinationValid) {
        throw new ImportError(
          'NOT_VALID_WELL_POSITION_SUBARRAY_COMBINATION',
          `${biomaterial['sample_id']}: ${wellPosition} & ${subArray}`,
          undefined,
          'plate',
        );
      }

      biomaterials.forEach((toMatchBiomaterial, localIndex) => {
        if (localIndex === index) return false;
        const pos = getPosition(toMatchBiomaterial[keys.wellPosition]);
        const isSamePlate = toMatchBiomaterial[keys.plateId] === plateId;
        const isSameColumn = pos.x === columnIndex;
        const isSameRow = pos.y === rowIndex;
        const biomaterialsBeingCompared = `${i18n('SAMPLE_ID', 'case')}: ${biomaterial['sample_id']} & ${toMatchBiomaterial['sample_id']}`;

        // two samples can't have same plate id and well position
        const isSamePlateAndPosition = isSamePlate && isSameRow && isSameColumn;
        if (isSamePlateAndPosition) {
          throw new ImportError('NOT_VALID_DUPLICATED_PLATE_AND_POSITION', biomaterialsBeingCompared, undefined, 'plate');
        }

        // two samples on same plate and column cannot have different beadchip serial
        const isSerialInvalid = isSamePlate && isSameColumn && beadchipBarcode !== toMatchBiomaterial['beadchip_serial'];
        if (isSerialInvalid) {
          throw new ImportError('NOT_VALID_DIFFERENT_BARCODE_IN_SAME_COLUMN', biomaterialsBeingCompared, undefined, 'plate');
        }

        const isDuplicatedBeadchipSerial = !(isSamePlate && isSameColumn) && beadchipBarcode === toMatchBiomaterial['beadchip_serial'];
        if (isDuplicatedBeadchipSerial) {
          throw new ImportError(
            'NOT_VALID_DUPLICATED_BARCODES_WITHIN_ORGANIZATION',
            `${biomaterialsBeingCompared} (${beadchipBarcode})`,
            undefined,
            'plate'
          );
        }
      });

      return {
        beadchipBarcode,
        index,
        plateColumn: columnIndex,
        plateId,
        plateRow: rowIndex,
        sampleId: biomaterial['sample_id'],
        subArray,
        wellPosition,
      };
    });

    const { results: beadchips } = await AppAPI.Plate.list({
      filters: { 'beadchip_barcode__in': biomaterials.map(bio => bio[keys.beadchipBarcode]).join(',') }
    });

    const plateToBiomaterialReducer = (biomaterials, plate) => {
      plate.beadchips.forEach(beadchip => {
        beadchip.biomaterials.forEach(biomaterial => {
          const { x, y } = getPosition(biomaterial['plate_position']);
          biomaterials.push({
            beadchipBarcode: beadchip.barcode,
            plateColumn: x,
            plateId: plate['external_id'],
            plateRow: y,
            wellPosition: biomaterial['plate_position'],
            sampleId: biomaterial.name,
            subArray: biomaterial['sub_array_id'],
          });
        });
      });
      return biomaterials;
    };
    const beadchipBiomaterials = beadchips.reduce(plateToBiomaterialReducer, []);

    validatedBiomaterials.forEach(biomaterial => {
      beadchipBiomaterials.forEach(beadchipBiomaterial => {
        const isSame = (key) => biomaterial[key] === beadchipBiomaterial[key];
        const isSamePlate = isSame('plateId');
        const isSameColumn = isSame('plateColumn');
        const isSameRow = isSame('plateRow');
        const biomaterialsBeingCompared = `${i18n('SAMPLE_ID', 'case')}: ${biomaterial.sampleId} & ${beadchipBiomaterial.sampleId}`;

        // two samples can't have same plate id and well position
        const isSamePlateAndPosition = isSamePlate && isSameColumn && isSameRow;
        if (isSamePlateAndPosition) {
          throw new ImportError(
            'NOT_VALID_DUPLICATED_PLATE_AND_POSITION',
            `${biomaterialsBeingCompared}\n` +
            `${i18n('PLATE_ID')}: ${biomaterial.plateId}, ${i18n('WELL_POSITION')}: ${biomaterial.wellPosition}`,
            undefined,
            'plate'
          );
        }

        // two samples on same plate and column cannot have different beadchip serial
        const isSerialInvalid = isSamePlate && isSameColumn && !isSame('beadchipBarcode');
        if (isSerialInvalid) {
          throw new ImportError(
            'NOT_VALID_DIFFERENT_BARCODE_IN_SAME_COLUMN',
            `${biomaterialsBeingCompared}\n` +
            `${i18n('PLATE_ID')}: ${beadchipBiomaterial.plateId}\n` +
            `${i18n('BEADCHIP_BARCODE')}: ${beadchipBiomaterial.beadchipBarcode}\n` +
            `${i18n('COLUMN')}: ${beadchipBiomaterial.plateColumn + 1}`,
            undefined,
            'plate'
          );
        }

        const isDuplicatedBeadchipSerial = !(isSamePlate && isSameColumn) && isSame('beadchipBarcode');
        if (isDuplicatedBeadchipSerial) {
          throw new ImportError(
            'NOT_VALID_DUPLICATED_BARCODES_WITHIN_ORGANIZATION',
            `${biomaterialsBeingCompared} (${biomaterial.beadchipBarcode})`,
            undefined,
            'plate'
          );
        }
      });
    });
  }

  /**
   * Validate biomaterials
   * @param {array} biomaterials
   * @returns {void}
   */
  static async biomaterials(biomaterials, caseId) {
    // extract fields to validate
    const lists = biomaterials.reduce((lists, row, index) => {
      if (row['sample_barcode']) lists.barcodes.push(row['sample_barcode']);
      if (row['sample_id']) lists.names.push(row['sample_id']);
      if (row['tube_id']) lists.tubes.push(row['tube_id']);
      Validator.bioType(row['type'], index);
      return lists;
    }, { barcodes: [], names: [], tubes: [] });

    // validate barcodes
    await Validator.barcodes(lists.barcodes);

    // validate biomaterial names
    await Validator.names(lists.names, caseId);

    // validate tube ids
    await Validator.tubes(lists.tubes, caseId);
  }

  /**
   * Helper to validate biomaterial names
   * @param {string} bioType
   * @param {number} index of the row
   * @returns {void}
   */
  static bioType(bioType, index) {
    if (AppAPI.Biomaterial.BIO_TYPE_VALUES.indexOf(replaceDashAndSpace(bioType)) === -1) {
      throw new ImportError('NOT_VALID_BIOMATERIAL_TYPE', bioType, index + 1);
    }
  }

  /**
   * Validate case accessioning data
   * @param {object} parsed file content
   * @returns {void}
   */
  static async caseAccessioningData(content, fileType = 'ca') {
    // validate file type, version and date
    if ((content.header['file_type'] || '').toLowerCase() !== fileType) {
      throw new Error('NOT_VALID_FILE_TYPE');
    }
    if (content.header['file_version'] !== '1') {
      throw new Error('NOT_VALID_FILE_VERSION');
    }
    if (!content.header['file_date'] || (new Date(content.header['file_date'])).isInvalid()) {
      throw new Error('NOT_VALID_FILE_DATE');
    }
    if (!isIdValid(content.header['case_id'])) {
      throw new ImportError('NOT_VALID_ID', 'Header field: Case ID');
    }

    const supportedArrayTypes = [ARRAY_VERSION.KARYOMAP_V2];
    const arrayType = (content.header['array_type'] ?? '').toLowerCase().replace(/ /g, '-');
    if (!supportedArrayTypes.includes(arrayType)) {
      throw new Error('NOT_VALID_ARRAY_TYPE');
    }

    // Validate DOB
    if (['mother_dob', 'father_dob'].some(
      (field) => content.header[field] && (new Date(content.header[field])).isInvalid()
    )) {
      throw new Error('NOT_VALID_DOB');
    }

    // validate allowed characters in header
    Object.entries({
      'referring_centre': MAX_LENGTH_NAME,
      'consultant': MAX_LENGTH_NAME,
      'father_first_name': MAX_LENGTH_NAME,
      'father_last_name': MAX_LENGTH_NAME,
      'mother_first_name': MAX_LENGTH_NAME,
      'mother_last_name': MAX_LENGTH_NAME,
      'notes': MAX_LENGTH_NOTES,
    }).forEach(([field, maxLength]) => {
      if (content.header[field]?.length > maxLength) {
        throw new ImportError(
          'NOT_VALID_CHARACTERS_COUNT_IN_HEADER_FIELDS',
          `Max length for ${field} is ${maxLength}`
        );
      }
    });

    const hgMap = {
      [ARRAY_VERSION.KARYOMAP_V1]: '19',
      [ARRAY_VERSION.KARYOMAP_V2]: '38',
    };

    // validate gene regions
    await Validator.genes(content.generegion, hgMap[arrayType]);

    // validate trio
    Validator.trio(content.trio);

    // validate allowed characters in fields
    const allBiomaterials = [
      ...content.trio,
      ...content.data,
    ];

    const ids = ['cycle_id', 'requisition', 'sample_barcode', 'sample_id', 'tube_id'];
    allBiomaterials.forEach((row, index) => ids.forEach(field => {
      if (row[field] && !isIdValid(row[field])) {
        throw new ImportError('NOT_VALID_CHARACTERS_IN_SAMPLE_FIELDS', `${field} in row: ${index + 1}`);
      }
    }));

    const numbers = ['260/230', '280', 'concentration_ngul', 'volume_ul'];
    allBiomaterials.forEach((row, index) => numbers.forEach(field => {
      if (row[field] && isNaN(Number(row[field]))) {
        throw new ImportError('NOT_VALID_NUMBERS_IN_SAMPLE_FIELDS', `${row.reference} ${field}`, index + 1);
      }
    }));
  }

  /**
   * Validate gene regions
   * @param {array} genes
   * @returns {void}
   */
  static async genes(genes, hg) {
    // mutate gene regions to remove empty rows
    genes = genes.filter((row) => Boolean(row['region_name']));

    // validate required gene regions fields
    const required = [
      'chromosome',
      'start',
      'end',
      'inheritance_type',
    ];
    required.forEach(field => genes.forEach(geneRow => {
      if (!geneRow[field]) {
        throw new ImportError('NOT_VALID_EMPTY_GENE_REGIONS_FIELDS', `Missing field: ${field}`);
      }
    }));

    // validate inheritance type and chromosome combination

    genes.forEach((row) => {
      const isX = row.chromosome === 'X';
      const validOptions = isX ? AppAPI.GeneRegion.X_TYPE_VALUES : AppAPI.GeneRegion.NON_X_TYPE_VALUES;
      const inheritanceType = replaceDashAndSpace(row['inheritance_type']);
      if (!validOptions.includes(inheritanceType)) {
        throw new ImportError('NOT_VALID_GENE_TYPE', `"${inheritanceType}" for chromosome ${row.chromosome}`);
      }
    });

    // validate chromosome values
    const chromosomes = await MChromosomeDictionary.load({
      bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
      dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
    });
    const values = chromosomes.map(({ label }) => label);
    if (genes.some((row) => values.indexOf(row.chromosome) === -1)) {
      throw new Error('NOT_VALID_CHROMOSOME_GENE_REGION_FIELD');
    }

    // validate start and end position
    if (genes.some((row) => {
      const startAt = Number(row['start']);
      const stopAt = Number(row['end']);
      const chromosome = chromosomes.find((chromosome) => chromosome.label === row.chromosome);
      return startAt < 0 || stopAt < 0
        || startAt > chromosome.length || stopAt > chromosome.length
        || startAt >= stopAt;
    })) {
      throw new Error('NOT_VALID_CHROMOSOME_POSITION_GENE_REGION_FIELD');
    }

    // validate type
    if (genes.some(
      (row) => AppAPI.GeneRegion.TYPE_VALUES.indexOf(replaceDashAndSpace(row['inheritance_type'])) === -1
    )) {
      throw new Error('NOT_VALID_TYPE_GENE_REGIONS');
    }

    // validate mutation site
    if (genes.some((row) => {
      const startAt = Number(row['start']);
      const stopAt = Number(row['end']);
      const value = Number(row['mutation_site']);
      return value && (value < startAt || value > stopAt);
    })) {
      throw new Error('NOT_VALID_MUTATION_SITE_GENE_REGIONS');
    }

    // validate bio statuses
    const statuses = ['mother_status', 'father_status', 'reference1_status', 'reference2_status'];
    if (genes.some((row) => statuses.some((field) => {
      return row[field]
        && AppAPI.GeneRegion.BIO_STATUSES.indexOf(replaceDashAndSpace(row[field])) === -1;
    }))) {
      throw new Error('NOT_VALID_SAMPLE_STATUS_GENE_REGIONS');
    }
  }

  /**
   * Validate combination of gene regions and trio
   * @param {object}
   * generegion {array}
   * trio {array}
   * @returns {void}
   */
  static geneTrio({ generegion, trio }) {
    const invalidStatuses = [];
    const _validateTrioGeneStatus = (field) => {
      const biomaterial = trio.find((biomaterial) => biomaterial.reference.toLowerCase() === field);
      const missingBiomaterial = !biomaterial && generegion.some((row) => row[`${field}_status`]);
      const missingStatus = biomaterial && generegion.some((row) => !row[`${field}_status`]);
      if (missingBiomaterial || missingStatus) {
        throw new Error('NOT_VALID_MISMATCH_GENE_REGIONS_TRIO_STATUSES');
      }
      if (biomaterial) {
        const sexFirstLetter = biomaterial.sex.toLowerCase()[0];
        generegion.forEach((row) => {
          const isValid = validateStatusForInheritanceType({
            inheritanceType: replaceDashAndSpace(row['inheritance_type']),
            pedegree: replaceDashAndSpace(biomaterial.pedegree),
            sex: sexFirstLetter,
            status: replaceDashAndSpace(row[`${field}_status`])
          });
          if (!isValid) invalidStatuses.push(`${biomaterial['sample_id']} - ${row['region_name']}`);
        });
      }
    };

    // required status if biomaterial is present in trio
    _validateTrioGeneStatus('mother');
    _validateTrioGeneStatus('father');
    _validateTrioGeneStatus('reference1');
    _validateTrioGeneStatus('reference2');

    if (invalidStatuses.length > 0) {
      throw new ImportError('NOT_VALID_TYPE_GENE_REGIONS', invalidStatuses.join('\n'));
    }
  }

  /**
   * Helper to validate biomaterial names
   * @param {array} names
   * @param {number} caseId
   * @returns {void}
   */
  static async names(names, caseId) {
    const setNames = new Set(names);
    if (names.length !== setNames.size) throw new ImportError('NOT_VALID_DUPLICATED_BIOMATERIAL_NAMES');

    if (caseId) {
      const res = await AppAPI.Biomaterial.list({
        filters: { case: caseId, 'name__in': names },
        limit: 100,
      });

      ((res && res.results) || []).forEach((biomaterial) => {
        const { name } = biomaterial;
        const row = names.findIndex((value) => value === name);
        if (row !== -1) throw new ImportError('NOT_VALID_DUPLICATED_BIOMATERIAL_NAMES', `${name}`, row + 1);
      });
    }
  }

  /**
   * Helper to validate trio biomaterials
   * @param {array} biomaterialsx
   * @returns {void}
   */
  static trio(biomaterials) {
    // split trio biomaterials by reference
    const mother = biomaterials.find((biomaterial) => biomaterial.reference.toLowerCase() === 'mother');
    const father = biomaterials.find((biomaterial) => biomaterial.reference.toLowerCase() === 'father');
    const references = [
      biomaterials.find((biomaterial) => biomaterial.reference.toLowerCase() === 'reference1'),
      biomaterials.find((biomaterial) => biomaterial.reference.toLowerCase() === 'reference2'),
    ].filter((reference) => Boolean(reference?.['sample_id']));

    // validate required
    const required = ['pedegree', 'sex', 'tube_id', 'sample_id', 'type'];
    biomaterials.forEach((row, index) => {
      required.forEach((field) => {
        if (!row[field]) {
          throw new ImportError('NOT_VALID_EMPTY_TRIO_FIELDS', `${row.reference} ${field}`, index + 1);
        }
      });
    });

    // validate pedegree
    if (mother && mother.pedegree.toLowerCase() !== 'mother') {
      throw new Error('NOT_VALID_MOTHER_PEDEGREE_TRIO');
    }
    if (father && father.pedegree.toLowerCase() !== 'father') {
      throw new Error('NOT_VALID_FATHER_PEDEGREE_TRIO');
    }
    const REFERENCE_PEDEGREE_VALUES = AppAPI.Biomaterial.PEDEGREE_VALUES.filter(
      pedegree => ['father', 'mother', 'sample'].indexOf(pedegree) === -1
    );
    if (references.some(
      (reference) => REFERENCE_PEDEGREE_VALUES.indexOf(replaceDashAndSpace(reference.pedegree)) === -1
    )) {
      throw new Error('NOT_VALID_REFERENCE_PEDEGREE_TRIO');
    }

    // validate sex value
    if (
      (father && father.sex?.toLowerCase() !== 'male') ||
      (mother && mother.sex?.toLowerCase() !== 'female')
    ) {
      throw new Error('NOT_VALID_SEX_TRIO');
    }

    // validate reference sex
    if (references.some(
      (reference) => !['female', 'male', 'unknown'].includes(reference.sex?.toLowerCase())
    )) {
      throw new Error('NOT_VALID_REFERENCE_SEX_TRIO');
    }
  }

  /**
   * Helper to validate tubes
   * @param {array} tubes
   * @param {number} caseId
   * @returns {void}
   */
  static async tubes(tubes, caseId) {
    const setTubes = new Set(tubes);
    if (tubes.length !== setTubes.size) throw new ImportError('NOT_VALID_DUPLICATED_TUBE_IDS');

    if (caseId) {
      if (tubes.length > 0) {
        const res = await AppAPI.Biomaterial.list({
          filters: { case: caseId, 'tube_id__in': tubes },
          limit: 100,
        });

        ((res && res.results) || []).forEach((biomaterial) => {
          const { name, tube_id: tubeId } = biomaterial;
          const row = tubes.findIndex((value) => value === tubeId);
          if (row !== -1) throw new ImportError('NOT_VALID_DUPLICATED_TUBE_IDS', `${name}: ${tubeId}`, row + 1);
        });
      }
    }
  }
}
