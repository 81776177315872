// Importing react libs
import React, { useEffect, useState } from 'react';

// Importing antd libs
import { Descriptions, Typography } from 'antd';
import 'antd/es/descriptions/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { MChromosomeDictionary } from 'helix-modules/chromosome';

// Defining constants
const { Item } = Descriptions;
const { Title } = Typography;

// Exporting component
export const Detail = ({ chromosome, isV1Array, length, start }) => {
  const Language = useLanguage();
  const [band, setBand] = useState();
  const [chromList, setChromList] = useState([]);
  const end = start + length;

  useEffect(() => {
    const chromListChromosome = chromList.find(({ label }) => label === chromosome);
    if (chromListChromosome) {
      setBand(MChromosomeDictionary.getCytoband({
        chromosome: chromListChromosome,
        position: start + length / 2,
      }));
    }
  }, [chromList]);

  useEffect(() => {
    const loadCytobands = async () => {
      const hg = isV1Array ? '19' : '38';
      setChromList(await MChromosomeDictionary.load({
        bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
        dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
      }));
    };
    loadCytobands();
  }, [isV1Array]);

  return <div>
    <Title level={3}>{Language.get('case', 'REGION_DETAILS')}</Title>
    <Descriptions bordered className="trio-table-info" column={1} contentStyle={{ minWidth: 100 }} size="small">
      <Item label={Language.get('case', 'CHROMOSOME')}>{chromosome}</Item>
      <Item label={Language.get('report', 'BAND')}>{band}</Item>
      <Item label={Language.get('case', 'START')}>{start}</Item>
      <Item label={Language.get('case', 'END')}>{end}</Item>
      <Item label={Language.get('case', 'SIZE')}>{length}</Item>
    </Descriptions>
  </div>;
};
