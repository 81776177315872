// Importing react libs
import React from 'react';

// Importing antd libs
import { Col, Row, Table, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/table/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Title } = Typography;

// Exporting component
export const DNA = ({ biomaterials }) => {
  const Language = useLanguage();

  const data = [
    biomaterials.father,
    biomaterials.mother,
    biomaterials.reference,
    ...biomaterials.embryos,
  ];

  const columns = [{
    dataIndex: 'biomaterial_name',
    title: Language.get('case', 'SAMPLE_ID'),
  }, {
    dataIndex: 'biomaterial_designation',
    title: Language.get('biomaterial', 'DESIGNATION'),
    render: (text) => typeof text === 'string' ? Language.get('biomaterial', text || 'SAMPLE') : '',
  }, {
    dataIndex: 'biomaterial_bio_type',
    title: Language.get('case', 'SAMPLE_TYPE'),
    render: (text) => (text && Language.get('biomaterial', text)) || '',
  }, {
    dataIndex: ['biomaterial_metadata', 'volume_ul'],
    title: Language.get('case', 'VOLUME'),
  }, {
    dataIndex: ['biomaterial_metadata', 'concentration_ngul'],
    title: Language.get('case', 'CONCENTRATION'),
  }, {
    dataIndex: ['biomaterial_metadata', '260_280'],
    title: Language.get('biomaterial', '260_280'),
  }, {
    dataIndex: ['biomaterial_metadata', '260_230'],
    title: Language.get('biomaterial', '260_230'),
  }];

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{Language.get('case', 'QC_DNA')}</Title>
      </Col>
      <Col offset={1} span={23}>
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="biomaterial_id"
        />
      </Col>
    </Row>
  );
};
