// Importing react libs
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Importing antd libs
import {
  Button,
  Form,
  Modal,
  Tooltip,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/tooltip/style/css';
import { EyeOutlined } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { AnalysisForm, TRIO_DESIGNATIONS, mapAnalysisSettings } from 'components/analysis';
import ReAnalyze from 'components/analysis/reanalyze';

// Importing component style
import './list.scss';

// Exporting component
export const List = (props) => {
  const { case: caseObj, onUpdate } = props;
  const Language = useLanguage();
  const [form] = Form.useForm();
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const renderViewModal = (analysis) => {
    const trioAnalysisBiomaterials = analysis.biomaterials?.filter(
      bio => TRIO_DESIGNATIONS.includes(bio['biomaterial_designation'])
    );
    const trio = trioAnalysisBiomaterials.map(bio => ({
      ...bio,
      name: bio['biomaterial_name'],
      designation: bio['biomaterial_designation'],
      pedegree: bio['biomaterial_pedegree'],
      barcode: bio['biomaterial_barcode'],
      bio_type: bio['biomaterial_bio_type'],
      status: bio['biomaterial_status'],
    }));
    setShowViewModal(true);
    form.setFieldsValue({
      ...mapAnalysisSettings(analysis.setting),
      name: analysis.name,
      trio,
    });
  };

  const columns = [{
    dataIndex: 'analysis_external_id',
    sorter: true,
    title: Language.get('analysis', 'ANALYSIS_ID'),
    render: (value, record) => {
      if (record.status === 'completed') {
        const link = `/case/${record.case}/analysis/${record.id}`;
        return <Link to={link}>{value}</Link>;
      }
      return value;
    },
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'name',
    title: Language.get('common', 'NAME'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'reference_id',
    title: Language.get('analysis', 'REFERENCE_ID'),
  }, {
    dataIndex: 'notes',
    title: Language.get('common', 'NOTES'),
  }, {
    dataIndex: 'created_on',
    sorter: true,
    title: Language.get('common', 'DATE_CREATED'),
    render: (value) => (new Date(value)).format(),
    ...tableColumnFilterDate()
  }, {
    dataIndex: 'updated_on',
    sorter: true,
    title: Language.get('common', 'DATE_MODIFIED'),
    render: (value) => (new Date(value)).format(),
    ...tableColumnFilterDate()
  }];

  const onDelete = async (item) => {
    const res = await AppAPI.Analysis.delete(item.id);
    if (res.error) {
      res.msg = Language.get('biomaterial', res.msg);
      return res;
    }
    onUpdate(); // propagate updates to reload biomaterial list
  };
  const reader = async ({ filters, limit, offset, order }) => {
    const caseId = caseObj?.id;
    if (caseId) {
      const mapper = {
        name: 'name__icontains',
      };
      filters = Object.keys(filters).reduce((newFilters, field) => {
        newFilters[mapper[field] || field] = filters[field];
        return newFilters;
      }, {});
      filters['case'] = [caseId];
      order = order || '-updated_on';
      return await AppAPI.Analysis.list({ filters, limit, offset, order });
    }
    return { result: [] };
  };
  const parser = (objects) => {
    objects = objects?.map((analysis) => {
      if (analysis.biomaterials) {
        const ref = analysis.biomaterials?.find((item) => item['biomaterial_designation'] === 'reference');
        if (ref) analysis['reference_id'] = ref['biomaterial_name'];
      }
      return analysis;
    });
    return objects;
  };

  useEffect(() => {
    if (caseObj) {
      setListKey((Math.random() + 1).toString(36).substring(2));
    }
  }, [caseObj]);

  return (
    <div className="analysis-list-wrapper">
      <div className="title-with-action">
        <h2>{Language.get('analysis', 'ANALYSES')}</h2>
        <Button
          disabled={!caseObj?.id}
          size="small"
          type="primary"
          onClick={() => { form.resetFields(); setShowAddEditModal(true); }}
        >
          {Language.get('common', 'ADD')}
        </Button>
      </div>
      <HelixList
        actions={[
          (record) => (
            <Tooltip title={Language.get('common', 'VIEW_HOVER_INFO')}>
              <Button size="small" icon={<EyeOutlined />} onClick={() => renderViewModal(record)} />
            </Tooltip>
          ),
          (record) => <ReAnalyze key='re-analyze' analysis={record} showText={false} size="small" onSuccess={onUpdate} />
        ]}
        columns={columns}
        context="common"
        dataField="results"
        dataCountField="count"
        defaultSort={{ field: 'updated_on', order: 'descend' }}
        key={listKey}
        name="analyses"
        parser={parser}
        reader={reader}
        rowKey="id"
        showPagination={false}
        onDelete={onDelete}
      />
      <Modal
        destroyOnClose={true}
        open={showAddEditModal | showViewModal}
        title={Language.get('common', showViewModal ? 'VIEW' : 'ADD')}
        width={1200}
        onOk={() => showViewModal ? setShowViewModal(false) : form.submit()}
        onCancel={() => {
          form.resetFields();
          setShowAddEditModal(false);
          setShowViewModal(false);
        }}
      >
        <AnalysisForm
          case={caseObj}
          form={form}
          onUpdate={() => {
            form.resetFields();
            onUpdate(); // propagate updates to reload biomaterial list
            setShowAddEditModal(false);
          }}
          readOnly={showViewModal}
        />
      </Modal>
    </div>
  );
};
