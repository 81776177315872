// Importing React and external libs
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Importing Antd
import {
  Button,
  Form,
  Input,
  message,
  Radio,
  Select
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/message/style/css';
import 'antd/es/radio/style/css';
import 'antd/es/select/style/css';

// Importing antd icons
import { SaveOutlined } from '@ant-design/icons';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing modules
import AppAPI from 'modules/api';
import Permission from 'modules/permission';

// Importing styles
import './form.scss';

const { Item } = Form;
const { Option } = Select;

export const UserForm = () => {
  const [form] = Form.useForm();
  const Language = useLanguage();
  const navigate = useNavigate();
  const { id } = useParams();
  const numericId = parseInt(id);
  const [editPassword, setEditPassword] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  const [searchOrganizationsTO] = useState({ current: null });
  const IS_HOST_ADMIN = Permission.isHostAdmin();

  useEffect(() => {
    const _loadData = async () => {
      if (numericId) {
        const getDetailUser = await AppAPI.User.get(numericId);
        setUser(getDetailUser);
        setTimeout(() => form.resetFields(), 0);
      }
      const resRoles = await AppAPI.Role.list({});
      if (resRoles.results) {
        setRoles(resRoles.results);
      }

      if (IS_HOST_ADMIN) {
        _onSearch();
      }
    };
    _loadData();
  }, [numericId]);

  const _onSearch = (value) => {
    if (searchOrganizationsTO.current) clearTimeout(searchOrganizationsTO.current);
    searchOrganizationsTO.current = setTimeout(async () => {
      const res = await AppAPI.Organization.list({ filters: { name__icontains: [value] } });
      setOrganizations(res.results);
    }, 300);
  };

  const onFinish = async (data) => {
    const res = await AppAPI.User.save({
      ...(IS_HOST_ADMIN && { organization: data.organization }),
      ...(user && { id: user.id }),
      role: data.role,
      password: data.password,
      username: data.username,
      'first_name': data['first_name'],
      'is_dev': data['is_dev'],
      'last_name': data['last_name'],
    });

    if (res.error) {
      res.msg = res.msg instanceof Array ? res.msg : [res.msg];
      return res.msg.map((msg) => message.error(Language.get('user', msg)));
    }
    message.success(Language.get('common', 'DATA_SAVED_SUCCESFULLY'));
    navigate('/user');
  };

  const header = user
    ? `${Language.get('common', 'EDITING')} ${user['first_name']} ${user['last_name']}`
    : `${Language.get('common', 'NEW')} ${Language.get('user', 'USER')}`;

  return (
    <div className="user-add-edit">
      <div className="user-add-edit-header">
        <h1>{header}</h1>
      </div>
      <Form
        className="user-add-edit-form"
        form={form}
        initialValues={user}
        name="user-form"
        validateMessages={{
          required: Language.get('common', 'NOT_VALID_EMPTY_VALUE'),
        }}
        onFinish={onFinish}
      >
        <div className="form-container">
          {IS_HOST_ADMIN && (
            <Item
              colon={false}
              label={Language.get('organization', 'ORGANIZATION')}
              name="organization"
              rules={[{
                message: `${Language.get('organization', 'ORGANIZATION')} ${Language.get('login', 'NOT_VALID_EMPTY_VALUE')}.`,
                required: true
              }]}
            >
              <Select showSearch optionFilterProp="title" onSearch={_onSearch}>
                {organizations.map((organization) => <Option key={organization.id} title={organization.name} value={organization.id}>{organization.name}</Option>)}
              </Select>
            </Item>
          )}
          <Item
            colon={false}
            label={Language.get('user', 'FIRST_NAME')}
            name="first_name"
            rules={[{
              message: `${Language.get('user', 'FIRST_NAME')} ${Language.get('login', 'NOT_VALID_EMPTY_VALUE')}`,
              required: true
            }]}
          >
            <Input />
          </Item>
          <Item
            colon={false}
            label={Language.get('user', 'LAST_NAME')}
            name="last_name"
            rules={[{
              message: `${Language.get('user', 'LAST_NAME')} ${Language.get('login', 'NOT_VALID_EMPTY_VALUE')}`,
              required: true
            }]}
          >
            <Input />
          </Item>
          <Item
            colon={false}
            label={Language.get('login', 'EMAIL')}
            name="username"
            rules={[
              { message: Language.get('login', 'EMAIL_NOT_VALID') },
              { message: `${Language.get('login', 'EMAIL')} ${Language.get('login', 'NOT_VALID_EMPTY_VALUE')}`, required: true },
            ]}
          >
            <Input />
          </Item>
          {((user && !editPassword) && (
            <Item colon={false} label={Language.get('login', 'PASSWORD')}>
              <Button type="link" onClick={() => setEditPassword((prev) => !prev)}>{Language.get('common', 'UPDATE')}</Button>
            </Item>
          ))
            || (
              <Fragment>
                <Item
                  colon={false}
                  label={Language.get('login', 'PASSWORD')}
                  name="password"
                  rules={[{
                    message: `${Language.get('user', 'PASSWORD')} ${Language.get('login', 'NOT_VALID_EMPTY_VALUE')}`,
                    required: true,
                  }]}
                >
                  <Input type="password" />
                </Item>
                <Item
                  colon={false}
                  label={Language.get('login', 'CONFIRM_PASSWORD')}
                  name="confirmPassword"
                  rules={[
                    { message: Language.get('user', 'CONFIRM_PASSWORD_REQUIRED'), required: true },
                    {
                      validator: (_, value) => value && value !== form.getFieldValue('password')
                        ? Promise.reject(new Error(Language.get('user', 'PASSWORDS_DONT_MATCH')))
                        : Promise.resolve()
                    }
                  ]}
                >
                  <Input type="password" />
                </Item>
                {user && (
                  <Item colon={false} label={<span></span>}>
                    <Button type="link" onClick={() => setEditPassword((prev) => !prev)}>{Language.get('common', 'CANCEL')}</Button>
                  </Item>
                )}
              </Fragment>
            )}
          <Item
            colon={false}
            label={Language.get('user', 'ROLE')}
            name="role"
            rules={[{
              message: `${Language.get('user', 'ROLE')} ${Language.get('login', 'NOT_VALID_EMPTY_VALUE')}`,
              required: true
            }]}
          >
            <Select>
              {roles.map((role) => (
                <Option key={role.id} value={(role.name).toLowerCase()}>{Language.get('user', role.name)}</Option>
              ))}
            </Select>
          </Item>
          <Item
            colon={false}
            initialValue={false}
            label={Language.get('user', 'IS_DEVELOPER')}
            name="is_dev"
            rules={[{ required: true }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={true}>{Language.get('common', 'YES').toUpperCase()}</Radio.Button>
              <Radio.Button value={false}>{Language.get('common', 'NO').toUpperCase()}</Radio.Button>
            </Radio.Group>
          </Item>
        </div>
        <div className="form-container">
          <div className="form-action-buttons">
            <Button onClick={() => navigate('/user')}>{Language.get('common', 'CANCEL')}</Button>
            <Button type="primary" htmlType="submit">
              <SaveOutlined /> {Language.get('common', 'SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
