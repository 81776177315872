// Importing react libs
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Importing antd libs
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/col/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/row/style/css';
import 'antd/es/select/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import Label from 'helix/ui/label';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app helper
import { Notification } from 'helper/handler/notification';

// Importing app component
import { ImportUpload } from 'components/case/import';

// Importing component style
import './form.scss';
import { MAX_LENGTH_NAME as MAX_LENGTH, idFormValidator } from 'components/common/validators';

// Defining constants
const { Item } = Form;
const MAX_LENGTH_NOTES = 500;

// Exporting component
export const CaseForm = (props) => {
  const { case: caseObj, onImport, onUpdate } = props;
  const Language = useLanguage();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(['1', 'true'].includes(params.get('edit')));

  const onFinish = async (values) => {
    if (caseObj) values['id'] = caseObj?.id;
    const res = await AppAPI.Case.save(values);

    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);

    if (caseObj?.id) onUpdate(res);
    else navigate(`/accessioning/${res.id}`);
    setEditMode(false);
  };

  useEffect(() => {
    form.resetFields();
    if (caseObj && editMode) {
      form.setFieldsValue(caseObj);
    }
  }, [editMode, caseObj]);

  const edit = !caseObj || (caseObj && editMode);

  return (
    <div className="case-form">
      <h2>{Language.get('accessioning', 'ACCESSIONING')}</h2>
      <Form
        form={form}
        initialValues={{ is_v1_array: false, ...caseObj }}
        name="case-form"
        validateMessages={{
          required: Language.get('accessioning', 'NOT_VALID_EMPTY_VALUE'),
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col lg={16}>
            <Row gutter={12}>
              <Col lg={12}>
                <Item
                  label={<Label name={Language.get('case', 'CASE_ID')} title={Language.get('case', 'CASE_ID_INFO')} />}
                  name="external_id"
                  rules={[{ required: true }, idFormValidator(Language.get('accessioning', 'NOT_VALID_ID'))]}
                >
                  {edit
                    ? <Input maxLength={MAX_LENGTH} showCount />
                    : caseObj?.['external_id']
                  }
                </Item>
              </Col>
              <Col lg={12}>
                <Item
                  label={Language.get('case', 'CONSULTANT')}
                  name="consultant"
                  rules={[{
                    max: MAX_LENGTH,
                    message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}`,
                  }]}
                >
                  {edit
                    ? <Input maxLength={MAX_LENGTH} showCount />
                    : caseObj?.consultant
                  }
                </Item>
              </Col>
              <Col lg={12}>
                <Item
                  label={Language.get('case', 'REFERRING_CENTRE')}
                  name="centre"
                  rules={[{
                    max: MAX_LENGTH,
                    message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}`,
                  }]}
                >
                  {edit
                    ? <Input maxLength={MAX_LENGTH} showCount />
                    : caseObj?.centre
                  }
                </Item>
              </Col>
              <Col lg={12}>
                <Item
                  label={Language.get('accessioning', 'BEADCHIP_TYPE')}
                  name="is_v1_array"
                >
                  {edit
                    ? (
                      <Select disabled>
                        <Select.Option value={false}>{Language.get('organization', 'KARYOMAP_V2')}</Select.Option>
                      </Select>
                    )
                    : Language.get('organization', caseObj?.['is_v1_array'] ? 'HUMANKARYOMAP_12_V1' : 'KARYOMAP_V2')
                  }
                </Item>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Item
              label={Language.get('case', 'NOTES')}
              name="notes"
              rules={[{
                max: MAX_LENGTH_NOTES,
                message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH_NOTES}`,
              }]}
            >
              {edit
                ? <Input.TextArea rows={4} maxLength={MAX_LENGTH_NOTES} showCount />
                : <pre>{caseObj?.notes}</pre>
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            className="btn-container"
          >
            <ImportUpload case={caseObj} onImport={onImport} />
            {caseObj && editMode && (
              <Button onClick={() => setEditMode(false)}>{Language.get('common', 'CANCEL')}</Button>
            )}
            {edit
              ? <Button htmlType="submit" key="submit" type="primary">{Language.get('common', 'SAVE')}</Button>
              : <Button key="edit" onClick={() => setEditMode(true)}>{Language.get('common', 'EDIT')}</Button>
            }
          </Col>
        </Row>
      </Form>
    </div>
  );
};
