// Importing React and external libs
import React, { useState, useEffect } from 'react';

// Importing momentjs
import moment from 'moment';

// Setting iso week starting Mondays
moment.locale(navigator.language, {
  week: {
    dow: 1,
  },
});

// Importing Antd
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/date-picker/style/css';
import 'antd/es/form/style/css';
import 'antd/es/select/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { BACKEND_DATE_FORMAT } from 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing Helix components
import Label from 'helix/ui/label';

// Importing app modules
import AppAPI from 'modules/api';

// Importing components
import { Line, Pie } from 'components/chart';

// Importing styles
import './signed.scss';

// Constatnts
const { Item } = Form;
const { Option } = Select;

const DEFAULT_WEEKS = 10;
const LINE_COLOR = {
  'qc_fail': '#fd7e14',
  'qc_pass': '#004a9d',
};
const LINE_DATASET = [
  { key: 'qc_fail', stroke: LINE_COLOR['qc_fail'] },
  { key: 'qc_pass', stroke: LINE_COLOR['qc_pass'] },
];

const PIE_COLOR = {
  'euploid': '#789fdb',
  'mitotic_aneuploid': '#f47437',
  'mitotic_segmental': '#d30000',
  'meiotic_aneuploid': '#ffd84c',
  'meiotic_segmental': '#ffbb00',
  'no_result_no_result': '#a5a7a6',
  'unknown_euploid': '#789fdb',
  'unknown_no_result': '#a5a7a6',
};
const PIE_DATASET = {
  'euploid': { color: PIE_COLOR['euploid'] },
  'mitotic_aneuploid': { color: PIE_COLOR['mitotic_aneuploid'] },
  'meiotic_aneuploid': { color: PIE_COLOR['meiotic_aneuploid'] },
  'mitotic_segmental': { color: PIE_COLOR['mitotic_segmental'] },
  'meiotic_segmental': { color: PIE_COLOR['meiotic_segmental'] },
  'no_result_no_result': { color: PIE_COLOR['no_result_no_result'] },
  'unknown_euploid': { color: PIE_COLOR['unknown_euploid'] },
  'unknown_no_result': { color: PIE_COLOR['unknown_no_result'] },
};

// exporting component
export const Signed = () => {
  const Language = useLanguage();
  const [form] = Form.useForm();
  const [lineData, setLineData] = useState();
  const [pieData, setPieData] = useState();
  const [centre, setCentre] = useState('*');
  const [centres, setCentres] = useState([]);
  const [end, setEnd] = useState(moment());
  const [start, setStart] = useState(moment().subtract(DEFAULT_WEEKS, 'weeks'));
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');

  const disableButton = !(start && end);

  const inputsOpts = {
    end: {
      initialValue: end,
      rules: [{ message: `${Language.get('dashboard', 'DATE')} ${Language.get('dashboard', 'NOT_VALID_EMPTY_VALUE')}`, required: true },],
    },
    start: {
      initialValue: start,
      rules: [{ message: `${Language.get('dashboard', 'DATE')} ${Language.get('dashboard', 'NOT_VALID_EMPTY_VALUE')}`, required: true },],
    },
  };

  const applyFilters = async (payload) => {
    payload.end = payload.end.format(BACKEND_DATE_FORMAT);
    payload.start = payload.start.format(BACKEND_DATE_FORMAT);
    const qcData = await AppAPI.Organization.statistics(payload, 'qc');
    setLineData(qcData.error ? [] : qcData);

    if (isCnvEnabled) {
      const resultData = await AppAPI.Organization.statistics(payload, 'result');
      setPieData(resultData.error ? [] : resultData);
    }
  };
  const onFinish = async (values) => await applyFilters(values);

  useEffect(() => {
    async function _loadData() {
      setCentres(await AppAPI.Organization.centres());
      await applyFilters({
        centre,
        end,
        start,
      });
    }
    _loadData();
  }, []);

  return (
    <Row className='statistic-wrapper' gutter={16} wrap={true}>
      <Col flex={isCnvEnabled && '1 0'}>
        <Line
          context="dashboard"
          data={lineData}
          dataset={LINE_DATASET}
          height={350}
          id='qc-line-chart'
          title={`${Language.get('dashboard', 'SAMPLE')} ${Language.get('dashboard', 'QC')}`}
          width={525}
        />
      </Col>
      {isCnvEnabled && (
        <Col flex={isCnvEnabled && '1 0'}>
          <Pie
            context="dashboard"
            data={pieData}
            dataset={PIE_DATASET}
            height={350}
            id='result-pie-chart'
          />
        </Col>
      )}
      <Col flex="0 0 350px">
        <h5>{Language.get('dashboard', 'SETTINGS')}</h5>
        <Form
          className='statistics-form'
          form={form}
          name="statistics-form"
          onFinish={onFinish}
        >
          <Item
            colon={true}
            label={<Label name={`${Language.get('dashboard', 'TIME_FRAME_START')}`} title={`${Language.get('dashboard', 'TIME_FRAME_START')}`} />}
            name='start'
            {...inputsOpts.start}
          >
            <DatePicker
              format='GGGG-WW'
              disabledDate={(date) => end && date >= end}
              onChange={(value) => setStart(value)}
            />
          </Item>
          <Item
            colon={true}
            label={<Label name={`${Language.get('dashboard', 'TIME_FRAME_END')}`} title={`${Language.get('dashboard', 'TIME_FRAME_END')}`} />}
            name='end'
            {...inputsOpts.end}
          >
            <DatePicker
              format='GGGG-WW'
              disabledDate={(date) => date > moment().endOf('day') || (start && date < start)}
              onChange={(value) => setEnd(value)}
            />
          </Item>
          <Item
            colon={true}
            initialValue={'*'}
            label={<Label name={`${Language.get('dashboard', 'REFERRING_CENTRE')}`} title={`${Language.get('dashboard', 'REFERRING_CENTRE')}`} />}
            name="centre"
          >
            <Select
              value={centre}
              onChange={(value) => setCentre(value)}
            >
              {centres && centres.length > 0 && ['*', ...centres].map((centre) => (<Option key={centre}>{centre}</Option>))}
            </Select>
          </Item>
          <div className="button-wrapper">
            <Button
              className='raw-data-button'
              disabled={disableButton}
              type='primary'
              onClick={() => console.log('Export data!')}
            >
              {Language.get('dashboard', 'EXPORT_DATA')}
            </Button>
            <Button
              className='apply-button'
              disabled={disableButton}
              htmlType="submit"
              key="submit"
              type="primary"
            >
              {Language.get('dashboard', 'APPLY')}
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
