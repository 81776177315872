const CALL_RATE_THRESHOLD = {
  GENOMIC: 0.8,
  EMBRYO: 0.6,
};

export const isBeadChipScanned = (beadchip) => {
  return beadchip.biomaterials.some((bio) => Boolean(bio['file_uri']));
};

export const isFailedBiomaterial = (biomaterial) => {
  const callRate = Number.parseFloat(biomaterial?.stats?.callRate);
  const threshold = biomaterial['bio_type'] === 'trophectoderm'
    ? CALL_RATE_THRESHOLD.EMBRYO
    : CALL_RATE_THRESHOLD.GENOMIC;

  return callRate < threshold;
};

export const sortBeadchips = (plates) =>
  plates.map((plate) => ({
    ...plate,
    beadchips: plate.beadchips.sort((curr, next) => {
      const position1 = curr.biomaterials[0]['plate_position'];
      const position2 = next.biomaterials[0]['plate_position'];

      return (
        Number.parseInt(position1.slice(-2), 10) -
        Number.parseInt(position2.slice(-2), 10)
      );
    }),
  }));
