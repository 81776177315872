// Importing react libs
import React from 'react';

// Importing antd libs
import { Descriptions } from 'antd';
import 'antd/es/descriptions/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './phase.scss';

// Defining constants
const { Item } = Descriptions;

// Exporting component
export const Phase = ({ region }) => {
  const Language = useLanguage();

  const {
    nonSupportive,
    predictedPhases,
    predictedStatus,
    supportive,
  } = region;

  return (
    <Descriptions className='phases' contentStyle={{ minWidth: 100 }} column={1} size="small" bordered>
      <Item label={Language.get('case', 'PREDICTED_PHASE')}>{predictedPhases.join(', ')}</Item>
      <Item label={Language.get('case', 'PREDICTED_STATUS')}>{Language.get('gene', predictedStatus)}</Item>
      <Item label={Language.get('case', 'SUP_EVIDENCE')}>
        {supportive.map(support => <div key={support}>{support}</div>)}
      </Item>
      <Item label={Language.get('case', 'NON_SUP_EVIDENCE')}>
        {nonSupportive.map(contrary => <div key={contrary}>{contrary}</div>)}
      </Item>
    </Descriptions>
  );
};
