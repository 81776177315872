// Importing react libs
import React from 'react';

// Importing antd libs
import { Form, Select, Table } from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/select/style/css';
import 'antd/es/table/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { getStatusForInheritanceType } from 'components/gene/rules';
import { statusValidator } from 'components/gene/validators';

// Importing component style
import './geneList.scss';

// Exporting component
export const BiomaterialGeneRegionList = ({ genes, pedegree, sex, onUpdate }) => {
  const Language = useLanguage();
  const i18n = (key) => Language.get('gene', key);

  const columns = [{
    dataIndex: 'name',
    key: 'name',
    title: Language.get('gene', 'NAME'),
  }, {
    dataIndex: 'chromosome',
    key: 'chromosome',
    title: Language.get('gene', 'CHROM'),
    render: (chrom) => ({ '23': 'X', '24': 'Y' }[chrom] ?? chrom)
  }, {
    dataIndex: 'cytoband',
    key: 'cytoband',
    title: Language.get('gene', 'CYTOBAND'),
  }, {
    dataIndex: 'status',
    key: 'status',
    title: Language.get('biomaterial', 'Status'),
    render: (_, record, index) => {
      const opts = getStatusForInheritanceType(record.type)({ pedegree, sex });
      return (
        <Form.Item
          name={`${record.name}_status`}
          rules={[{ validator: statusValidator({ i18n, inheritanceType: record.type, pedegree, sex }) }]}
        >
          <Select
            placeholder={i18n('TYPE')}
            options={opts.map((option) => ({ label: i18n(option), value: option }))}
            onChange={(newValue) => onUpdate({ id: record.id, index, value: newValue })}
          />
        </Form.Item>
      );
    },
  }];

  return (
    <div className="biomaterial-gene-region-list-wrapper">
      <Table
        columns={columns}
        dataSource={Object.values(genes)}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </div>
  );
};
