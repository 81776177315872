// Importing libs
import pdfMake from 'pdfmake';
import html2canvas from 'html2canvas';

// Importing app helpers
import { COMMON_STYLES, DEFAULT_STYLE, MARGINS } from './styles';
import { content } from './content';
import { footer } from './footer';

export const svgElMap = {
  'KARYOMAP': 'd3fc-canvas.canvas-plot-area',
  'LOGR_CHART': 'd3fc-canvas.webgl-plot-area',
  'B_ALLELE_CHART': 'd3fc-canvas.webgl-plot-area',
};

export const getChartDataUrl = (chartS, elS) => new Promise((res) => {
  const d3fcElement = document.querySelector(`${chartS} ${elS}`);

  if (!d3fcElement) res(null);

  const eventHandler = (event) => {
    html2canvas(document.querySelector(chartS), { scale: 2 }).then((chart) => res(chart.toDataURL()));
    event.target.removeEventListener('draw', eventHandler);
  };

  // add an event listener to capture the canvas WebGL content as dataURL
  // right after the drawing process before the drawing buffer is flushed
  // https://github.com/niklasvh/html2canvas/issues/2362
  d3fcElement.addEventListener('draw', eventHandler);

  // trigger the draw event so that our event handler runs
  d3fcElement.requestRedraw();
});

export const generateReport = async (options, data) => {
  const { language, pageSize, margin, i18n } = options;
  if (!data) throw new Error(i18n('NOT_VALID_DATA'));

  const font = language === 'cn' ? 'Noto Sans SC' : 'Noto Sans';
  const fontPath = font.toLowerCase().replace(' ', '-');

  pdfMake.fonts = {
    [font]: {
      normal: `${window.location.origin}/fonts/${fontPath}/regular.ttf`,
      bold: `${window.location.origin}/fonts/${fontPath}/bold.ttf`,
    },
  };

  return new Promise((resolve, reject) => {
    try {
      const pdf = pdfMake.createPdf({
        content: content({ data, i18n }),
        defaultStyle: { ...DEFAULT_STYLE, font },
        footer: footer({ data, i18n }),
        pageMargins: MARGINS[margin],
        pageSize: pageSize.toUpperCase(),
        styles: COMMON_STYLES,
        pageBreakBefore: (currentNode, _followingNodesOnPage, nodesOnNextPage, /* previousNodesOnPage */) => (
          currentNode.style === 'nonbreaking' &&
          nodesOnNextPage[0]?.style === 'nonbreaking'
        ),
      });
      pdf.getBlob(resolve);
    } catch (error) {
      reject(error);
    }
  });
};
