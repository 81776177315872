const INHERITANCE_TYPES = {
  AUTOSOMAL_DOMINANT: 'autosomal_dominant',
  AUTOSOMAL_RECESSIVE: 'autosomal_recessive',
  X_LINKED_DOMINANT: 'x_linked_dominant',
  X_LINKED_RECESSIVE: 'x_linked_recessive',
  UNKNOWN: 'unknown',
};

const STATUS = {
  HEMIZYGOUS_AFFECTED: 'hemizygous_affected',
  HEMIZYGOUS_AFFECTED_MATERNAL: 'hemizygous_affected_maternal',
  HEMIZYGOUS_NORMAL: 'hemizygous_normal',
  HETEROZYGOUS_AFFECTED: 'heterozygous_affected',
  HETEROZYGOUS_AFFECTED_MATERNAL: 'heterozygous_affected_maternal',
  HETEROZYGOUS_AFFECTED_PATERNAL: 'heterozygous_affected_paternal',
  HETEROZYGOUS_CARRIER: 'heterozygous_carrier',
  HETEROZYGOUS_CARRIER_MATERNAL: 'heterozygous_carrier_maternal',
  HETEROZYGOUS_CARRIER_PATERNAL: 'heterozygous_carrier_paternal',
  HETEROZYGOUS: 'heterozygous',
  HOMOZYGOUS_AFFECTED: 'homozygous_affected',
  HOMOZYGOUS_NORMAL: 'homozygous_normal',
  UNAFFECTED: 'unaffected',
  UNKNOWN: 'unknown'
};

const SEX = {
  FEMALE: 'f',
  MALE: 'm',
  UNKNOWN: 'u',
};

const PEDEGREE = {
  'father': 'father',
  'mother': 'mother',
  'matern_aunt': 'matern_aunt',
  'matern_grand_father': 'matern_grand_father',
  'matern_grand_mother': 'matern_grand_mother',
  'matern_uncle': 'matern_uncle',
  'patern_aunt': 'patern_aunt',
  'patern_grand_father': 'patern_grand_father',
  'patern_grand_mother': 'patern_grand_mother',
  'patern_uncle': 'patern_uncle',
  'sample': 'sample',
  'sample_reference': 'sample_reference',
  'sibling': 'sibling',
};


const ALL_SEX = Object.values(SEX);
const REF_PEDEGREE_MALE = [
  PEDEGREE.matern_grand_father,
  PEDEGREE.matern_uncle,
  PEDEGREE.patern_grand_father,
  PEDEGREE.patern_uncle
];
const REF_PEDEGREE_FEMALE = [
  PEDEGREE.matern_aunt,
  PEDEGREE.matern_grand_mother,
  PEDEGREE.patern_aunt,
  PEDEGREE.patern_grand_mother,
];
const REF_PEDEGREE_VARIABLE_SEX = [PEDEGREE.sample_reference, PEDEGREE.sibling];

/**
 * Structure of this object for validation.
 *  - If sex is fixed we directly provide statuses
 *  - If sex needs to be defined by user (is variable) we use sex to identify correct array of statuses
 *
 * {
 *   Inheritance type (gene type): {
 *      "Pedegrees With Fixed Sex": [Array of allowed statuses]
 *      "Pedegress With Variable Sex": {
 *         "Sex (sibling or sample reference)": [Array of allowed statuses]
 *      }
 *   }
 * }
 */

const ALLOWED_STATUS_FOR_INHERITANCE_TYPE = {
  [INHERITANCE_TYPES.UNKNOWN]: {
    [[PEDEGREE.father, PEDEGREE.mother, ...REF_PEDEGREE_FEMALE, ...REF_PEDEGREE_MALE].join('|')]: [STATUS.UNKNOWN],
    [REF_PEDEGREE_VARIABLE_SEX.join('|')]: {
      [ALL_SEX.join('|')]: [STATUS.UNKNOWN]
    },
  },
  [INHERITANCE_TYPES.AUTOSOMAL_DOMINANT]: {
    [[PEDEGREE.father, PEDEGREE.mother].join('|')]: [
      STATUS.HETEROZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
    ],
    [[...REF_PEDEGREE_FEMALE, ...REF_PEDEGREE_MALE].join('|')]: [
      STATUS.HETEROZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
      STATUS.UNKNOWN,
    ],
    [REF_PEDEGREE_VARIABLE_SEX.join('|')]: {
      [ALL_SEX.join('|')]: [
        STATUS.HETEROZYGOUS_AFFECTED_MATERNAL,
        STATUS.HETEROZYGOUS_AFFECTED_PATERNAL,
        STATUS.HOMOZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_NORMAL,
        STATUS.UNKNOWN,
      ]
    },
  },
  [INHERITANCE_TYPES.AUTOSOMAL_RECESSIVE]: {
    [[PEDEGREE.father, PEDEGREE.mother].join('|')]: [
      STATUS.HETEROZYGOUS_CARRIER,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
    ],
    [[...REF_PEDEGREE_FEMALE, ...REF_PEDEGREE_MALE].join('|')]: [
      STATUS.HETEROZYGOUS_CARRIER,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
      STATUS.UNKNOWN
    ],
    [REF_PEDEGREE_VARIABLE_SEX.join('|')]: {
      [ALL_SEX.join('|')]: [
        STATUS.HETEROZYGOUS_CARRIER_MATERNAL,
        STATUS.HETEROZYGOUS_CARRIER_PATERNAL,
        STATUS.HOMOZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_NORMAL,
        STATUS.UNKNOWN
      ]
    },
  },
  [INHERITANCE_TYPES.X_LINKED_DOMINANT]: {
    [PEDEGREE.father]: [
      STATUS.HEMIZYGOUS_AFFECTED,
      STATUS.HEMIZYGOUS_NORMAL,
    ],
    [PEDEGREE.mother]: [
      STATUS.HETEROZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
    ],
    [REF_PEDEGREE_FEMALE.join('|')]: [
      STATUS.HETEROZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
      STATUS.UNKNOWN,
    ],
    [REF_PEDEGREE_MALE.join('|')]: [
      STATUS.HEMIZYGOUS_AFFECTED,
      STATUS.HEMIZYGOUS_NORMAL,
      STATUS.UNKNOWN
    ],
    [PEDEGREE.sibling]: {
      [SEX.FEMALE]: [
        STATUS.HETEROZYGOUS_AFFECTED_MATERNAL,
        STATUS.HETEROZYGOUS_AFFECTED_PATERNAL,
        STATUS.HOMOZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_NORMAL,
        STATUS.UNKNOWN,
      ],
      [SEX.MALE]: [
        STATUS.HEMIZYGOUS_AFFECTED,
        STATUS.HEMIZYGOUS_NORMAL,
        STATUS.UNKNOWN
      ],
      [SEX.UNKNOWN]: [
        STATUS.HETEROZYGOUS_AFFECTED_MATERNAL, // female
        STATUS.HETEROZYGOUS_AFFECTED_PATERNAL, // female
        STATUS.HOMOZYGOUS_AFFECTED, // female
        STATUS.HOMOZYGOUS_NORMAL, // female
        STATUS.HEMIZYGOUS_AFFECTED, // male
        STATUS.HEMIZYGOUS_NORMAL, // male
        STATUS.UNKNOWN,
      ]
    },
    [PEDEGREE.sample_reference]: {
      [SEX.FEMALE]: [
        STATUS.HETEROZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_NORMAL,
        STATUS.UNKNOWN,
      ],
      [SEX.MALE]: [
        STATUS.HEMIZYGOUS_AFFECTED,
        STATUS.HEMIZYGOUS_NORMAL,
        STATUS.UNKNOWN
      ],
      [SEX.UNKNOWN]: [
        STATUS.HETEROZYGOUS_AFFECTED, // female
        STATUS.HOMOZYGOUS_AFFECTED, // female
        STATUS.HOMOZYGOUS_NORMAL, // female
        STATUS.HEMIZYGOUS_AFFECTED, // male
        STATUS.HEMIZYGOUS_NORMAL, // male
        STATUS.UNKNOWN,
      ]
    }
  },
  [INHERITANCE_TYPES.X_LINKED_RECESSIVE]: {
    [PEDEGREE.father]: [
      STATUS.HEMIZYGOUS_AFFECTED,
      STATUS.HEMIZYGOUS_NORMAL,
    ],
    [PEDEGREE.mother]: [
      STATUS.HETEROZYGOUS_CARRIER,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
    ],
    [REF_PEDEGREE_FEMALE.join('|')]: [
      STATUS.HETEROZYGOUS_CARRIER,
      STATUS.HOMOZYGOUS_AFFECTED,
      STATUS.HOMOZYGOUS_NORMAL,
      STATUS.UNKNOWN,
    ],
    [REF_PEDEGREE_MALE.join('|')]: [
      STATUS.HEMIZYGOUS_AFFECTED,
      STATUS.HEMIZYGOUS_NORMAL,
      STATUS.UNKNOWN
    ],
    [PEDEGREE.sibling]: {
      [SEX.FEMALE]: [
        STATUS.HETEROZYGOUS_CARRIER_MATERNAL,
        STATUS.HETEROZYGOUS_CARRIER_PATERNAL,
        STATUS.HOMOZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_NORMAL,
        STATUS.UNKNOWN,
      ],
      [SEX.MALE]: [
        STATUS.HEMIZYGOUS_AFFECTED,
        STATUS.HEMIZYGOUS_NORMAL,
        STATUS.UNKNOWN
      ],
      [SEX.UNKNOWN]: [
        STATUS.HETEROZYGOUS_CARRIER_MATERNAL, // female
        STATUS.HETEROZYGOUS_CARRIER_PATERNAL, // female
        STATUS.HOMOZYGOUS_AFFECTED, // female
        STATUS.HOMOZYGOUS_NORMAL, // female
        STATUS.HEMIZYGOUS_AFFECTED, // male
        STATUS.HEMIZYGOUS_NORMAL, // male
        STATUS.UNKNOWN,
      ]
    },
    [PEDEGREE.sample_reference]: {
      [SEX.FEMALE]: [
        STATUS.HETEROZYGOUS_CARRIER,
        STATUS.HOMOZYGOUS_AFFECTED,
        STATUS.HOMOZYGOUS_NORMAL,
        STATUS.UNKNOWN,
      ],
      [SEX.MALE]: [
        STATUS.HEMIZYGOUS_AFFECTED,
        STATUS.HEMIZYGOUS_NORMAL,
        STATUS.UNKNOWN
      ],
      [SEX.UNKNOWN]: [
        STATUS.HETEROZYGOUS_CARRIER, // female
        STATUS.HOMOZYGOUS_AFFECTED, // female
        STATUS.HOMOZYGOUS_NORMAL, // female
        STATUS.HEMIZYGOUS_AFFECTED, // male
        STATUS.HEMIZYGOUS_NORMAL, // male
        STATUS.UNKNOWN,
      ]
    }
  },
};

const matchKey = (value) => (key) => new RegExp(`^(${key})$`).test(value);

export const getStatusForInheritanceType = (inheritanceType) => ({ pedegree, sex }) => {
  const allOptions = Object.values(STATUS);

  if (!inheritanceType) return allOptions;

  const inheritanceTypeRules = ALLOWED_STATUS_FOR_INHERITANCE_TYPE[inheritanceType];
  const pedegreeKey = Object.keys(inheritanceTypeRules).find(matchKey(pedegree));

  if (!pedegreeKey) allOptions;

  const pedegreeRules = inheritanceTypeRules[pedegreeKey];
  const sexKey = Object.keys(pedegreeRules).find(matchKey(sex ?? 'u'));

  return [PEDEGREE.sample_reference, PEDEGREE.sibling].includes(pedegree)
    ? pedegreeRules[sexKey]
    : pedegreeRules;
};

export const validateStatusForInheritanceType = ({ inheritanceType, pedegree, sex, status }) => {
  const opts = getStatusForInheritanceType(inheritanceType)({ pedegree, sex });
  return opts.includes(status);
};
