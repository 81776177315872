// Importing React and external libs
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import { default as HelixList } from 'helix/ui/list';

// Importing app modules
import AppAPI from 'modules/api';

// Importing styles
import './search.scss';

const getRefFromAnalysis = (analysis) => {
  const biomaterials = Array.isArray(analysis.biomaterials) ? analysis.biomaterials : [];
  const ref = biomaterials.find(bio => bio['biomaterial_designation'] === 'reference');
  return ref;
};

// Search Component
export const Search = () => {
  const Language = useLanguage();
  const location = useLocation();
  const [analysesListKey, setAnalysesListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [biomaterialsListKey, setBiomaterialsListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [casesListKey, setCasesListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [search, setSearch] = useState();

  const analysesColumns = [{
    dataIndex: 'analysis_external_id',
    sorter: true,
    title: Language.get('analysis', 'ANALYSIS_ID'),
    render: (value, record) => <Link to={`/case/${record.case}/analysis/${record.id}`}>{value}</Link>
  }, {
    key: 'reference_sample',
    title: Language.get('accessioning', 'REFERENCE_SAMPLE'),
    render: (_, record) => getRefFromAnalysis(record)?.['biomaterial_name']
  }, {
    key: 'reference_type',
    title: Language.get('accessioning', 'REFERENCE_TYPE'),
    render: (_, record) => {
      const ref = getRefFromAnalysis(record);
      return ref ? Language.get('biomaterial', ref['biomaterial_bio_type']) : '';
    }
  }, {
    dataIndex: 'notes',
    title: Language.get('common', 'NOTES'),
  }, {
    dataIndex: 'created_on',
    sorter: true,
    title: Language.get('common', 'DATE_CREATED'),
    render: (value) => (new Date(value)).format(),
  }, {
    dataIndex: 'updated_on',
    sorter: true,
    title: Language.get('common', 'DATE_MODIFIED'),
    render: (value) => (new Date(value)).format(),
  }];

  const biomaterialsColumns = [{
    dataIndex: 'name',
    sorter: true,
    title: Language.get('biomaterial', 'SAMPLE_ID'),
    render: (name, record) => {
      const { biomaterial_analyses: bioAnalyses, case: caseId, } = record;
      let link = '';
      if (bioAnalyses?.[0]) {
        const { analysis_id: analysisId, biomaterial_id: biomaterialId } = bioAnalyses[0];
        link = `/case/${caseId}/analysis/${analysisId}/sample/${biomaterialId}`;
      } else {
        link = `/accessioning/${caseId}`;
      }
      return <Link to={link}>{name}</Link>;
    }
  }, {
    dataIndex: 'tube_id',
    sorter: true,
    title: Language.get('biomaterial', 'TUBE_ID'),
  }, {
    dataIndex: 'barcode',
    sorter: true,
    title: Language.get('biomaterial', 'BARCODE'),
  }, {
    dataIndex: 'beadchip_barcode',
    sorter: true,
    title: Language.get('report', 'BEADCHIP_BARCODE'),
    render: (barcode) => <Link to={`/metrics?beadchip=${barcode}`}>{barcode}</Link>
  }, {
    dataIndex: 'sub_array_id',
    sorter: true,
    title: Language.get('biomaterial', 'sub_array_id'),
  }, {
    dataIndex: 'designation',
    sorter: true,
    title: Language.get('biomaterial', 'DESIGNATION'),
    render: (value) => value && Language.get('biomaterial', value),
  }, {
    dataIndex: 'pedegree',
    sorter: true,
    title: Language.get('biomaterial', 'PEDEGREE'),
    render: (value) => Language.get('biomaterial', value),
  }, {
    dataIndex: 'bio_type',
    sorter: true,
    title: Language.get('biomaterial', 'TYPE'),
    render: (value) => Language.get('biomaterial', value),
  }, {
    dataIndex: 'created_on',
    sorter: true,
    title: Language.get('common', 'DATE_CREATED'),
    render: (value) => (new Date(value)).format(),
  }, {
    dataIndex: 'updated_on',
    sorter: true,
    title: Language.get('common', 'DATE_MODIFIED'),
    render: (value) => (new Date(value)).format(),
  }];

  const casesColumns = [{
    dataIndex: 'external_id',
    sorter: true,
    title: Language.get('case', 'CASE_ID'),
    render: (value, record) => <Link to={`/accessioning/${record.id}`}>{value}</Link>,
  }, {
    dataIndex: 'mother_lastname',
    sorter: true,
    title: Language.get('case', 'LAST_NAME'),
  }, {
    dataIndex: 'mother_firstname',
    sorter: true,
    title: Language.get('case', 'FIRST_NAME'),
  }, {
    dataIndex: 'centre',
    sorter: true,
    title: Language.get('case', 'REFERRING_CENTRE'),
  }, {
    dataIndex: 'consultant',
    sorter: true,
    title: Language.get('case', 'CONSULTANT'),
  }, {
    dataIndex: 'created_on',
    sorter: true,
    title: Language.get('common', 'DATE_CREATED'),
    render: (value) => (new Date(value)).format(),
  }, {
    dataIndex: 'updated_on',
    sorter: true,
    title: Language.get('common', 'DATE_MODIFIED'),
    render: (value) => (new Date(value)).format(),
  }];

  const searchAnalyses = async ({ limit, offset, order }) => {
    const filters = { 'search': search };
    order = order || '-updated_on';
    return await AppAPI.Analysis.list({ filters, limit, offset, order });
  };

  const searchBiomaterials = async ({ limit, offset, order }) => {
    const filters = { 'search': search };
    order = order || '-updated_on';
    return await AppAPI.Biomaterial.list({ filters, limit, offset, order });
  };

  const searchCases = async ({ limit, offset, order }) => {
    const filters = { 'search': search };
    order = order || '-updated_on';
    return await AppAPI.Case.list({ filters, limit, offset, order });
  };

  const _highlight = () => {
    const mark = new RegExp(`${search}`, 'ig');
    document.querySelectorAll('mark').forEach((elm) => {
      const parent = elm.parentNode;
      parent.innerHTML = parent.innerText.replace(/(<mark>|<\/mark>)/, '');
    });
    [...document.querySelectorAll('TD:not(.action)')].forEach((elm) => {
      elm = elm.querySelector('a') ?? elm;
      const [match] = elm.innerText.match(mark) || [];
      if (match) {
        elm.innerHTML = elm.innerText.replace(mark, `<mark>${match}</mark>`);
      }
    });
  };

  useEffect(() => {
    if (search) {
      setAnalysesListKey((Math.random() + 1).toString(36).substring(2));
      setBiomaterialsListKey((Math.random() + 1).toString(36).substring(2));
      setCasesListKey((Math.random() + 1).toString(36).substring(2));
    }
  }, [search]);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    setSearch(params.get('q'));
  }, [location]);

  return (
    <Fragment>
      <Fragment>
        <h1>{Language.get('analysis', 'ANALYSES')}</h1>
        <HelixList
          columns={analysesColumns}
          context="common"
          dataField="results"
          dataCountField="count"
          defaultSort={{ field: 'updated_on', order: 'descend' }}
          key={analysesListKey}
          name="search-analyses"
          pageSize={5}
          reader={searchAnalyses}
          onLoad={_highlight}
          rowKey="id"
        />
      </Fragment>
      <Fragment>
        <h1>{Language.get('biomaterial', 'BIOMATERIALS')}</h1>
        <HelixList
          columns={biomaterialsColumns}
          context="common"
          dataField="results"
          dataCountField="count"
          defaultSort={{ field: 'updated_on', order: 'descend' }}
          key={biomaterialsListKey}
          name="search-biomaterials"
          pageSize={5}
          reader={searchBiomaterials}
          onLoad={_highlight}
          rowKey="id"
        />
      </Fragment>
      <Fragment>
        <h1>{Language.get('case', 'CASES')}</h1>
        <HelixList
          columns={casesColumns}
          context="common"
          dataField="results"
          dataCountField="count"
          defaultSort={{ field: 'updated_on', order: 'descend' }}
          key={casesListKey}
          name="search-cases"
          pageSize={5}
          reader={searchCases}
          onLoad={_highlight}
          rowKey="id"
        />
      </Fragment>
    </Fragment>
  );
};
