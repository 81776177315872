export const snps = [
  {
    name: 'ATXN10',
    paternal: {
      left: 33,
      main: 11,
      right: 0,
    },
    maternal: {
      left: 26,
      main: 2,
      right: 0,
    },
    total: {
      left: 283,
      main: 63,
      right: 0,
    },
  },
  {
    name: 'EMD',
    paternal: {
      left: 23,
      main: 12,
      right: 0,
    },
    maternal: {
      left: 28,
      main: 5,
      right: 0,
    },
    total: {
      left: 273,
      main: 53,
      right: 0,
    },
  },
];
