// Importing react and external libs
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';

// Importing antd libs
import { Select } from 'antd';
import 'antd/es/select/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

const fetchDiseases = async ({ search = '', selectedId }) => {
  let objects = [];
  const res01 = await AppAPI.DiseaseDictionary.list({
    filters: { 'name__icontains': [search] }
  });
  objects = res01?.results || objects;
  if (selectedId && !selectedId.toString().endsWith('-custom') && !objects.find((disease) => disease.id === selectedId)) {
    const res02 = await AppAPI.DiseaseDictionary.list({
      filters: { 'id': [selectedId] }
    });
    objects = [...objects, ...(res02?.results ?? [])];
  }
  return objects;
};

const DiseaseSelector = ({
  value,
  onChange,
  getLabel = (disease) => disease.name,
  caseRegions = [],
  ...restProps
}) => {
  const [diseases, setDiseases] = useState([]);
  const [options, setOptions] = useState([]);
  const Language = useLanguage();

  const _loadDiseases = useCallback(async (search, selectedId) => {
    const diseases = await fetchDiseases({ search, selectedId });
    const caseDiseases = caseRegions.map(({
      id,
      chromosome,
      disease_dictionary: diseaseDictionaryId,
      name,
      length,
      start_at: startAt,
    }) => ({
      chromosome: chromosome,
      gene: `${name} (${Language.get('gene', 'GENE_REGION_SELECTOR_SUFFIX')})`,
      name: `${name}:${chromosome}:${startAt}-${startAt + length}`,
      id: `${diseaseDictionaryId ?? id}-custom`,
      length,
      start_at: startAt,
    }));
    const combinedDiseases = [...caseDiseases, ...diseases];
    setDiseases(combinedDiseases);
    setOptions(combinedDiseases.map((disease) => ({ label: getLabel(disease), value: disease.id })));
  }, []);

  const _debouncedLoadDiseases = useCallback(debounce(_loadDiseases, 300), []);

  useEffect(() => {
    _debouncedLoadDiseases('', value);
  }, [value]);

  return (
    <Select
      style={{ minWidth: '14ch' }}
      allowClear
      optionFilterProp="label"
      options={options}
      showSearch
      value={value}
      onChange={(value) => {
        onChange?.(value, diseases.find(disease => disease.id === value));
      }}
      onSearch={(search) => _debouncedLoadDiseases(search, value)}
      {...restProps}
    />
  );
};

export default DiseaseSelector;