// Importing react libs
import React from 'react';

// Importing Helix components
import RawContent from 'helix/ui/rawContent';


// Importing component style
import './log.scss';

const biomaterialDesc = (sample) => {
  const {
    beadchip_barcode: barcode,
    biomaterial_designation: designation,
    biomaterial_subarray: subarray,
    biomaterial_name: name,
  } = sample;

  let desc = `${((designation ?? '') || 'SAMPLE').toUpperCase()}: ${name}`;

  if (barcode && subarray) desc += ` - ${barcode}_${subarray}`;

  return `${desc}.gtc`;
};

export const Log = (props) => {
  const { analysis } = props;
  const trioLog = analysis?.['log_content'] ?? '';
  const { father, mother, reference, embryos } = analysis.biomaterialsByDesignation;

  return (
    <div className="logs">
      <RawContent>
        {[mother, father, reference].map(sample => (
          <h3 key={sample.id}>{biomaterialDesc(sample)}</h3>
        ))}
        <div className='log-content'>{trioLog}</div>
        <br />
        <br />
        {embryos.map(embryo => (
          <section key={embryo.id}>
            <h3>{biomaterialDesc(embryo)}</h3>
            <div className='log-content'>{embryo['log_content']?.replace?.(/embryo/g, 'sample')}</div>
            <br />
          </section>
        ))}
      </RawContent>
    </div>
  );
};
