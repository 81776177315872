// Importing react libs
import React from 'react';

// Importing antd libs
import { Typography } from 'antd';
import 'antd/es/typography/style/css';

// Importing Helix components
import RawContent from 'helix/ui/rawContent';

import './warning.scss';

// Defining constants
const { Title } = Typography;

/**
 *
 * @param {Object} props
 * @param {string} title identifier / heading for the warnings
 * @param {Array<string>} warnings the list of warnings to display
 */
export const Warning = ({ title, warnings }) => {
  return (
    <RawContent>
      <Title level={4}>{title}</Title>
      {warnings.map((warning, idx) => <div key={idx}>{warning}</div>)}
    </RawContent>
  );
};
