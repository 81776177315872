// Importing react libs
import React, { useEffect, useState } from 'react';

// Importing antd libs
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Modal,
} from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/button/style/css';
import 'antd/es/checkbox/style/css';
import 'antd/es/form/style/css';
import 'antd/es/modal/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { MChromosomeDictionary } from 'helix-modules/chromosome';

// Importing Helix components
import { default as HelixList, tableColumnFilterSearch } from 'helix/ui/list';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { GeneForm } from 'components/gene';

// Importing component style
import './list.scss';

// Exporting component
export const List = (props) => {
  const { case: caseObj, onDelete: onDeleteProp, onToogleDisableRegion, onUpdate } = props;
  const Language = useLanguage();
  const [form] = Form.useForm();
  const [chromList, setChromList] = useState([]);
  const [gene, setGene] = useState();
  const [geneRegionCount, setGeneRegionCount] = useState(0);
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const GENE_REGION_LIMIT = 10;

  const columns = [{
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    title: Language.get('gene', 'GENE'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'chromosome',
    key: 'chromosome',
    sorter: true,
    render: (value) => ({ '23': 'X', '24': 'Y' }[value] || value),
    title: Language.get('gene', 'CHROM'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'start_at',
    key: 'start_at',
    sorter: true,
    title: Language.get('gene', 'START'),
  }, {
    dataIndex: 'end',
    key: 'end',
    title: Language.get('gene', 'END'),
  }, {
    dataIndex: 'cytoband',
    key: 'cytoband',
    title: Language.get('gene', 'CYTOBAND'),
  }, {
    dataIndex: 'links',
    key: 'links',
    title: Language.get('gene', 'LINKS'),
    sorter: true,
    render: (links) => (
      <div className="gene-links">
        {links.map(({ text, url }, index) => (
          <a href={url} key={index} rel="noreferrer" target="_blank">{text}</a>
        ))}
      </div>
    ),
  }, {
    dataIndex: 'gene_type',
    key: 'gene_type',
    sorter: true,
    title: Language.get('gene', 'TYPE'),
    render: (value) => (value && Language.get('gene', value)) ?? '',
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'mutation_site',
    key: 'mutation_site',
    sorter: true,
    title: Language.get('gene', 'MUTATION_SITE'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'mother',
    key: 'mother',
    title: Language.get('gene', 'MOTHER'),
  }, {
    dataIndex: 'father',
    key: 'father',
    title: Language.get('gene', 'FATHER'),
  }, {
    dataIndex: 'references',
    key: 'references',
    title: Language.get('gene', 'REFERENCES'),
  }];

  const onDelete = async (item) => {
    const res = await AppAPI.GeneRegion.delete(item.id);
    if (res?.error) res.msg = Language.get('accessioning', res.msg);
    onDeleteProp?.();
    return res;
  };
  const onEdit = async (item) => { setGene(item); setShowAddEditModal(true); };
  const reader = async ({ filters, limit, offset, order }) => {
    const caseId = caseObj?.id;
    if (caseId) {
      const mapper = {
        name: 'name__icontains',
        chromosome: 'chromosome__icontains',
        'gene_type': 'gene_type__icontains',
        'mutation_site': 'mutation_site__icontains',
      };
      filters = Object.keys(filters).reduce((newFilters, field) => {
        newFilters[mapper[field] || field] = filters[field];
        return newFilters;
      }, {});
      filters['case'] = [caseId];
      const res = await AppAPI.GeneRegion.list({ filters, limit, offset, order });
      setGeneRegionCount(res?.results?.length || 0);
      return res;
    }
    setGeneRegionCount(0);
    return { result: [] };
  };
  const parser = (objects) => {
    // calculate cytobands and other stuff
    const _diseaseStatus = (object, parent) => {
      object[parent] = object.biomaterials.find(
        (obj) => obj.biomaterial_designation === parent
      )?.status;
      object[parent] = object[parent] && Language.get('gene', object[parent]);
    };

    let ensemblDomain = 'www';
    let hg = '38';
    if (caseObj?.is_v1_array) { // if checked then hg19
      ensemblDomain = 'grch37';
      hg = '19';
    }

    return (objects || []).map((object) => {
      object.end = object['start_at'] + object.length;
      // get cytoband by position
      object.cytoband = MChromosomeDictionary.getCytoband({
        chromosome: chromList.find(({ label }) => label === object.chromosome),
        position: object['start_at'] + (object.length / 2),
      });

      // generate links
      const params = `${object.chromosome}:${object['start_at']}-${object.end}`;
      object.links = [{
        text: 'UCSC',
        url: `http://genome.ucsc.edu/cgi-bin/hgTracks?db=hg${hg}&position=chr${params}`,
      }, {
        text: 'Ensembl',
        url: `http://${ensemblDomain}.ensembl.org/Homo_sapiens/Location/View?r=${params}`,
      }];

      _diseaseStatus(object, 'mother');
      _diseaseStatus(object, 'father');
      object.references = (
        <div>
          {object.biomaterials.filter(
            (obj) => obj.biomaterial_designation === 'reference'
          ).map(
            (ref) => (
              <div key={ref.id}>
                {Language.get('biomaterial', ref['biomaterial_pedegree'])}: {Language.get('gene', ref.status)}
              </div>
            )
          )}
        </div>
      );
      return object;
    });
  };

  useEffect(() => {
    setListKey((Math.random() + 1).toString(36).substring(2));
  }, [caseObj]);

  useEffect(() => {
    if (chromList) {
      setListKey((Math.random() + 1).toString(36).substring(2));
    }
  }, [chromList]);

  useEffect(() => {
    const loadCytobands = async () => {
      const hg = caseObj?.['is_v1_array'] ? '19' : '38';
      setChromList(await MChromosomeDictionary.load({
        bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
        dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
      }));
    };
    loadCytobands();
  }, []);

  return (
    <div className="gene-list-wrapper">
      <div className="title-with-action">
        <h2>{Language.get('gene', 'GENE_REGION')}</h2>
        <Button
          disabled={caseObj?.['disable_region'] || !caseObj?.id || (geneRegionCount >= GENE_REGION_LIMIT)}
          size="small"
          type="primary"
          onClick={() => { form.resetFields(); setGene(undefined); setShowAddEditModal(true); }}
        >
          {Language.get('common', 'ADD')}
        </Button>
      </div>
      {geneRegionCount >= GENE_REGION_LIMIT && (
        <Alert
          banner
          className="gene-alert"
          message={Language.get('gene', 'GENE_REGION_LIMIT')}
          type="info"
          showIcon
        />
      )}
      <Checkbox
        disabled={geneRegionCount !== 0}
        checked={caseObj?.['disable_region']}
        onChange={(event) => caseObj && onToogleDisableRegion(event.target.checked)}
      >
        {Language.get('gene', 'NO_REGION_SPECIFIED')}
      </Checkbox>
      <HelixList
        columns={columns}
        context="common"
        dataField="results"
        dataCountField="count"
        key={listKey}
        name="gene-regions"
        parser={parser}
        reader={reader}
        rowKey="id"
        showPagination={false}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        open={showAddEditModal}
        title={Language.get('common', gene ? 'EDIT' : 'ADD')}
        width={750}
        onOk={() => form.submit()}
        onCancel={() => Modal.confirm({
          content: Language.get('common', 'ARE_YOU_SURE_CANCEL_EDITION'),
          cancelText: Language.get('common', 'NO'),
          icon: <ExclamationCircleOutlined />,
          okText: Language.get('common', 'YES'),
          onOk: () => {
            form.resetFields();
            setGene(undefined);
            setShowAddEditModal(false);
          },
        })}
      >
        <GeneForm
          case={caseObj}
          form={form}
          gene={gene}
          onUpdate={() => {
            setListKey((Math.random() + 1).toString(36).substring(2));
            setShowAddEditModal(false);
            onUpdate?.();
          }}
        />
      </Modal>
    </div>
  );
};
