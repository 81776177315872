// Importing react libs and external libs
import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash-es';

// Importing antd libs
import {
  Col,
  Collapse,
  Row,
  Select,
  Spin,
  Typography
} from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/collapse/style/css';
import 'antd/es/row/style/css';
import 'antd/es/select/style/css';
import 'antd/es/spin/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useGenomePositions from 'helix-hooks/chart/useGenomePositions';

// Importing Helix components
import Reference from 'helix/chart/haploblock/reference';
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing local components
import CaseViewNavigation from 'components/case/view/navigation';
import CaseViewHaploblock from 'components/case/view/haploblock';
import { Warning } from 'components/analysis/warning';
import useFlanking from 'hooks/useFlanking';
import useLocalStorage from 'hooks/useLocalStorage';

// Importing component style
import './overview.scss';

const { Panel } = Collapse;

const Stat = ({ label, children }) => {
  return (
    <div className="stat">
      <div className="stat-label">{label}:</div>
      <div>{children}</div>
    </div>
  );
};

const BiomaterialStats = ({
  chrom,
  title,
  sampleId,
  sampleName,
  stats,
  children
}) => {
  const { pathname } = useLocation();

  return (
    <Row gutter={16} className='biomaterial-stats'>
      <Col span={2}>
        {title ? <div className='biomaterial-stats-title'>{title}</div> : null}
        <Link to={`${pathname}/sample/${sampleId}/chromosome/${chrom}`}>{sampleName}</Link>
      </Col>
      <Col className='case-stats' span={2}>
        <Stat label="Call rate">{stats.callRate}</Stat>
        <Stat label="AA">{stats.aaRate}</Stat>
        <Stat label="AB">{stats.abRate}</Stat>
        <Stat label="BB">{stats.bbRate}</Stat>
      </Col>
      <Col className='case-stats' span={2}>
        <Stat label="ADO">{stats.adoRate}</Stat>
        <Stat label="Mis-Call">{stats.misCallRate}</Stat>
        <Stat label="X Het Rate">{stats.xHetRate}</Stat>
        <Stat label="Y Call Rate">{stats.yCallRate}</Stat>
      </Col>
      {children}
    </Row>
  );
};

const NavigationControl = ({ label, children }) => {
  return (
    <div className="navigation-control">
      <span className='label'>{label}:</span>
      {children}
    </div>
  );
};

const TwoRegionCol = ({ leftChild, rightChild, twoRegionView }) => (
  <Fragment>
    <Col span={twoRegionView ? 9 : 18}>
      {leftChild}
    </Col>
    {twoRegionView && (
      <Col span={9}>
        {rightChild}
      </Col>
    )}
  </Fragment>
);

const CaseViewOverview = ({
  analysis,
  analysisChartFiles,
  chrom: globalChrom,
  geneRegions,
  setChrom,
}) => {
  const Language = useLanguage();
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');
  const {
    chroms,
    chromPositions,
    isLoading: isLoadingGenomePositions
  } = useGenomePositions('hg38');
  const [enableSnps, setEnableSnps] = useLocalStorage('caseViewEnableSnps', true);
  const [breakpoints, setBreakpoints] = useState(isCnvEnabled);
  const [twoRegionView, setTwoRegionView] = useState(geneRegions.length > 1);
  const secondChromInitial = geneRegions?.[1]?.chromosome ?? globalChrom;
  const [secondChrom, setSecondChrom] = useState(secondChromInitial);
  const flanking = useFlanking({ analysisSetting: analysis?.setting });

  const chromPosition = {
    start: chromPositions[globalChrom]?.start ?? 0,
    end: chromPositions[globalChrom]?.end ?? 0,
  };
  const chromExtent = [chromPosition.start, chromPosition.end];

  const chromPositionTwo = {
    start: chromPositions[secondChrom]?.start ?? 0,
    end: chromPositions[secondChrom]?.end ?? 0,
  };
  const chromExtentTwo = [chromPositionTwo.start, chromPositionTwo.end];

  const [transformedExtent, setTransformedExtent] = useState(chromExtent);
  const [transformedExtentTwo, setTransformedExtentTwo] = useState(chromExtentTwo);

  if (isLoadingGenomePositions) return <Spin />;

  const { biomaterialsByDesignation } = analysis;

  const fatherSample = biomaterialsByDesignation.father;
  const motherSample = biomaterialsByDesignation.mother;
  const referenceSample = biomaterialsByDesignation.reference;
  const samplesByCycle = _.groupBy(biomaterialsByDesignation.embryos, (biomaterial) => {
    return biomaterial['biomaterial_cycle_id'] ?? 'No Cycle';
  });

  const pedegree = referenceSample['biomaterial_pedegree'];
  const [refUpperColor, refLowerColor] = AppAPI.Biomaterial.PEDEGREE_TO_COLOR[pedegree];

  const haploDiseaseRegions = geneRegions
    .map(region => ({
      chromosome: region.chromosome,
      end: region['start_at'] + region.length,
      start: region['start_at'],
      mutationSite: region['mutation_site'],
    }));

  return (
    <section className='case-overview'>
      <Typography.Title level={2}>
        {Language.get('report', 'WARNINGS')}
      </Typography.Title>
      {Object.entries(analysis.warningsBySample).map(([title, warnings]) => (
        <Warning
          key={title}
          title={title}
          warnings={warnings}
        />
      ))}
      <Row className='case-navigation' gutter={16}>
        <Col span={6}>
          <NavigationControl label={Language.get('case', 'SNP_DETAILS')}>
            <Select value={enableSnps} onChange={setEnableSnps}>
              <Select.Option value={false}>Off</Select.Option>
              <Select.Option value={true}>On</Select.Option>
            </Select>
          </NavigationControl>
          {isCnvEnabled && (
            <NavigationControl label={Language.get('case', 'COMMON_BREAKPOINTS')}>
              <Select value={breakpoints} onChange={setBreakpoints}>
                <Select.Option value={false}>Off</Select.Option>
                <Select.Option value={true}>On</Select.Option>
              </Select>
            </NavigationControl>
          )}
          <NavigationControl label={Language.get('case', 'TWO_REGION_VIEW')}>
            <Select value={twoRegionView} onChange={setTwoRegionView}>
              <Select.Option value={false}>Off</Select.Option>
              <Select.Option value={true}>On</Select.Option>
            </Select>
          </NavigationControl>
        </Col>
        <TwoRegionCol
          leftChild={(
            chromPosition.end > 0 ? <CaseViewNavigation
              chrom={globalChrom}
              chroms={chroms}
              chromPosition={chromPosition}
              chromPositions={chromPositions}
              flanking={flanking}
              geneRegions={geneRegions}
              setChrom={setChrom}
              setTransformedExtent={setTransformedExtent}
              defaultRegion={geneRegions[0]}
            /> : null
          )}
          rightChild={(
            chromPositionTwo.end > 0 ? <CaseViewNavigation
              chrom={secondChrom}
              chroms={chroms}
              chromPosition={chromPositionTwo}
              chromPositions={chromPositions}
              flanking={flanking}
              geneRegions={geneRegions}
              setChrom={setSecondChrom}
              setTransformedExtent={setTransformedExtentTwo}
              defaultRegion={geneRegions[1]}
            /> : null
          )}
          twoRegionView={twoRegionView}
        />
      </Row>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Parental Samples" key="1">
          {fatherSample && (
            <BiomaterialStats
              chrom={globalChrom}
              sampleId={fatherSample.id}
              sampleName={fatherSample['biomaterial_name']}
              stats={fatherSample.stats}
              title={Language.get('biomaterial', 'FATHER')}
            >
              <TwoRegionCol
                leftChild={<Reference lowerColor="red" />}
                rightChild={<Reference lowerColor="red" />}
                twoRegionView={twoRegionView}
              />
            </BiomaterialStats>
          )}
          <br />
          {motherSample && (
            <BiomaterialStats
              chrom={globalChrom}
              sampleId={motherSample.id}
              sampleName={motherSample['biomaterial_name']}
              stats={motherSample.stats}
              title={Language.get('biomaterial', 'MOTHER')}
            >
              <TwoRegionCol
                leftChild={<Reference upperColor="orange" lowerColor="green" />}
                rightChild={<Reference upperColor="orange" lowerColor="green" />}
                twoRegionView={twoRegionView}
              />
            </BiomaterialStats>
          )}
        </Panel>
      </Collapse>
      <div className="reference-wrapper">
        {referenceSample && (
          <BiomaterialStats
            chrom={globalChrom}
            sampleId={referenceSample.id}
            sampleName={referenceSample['biomaterial_name']}
            stats={referenceSample.stats}
            title={Language.get('biomaterial', 'REFERENCE')}
          >
            <TwoRegionCol
              leftChild={<Reference lowerColor={refLowerColor} upperColor={refUpperColor} />}
              rightChild={<Reference lowerColor={refLowerColor} upperColor={refUpperColor} />}
              twoRegionView={twoRegionView}
            />
          </BiomaterialStats>
        )}
      </div>
      <Collapse defaultActiveKey={[Object.keys(samplesByCycle)]}>
        {_.map(samplesByCycle, (samples, cycleId) => (
          <Panel key={cycleId ?? '0'} header={cycleId}>
            {samples.map(sample => (
              <Fragment key={sample.id}>
                <BiomaterialStats
                  chrom={globalChrom}
                  sampleId={sample.id}
                  sampleName={sample['biomaterial_name']}
                  stats={sample.stats}
                >
                  <TwoRegionCol
                    leftChild={(
                      <CaseViewHaploblock
                        chrom={globalChrom}
                        chromExtent={chromExtent}
                        diseaseRegions={haploDiseaseRegions.filter(region => region.chromosome === globalChrom)}
                        enableSnps={enableSnps}
                        flanking={flanking}
                        haploblocksFile={analysisChartFiles[sample['bp_analysis_id']]?.['haploblocks']}
                        snpsFile={analysisChartFiles[sample['bp_analysis_id']]?.[`chr${globalChrom}`]}
                        transformedExtent={transformedExtent}
                      />
                    )}
                    rightChild={(
                      <CaseViewHaploblock
                        chrom={secondChrom}
                        chromExtent={chromExtentTwo}
                        diseaseRegions={haploDiseaseRegions.filter(region => region.chromosome === secondChrom)}
                        enableSnps={enableSnps}
                        flanking={flanking}
                        haploblocksFile={analysisChartFiles[sample['bp_analysis_id']]?.['haploblocks']}
                        snpsFile={analysisChartFiles[sample['bp_analysis_id']]?.[`chr${secondChrom}`]}
                        transformedExtent={transformedExtentTwo}
                      />
                    )}
                    twoRegionView={twoRegionView}
                  />
                </BiomaterialStats>
              </Fragment>
            ))}
          </Panel>
        ))}
      </Collapse>
    </section>
  );
};

export default CaseViewOverview;
