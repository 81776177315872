import useFlanking from 'hooks/useFlanking';

// Importing app helper
import { analysisSettings } from './analysisSettings';
import { beadArray } from './qcBeadArray';
import { caseSummary } from './caseSummary';
import { dnaTable } from './qcDNA';
import { pageHeader } from './pageHeader';
import { regionStatistics } from './regionStatistics';
import { regionsSummary } from './regionsSummary';
import { sampleDetails } from './sampleDetails';
import { samplesSummary } from './samplesDetails';
import { section } from './common';
import { signOffHistory } from './signOffHistory';
import { trioInfo } from './trioInfo';

export const content = ({ data, i18n }) => {
  const {
    analysis: {
      biomaterials,
      biomaterialsByDesignation,
      warningsBySample,
      ...analysisInfo
    },
    caseInfo,
    regions,
    icons,
    info,
    chartImages,
  } = data;

  const { embryos, father, mother, reference } = biomaterialsByDesignation;
  const flanking = useFlanking({ analysisSetting: analysisInfo.setting });

  const warningsText = Object.entries(warningsBySample).reduce(
    (accumulated, [sampleKey, warnings]) => {
      const subHeading = { text: sampleKey, bold: true, margin: [0, 0, 0, 2] };
      const warningsText = {
        text: warnings?.join('; ') ?? '',
        margin: [0, 0, 0, 5],
      };
      return [...accumulated, subHeading, warningsText];
    },
    [],
  );

  return [
    pageHeader({
      address: data.info.address,
      image: data.info.logo,
      title: i18n('TITLE'),
    }),
    section(i18n('DESCRIPTION'), data.info.introduction),
    section(i18n('WARNINGS'), warningsText),
    section(i18n('CASE_SUMMARY'), [
      caseSummary({ analysisInfo, caseInfo, i18n }),
      regionsSummary({ i18n, regions }),
      trioInfo({ father, mother, reference, i18n }),
    ]),
    section(i18n('SAMPLE_DETAILS'), samplesSummary({
      biomaterials: embryos,
      i18n,
      icons
    })),
    signOffHistory({ caseInfo, i18n }),
    section(i18n('ANALYSIS_SETTINGS'), analysisSettings({ caseInfo, flanking, i18n, reference })),
    section(i18n('QC_BEAD_ARRAYS'), beadArray({ biomaterials, i18n })),
    section(i18n('QC_DNA'), dnaTable({ biomaterials, i18n })),
    section(i18n('REGION_STATISTICS'), regionStatistics({ i18n, regions, reference })),
    embryos.map((biomaterial) => sampleDetails({ biomaterial, caseInfo, chartImages, i18n, icons, info })),
  ];
};
