// Importing react libs
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

// Importing antd libs
import { Modal, message } from 'antd';
import 'antd/es/modal/style/css';
import 'antd/es/message/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing containers
import { Login } from 'containers';

// Importing app modules
import AppAPI from 'modules/api';

// Importing styles
import './style.scss';

export const DEFAULT_IDLE_TIME_MS = 300_000;
export const EVENT_SESSION_EXPIRED = 'sessionExpired';
export const EVENT_SESSION_REFRESH_FAILURE = 'sessionRefreshFailure';
export const EVENT_SESSION_REFRESH_SUCCESS = 'sessionRefreshSuccess';
export const EVENT_SESSION_STARTED = 'sessionStarted';
export const EVENT_SESSION_STOPPED = 'sessionStopped';
export const SESSION_EXPIRE_BUFFER_TIME_MS = 5_000;
export const TAB_SYNC_TIME_MS = 250;

export default function Session() {
  const Language = useLanguage();
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const { sessionTimeout } = window.app?.user?.organization?.setting?.MISCELLANEOUS ?? {
    sessionTimeout: DEFAULT_IDLE_TIME_MS / 1000
  };

  const { start, pause } = useIdleTimer({
    crossTab: true,
    element: document.body,
    promptBeforeIdle: SESSION_EXPIRE_BUFFER_TIME_MS * 2,
    startManually: true,
    syncTimers: TAB_SYNC_TIME_MS,
    timeout: sessionTimeout * 1000,
    onIdle: () => dispatchEvent(new CustomEvent(EVENT_SESSION_EXPIRED)),
  });

  const setTokenRefreshTimer = () => {
    const sessionExpiresAt = AppAPI.User.sessionExpiresIn() - SESSION_EXPIRE_BUFFER_TIME_MS;
    if (sessionExpiresAt > 0) {
      const tokenRefreshTimer = setTimeout(async () => {
        const res = await AppAPI.User.refreshToken();
        dispatchEvent(
          res.error
            ? new CustomEvent(EVENT_SESSION_REFRESH_FAILURE)
            : new CustomEvent(EVENT_SESSION_REFRESH_SUCCESS)
        );
      }, sessionExpiresAt);
      return tokenRefreshTimer;
    } else {
      dispatchEvent(new CustomEvent(EVENT_SESSION_EXPIRED));
    }
  };

  useEffect(() => {
    let timerId = null;

    const onSessionExpired = () => {
      setShowLoginPopup(true);
      pause();
      AppAPI.User.OAuth.clear();
      clearTimeout(timerId);

    };
    const onSessionRefreshFailure = () => {
      setShowLoginPopup(true);
      pause();
      clearTimeout(timerId);
    };
    const onSessionRefreshSuccess = () => {
      setShowLoginPopup(false);
      clearTimeout(timerId);
      timerId = setTokenRefreshTimer();
    };
    const onSessionStarted = () => {
      setShowLoginPopup(false);
      clearTimeout(timerId);
      timerId = setTokenRefreshTimer();
      start();
    };
    const onSessionStopped = () => {
      setShowLoginPopup(false);
      pause();
      clearTimeout(timerId);
    };

    // Hide the popup on all other tabs if the user logged in on some tab
    const listenToStorage = ({ key, newValue, oldValue }) => {
      if (
        key === AppAPI.User.OAuth.EXPIRES_AT_KEY &&
        oldValue === null &&
        newValue !== null
      ) {
        setShowLoginPopup(false);
      }
    };

    addEventListener(EVENT_SESSION_EXPIRED, onSessionExpired);
    addEventListener(EVENT_SESSION_REFRESH_FAILURE, onSessionRefreshFailure);
    addEventListener(EVENT_SESSION_REFRESH_SUCCESS, onSessionRefreshSuccess);
    addEventListener(EVENT_SESSION_STARTED, onSessionStarted);
    addEventListener(EVENT_SESSION_STOPPED, onSessionStopped);
    addEventListener('storage', listenToStorage);

    return () => {
      clearTimeout(timerId);
      removeEventListener(EVENT_SESSION_EXPIRED, onSessionExpired);
      removeEventListener(EVENT_SESSION_REFRESH_SUCCESS, onSessionRefreshSuccess);
      removeEventListener(EVENT_SESSION_REFRESH_FAILURE, onSessionRefreshFailure);
      removeEventListener(EVENT_SESSION_STARTED, onSessionStarted);
      removeEventListener(EVENT_SESSION_STOPPED, onSessionStopped);
      removeEventListener('stroage', listenToStorage);
    };
  }, []);


  return showLoginPopup && (
    <Modal
      className='session-modal'
      centered
      closable={false}
      footer={null}
      maskStyle={{
        backdropFilter: 'blur(3px)'
      }}
      open={showLoginPopup}
      title={Language.get('organization', 'SESSION_TIMEOUT_MODAL_TITLE')}
    >
      <Login
        onSignedIn={async (credentials) => {
          const res = await AppAPI.User.byUsername(credentials.username);
          if (res.error) {
            await AppAPI.User.logout();
            return message.error(Language.get('login', res.msg));
          }
          window.app = { ...window.app, user: res };
          dispatchEvent(new CustomEvent(EVENT_SESSION_STARTED));
        }} />
    </Modal>);
}