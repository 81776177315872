// Importing react libs
import React from 'react';

// Importing antd libs
import { Col, Row, Table, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/table/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Title } = Typography;

// Exporting component
export const BeadArrays = ({ biomaterials }) => {
  const Language = useLanguage();

  const data = [
    biomaterials.father,
    biomaterials.mother,
    biomaterials.reference,
    ...biomaterials.embryos,
  ];

  const columns = [{
    dataIndex: 'beadchip_barcode',
    title: Language.get('report', 'BEADCHIP_BARCODE'),
  }, {
    dataIndex: 'biomaterial_subarray',
    title: Language.get('report', 'SUBARRAY'),
  }, {
    dataIndex: 'biomaterial_name',
    title: Language.get('report', 'SAMPLE_ID'),
  }, {
    dataIndex: 'biomaterial_pedegree',
    title: Language.get('biomaterial', 'PEDEGREE'),
    render: (text) => Language.get('biomaterial', text),
  }, {
    dataIndex: 'biomaterial_bio_type',
    title: Language.get('report', 'SAMPLE_TYPE'),
    render: (text) => (text && Language.get('biomaterial', text)) || '',
  }, {
    dataIndex: ['stats', 'qc'],
    title: Language.get('report', 'QC_STATUS'),
  }, {
    dataIndex: ['stats', 'callRate'],
    title: Language.get('report', 'CALL_RATE'),
  }, {
    dataIndex: ['stats', 'aaRate'],
    title: Language.get('report', 'AA'),
  }, {
    dataIndex: ['stats', 'abRate'],
    title: Language.get('report', 'AB'),
  }, {
    dataIndex: ['stats', 'bbRate'],
    title: Language.get('report', 'BB'),
  }, {
    dataIndex: ['stats', 'adoRate'],
    title: Language.get('report', 'ADO'),
  }, {
    dataIndex: ['stats', 'misCallRate'],
    title: Language.get('report', 'MIS_CALL'),
  }, {
    dataIndex: ['stats', 'xHetRate'],
    title: Language.get('report', 'X_HET_RATE'),
  }, {
    dataIndex: ['stats', 'yCallRate'],
    title: Language.get('report', 'Y_CALL_RATE'),
  }];

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{Language.get('report', 'QC_BEAD_ARRAYS')}</Title>
      </Col>
      <Col offset={1} span={23}>
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="biomaterial_id"
        />
      </Col>
    </Row>
  );
};
