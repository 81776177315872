// Importing react libs
import React, { useEffect, useState } from 'react';

// Importing antd libs
import { Form, Input, message } from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/message/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import { default as HelixList } from 'helix/ui/list';
import Label from 'helix/ui/label';

// Importing app modules
import AppAPI from 'modules/api';
import { Notification } from 'helper/handler/notification';

// Importing app components
import { Analysis, BEAD_ARRAY_VERSION, INTENSITY_REFERENCE, SCANNING_HARDWARE } from 'components/organization';

// Importing component style
import './form.scss';

export const TRIO_DESIGNATIONS = ['father', 'mother', 'reference'];

export const mapAnalysisSettings = (setting = {}) => {
  const { ANALYSIS } = setting;
  return {
    beadArrayVersion: ANALYSIS?.BEAD_ARRAY_VERSION ?? BEAD_ARRAY_VERSION.KARYOMAP_V2,
    cnCallingOptions: ANALYSIS?.CN_CALLING_OPTIONS,
    flankingRegion: ANALYSIS?.FLANKING_REGION ?? 2,
    intensityReference: ANALYSIS?.INTENSITY_REFERENCE ?? INTENSITY_REFERENCE.VERSION_1,
    scanningHardware: ANALYSIS?.SCANNING_HARDWARE ?? SCANNING_HARDWARE.I_SCAN,
  };
};

// Exporting component
export const AnalysisForm = (props) => {
  const { case: caseObj, form: modalForm, onUpdate, readOnly } = props;
  const Language = useLanguage();
  const [form] = modalForm ? [modalForm] : Form.useForm();
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [references, setReferences] = useState([]);
  const { Item } = Form;

  const initialValues = {
    ...mapAnalysisSettings(window.app?.user?.organization?.setting),
    name: '',
    trio: [],
  };

  const columns = [{
    dataIndex: 'name',
    sorter: true,
    title: Language.get('biomaterial', 'NAME'),
  }, {
    dataIndex: 'designation',
    sorter: true,
    title: Language.get('biomaterial', 'DESIGNATION'),
    render: (value) => value && Language.get('biomaterial', value),
  }, {
    dataIndex: 'pedegree',
    sorter: true,
    title: Language.get('biomaterial', 'PEDEGREE'),
    render: (value) => Language.get('biomaterial', value),
  }, {
    dataIndex: 'barcode',
    sorter: true,
    title: Language.get('biomaterial', 'BARCODE'),
  }, {
    dataIndex: 'bio_type',
    sorter: true,
    title: Language.get('biomaterial', 'TYPE'),
    render: (value) => Language.get('biomaterial', value),
  }, {
    dataIndex: 'status',
    sorter: true,
    title: Language.get('biomaterial', 'STATUS'),
  }];

  const onFinish = async (values) => {
    // validate number of biomaterial reference
    if (references.length !== 3) {
      return message.error(Language.get('analysis', 'NOT_VALID_NUMBER_OF_REFERENCE_SAMPLES'));
    }
    if (references.filter((ref) => ref.designation === 'father').length !== 1) {
      return message.error(Language.get('analysis', 'NOT_VALID_NUMBER_OF_FATHER_REFERENCE_SAMPLE'));
    }
    if (references.filter((ref) => ref.designation === 'mother').length !== 1) {
      return message.error(Language.get('analysis', 'NOT_VALID_NUMBER_OF_MOTHER_REFERENCE_SAMPLE'));
    }
    // set the case id
    if (caseObj?.id) values['case'] = caseObj.id;
    // set the reference ids
    if (references) values['biomaterials'] = references.map((ref) => ref.id);

    // set the settings
    values['setting'] = {
      ANALYSIS: {
        'BEAD_ARRAY_VERSION': values.beadArrayVersion,
        'CN_CALLING_OPTIONS': values.cnCallingOptions,
        'FLANKING_REGION': values.flankingRegion,
        'INTENSITY_REFERENCE': values.intensityReference,
        'SCANNING_HARDWARE': values.scanningHardware,
      },
    };
    delete values.beadArrayVersion;
    delete values.cnCallingOptions;
    delete values.flankingRegion;
    delete values.intensityReference;
    delete values.scanningHardware;

    const res = await AppAPI.Analysis.save(values);

    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);
    return onUpdate();
  };

  const reader = async ({ filters, offset, order }) => {
    const caseId = caseObj?.id;
    if (caseId) {
      filters['case'] = [caseId];
      filters['designation__in'] = TRIO_DESIGNATIONS;
      order = order || '-updated_on';
      return await AppAPI.Biomaterial.list({ filters, offset, order });
    }
    return { results: [] };
  };

  const trioReader = () => ({ count: 3, results: form.getFieldValue('trio') });

  const _onRowSelect = (values) => {
    setReferences(values);
    // populate the analysis name with reference name
    const referenceName = values.find((item) => item.designation === 'reference')?.name.replace(' ', '_');
    if (referenceName) form.setFieldsValue({ name: referenceName });
  };

  useEffect(() => {
    if (caseObj) {
      setListKey((Math.random() + 1).toString(36).substring(2));
    }
  }, [caseObj]);

  return (
    <div className="reference-biomaterial-list-wrapper">
      <div className="title-with-action">
        <h2>{Language.get('biomaterial', 'REFERENCE_SAMPLES')}</h2>
      </div>
      <Form
        form={form}
        initialValues={initialValues}
        name="analysis-form"
        onFinish={onFinish}
        validateMessages={{
          required: Language.get('common', 'NOT_VALID_EMPTY_VALUE'),
        }}
      >
        <HelixList
          columns={columns}
          context="common"
          dataField="results"
          dataCountField="count"
          defaultSort={{ field: 'updated_on', order: 'descend' }}
          key={listKey}
          name="reference_biomaterials"
          reader={readOnly ? trioReader : reader}
          rowKey="id"
          showPagination={false}
          onSelectedRows={readOnly ? null : _onRowSelect}
        />
        <Item
          label={(<Label name={Language.get('analysis', 'ANALYSIS_NAME')} title={Language.get('analysis', 'ANALYSIS_NAME_INFO')} />)}
          name="name"
          rules={[
            { required: true },
            {
              validator: (_, value) => value && (/^[a-z0-9-._]+$/i).test(value)
                ? Promise.resolve()
                : Promise.reject(new Error(Language.get('common', 'NOT_VALID_ALPHANUMERIC')))
            }
          ]}
        >
          <Input readOnly={readOnly} />
        </Item>
        <Analysis canEdit={!readOnly} initialValues={initialValues} />
      </Form>
    </div>
  );
};
