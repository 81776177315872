const BASE_URL = window.location.origin;

export const COLORS = {
  PURPLE_700: '#7B1FA2',
  VL_BLACK: '#2d2d2f',
  VL_BLUE: '#004a9d',
  VL_GRAY_1: '#606060',
  VL_GRAY_2: '#c9c9c9',
  VL_GRAY_3: '#ececec',
  VL_GRAY_4: '#f5f5f5',
  VL_LIGHT_BLUE: '#9fcdf0',
  VL_RED: '#f5222d',
  VL_WHITE: '#ffffff',
};

export const COMMON_STYLES = {
  h1: {
    bold: true,
    color: COLORS.VL_LIGHT_BLUE,
    fontSize: 28,
  },
  h2: {
    bold: true,
    color: COLORS.VL_BLACK,
    fontSize: 14,
    margin: [0, 16, 0, 8],
  },
  h3: {
    bold: true,
    fontSize: 10,
    margin: [0, 8, 0, 4],
  },
  label: {
    bold: true,
    fontSize: 10,
  },
  primary: { color: COLORS.VL_BLACK },
  secondary: { color: COLORS.VL_GRAY_1, fontSize: 10 },
  small: { fontSize: 9 },
  xsmall: { fontSize: 8 },
  xxsmall: { fontSize: 7 },
  table: { fontSize: 7 },
};

export const DEFAULT_STYLE = {
  font: 'Noto Sans',
  fontSize: 10,
};

export const FONTS = {
  'Noto Sans': {
    bold: `${BASE_URL}/static/fonts/noto-sans/NotoSans-Bold.ttf`,
    normal: `${BASE_URL}/static/fonts/noto-sans/NotoSans-Regular.ttf`,
  },
  'Noto Sans SC': {
    bold: `${BASE_URL}/static/fonts/noto-sans-sc/NotoSansSC-Bold.otf`,
    normal: `${BASE_URL}/static/fonts/noto-sans-sc/NotoSansSC-Regular.otf`,
  },
};

export const MARGINS = { small: [20, 20, 20, 38], normal: [30, 30, 30, 38] };
