import React from 'react';

import Abstract from './abstract';

// Import helix modules
import 'helix-modules/sDate';

const LOG_TYPE = {
  'created': 'success',
  'deleted': 'success',
  'updated': 'success',
  'failed': 'error',
  'failed_embryos': 'error',
  'failed_trio': 'error',
  'succeeded': 'success',
  'completed': 'success',
  'signoff': 'success',
  '': 'info',
};

// Temporarily removing all links from log for consistency
const LogWithLink = ({ prefix, suffix, linkText }) => {
  return `${prefix} ${linkText} ${suffix}`;
};

class Log extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'log';
    this._updateEndpoint();
  }

  /**
   * get logs to populate the alerts
   * @param {void}
   * @return {void}
  **/
  async alerts(extras = { limit: 5 }) {
    let fQuery = '/?';
    Object.keys(extras).forEach((param) => fQuery = `${fQuery}&${param}=${extras[param]}`);
    const res = await fetch(
      `${this.constants.ENDPOINT}${fQuery}`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        credentials: 'include',
        method: 'GET'
      },
    );
    return this._handleError(res);
  }

  /**
   *
   * @param {Object} log
   * @param {number} log.id
   * @param {string} log.action
   * @param {string} log.actor_id
   * @param {string} log.actor_type
   * @param {string} log.created_on
   * @param {Object} log.payload
   *
   * @returns {Object} object with parsed action and formatted time
   */
  parseLog(log, language) {
    let parsedAction = log.action;

    // analysis actions
    if (parsedAction === 'analysis_created') {
      const { id, name, case: kMapCase } = log.payload;
      const analysis = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = `Analysis ${analysis} created for case ${caseString}`;
    }
    if (parsedAction === 'analysis_updated') {
      const { id, name, case: kMapCase } = log.payload;
      const analysis = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = `Analysis ${analysis} updated from case ${caseString}`;
    }
    if (parsedAction === 'analysis_deleted') {
      const { id, name, case: kMapCase } = log.payload;
      const analysis = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = `Analysis ${analysis} deleted from case ${caseString}`;
    }
    if (parsedAction === 'analysis_completed') {
      const { id, name, case: kMapCase } = log.payload;
      const analysis = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = (
        <LogWithLink
          link={`/case/${kMapCase.id}/analysis/${id}`}
          linkText={analysis}
          prefix="Analysis"
          suffix={`from case ${caseString} completed successfully`}
        />
      );
    }
    if (['analysis_failed_embryos', 'analysis_failed_trio', 'analysis_processing_trio'].includes(parsedAction)) {
      const { id, name, case: kMapCase } = log.payload;
      const analysis = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      const actionToLog = {
        'analysis_failed_embryos': 'failed sample(s) analyses',
        'analysis_failed_trio': 'failed trio analysis',
        'analysis_processing_trio': 'was started'
      };
      parsedAction = (
        <LogWithLink
          link={`/case/${kMapCase.id}`}
          linkText={analysis}
          prefix="Analysis"
          suffix={`from case ${caseString} ${actionToLog[parsedAction]}`}
        />
      );
    }
    if (parsedAction === 'analysis_reanalyze') {
      const { id, case: kMapCase } = log.payload;
      const { actor_id: username } = log;
      const analysis = `${kMapCase['external_id']}-${id}`;
      parsedAction = (
        <LogWithLink
          link={`/accessioning/${kMapCase.id}`}
          linkText={analysis}
          prefix="Re-analysis triggered for analysis"
          suffix={`by ${username}`}
        />
      );
    }

    // case actions
    if (parsedAction === 'case_created') {
      const { external_id: externalId, id, name } = log.payload;
      parsedAction = `Case ${name ?? ''} (${id}) with external ID: (${externalId}) created`;
    }
    if (parsedAction === 'case_updated') {
      const { external_id: externalId, id, name } = log.payload;
      parsedAction = `Case ${name ?? ''} (${id}) with external ID: (${externalId}) updated`;
    }
    if (parsedAction === 'case_deleted') {
      const { external_id: externalId, id, name } = log.payload;
      parsedAction = `Case ${name ?? ''} (${id}) with external ID: (${externalId}) deleted`;
    }
    if (parsedAction === 'case_locked') {
      const { external_id: externalId, id } = log.payload;
      parsedAction = `Case ${externalId} (${id}) with external ID: (${externalId}) was locked`;
    }
    if (parsedAction === 'case_unlocked') {
      const { external_id: externalId, id, name } = log.payload;
      parsedAction = `Case ${name ?? ''} (${id}) with external ID: (${externalId}) was unlocked`;
    }
    if (parsedAction === 'case_data_upload_succeeded') {
      const { external_id: externalId, id, count } = log.payload;
      parsedAction = `Data upload succeeded for ${count ?? ''} samples in case ${externalId} (${id})`;
    }
    if (parsedAction === 'case_data_upload_failed') {
      const { external_id: externalId, id, count } = log.payload;
      parsedAction = `Data upload failed for ${count ?? ''} samples in case ${externalId} (${id})`;
    }

    if (parsedAction === 'case_cancel_signoff') {
      const { id, external_id: externalId, } = log.payload;
      const caseString = `${externalId} (${id})`;
      parsedAction = `Case ${caseString} sign-off cancelled by ${log['actor_id']}`;
    }
    if (parsedAction === 'case_signoff') {
      const { id, external_id: externalId, } = log.payload;
      const caseString = `${externalId} (${id})`;
      parsedAction = `Case ${caseString} signed-off by ${log['actor_id']}`;
    }

    // plate actions
    if (parsedAction === 'plate_created') {
      const { author, external_id: externalId, id } = log.payload;
      parsedAction = `Plate ${externalId} (${id}) prepared by ${author}`;
    }
    if (parsedAction === 'plate_updated') {
      const { author, external_id: externalId, id } = log.payload;
      parsedAction = `Plate ${externalId} (${id}) prepared by ${author} updated`;
    }
    if (parsedAction === 'plate_deleted') {
      const { author, external_id: externalId, id } = log.payload;
      parsedAction = `Plate ${externalId} (${id}) prepared by ${author} deleted`;
    }
    if (parsedAction === 'plate_pooled') {
      const { author, external_id: externalId, id } = log.payload;
      parsedAction = `Plate ${externalId} (${id}) prepared by ${author} finalized`;
    }

    // region actions
    if (parsedAction === 'gene_region_created') {
      const { case: kMapCase, id, name } = log.payload;
      const region = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = `Gene Region ${region} in case ${caseString} created`;
    }
    if (parsedAction === 'gene_region_updated') {
      const { case: kMapCase, id, name } = log.payload;
      const region = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = `Gene Region ${region} in case ${caseString} updated`;
    }
    if (parsedAction === 'gene_region_deleted') {
      const { case: kMapCase, id, name } = log.payload;
      const region = `${name} (${id})`;
      const caseString = `${kMapCase['external_id']} (${kMapCase.id})`;
      parsedAction = `Gene Region ${region} in case ${caseString} deleted`;
    }

    // organization actions
    if (parsedAction === 'settings_updated') {
      parsedAction = 'Organization settings updated';
    }

    // user actions
    if (parsedAction === 'user_created') {
      const { id, role, username } = log.payload;
      parsedAction = `A ${role} user with ${username} (${id}) created`;
    }
    if (parsedAction === 'user_updated') {
      const { id, role, username } = log.payload;
      parsedAction = `A ${role} user with ${username} (${id}) updated`;
    }
    if (parsedAction === 'user_deleted') {
      const { id, role, username } = log.payload;
      parsedAction = `A ${role} user with ${username} (${id}) deleted`;
    }
    if (parsedAction === 'login') {
      const { actor_id: username } = log;
      parsedAction = `${username} logged in`;
    }
    if (parsedAction === 'login_failed') {
      const { actor_id: username } = log;
      parsedAction = `${username} failed to log in`;
    }
    if (parsedAction === 'logout') {
      const { actor_id: username } = log;
      parsedAction = `${username} logged out`;
    }

    // sample actions
    if (parsedAction === 'sample_qc_failed') {
      const {
        case_id: caseId,
        case_external_id: caseExternalId,
        biomaterial_name: bioName,
        analysis_id: analysisId,
      } = log.payload;

      parsedAction = (
        <LogWithLink
          prefix={`Sample ${bioName} from analysis`}
          link={`/accessioning/${caseId}`}
          linkText={`${caseExternalId}-${analysisId}`}
          suffix={`in case ${caseExternalId} (${caseId}) failed QC`}
        />
      );
    }

    if (parsedAction.startsWith?.('sample_')) {
      const sampleAction = (sample, actionType) => {
        const { case: kMapCase, cycle_id: cycleId, designation, pedegree, id: sampleId } = sample;
        const inCase = ` in case ${kMapCase['external_id']} (${kMapCase.id})`;
        const onCycle = ` on cycle (${cycleId ?? 'NA'})`;
        const designationString = designation && ` and designation ${language.get('biomaterial', designation)}` || '';
        const pedegreeLang = language.get('biomaterial', pedegree);
        const sampleString = ` (${sampleId}), with pedegree: ${pedegreeLang}${designationString}`;
        return `Sample${sampleString} ${inCase}${onCycle} ${actionType}`;
      };
      parsedAction = sampleAction(log.payload, parsedAction.split('_').at(-1));
    }

    const actionType = Object.keys(LOG_TYPE).find(key => (log.action).endsWith(key));

    return {
      action: parsedAction,
      time: new Date(log['created_on']).format(),
      logType: LOG_TYPE[actionType]
    };
  }
}

export default new Log();
