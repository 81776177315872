// Importing React and external libs
import React from 'react';
import { useDrop } from 'react-dnd';

// Importing antd libs
import { Select, Form, Tag } from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/select/style/css';
import 'antd/es/tag/style/css';

// Importing antd icons
import { DropboxOutlined } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './biomaterials.scss';

// Importing DND drop type
import { DND_ITEM_TYPE_BIOMATERIAL } from 'components/report/search';

// Defining constants
const { Item } = Form;

// Exporting component
export const Biomaterials = (props) => {
  const { biomaterials, onBiomaterialDrop, onRemoveBiomaterial } = props;
  const Language = useLanguage();
  const [{ canDrop }, drop] = useDrop({
    accept: DND_ITEM_TYPE_BIOMATERIAL,
    drop: onBiomaterialDrop,
    collect: (monitor) =>({
      canDrop: Boolean(monitor.canDrop())
    })
  });

  const tagRender = ({ label, value, ...otherProps }) => (
    <Tag {...otherProps} onClose={() => onRemoveBiomaterial(value)}>
      {label}
    </Tag>
  );

  const selectValue = Object.entries(biomaterials).map(([key, item]) => ({
    key,
    label: `Case: ${item.caseObj.external_id} - Analysis: ${item.analysisId} - ${item.biomaterial.biomaterial_name}`,
    value: item,
  }));

  return (
    <div className='biomaterial-dropbox' ref={drop}>
      {canDrop && (
        <div className='overlay'>
          <div>{Language.get('reportBuilder', 'DROP_HERE')}</div>
          <DropboxOutlined />
        </div>
      )}
      <Item label={Language.get('reportBuilder', 'SAMPLES_LIST_TITLE')}>
        <Select
          mode="tags"
          placeholder={Language.get('reportBuilder', 'DND_DROPZONE_PLACEHOLDER')}
          value={selectValue}
          tagRender={tagRender}
          open={false} />
      </Item>
    </div>
  );
};
