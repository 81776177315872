import Abstract from './abstract';

export const CHARTS = {
  'KARYOMAP': 'KARYOMAP',
  'CNV_CHART': 'CNV_CHART',
  'LOGR_CHART': 'LOGR_CHART',
  'B_ALLELE_CHART': 'B_ALLELE_CHART'
};

class Organization extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'organization';
    this._updateEndpoint();
  }

  /**
   * Hit /api/organization/default_setting
   * @param {string} key
   * @return {object} default app setting
  **/
  getHostSetting(key) {
    const hostSetting = window.app?.user?.['host_settings'];
    if (key) {
      return hostSetting?.[key] ?? null;
    } else {
      return hostSetting;
    }
  }

  getSupportedCharts() {
    const isCnvEnabled = this.getHostSetting('enable_cnv');
    const supportedCharts = [
      CHARTS.KARYOMAP,
      ...(isCnvEnabled ? [CHARTS.CNV_CHART] : []),
      CHARTS.LOGR_CHART,
      CHARTS.B_ALLELE_CHART
    ];

    return supportedCharts;
  }

  /**
   * request centres within the organization
   * @param {void}
   * @return {void}
  **/
  async centres() {
    const res = await fetch(
      `${this.constants.ENDPOINT}/centres/`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        credentials: 'include',
        method: 'GET',
      },
    );
    return this._handleError(res);
  }

  /**
   * request statistic of the organization
   * @param {object} payload
   * centre {string}
   * end {string}
   * start {string}
   * @return {void}
  **/
  async statistics(payload, field = 'qc') {
    const res = await fetch(
      `${this.constants.ENDPOINT}/statistics_${field}/`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(payload),
      },
    );
    return this._handleError(res);
  }

  /**
   * Helper to update the endpoint if base api url changed
   * @param {void}
   * @return {void}
  **/
  _updateEndpoint() {
    this.constants.ENDPOINT = `${this.constants.API_URL}/${this.constants.VERSION}/${this.resource}`;
    this.constants.LOGO = `${this.constants.ENDPOINT}/logo/`;
  }
}

export default new Organization();
