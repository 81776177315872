export const bold = (text) => ({ bold: true, text });

export const h1 = (text) => ({ style: 'h1', text });

export const h2 = (text) => ({ style: 'h2', text });

export const h3 = (text) => ({ style: 'h3', text });

export const hr = () => ({
  canvas: [{
    lineColor: 'gray',
    lineWidth: 1,
    type: 'line',
    x1: 0,
    x2: 515,
    y1: 0,
    y2: 0,
  }],
  margin: [0, 5],
});

export const keyValueTable = (header, values) => {
  return nonBreakable(
    header && h3(header),
    {
      style: 'table',
      table: {
        body: [
          ...Object.entries(values).map(([key, value]) => [
            { bold: true, text: key },
            value || '',
          ]),
        ],
        widths: ['auto', '*']
      },
    },
  );
};

export const nonBreakable = (first, second) => ([
  { stack: [first], style: 'nonbreaking' },
  { stack: [second], style: 'nonbreaking' },
]);

export const preventUndefined = ((items) => items.map((item) => item ?? ''));

export const reportItem = (label = '', value = '') => ({
  text: [
    { style: 'label', text: `${label}: ` },
    { style: 'secondary', text: value },
  ],
});

export const section = (header, content) => nonBreakable(h2(header), content);

export const stickyHeaderPages = (header, children) => ({
  layout: 'noBorders',
  pageBreak: 'before',
  pageOrientation: 'portrait',
  table: {
    body: [[header], [children]],
    headerRows: 1,
    widths: ['100%'],
  },
});

export const tableHeader = (columns) => columns.map(
  (column) => ({ bold: true, text: column })
);
