// Importing react libs
import React from 'react';

// Importing antd libs
import {
  Modal,
  Table,
} from 'antd';
import 'antd/es/modal/style/css';
import 'antd/es/table/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './saImportConfirm.scss';

export const SAImportConfirm = ({ data, open, onCancel, onConfirm }) => {
  const Language = useLanguage();

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      title={Language.get('case', 'BIOMATERIALS_IMPORT_SUMMARY')}
      onCancel={onCancel}
      onOk={onConfirm}
    >
      <div className="sa-import-confirm-wrapper">
        <h2>{Language.get('case', 'BIOMATERIALS_IMPORT_SUMMARY_CONFIRM')}</h2>

        <h3>{Language.get('case', 'BIOMATERIALS_SUMMARY')}</h3>
        {data && (
          <Table
            columns={[{
              dataIndex: 'tube_id',
              title: Language.get('biomaterial', 'TUBE_ID'),
            }, {
              dataIndex: 'name',
              title: Language.get('biomaterial', 'SAMPLE_ID'),
            }, {
              dataIndex: 'barcode',
              title: Language.get('biomaterial', 'BARCODE'),
            }, {
              dataIndex: 'bio_type',
              title: Language.get('biomaterial', 'BIO_TYPE'),
              render: (value) => Language.get('biomaterial', value),
            }]}
            dataSource={data}
            pagination={false}
            rowKey="name"
            size="small"
          />
        )}
      </div>
    </Modal>
  );
};
