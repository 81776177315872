// Importing app modules & components
import { RESULT_COLORS } from 'styles/theme/colors';
import AppAPI from 'modules/api';

// Importing app helper
import {
  bold,
  h3,
  hr,
  keyValueTable,
  nonBreakable,
  preventUndefined,
  reportItem,
  stickyHeaderPages,
  tableHeader,
} from './common';

const regionBlock = ({ i18n, index, region }) => {
  return nonBreakable(h3(`${i18n('REGION')} ${index + 1}: ${region.name}`), {
    columnGap: 10,
    columns: [
      {
        stack: keyValueTable(null, {
          [i18n('PREDICTED_PHASE')]: region.predictedPhases.join(', '),
          [i18n('PREDICTED_STATUS')]: i18n(region.predictedStatus, 'gene'),
          [i18n('SUPPORTING_EVIDENCE')]: region.supportive.join('\n'),
          [i18n('CONTRARY_EVIDENCE')]: region.nonSupportive.join('\n'),
        }),
        width: '*',
      },
      snpTable({ snp1: region.snpP1, snp2: region.snpP2, type: 'paternal' }, i18n),
      snpTable({ snp1: region.snpM1, snp2: region.snpM2, type: 'maternal' }, i18n),
    ],
  });
};

const snpTable = ({ snp1, snp2, type }, i18n) => {
  const colors = {
    paternal: [
      { color: 'white', fillColor: RESULT_COLORS.PATERNAL_1.fill },
      { color: 'white', fillColor: RESULT_COLORS.PATERNAL_2.fill },
    ],
    maternal: [
      { color: 'black', fillColor: RESULT_COLORS.MATERNAL_1.fill },
      { color: 'white', fillColor: RESULT_COLORS.MATERNAL_2.fill },
    ],
  };

  const title = {
    alignment: 'center',
    bold: true,
    colSpan: 2,
  };

  const _getColor = (index) => ({
    color: colors[type][index].color,
    fillColor: colors[type][index].fillColor,
  });

  return {
    color: '#444',
    style: 'table',
    table: {
      body: [
        [
          '',
          { text: `${i18n(`${type}_1`)}`, ...title, ..._getColor(0) },
          '',
          { text: `${i18n(`${type}_2`)}`, ...title, ..._getColor(1) },
          '',
        ],
        tableHeader([
          i18n('REGION'),
          i18n('KEY'),
          i18n('NON_KEY'),
          i18n('KEY'),
          i18n('NON_KEY'),
        ]),
        ...['left', 'main', 'right'].map((field) => preventUndefined([
          bold(i18n(`${field}_FLANKING`)),
          snp1.key[field],
          snp1.nonKey[field],
          snp2.key[field],
          snp2.nonKey[field],
        ])),
      ],
      headerRows: 2,
    },
  };
};

const sampleResult = ({ biomaterial, i18n, icons }) => {
  const { stats } = biomaterial;
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');
  return {
    style: 'table',
    table: {
      body: [
        tableHeader([
          i18n('CALL_RATE'),
          i18n('AA'),
          i18n('AB'),
          i18n('BB'),
          i18n('ADO'),
          i18n('MIS_CALL'),
          i18n('X_HET_RATE'),
          i18n('Y_CALL_RATE'),
          ...(isCnvEnabled ? [i18n('ANEUPLOIDY_STATUS')] : []),
          i18n('RESULT'),
        ]),
        preventUndefined([
          stats?.callRate,
          stats?.aaRate,
          stats?.abRate,
          stats?.bbRate,
          stats?.adoRate,
          stats?.misCallRate,
          stats?.xHetRate,
          stats?.yCallRate,
          ...(isCnvEnabled ? [stats?.aneuploidyStatus] : []),
          { fit: [15, 15], svg: icons[biomaterial.id], width: '*' },
        ]),
      ],
      headerRows: 1,
    },
  };
};

const sampleHeader = ({ biomaterial: bio, caseInfo, i18n }) => {
  return [
    {
      layout: 'noBorders',
      table: {
        body: [[
          reportItem(i18n('CASE_ID'), caseInfo['external_id']),
          reportItem(i18n('SUBJECT_FIRSTNAME', 'case'), bio['biomaterial_firstname']),
          reportItem(i18n('BEADCHIP_BARCODE'), bio['beadchip_barcode']),
        ], [
          reportItem(i18n('SAMPLE_ID'), bio['biomaterial_name']),
          reportItem(i18n('SUBJECT_LASTNAME', 'case'), bio['biomaterial_lastname']),
          reportItem(i18n('SUBARRAY'), bio['biomaterial_subarray']),
        ]],
        headerRows: 0,
        widths: ['*', '*', '*'],
      },
    },
    hr(),
  ];
};

export const sampleDetails = ({ biomaterial, caseInfo, chartImages, i18n, icons, info }) => {
  const getPdfImages = (chartKey, { fit, margin }) => Array.isArray(info.charts) && info.charts.length > 0
    ? info.charts.filter(key => key === chartKey).map((chart) => ({
      fit: fit ?? [550, 550],
      image: chartImages[`${chart}-${biomaterial['bp_analysis_id']}`],
      margin: margin ?? [0, 5, 0, 5]
    })) : [];

  const [karyomapImage] = getPdfImages('KARYOMAP', { margin: [0, 20, 0, 10] });
  const [logRImage] = getPdfImages('LOGR_CHART', { fit: [800, 800] });
  const [bafImage] = getPdfImages('B_ALLELE_CHART', { fit: [800, 800] });

  return stickyHeaderPages(sampleHeader({ biomaterial, caseInfo, i18n }), [
    h3(i18n('SAMPLE_RESULT')),
    sampleResult({ biomaterial, i18n, icons }),
    hr(),
    biomaterial.stats?.regions?.map((region, index) =>
      regionBlock({ region, index, i18n })
    ),
    karyomapImage ?? { text: '' },
    (logRImage || bafImage) ? {
      stack: [
        logRImage ?? { text: '' },
        bafImage ?? { text: '' },
      ],
      pageOrientation: 'landscape',
      pageBreak: 'before',
    } : { text: '' },
  ]);
};
