// Importing react libs
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Importing antd libs
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import 'antd/es/dropdown/style/css';
import 'antd/es/menu/style/css';

// Importing Helix hooks & functions
import { getPosition } from 'helix/chart/plate';
import useLanguage from 'helix-hooks/language';
import 'helix-modules/sDate';

// Importing Helix components
// import BtnConfirm from 'helix/ui/btnConfirm';
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app componenets
import { Beadchip } from 'components/plate';
import useDataExportFile from 'components/case/useDataExportFile';

// Importing component style
import './recentBeadchipList.scss';

// Importing component helpers
import { isBeadChipScanned, sortBeadchips } from 'components/plate/helper/data';

const BeadchipMenu = ({ plate }) => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const caseIdsSet = plate.beadchips.reduce((ids, beadchip) => {
    beadchip.biomaterials.forEach(biomaterial => ids.add(biomaterial.case));
    return ids;
  }, new Set());
  const caseIds = [...caseIdsSet];
  const { download, isLoading } = useDataExportFile({
    caseIds,
    fileName: `exported_plate_${plate['external_id']}`,
    plateId: plate.id
  });
  return (
    <Menu
      items={[
        { key: 'planner', label: Language.get('common', 'PLANNER') },
        // ...(AppAPI.User.isDev ? [] : [{ key: 'files', label: Language.get('common', 'FILES') }]),
        { key: 'qc', label: <Link to={`/metrics?plate=${plate['external_id']}`}>{Language.get('common', 'QC')}</Link> },
        {
          disabled: caseIds.length === 0 || isLoading,
          icon: isLoading ? <LoadingOutlined spin /> : null,
          key: 'export',
          label: Language.get('file', 'DATA_EXPORT_FILE'),
          onClick: download
        },
      ]}
      onClick={({ key }) => {
        if (key === 'planner') navigate(`/planner/${plate.id}`);
      }}
    />
  );
};

const RecentBeadchips = ({ beadchips }) => {
  const Language = useLanguage();
  const stats = beadchips.reduce(
    (stats, chip) => [...stats, ...chip.biomaterials.map(bio => bio.stats)],
    []
  );
  const { scanDate } = stats.find(stat => Boolean(stat.scanDate)) ?? { scanDate: null };
  const formattedScanDate = scanDate ? new Date(scanDate).format('date') : null;

  const getBeadText = (material) => {
    const { callRate, abRate } = material?.stats ?? { callRate: null, abRate: null };
    const [callRatePercent, abRatePercent] = [callRate, abRate].map(num => parseFloat(num) * 100);

    if (callRatePercent + abRatePercent) { // would be false if one of both is an invalid number
      return (
        <span>
          <strong>T</strong>:&nbsp;{callRatePercent.toFixed(1)}%,&nbsp;
          <strong>AB</strong>:&nbsp;{abRatePercent.toFixed(1)}%
        </span>
      );
    }

    return Language.get('beadchip', 'WAITING');
  };

  return (
    <div className='recent-beadchips'>{beadchips.map(
      beadchip => (
        <Beadchip
          barcode={beadchip.barcode}
          data={beadchip.biomaterials}
          key={beadchip.id}
          slotAboveBox={
            <div>
              <Link to={`/metrics?beadchip=${beadchip.barcode}`}>{beadchip.barcode}</Link>
              <div>{formattedScanDate}</div>
            </div>
          }
          getBeadText={getBeadText}
        />
      ))
    }</div>
  );
};

// Exporting component
export const RecentBeadchipsList = ({
  pageSize,
  showMorePath,
  showPagination,
}) => {
  const Language = useLanguage();

  const tableColumns = [{
    dataIndex: 'external_id',
    key: 'external_id',
    sorter: true,
    title: Language.get('beadchip', 'PLATE_ID'),
    render: (externalId, row) => <Link to={`/planner/${row.id}`}>{externalId}</Link>,
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'prepared_on',
    key: 'prepared_on',
    sorter: true,
    title: Language.get('beadchip', 'PREP_DATE'),
    render: (value) => (new Date(value)).format('date'),
    ...tableColumnFilterDate(),
  }, {
    dataIndex: 'beadchips',
    key: 'beadchips-scanned',
    sorter: true,
    title: Language.get('beadchip', 'BEADCHIPS_SCANNED'),
    render: (beadchips) => beadchips.filter(isBeadChipScanned).length,
  }, {
    dataIndex: 'beadchips',
    key: 'beadchips-waiting',
    sorter: true,
    title: Language.get('beadchip', 'BEADCHIPS_WAITING'),
    render: (beadchips) => beadchips.length - beadchips.filter(isBeadChipScanned).length,
  }, {
    key: 'operation',
    title: Language.get('common', 'ACTIONS'),
    render: (plate) => (
      <div className="items-center">
        <Dropdown
          className="dropdown-label"
          overlay={<BeadchipMenu plate={plate} />}
        >
          <span>{Language.get('common', 'ACTIONS')} <DownOutlined /></span>
        </Dropdown>
      </div>
    )
  }];

  const reader = async ({ filters, limit, offset, order }) => {
    const mapper = {
      external_id: 'external_id__icontains',
      plate_id: 'plate_id__icontains',
    };
    filters = Object.keys(filters).reduce((newFilters, field) => {
      newFilters[mapper[field] || field] = filters[field];
      return newFilters;
    }, {});

    const { results, ...response } = await AppAPI.Plate.list({ filters, limit, offset, order });
    const plates = Array.isArray(results) ? results : [];

    plates.forEach(plate => plate.beadchips.forEach((beadchip) => {
      beadchip.biomaterials.forEach(biomaterial => {
        biomaterial.stats = AppAPI.Biomaterial.parseStats(
          biomaterial.analyses?.[0]?.['bp_analysis_stats']
        );
        const position = getPosition(biomaterial['plate_position']);
        biomaterial.x = position.x;
        biomaterial.y = position.y;
      });
    }));

    const sorted = sortBeadchips(plates);

    return { ...response, results: sorted };
  };

  return (
    <div className="recent-beadchips-list">
      <HelixList
        columns={tableColumns}
        context="common"
        defaultSort={{ field: 'prepared_on', order: 'descend' }}
        expandable={{
          expandedRowRender: (plate) => <RecentBeadchips beadchips={plate.beadchips} />
        }}
        name="recent-beadchip"
        pageSize={pageSize}
        reader={reader}
        rowKey="id"
        showPagination={showPagination}
        showMorePath={showMorePath}
      />
    </div>
  );
};
