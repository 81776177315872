/*global RELEASE_VERSION*/

// Importing react libs
import { useState } from 'react';

// Importing antd libs
import { message } from 'antd';
import 'antd/es/message/style/css';

// Importing app modules
import AppAPI from 'modules/api';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { MChromosomeDictionary } from 'helix-modules/chromosome';
import 'helix-modules/sDate';

const COLUMNS = [
  'Case_ID',
  'Analysis_ID',
  'Cycle_ID',
  'Sample_ID',
  'Internal_ID',
  'Tube_ID',
  'Sample_Barcode',
  'Requisition',
  'Designation',
  'Pedigree',
  'Sample_Type',
  'Accessioned_Sex',
  'Input_Volume',
  'Input_Conc',
  '260_280',
  '260_230',
  'Maternal_Sample_ID',
  'Maternal_Last_Name',
  'Maternal_First_Name',
  'Maternal_Date_of_Birth',
  'Paternal_Last_Name',
  'Paternal_First_Name',
  'Paternal_Date_of_Birth',
  'Referred_By',
  'Referring_Centre',
  'Accessioning_Date',
  'Hybridised',
  'Plate_ID',
  'Preparation_Date',
  'BeadChips_Defined',
  'Plate_Created_By',
  'Plate_Well',
  'Quad_WG-Pre_Lot',
  'WG-Post_1_LV1_Lot',
  'Single_Post_3_LV_Lot',
  'Post_2_LMV_Lot',
  'Post_4_LV_Lot',
  'BeadChip_Serial',
  'Subarray',
  'Analysis_Name',
  'GTC_Filename',
  'Analysis_Internal_ID',
  'Analysis_Reference_ID',
  'Reference_Pedigree',
  'Reference_Type',
  'Analysis_Notes',
  'Analysis_Date_Created',
  'Analysis_Date_Modified',
  'Gene_Regions',
  'BeadChip_Scan_Date',
  'Call_Rate',
  'AA_Rate',
  'AB_rate',
  'BB_Rate',
  'ADO_Rate',
  'MisCall_Rate',
  'X_Het_Rate',
  'Y_Call_Rate',
  'QC_Status',
  'Sample_Result',
  'Sample_Status',
  'Warnings',
  'Level_1_signoff',
  'Level_2_signoff',
  'SignOff_History',
  'Date_sample_Last_Update',
  'BeadChip_Type',
  'Scanning_Hardware',
  'Manifest_File',
  'Cluster_File',
  'Flanking_Size_Mb',
  'Software_Version',
  'Analysis_Version',
  // 'Reference_Version', Not available until next version
  // 'Overall_noise',
  // 'CNV_QC_Status',
  // 'CNV_Abnormalities_Reported',
  // 'Ploidy_Reported',
  // 'CNV_Status',
  // 'Reported_Sex',
  // 'Regions_Called',
  // 'chr1',
  // 'chr2',
  // 'chr3',
  // 'chr4',
  // 'chr5',
  // 'chr6',
  // 'chr7',
  // 'chr8',
  // 'chr9',
  // 'chr10',
  // 'chr11',
  // 'chr12',
  // 'chr13',
  // 'chr14',
  // 'chr15',
  // 'chr16',
  // 'chr17',
  // 'chr18',
  // 'chr19',
  // 'chr20',
  // 'chr21',
  // 'chr22',
  // 'chrX',
  // 'chrY',
  // 'CNV_Settings',
];

/**
 *
 * @param {Object} props
 * @param {Array<number>} props.caseIds
 * @param {number} props.plateId
 * @param {string} fileName
 * @returns {Object}
 */
export default function useDataExportFile({ caseIds, plateId, fileName }) {
  const Language = useLanguage();
  const [isLoading, setIsLoading] = useState(false);

  const download = async () => {
    setIsLoading(true);
    const lines = await Promise.all(caseIds.map(_loadCaseLines));
    const blob = new Blob(
      [
        '# Vitrolife kMap Report\n',
        `# Written on ${new Date().format()}\n`,
        `# Written by ${window.app?.user?.username}\n`,
        '# For Research Use Only. Not for use in diagnostic procedures\n',
        '\n',
        `${COLUMNS.join('\t')}\n`,
        ...lines,
      ],
      { type: 'text/tab-separated-values' },
    );
    const elm = document.createElement('a');
    elm.style.display = 'none';
    elm.href = URL.createObjectURL(blob);
    elm.download = fileName || `exported_case${caseIds.length > 1 ? 's' : ''}_${caseIds.join('+')}.tsv`;
    document.body.appendChild(elm);
    elm.click();
    document.body.removeChild(elm);
    setIsLoading(false);
  };

  const _loadCaseLines = async (caseId) => {
    const caseObj = await _loadCaseData(caseId);
    // case related fields
    const beadchipType = `Karyomap v${caseObj['is_v1_array'] ? '1.0' : '2.0'}`;
    const signOffLevel1 = `${caseObj['locked_by']}\t`;
    const signOffLevel2 = `${caseObj['signoff_history']?.[0]?.['signing_user_detail']?.['username']}\t`;
    const singOffHistory = caseObj['signoff_history'].reduce((line, history) => {
      line += `[${(new Date(history['signed_on'])).format()}] ${history['signing_user_detail']?.username}; `;
      return line;
    }, '') + '\t';

    const hg = caseObj?.['is_v1_array'] ? '19' : '38';
    const chromList = await MChromosomeDictionary.load({
      bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
      dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
    });

    // per analysis
    const lines = caseObj.analyses.reduce((lines, analysis) => {
      // get bio by designation
      const { embryos, father, mother, reference } = analysis.biomaterialsByDesignation;

      // formatting dates
      const analysisCreatedOn = (new Date(analysis['created_on'])).format();
      const analysisUpdatedOn = (new Date(analysis['updated_on'])).format();
      const fatherDOB = father['biomaterial_dob'] && (new Date(father['biomaterial_dob'])).format();
      const motherDOB = mother['biomaterial_dob'] && (new Date(mother['biomaterial_dob'])).format();

      // get gene regions snps
      const geneRegions = _getGeneRegions(reference, chromList);

      // defining line per embryo
      const samples = [father, mother, reference, ...embryos].filter(sample => !plateId || sample.beadchip?.['plate_id'] === plateId);
      const line = samples.reduce((line, embryo) => {
        const embryoCreatedOn = (new Date(embryo['biomaterial_created_on'])).format();
        const embryoUpdatedOn = (new Date(embryo['biomaterial_updated_on'])).format();
        const preparedOn = embryo['beadchip']?.['plate_prepared_on']
          && (new Date(embryo.beadchip['plate_prepared_on'])).format();

        // get result per region
        const results = _getEmbryoResults(embryo);

        line += `${caseObj['external_id']}\t`; //'Case_ID'
        line += `${analysis['analysis_external_id']}\t`; //'Analysis_ID'
        line += `${embryo['biomaterial_cycle_id']}\t`; //Cycle_ID
        line += `${embryo['biomaterial_name']}\t`; //Sample_ID
        line += `${embryo['id']}\t`; //Internal_ID
        line += `${embryo['biomaterial_tube_id']}\t`; //Tube_ID
        line += `${embryo['biomaterial_barcode']}\t`; //Sample_Barcode
        line += `${embryo['biomaterial_requisition']}\t`; //Requisition
        line += `${embryo['biomaterial_designation']}\t`; //Designation
        line += `${embryo['biomaterial_pedegree']}\t`; //Pedigree
        line += `${embryo['biomaterial_bio_type']}\t`; //Sample_Type
        line += `${embryo['biomaterial_sex']}\t`; //Accessioned_Sex
        line += `${embryo['biomaterial_metadata']?.['volume_ul']}\t`; //Input_Volume
        line += `${embryo['biomaterial_metadata']?.['concentration_ngul']}\t`; //Input_Conc
        line += `${embryo['biomaterial_metadata']?.['260_280']}\t`; //260_280
        line += `${embryo['biomaterial_metadata']?.['260_230']}\t`; //260_230
        line += `${mother['biomaterial_name']}\t`; //Maternal_Sample_ID
        line += `${mother['biomaterial_lastname']}\t`; //Maternal_Last_Name
        line += `${mother['biomaterial_firstname']}\t`; //Maternal_First_Name
        line += `${motherDOB}\t`; //Maternal_Date_of_Birth
        line += `${father['biomaterial_lastname']}\t`; //Paternal_Last_Name
        line += `${father['biomaterial_firstname']}\t`; //Paternal_First_Name
        line += `${fatherDOB}\t`; //Paternal_Date_of_Birth
        line += `${caseObj.consultant}\t`; //Referred_By
        line += `${caseObj.centre}\t`; //Referring_Centre
        line += `${embryoCreatedOn}\t`; //Accessioning_Date
        line += `${embryo['beadchip']?.['plate_pooled']}\t`; //Hybridised
        line += `${embryo['beadchip']?.['plate_external_id']}\t`; //Plate_ID
        line += `${preparedOn}\t`; //Preparation_Date
        line += `${embryo['beadchip']?.['beadchips_defined']}\t`; //BeadChips_Defined
        line += `${embryo['beadchip']?.['plate_prepared_by']}\t`; //Plate_Created_By
        line += `${embryo['biomaterial_plate_position']}\t`; //Plate_Well
        line += `${embryo['beadchip']?.metadata?.['lot_box_0']}\t`; //Quad_WG-Pre_Lot
        line += `${embryo['beadchip']?.metadata?.['lot_box_1']}\t`; //WG-Post_1_LV1_Lot
        line += `${embryo['beadchip']?.metadata?.['lot_box_2']}\t`; //Single_Post_3_LV_Lot
        line += `${embryo['beadchip']?.metadata?.['lot_box_3']}\t`; //Post_2_LMV_Lot
        line += `${embryo['beadchip']?.metadata?.['lot_box_4']}\t`; //Post_4_LV_Lot
        line += `${embryo['beadchip']?.barcode}\t`; //BeadChip_Serial
        line += `${embryo['biomaterial_subarray']}\t`; //Subarray
        line += `${analysis.name}\t`; //Analysis_Name
        line += `${embryo['stats']?.['fileName']}\t`; //GTC_Filename
        line += `${analysis.id}\t`; //Analysis_Internal_ID
        line += `${reference['biomaterial_name']}\t`; //Analysis_Reference_ID
        line += `${reference['biomaterial_pedegree']}\t`; //Reference_Pedigree
        line += `${reference['biomaterial_bio_type']}\t`; //Reference_Type
        line += `${analysis.notes}\t`; //Analysis_Notes
        line += `${analysisCreatedOn}\t`; //Analysis_Date_Created
        line += `${analysisUpdatedOn}\t`; //Analysis_Date_Modified
        line += `${geneRegions}\t`; //Gene_Regions
        line += `${embryo['stats']?.scanDate}\t`; //BeadChip_Scan_Date
        line += `${embryo['stats']?.['callRate']}\t`; //Call_Rate
        line += `${embryo['stats']?.['aaRate']}\t`; //AA_Rate
        line += `${embryo['stats']?.['abRate']}\t`; //AB_rate
        line += `${embryo['stats']?.['bbRate']}\t`; //BB_Rate
        line += `${embryo['stats']?.['adoRate']}\t`; //ADO_Rate
        line += `${embryo['stats']?.['misCallRate']}\t`; //MisCall_Rate
        line += `${embryo['stats']?.['xHetRate']}\t`; //X_Het_Rate
        line += `${embryo['stats']?.['yCallRate']}\t`; //Y_Call_Rate
        line += `${embryo.qc}\t`; //QC_Status
        line += `${results}\t`; //Sample_Result
        line += `${embryo['disease_status']}\t`; //Sample_Status
        line += `${embryo['stats']?.['warnings']?.join(':')}\t`; //Warnings
        line += signOffLevel1; //Level_1_signoff
        line += signOffLevel2; //Level_2_signoff
        line += singOffHistory; //SignOff_History
        line += `${embryoUpdatedOn}\t`; //Date_sample_Last_Update
        line += `${beadchipType}\t`; //BeadChip_Type
        line += `${embryo['stats']?.['scanningHardware']}\t`; //Scanning_Hardware
        line += `${embryo['stats']?.['manifestName']}\t`; //Manifest_File
        line += '\t'; //Cluster_File
        line += `${embryo['stats']?.['flankSetting']}\t`; //Flanking_Size_Mb
        line += `${RELEASE_VERSION}\t`; //Software_Version
        line += `${embryo['stats']?.['algorithmVersion']}`; //Analysis_Version
        return `${line.replace(/(null|undefined)/gi, '')}\n`;
      }, '');
      return lines + line;
    }, '');
    return lines;
  };

  const _loadCaseData = async (caseId) => {
    const [res, geneRegionsRes] = await Promise.all([
      AppAPI.Case.get(caseId),
      AppAPI.GeneRegion.list({ filters: { 'case': caseId } })
    ]);
    // parsing gene regions
    res.geneRegions = (Array.isArray(geneRegionsRes.results) ? geneRegionsRes.results : []).map(
      region => ({ ...region, chromosome: { '23': 'X', '24': 'Y' }[region.chromosome] ?? region.chromosome })
    );
    // get analyses
    res.analyses = (Array.isArray(res.analyses) ? res.analyses : []).map(AppAPI.Analysis.parseAnalysisForReport);
    if (res.error) {
      setIsLoading(false);
      return message.error(`${res['external_id']}: ${Language.get('common', res.msg)}`);
    } else if (res.analyses.length === 0) {
      setIsLoading(false);
      return message.error(`${res['external_id']}: ${Language.get('common', 'ANALYSIS_NOT_AVAILABLE')}`);
    }
    setIsLoading(false);
    return res;
  };

  const _getEmbryoResults = (embryo) => {
    return embryo['stats']?.regions?.reduce((results, region) => {
      results += `${region.nameAndLocation.replace(' ', ':')}:${region.predictedPhases.join(' ')}:${region.predictedStatus}:`;
      results += `${region.supportive?.[0] || 'NA'}/${region.nonSupportive?.[0] || 'NA'}:`;
      results += `${region.supportive?.[1] || 'NA'}/${region.nonSupportive?.[1] || 'NA'}:`;
      results += `${region.snpP1?.key?.left}/${region.snpP1?.nonKey?.left}:`;
      results += `${region.snpP1?.key?.main}/${region.snpP1?.nonKey?.main}:`;
      results += `${region.snpP1?.key?.right}/${region.snpP1?.nonKey?.right}:`;
      results += `${region.snpP2?.key?.left}/${region.snpP2?.nonKey?.left}:`;
      results += `${region.snpP2?.key?.main}/${region.snpP2?.nonKey?.main}:`;
      results += `${region.snpP2?.key?.right}/${region.snpP2?.nonKey?.right}:`;
      results += `${region.snpM1?.key?.left}/${region.snpM1?.nonKey?.left}:`;
      results += `${region.snpM1?.key?.main}/${region.snpM1?.nonKey?.main}:`;
      results += `${region.snpM1?.key?.right}/${region.snpM1?.nonKey?.right}:`;
      results += `${region.snpM2?.key?.left}/${region.snpM2?.nonKey?.left}:`;
      results += `${region.snpM2?.key?.main}/${region.snpM2?.nonKey?.main}:`;
      results += `${region.snpM2?.key?.right}/${region.snpM2?.nonKey?.right}`;
      return results;
    }, '');
  };

  const _getGeneRegions = (reference, chromList) => {
    return reference?.stats?.trioSnps?.reduce((geneRegions, snps) => {
      snps.start = Number(snps.start);
      snps.end = Number(snps.end);
      const length = snps.end - snps.start;
      const region = reference['gene_regions'].find(
        (region) => region.name === snps.name
          && region['gene_region_start_at'] === snps.start
          && region['gene_region_start_at'] + region['gene_region_length'] === snps.end
      );
      const cytoband = MChromosomeDictionary.getCytoband({
        chromosome: chromList.find(({ label }) => label === snps.chrom),
        position: snps.start + (length / 2),
      });
      geneRegions += `${snps.name}:`;
      geneRegions += `${snps.chrom}:`;
      geneRegions += `${cytoband}:`;
      geneRegions += `${snps.start}:`;
      geneRegions += `${snps.end}:`;
      geneRegions += `${length}:`;
      geneRegions += `${region?.['gene_region_mutation_site'] || 'NA'}:`;
      geneRegions += `${snps.inheritance}:`;
      geneRegions += `${snps['status_mother']}:`;
      geneRegions += `${snps['status_father']}:`;
      geneRegions += `${snps['status_reference']}:`;
      geneRegions += `${snps.total.left}/${snps.maternal.left}/${snps.paternal.left}:`;
      geneRegions += `${snps.total.main}/${snps.maternal.main}/${snps.paternal.main}:`;
      geneRegions += `${snps.total.right}/${snps.maternal.right}/${snps.paternal.right};`;
      return geneRegions;
    }, '');
  };

  return { download, isLoading };
}
