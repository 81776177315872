import { useEffect, useState } from 'react';
import pako from 'pako';
import * as d3 from 'd3';

const fetchTsv = async (url, options) => {
  const response = await fetch(url, {
    credentials: 'include',
    mode: 'cors',
    ...options,
  });

  if (url.endsWith('.gz')) {
    const rawFile = await response.arrayBuffer();
    return pako.ungzip(rawFile, { to: 'string' });
  }

  return await response.text();
};

const useTsv = ({ url, parser }) => {
  const [rawData, setRawData] = useState('');
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let controller;
    controller = new AbortController();
    if (url) {
      setIsLoading(true);
      setRawData('');
      fetchTsv(url, { signal: controller.signal })
        .then(setRawData)
        .catch(() => setRawData(''))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setRawData('');
    }
    return () => controller?.abort('');
  }, [url]);

  useEffect(() => {
    const parsed = d3.tsvParse(rawData, parser);
    setData(parsed);
    setColumns(parsed.columns);
  }, [rawData]);

  return { isLoading, data, columns };
};

export default useTsv;
