// Importing modules
import AppAPI from 'modules/api';

// Importing app helper
import { preventUndefined, tableHeader } from './common';

export const samplesSummary = ({ biomaterials, i18n, icons }) => {
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');
  const data = biomaterials.map((biomaterial) => {
    const stats = AppAPI.Biomaterial.parseStats(biomaterial['bp_analysis_stats']);
    return preventUndefined([
      biomaterial['biomaterial_name'],
      biomaterial['biomaterial_cycle_id'],
      biomaterial['biomaterial_barcode'],
      biomaterial['biomaterial_subarray'],
      i18n(biomaterial['biomaterial_bio_type'], 'biomaterial'),
      stats.regions.map(region => `${region.name}:${i18n(region.predictedStatus, 'gene')}`).join('\n'),
      ...(isCnvEnabled ? [stats.aneuploidyStatus] : []),
      stats.qc,
      { svg: icons[biomaterial.id], width: 15, height: 15 },
    ]);
  }
  );

  const headerColumns = [
    { title: i18n('SAMPLE_ID'), width: 'auto' },
    { title: i18n('CYCLE_ID'), width: 'auto' },
    { title: i18n('BARCODE'), width: '*' },
    { title: i18n('SUBARRAY'), width: 'auto' },
    { title: i18n('SAMPLE_TYPE'), width: '*' },
    { title: i18n('PREDICTED_STATUS'), width: '*' },
    ...(isCnvEnabled ? [{ title: i18n('ANEUPLOIDY_STATUS'), width: '*' }] : []),
    { title: i18n('QC'), width: 'auto' },
    { title: i18n('RESULT'), width: 'auto' },
  ];

  return {
    style: 'table',
    table: {
      body: [
        tableHeader(headerColumns.map(({ title }) => title)),
        ...data,
      ],
      widths: headerColumns.map(({ width }) => width),
      headerRows: 1,
    },
  };
};
