// Importing React and external libs
import React, { useEffect, useState, useRef, Fragment } from 'react';

// Importing Antd
import {
  Avatar,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Upload
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/message/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/select/style/css';
import 'antd/es/spin/style/css';
import 'antd/es/upload/style/css';

// Importing antd icons
import { CloseOutlined, EditOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix modules
import 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing Helix components
import Label from 'helix/ui/label';

// Importing modules
import AppAPI from 'modules/api';
import Permission from 'modules/permission';

// Import styles
import './profile.scss';

export const EXPANDED_CHARTS_OPTIONS = {
  'KARYOMAP': 'KARYOMAP',
  'CNV_CHART': 'CNV_CHART',
  'DETAILED_HAPLOBLOCK_CHART': 'DETAILED_HAPLOBLOCK_CHART',
  'B_ALLELE_CHART': 'B_ALLELE_CHART',
  'LOGR_CHART': 'LOGR_CHART',
};

const ChartSettings = (props) => {
  const Language = useLanguage();
  const { reloading, save } = props;
  const { Item } = Form;
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');
  const ENTRY_CHARTS_OPTIONS = [
    { label: Language.get('chart', 'KARYOMAP'), value: 'Karyomap' },
    ...(isCnvEnabled ? [{ label: Language.get('chart', 'IDEOGRAM'), value: 'Ideogram' }] : []),
  ];
  const expandedChartsOptions = Object.keys(EXPANDED_CHARTS_OPTIONS)
    .filter(chartKey => chartKey !== EXPANDED_CHARTS_OPTIONS.CNV_CHART || isCnvEnabled)
    .map(chartKey => ({
      label: Language.get('chart', chartKey),
      value: chartKey
    }));
  return (
    <Fragment>
      <Item
        colon={false}
        label={Language.get('user', 'ENTRY_CHARTS')}
        name="entry_charts"
      >
        <Checkbox.Group disabled={reloading} options={ENTRY_CHARTS_OPTIONS} onChange={() => save({ field: 'entry_charts' })} />
      </Item>
      <Item
        colon={false}
        label={Language.get('user', 'EXPANDED_CHARTS')}
        name="expanded_charts"
      >
        <Checkbox.Group disabled={reloading} options={expandedChartsOptions} onChange={() => save({ field: 'expanded_charts' })} />
      </Item>
    </Fragment>
  );
};

export const Profile = () => {
  const [form] = Form.useForm();
  const Language = useLanguage();
  const [avatar, setAvatar] = useState('');
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [user, setUser] = useState(window.app.user);
  let saveTO = undefined;
  let updateTO = undefined;

  const OAUTH = JSON.parse(window.localStorage.getItem('oauth'));
  const ACCESS_TOKEN = OAUTH['access_token'];

  useEffect(() => {
    const { user } = window.app || {};
    setAvatar(user.avatar);
  }, []);

  // Before upload
  const _beforeUpload = (file) => {
    if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
      message.error(Language.get('user', 'AVATAR_NOT_VALID_FILE_TYPE'));
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      message.error(Language.get('user', 'AVATAR_FILE_TOO_LARGE'));
      return false;
    }
    setLoading(true);
    return true;
  };

  const _onChange = (info) => { // TODO: Needs to be updated when adding the upload image feature
    const { file } = info;
    if (file.status === 'done' && file.response && file.response.avatar) {
      window.app.user.avatar = file.response.avatar;
      setAvatar(file.response.avatar);
      setLoading(false);
    } else if (file.status === 'error') {
      message.error(Language.get('common', (
        (file.response && file.response[0]) || file.response?.detail || 'AVATAR_UPLOADING_FAILED'
      )));
      setLoading(false);
    }
  };

  // Save the form data
  const save = ({ field, reload = false }) => {
    if (saveTO) clearTimeout(saveTO);
    saveTO = setTimeout(async () => {
      setReloading(true);
      const { user } = window.app || {};
      const { getFieldValue } = form;
      const modifiedPreferences = {
        ...user.preferences,
        [field]: getFieldValue(field),
      };
      const res = await AppAPI.User.save({
        id: user.id,
        preferences: modifiedPreferences,
      });
      setReloading(false);
      if (res.error) {
        return message.error(Language.get('user', res.msg));
      }
      window.app.user.preferences = modifiedPreferences;
      setUser(window.app.user);
      message.success(Language.get('common', 'DATA_SAVED_SUCCESFULLY'));
      return reload && window.location.reload();
    }, 1000);
  };

  // Update the form data
  const update = async () => {
    if (updateTO) clearTimeout(updateTO);
    updateTO = setTimeout(async () => {
      const { user } = window.app || {};
      try {
        const data = await form.validateFields();
        const res = await AppAPI.User.save({
          id: user.id,
          password: data.password,
        });
        if (res.error) {
          return message.error(Language.get('user', res.msg));
        }
        message.success(Language.get('common', 'DATA_SAVED_SUCCESFULLY'));
        window.location.reload();
      } catch (error) {
        console.error(error); // validation error.
      }
    }, 1000);
  };

  const defaultPreferences = {
    ['expanded_charts']: [EXPANDED_CHARTS_OPTIONS.DETAILED_HAPLOBLOCK_CHART]
  };

  return (
    <div className="user-profile">
      <h1>{Language.get('user', 'PROFILE')}</h1>
      <Form
        className="user-add-edit-form"
        form={form}
        initialValues={{ ...defaultPreferences, ...user.preferences }}
        name="profile-form"
      >
        <div className="row">
          <div className="col upload-col">
            <Upload
              accept="image/jpeg,image/png"
              action={AppAPI.User.constants.AVATAR}
              headers={{ Authorization: `Bearer ${ACCESS_TOKEN}` }}
              className="avatar-uploader"
              listType="picture-card"
              name="avatar"
              showUploadList={false}
              beforeUpload={_beforeUpload}
              onChange={_onChange}
            >
              {loading
                ? <Spin size="large" />
                : (
                  <Avatar
                    icon={<UserOutlined />}
                    size={100}
                    src={`${avatar}?id=${(Math.random() + 1).toString(36).substring(2)}`}
                  />
                )
              }
            </Upload>
          </div>
          <div className="col internal-col">
            <UserSettings
              form={form}
              reloading={reloading}
              user={user}
              save={save}
              update={update}
            />
          </div>
          <div className="col chart-col">
            <h1>{Language.get('user', 'CHART_SETTINGS')}</h1>
            <ChartSettings
              reloading={reloading}
              user={user}
              save={save}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

const UserSettings = (props) => {
  const Language = useLanguage();
  const password = useRef();
  const rePassword = useRef();
  const [disabled, setDisabled] = useState(true);
  const { form, reloading, user, save, update } = props;
  const { Item } = Form;
  const { Option } = Select;
  const zones = window?.app?.zones || [];
  const region = zones.find(zone => window.location.origin.includes(zone));
  const { LANGUAGES } = AppAPI.Host.getSetting();

  return (
    <Fragment>
      <Item colon={false} label={Language.get('user', 'LAST_NAME')}>
        {user['last_name']}
      </Item>
      <Item colon={false} label={Language.get('user', 'FIRST_NAME')}>
        {user['first_name']}
      </Item>
      <Item colon={false} label={Language.get('login', 'EMAIL')}>
        {user.username}
      </Item>
      <Item
        className="notification"
        colon={false}
        label={Language.get('user', 'EMAIL_NOTIFICATION')}
        name="email_notification"
      >
        <Select disabled={reloading} size="small" onChange={() => save({ field: 'email_notification' })}>
          <Option value="True">{Language.get('common', 'YES')}</Option>
          <Option value="False">{Language.get('common', 'NO')}</Option>
        </Select>
      </Item>
      <Item
        className="language"
        colon={false}
        label={Language.get('user', 'LANGUAGE')}
      >
        <Item name="language" noStyle>
          <Select disabled={reloading} size="small">
            {LANGUAGES.map((lng) => (<Option key={lng}>{Language.get('user', `LANGUAGE_${lng}`.toLocaleUpperCase())}</Option>))}
          </Select>
        </Item>
        <Button
          icon={<SaveOutlined />}
          size="small"
          tabIndex={-1}
          type="link"
          onClick={() => save({ field: 'language', reload: true })}
        />
      </Item>
      <Item colon={false} label={Language.get('organization', 'ORGANIZATION')}>
        {user['organization_name']}
      </Item>
      <Item colon={false} label={Language.get('common', 'REGION')}>
        {region && Language.get('login', region.toUpperCase())}
      </Item>
      <Item
        className="password"
        colon={false}
        label={Language.get('login', 'PASSWORD')}
        rules={[{
          message: <div>{`${Language.get('user', 'PASSWORD')} ${Language.get('common', 'NOT_VALID_EMPTY_VALUE')}`}</div>,
          required: true,
        }, {
          max: 16,
          message: <div>{`${Language.get('user', 'PASSWORD')} ${Language.get('user', 'NOT_VALID_MAX_LEN')} 16.`}</div>,
        }, {
          min: 8,
          message: <div>{`${Language.get('user', 'PASSWORD')} ${Language.get('user', 'NOT_VALID_MIN_LEN')} 8.`}</div>,
        }, {
          validator: (_, value) => {
            return (!value || value.length < 8 || /[a-z]/ig.test(value))
              ? Promise.resolve()
              : Promise.reject(<div>{`${Language.get('user', 'PASSWORD')} ${Language.get('user', 'NOT_VALID_AT_LEAST_ONE_LETTER')}`}</div>);
          },
        }, {
          validator: (_, value) => {
            return (!value || value.length < 8 || /[0-9]/ig.test(value))
              ? Promise.resolve()
              : Promise.reject(<div>{`${Language.get('user', 'PASSWORD')} ${Language.get('user', 'NOT_VALID_AT_LEAST_ONE_NUMBER')}`}</div>);
          },
        }, {
          validator: (_, value) => {
            return (!value || value.length < 8 || /\W/ig.test(value))
              ? Promise.resolve()
              : Promise.reject(<div>{`${Language.get('user', 'PASSWORD')} ${Language.get('user', 'NOT_VALID_AT_LEAST_ONE_NON_ALPHANUMERIC')}`}</div>);
          },
        }]}
      >
        <Item name="password" noStyle>
          <Input disabled={disabled} ref={password} size="small" tabIndex={1} type="password" />
        </Item>
        {disabled
          ? (
            <Button
              icon={<EditOutlined />}
              size="small"
              tabIndex={-1}
              type="link"
              onClick={() => setDisabled(false)}
            />
          )
          : (
            <Fragment>
              <Button
                icon={<CloseOutlined />}
                size="small"
                type="link"
                tabIndex={-1}
                onClick={() => {
                  form.setFields([{ error: null, name: 'password' }]);
                  setDisabled(true);
                }}
              />
              <Button icon={<SaveOutlined />} size="small" tabIndex={-1} type="link" onClick={update} />
            </Fragment>
          )
        }
      </Item>
      {!disabled && (
        <Item
          colon={false}
          className="password"
          label={Language.get('login', 'REENTER_PASSWORD')}
          name="repassword"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                const password = form.getFieldValue('password');
                return (password === value)
                  ? Promise.resolve()
                  : Promise.reject(<div>{`${Language.get('login', 'REENTER_PASSWORD')} ${Language.get('user', 'PASSWORDS_DONT_MATCH')}`}</div>);
              }
            }
          ]}
        >
          <Input
            disabled={disabled}
            ref={rePassword}
            size="small"
            tabIndex={2}
            type="password"
            onPressEnter={update}
          />
        </Item>
      )}
      {Permission.isLevel2() && (
        <Item
          className="access-keys"
          colon={false}
          label={
            <Label
              name={
                <span
                  className="access-keys-trigger"
                  onClick={async () => {
                    const secrets = await AppAPI.User.getAccess();
                    return Modal.info({
                      content: <pre className="json">{JSON.stringify(secrets, null, 2)}</pre>,
                      maskClosable: true,
                      okText: Language.get('common', 'CLOSE'),
                      title: Language.get('user', 'ACCESS_KEYS_MODAL_TITLE'),
                      width: 800,
                    });
                  }}
                >{Language.get('user', 'ACCESS_KEYS')}</span>
              }
              title={Language.get('user', 'ACCESS_KEYS_INFO')}
            />
          }
        />
      )}
    </Fragment>
  );
};
