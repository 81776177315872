// Importing react libs
import React from 'react';

// Importing antd libs
import { Radio } from 'antd';
import 'antd/es/radio/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

export const AnalysesPicker = ({ analyses, value, onChange }) => {
  const Language = useLanguage();

  const analysesMap = analyses.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const options = analyses.map((analysis) => ({
    label: `${Language.get('report', 'ANALYSIS')} ${analysis.id}`,
    value: analysis.id,
  }));

  const handleOnChange = (event) => {
    const { value } = event.target;
    onChange(analysesMap[value]);
  };

  return (
    <Radio.Group
      options={options}
      optionType="button"
      value={value}
      onChange={handleOnChange}
    />
  );
};
