export const ROLE_MAPPER = {
  'level_1': 'LEVEL_1',
  'level_2': 'LEVEL_2',
};
class Permission {
  /**
   * Check if superuser is kmapuser
   * @param {void}
   * @return {bool}
  **/
  isKMapUser() {
    const { isKMapUser } = window.app?.user ?? {};
    return this.isSuperuser() && isKMapUser;
  }

  /**
   * Check if logged user is level 1
   * @param {void}
   * @return {bool}
  **/
  isLevel1() {
    const { user: { role } } = window.app || { user: {} };
    return role && role === 'level_1';
  }

  /**
   * Check if logged user is level 2
   * @param {void}
   * @return {bool}
  **/
  isLevel2() {
    const { user: { role } } = window.app || { user: {} };
    return role && role === 'level_2';
  }

  /**
   * Check if logged user is host admin
   * @param {void}
   * @return {bool}
  **/
  isHostAdmin() {
    const { user: { host_admin: hostAdmin } } = window.app || { user: {} };
    return hostAdmin;
  }

  /**
   * Check if logged user is django superuser
   * @param {void}
   * @return {bool}
  **/
  isSuperuser() {
    const { user: { is_superuser: isSuperuser } } = window.app || { user: {} };
    return isSuperuser;
  }
}

export default new Permission();
