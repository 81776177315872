import Abstract from './abstract';

class Context extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'context';
    this._updateEndpoint();
  }

  /**
   * Hit GET {this.constants.ENDPOINT}/ get context obj detail
   * @param {void}
   * @return {object}
  **/
  async get() {
    const res = await fetch(
      `${this.constants.ENDPOINT}/self`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'GET',
      },
    );
    return this._handleError(res);
  }

  // NOT IMPLEMENTED METHODS
  async delete() {
    throw new TypeError('Context.delete not implemented.');
  }
  async list() {
    throw new TypeError('Context.list not implemented.');
  }
  async save() {
    throw new TypeError('Context.save not implemented.');
  }
}

export default new Context();
