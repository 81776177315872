// Importing react libs
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { groupBy } from 'lodash-es';
import JSZip from 'jszip';

// Import antd
import { Collapse, Divider, message, Spin } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/divider/style/css';
import 'antd/es/message/style/css';
import 'antd/es/spin/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing components
import { List } from './list';

// Importing styles
import './analysis.scss';
import { useQuery } from '@tanstack/react-query';

export const AnalysisFileList = ({ title, type }) => {
  const Language = useLanguage();
  const { id } = useParams();

  const { isFetching: loading, data: analyses } = useQuery({
    queryKey: [{ 'case_id': id, 'tags': ['kmap', 'data', 'zip'] }],
    queryFn: async () => {
      const res = await AppAPI.Analysis.filesByAnalysis({
        'case_id': id,
        'tags': ['kmap', 'data', 'zip']
      });
      if (res.error) {
        message.error(Language.get('login', res.msg));
        return {};
      }
      return groupBy(res, 'analysis_id');
    },
    initialData: {},
  });

  const prefix = (file) => `${file.analysisExternalId}_${file.name}`;

  const processFile = async (file) => {
    const res = await fetch(file['file_uri'], {
      credentials: 'include',
      mode: 'cors',
    });
    const singleZip = new JSZip();
    const unzipped = await singleZip.loadAsync(await res.blob());
    const promisesUnzipped = [];
    unzipped.forEach(async (path, entry) => {
      let newPath = path;
      ['father', 'mother', 'reference', 'sample'].forEach(designation => {
        if (newPath.includes(designation)) {
          newPath = newPath.replace(`${designation}.`, '');
          newPath = `${file.externalName[designation]}_${newPath}`;
        }
      });
      promisesUnzipped.push(new Promise((resolve) => {
        entry.async('uint8array').then(content => {
          resolve({ path: `${prefix(file)}/${prefix(file)}_${newPath}`, content });
        });
      }));
    });

    return Promise.all(promisesUnzipped);
  };

  return (
    <div className="analysis-files">
      {Object.keys(analyses).length > 0 && <h2>{title}</h2>}
      <div className="spinner">{loading && <Spin tip={`${Language.get('common', 'LOADING')}...`} />}</div>
      <Collapse bordered={false}>
        {Object.entries(analyses).map(([analysisId, analyses], index) => {
          const { case_external_id: caseExternalId } = analyses[0] ?? {};
          const analysisExternalId = `${caseExternalId}-${analysisId}`;
          const analysisFiles = (analyses ?? []).reduce((accFiles, analysis) => [
            ...accFiles, ...analysis.files.map(file => ({
              ...file,
              analysisId,
              analysisExternalId,
              caseExternalId,
              externalName: analysis['external_name'],
              name: analysis['files_name'],
            }))
          ], []);

          return (
            <Fragment key={index}>
              <List
                files={analysisFiles.filter(e => e['file_uri'].endsWith(`${type}.zip`))}
                title={`Analysis ${analysisExternalId}`}
                getFileName={(file) => {
                  const { file_uri: path } = file;
                  return `${prefix(file)}_${path?.split('/')?.at(-1)}`;
                }}
                processFile={processFile}
              />
              <Divider id={`analysis-${analysisId}`} />
            </Fragment>
          );
        })}
      </Collapse>
    </div>
  );
};
