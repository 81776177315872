// Importing react libs
import React from 'react';

// Importing antd libs
import { Descriptions, Typography } from 'antd';
import 'antd/es/descriptions/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Item } = Descriptions;
const { Title } = Typography;

// Exporting component
export const Disease = ({ analysis, geneRegion }) => {
  const { father, mother, reference } = analysis.biomaterialsByDesignation;
  const Language = useLanguage();

  const getStatus = (biomaterial) => {
    const biomaterialRegions = biomaterial['gene_regions'] ?? [];
    const { status } = biomaterialRegions.find(region => region['gene_region_id'] === geneRegion.id) ?? {};
    return status;
  };

  return <div>
    <Title level={3}>{Language.get('case', 'DISEASE_STATUS')}</Title>
    <Descriptions bordered className="trio-table-info" column={1} contentStyle={{ minWidth: 100 }} size="small">
      <Item label={Language.get('case', 'DISORDER_TYPE')}>{Language.get('gene', geneRegion['gene_type'])}</Item>
      <Item label={Language.get('case', 'MATERNAL_STATUS')}>{getStatus(mother) ? Language.get('gene', getStatus(mother)) : null}</Item>
      <Item label={Language.get('case', 'PATERNAL_STATUS')}>{getStatus(father) ? Language.get('gene', getStatus(father)) : null}</Item>
      <Item label={Language.get('case', 'REFERENCE_STATUS')}>{getStatus(reference) ? Language.get('gene', getStatus(reference)) : null}</Item>
    </Descriptions>
  </div>;
};
