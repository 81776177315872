// Importing React and external libs
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Importing Antd
import { Spin } from 'antd';
import 'antd/es/spin/style/css';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix components
import Error from 'helix/ui/error';

// Importing modules
import AppAPI from 'modules/api';
import Permission from 'modules/permission';

// Importing components
import { SettingForm } from './form';

export const OrganizationForm = () => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const params = useParams();
  const [organization, setOrganization] = useState(null);
  const IS_HOST_ADMIN = Permission.isHostAdmin();

  useEffect(() => {
    const _loadData = async () => {
      let organization = {};
      const setting = AppAPI.Organization.getHostSetting();
      const { id } = params || {};
      if (id) {
        organization = await AppAPI.Organization.get(id) || {};
      }
      setOrganization({ setting, ...organization });
    };
    _loadData();
  }, []);

  return organization
    ? (
      IS_HOST_ADMIN
        ? (<SettingForm organization={organization} onAfterSave={() => navigate('/dashboard')} />)
        : (<Error message={Language.get('organization', 'UNAUTHORIZED')} />)
    )
    : (<Spin size="large" />);
};
