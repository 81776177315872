// Importing react libs
import React from 'react';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { List } from 'components/case';
import { ImportUpload } from 'components/case/import';

// Importing container styles
import './index.scss';

// Exporting component
export const Case = () => {
  const Language = useLanguage();
  return (
    <div className="case">
      <h2>{Language.get('case', 'CASE_LIST')}</h2>
      <ImportUpload />
      <List name="cases" isListPage={true} />
    </div>
  );
};
