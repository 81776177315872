// Importing react and external libs
import React, { Fragment, useState } from 'react';

// Import antd
import {
  Button,
  Typography,
  message,
} from 'antd';
import 'antd/es/button/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing Helix components
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';

// Importing modules
import AppAPI from 'modules/api';

// Importing styles
import './list.scss';

export const List = () => {
  const Language = useLanguage();
  const [filters, setFilters] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const isFiltered = Object.values(filters ?? {}).some(filter => Boolean(filter));

  const reader = async ({ filters, limit, offset, order }) => {
    setFilters(filters);

    const mapper = {
      action: 'action__icontains',
      actor_id: 'actor_id__icontains',
    };
    filters = Object.keys(filters).reduce((newFilters, field) => {
      newFilters[mapper[field] || field] = filters[field];
      return newFilters;
    }, {});
    order = order || '-created_on';
    return await AppAPI.Log.list({ filters, limit, offset, order });
  };
  const columns = [{
    dataIndex: 'created_on',
    sorter: true,
    title: Language.get('log', 'CREATED').toUpperCase(),
    render: (date) => date ? (new Date(date)).format() : ' - ',
    ...tableColumnFilterDate(),
  }, {
    dataIndex: 'action',
    sorter: true,
    title: Language.get('common', 'ACTION').toUpperCase(),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'actor_id',
    sorter: true,
    title: Language.get('log', 'USER_ID').toUpperCase(),
    ...tableColumnFilterSearch(),
  }];

  const parser = (objects) => {
    return objects.map((obj) => {
      try {
        const { action } = AppAPI.Log.parseLog(obj, Language);
        obj.action = action;
      } catch (error) {
        console.log(error);
      }
      return obj;
    });
  };

  const _exportToTSV = async () => {
    setIsExporting(true);
    let next = true;
    let offset = 0;
    const limit = 500;
    const data = [];
    while (next) {
      const res = await reader({
        filters: filters,
        limit: limit,
        offset: offset,
      });
      if (res.error) {
        next = false;
        return message.error(`${Language.get('message', res.msg)}`);
      }
      if (!res?.next) next = false;
      data.push(...res.results);
      offset += limit;
    }
    const header = `${Language.get('common', 'CREATED')}\t${Language.get('common', 'ACTION')}\t${Language.get('login', 'USERNAME')}\r\n`;
    const colsName = ['created_on', 'action', 'actor_id'];
    const tsvContent = parser(data).reduce((lines, row) => {
      return lines + `${colsName.map((field) => row[field]).join('\t')}\r\n`;
    }, isFiltered ? `Filtered Log Data\r\n\r\n${header}` : header);
    const blob = new Blob([tsvContent], { encoding: 'UTF-8', type: 'text/tsv;charset=UTF-8' });
    const elm = document.createElement('a');
    elm.href = window.URL.createObjectURL(blob);
    let name = 'kMap_logs';
    if (isFiltered) name += '_filtered';
    if (Array.isArray(filters['created_on'])) {
      const [start, end] = filters['created_on'].map(strDate => new Date(strDate).format('date'));
      name += `_${start}_${end}`;
    }
    elm.download = `${name}.tsv`;
    document.body.appendChild(elm); // firefox compatibility
    elm.click();
    elm.remove();
    setIsExporting(false);
  };

  return (
    <Fragment>
      <div className="table-actions">
        <Typography.Title level={2}>
          {Language.get('log', isFiltered ? 'FILTERED_LOG_DATA' : 'LOG_DATA')}
        </Typography.Title>
        <Button
          loading={isExporting}
          size="small"
          type="primary"
          onClick={_exportToTSV}
        >
          {Language.get('common', 'EXPORT_TO_TSV')}
        </Button>
      </div>
      <HelixList
        columns={columns}
        context="common"
        dataField="results"
        dataCountField="count"
        defaultSort={{ field: 'created_on', order: 'descend' }}
        name='logs'
        pageSize={20}
        rowKey="id"
        parser={parser}
        reader={reader}
      />
    </Fragment>
  );
};