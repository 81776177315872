// Importing React and external libs
import React, { Fragment, useState } from 'react';

// Importing antd
import { Button, Skeleton } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/skeleton/style/css';

// Importing antd icons
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

// Importing modules
import AppAPI from 'modules/api';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing styles
import './style.scss';

// Constants
const HOME_PATH = '/dashboard';
const DEFAULT_TERMS = 'https://www.vitrolife.com/globalassets/_site-structure/terms-and-conditions/genomics/vitrolife-group-genomics-gtc_2024.pdf';

export const Terms = ({ hideActions, isFullPage = false }) => {
  const Language = useLanguage();
  const i18n = (key) => Language.get('common', key);

  const [loadingKey, setLoadingKey] = useState(false);
  const [isLoadingTerms, setIsLoadingTerms] = useState(true);

  const _agree = async () => {
    setLoadingKey('agree');
    const res = await AppAPI.User.signTerms(true);
    if (res['term_signedon']) {
      const pathname = window.location.pathname;
      window.location.pathname = pathname.includes('/terms') ? HOME_PATH : pathname;
    }
    setLoadingKey(false);
  };

  const _disagree = async () => {
    setLoadingKey('disagree');
    await AppAPI.User.logout();
    window.location.href = '/';
    setLoadingKey(false);
  };

  const terms = window.app.terms ?? AppAPI.Organization.getHostSetting('terms_file') ?? DEFAULT_TERMS;

  return (
    <article className={`terms${isFullPage ? ' full-page' : ''}`}>
      <h1>{i18n('TERMS_AND_CONDITIONS')}</h1>
      {isLoadingTerms ? <Skeleton active /> : null}
      <iframe
        className='terms-content'
        src={terms}
        onLoad={() => setIsLoadingTerms(false)}
      />
      {hideActions ? null : (
        <div className="terms-actions">
          <Fragment>
            <Button
              icon={<CloseOutlined />}
              loading={loadingKey === 'disagree' || isLoadingTerms}
              onClick={_disagree}
            >
              {i18n('DISAGREE')}
            </Button>
            <Button
              icon={<CheckOutlined />}
              loading={loadingKey === 'agree' || isLoadingTerms}
              type="primary"
              onClick={_agree}
            >
              {i18n('AGREE')}
            </Button>
          </Fragment>
        </div>
      )}
    </article>
  );

};
