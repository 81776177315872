// Importing react libs
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing antd libs
import { Button, Col, Descriptions, Form, Input, Row } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/col/style/css';
import 'antd/es/descriptions/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/row/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import ReAnalyze from 'components/analysis/reanalyze';

// Importing helper libs
import { Notification } from 'helper/handler/notification';

// Importing component style
import './header.scss';

// Defining constants
const { Item: FormItem } = Form;
const { Item: DItem } = Descriptions;
const { TextArea } = Input;
const { DESIGNATIONS } = AppAPI.Biomaterial;
const MAX_LENGTH_NOTES = 500;

// Exporting component
export const Header = ({ analysis, loadCaseData, caseObj: caseData }) => {
  const [caseObj, setCaseObj] = useState(caseData);
  const { notes: caseNotes, is_lock: isLocked } = caseObj;
  const Language = useLanguage();
  const navigate = useNavigate();
  const [oldNotes, setOldNotes] = useState(caseNotes);
  const [notes, setNotes] = useState(caseNotes);

  const handleNotesOnBlur = async (event) => {
    const newNotes = event.target.value;
    if (newNotes !== oldNotes) {
      const response = await AppAPI.Case.save({ id: analysis.case, notes: newNotes });
      setOldNotes(newNotes);
      setCaseObj(await loadCaseData());
      Notification.fromResponse(Language, response);
    }
  };
  const { biomaterial_name: name, biomaterial_pedegree: pedegree } = analysis.biomaterials.find(
    (biomaterial) => biomaterial['biomaterial_designation'] === DESIGNATIONS.REFERENCE
  ) || {
    'biomaterial_name': Language.get('case', 'UNKNOWN'),
    'biomaterial_pedegree': Language.get('case', 'UNKNOWN'),
  };

  return (
    <div className='case-detail'>
      <Row gutter={24}>
        <Col span={6}>
          <Descriptions column={1}>
            <DItem label={Language.get('case', 'REFERENCE_ID')}>{name}</DItem>
            <DItem label={Language.get('case', 'REFERENCE_TYPE')}>
              {Language.get('biomaterial', pedegree.toUpperCase())}
            </DItem>
          </Descriptions>
        </Col>
        <Col span={3}>
          <Button
            block
            type="primary"
            onClick={() => navigate(`/case/${analysis['case']}/files#analysis-${analysis.id}`)}
          >
            {Language.get('common', 'DOWNLOAD')}
          </Button>
          <ReAnalyze
            analysis={analysis}
            block
            style={{ marginTop: 10 }}
            onSuccess={() => navigate('/dashboard')}
          />
        </Col>
        <Col span={12}>
          <FormItem
            label={Language.get('common', 'NOTES')}
            rules={[{
              max: MAX_LENGTH_NOTES,
              message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH_NOTES}`,
            }]}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              className="case-detail-notes"
              onBlur={handleNotesOnBlur}
              onChange={(event) => setNotes(event.target.value)}
              value={notes}
              readOnly={isLocked}
            />
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};
