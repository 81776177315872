// Importing react libs and external libs
import React, { useEffect, useState } from 'react';

// Importing antd libs
import {
  Button,
  Form,
  InputNumber,
  Select,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input-number/style/css';
import 'antd/es/select/style/css';

// Importing helix components
import useChartZoom from 'helix-hooks/chart/useChartZoom';

// Importing local components
import ChromosomeNavigation from 'components/case/view/navigation/chromosome';
import DiseaseSelector from 'components/gene/diseaseSelector';

// Importing component style
import '../overview.scss';

export default function CaseViewNavigation({
  chrom: globalChrom,
  chroms,
  chromPosition,
  chromPositions,
  flanking,
  geneRegions,
  setChrom,
  defaultRegion,
  setTransformedExtent,
}) {
  const [bands, setBands] = useState([]);
  const [form] = Form.useForm();
  const chromExtent = [chromPosition.start, chromPosition.end];

  const createZoomRegion = (start, length) => {
    const flankingOffset = flanking * 1e6 * 1.1;
    return [start - flankingOffset, start + length + flankingOffset];
  };

  const {
    position,
    zoomIn,
    zoomOut,
    panLeft,
    panRight,
    transformedExtent,
    reset: zoomReset,
    setExtent,
  } = useChartZoom(chromExtent);

  const [transformedStart, transformedEnd] = transformedExtent;

  useEffect(() => {
    setTransformedExtent([transformedStart, transformedEnd]);
  }, [transformedStart, transformedEnd]);

  const getBandsFromChrom = (chrom) => Object.keys(chromPositions[chrom]?.bands ?? {});

  useEffect(() => {
    setBands(getBandsFromChrom(globalChrom));
  }, []);

  useEffect(() => {
    if (defaultRegion && globalChrom === defaultRegion.chromosome) {
      const [extentStart, extentEnd] = createZoomRegion(
        defaultRegion['start_at'],
        defaultRegion['length']
      );
      form.setFieldsValue({
        position: (extentStart + extentEnd) / 2,
        disease: { ...defaultRegion },
        diseaseId: `${defaultRegion['disease_dictionary'] ?? defaultRegion['id']}-custom`,
      });
      setExtent([extentStart, extentEnd]);
    }
  }, [defaultRegion]);

  return (
    <div>
      <Form
        className='case-navigation-controls'
        form={form}
        initialValues={{
          chrom: globalChrom,
          band: null,
          disease: null,
          diseaseId: null,
          position: null,
        }}
        layout="inline"
      >
        <Form.Item label="Chromosome" name="chrom" shouldUpdate>
          <Select
            placeholder="Chrom"
            onChange={(chrom) => {
              form.setFieldsValue({
                chrom,
                disease: null,
                diseaseId: null,
                position: null,
                band: null,
              });
              setBands(getBandsFromChrom(chrom));
            }}
          >
            {chroms.map(chrom => <Select.Option key={chrom} value={chrom}>{chrom}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="Band" name="band" shouldUpdate>
          <Select
            placeholder="Band"
            onChange={(band) => form.setFieldsValue({
              band,
              position: null,
              disease: null,
              diseaseId: null,
            })}
          >
            {bands.map(band => <Select.Option key={band} value={band}>{band}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="Position" name="position" shouldUpdate>
          <InputNumber
            className='position-control'
            placeholder='Enter position'
            min={chromPosition.start}
            max={chromPosition.end}
            onChange={(position) => form.setFieldsValue({
              band: null,
              position,
              disease: null,
              diseaseId: null,
            })}
          />
        </Form.Item>
        <Form.Item name="disease" />
        <Form.Item label="Disease" name="diseaseId" shouldUpdate>
          <DiseaseSelector
            caseRegions={geneRegions}
            placeholder="Select Disease"
            value={form.getFieldValue('diseaseId')}
            getLabel={({ gene }) => gene}
            onChange={(id, disease) => {
              form.setFieldsValue({
                chrom: disease?.chromosome ?? globalChrom,
                band: null,
                disease,
                diseaseId: id,
                position: disease ? disease['start_at'] + (disease.length / 2) : null,
              });
              setBands(getBandsFromChrom(disease?.chromosome ?? globalChrom));
            }}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" onClick={() => {
            const {
              chrom,
              disease,
              band,
              position: newPosition,
            } = form.getFieldsValue();
            const { bands, length: chromLength } = chromPositions[chrom];
            const flankingOffset = flanking * 1e6 * 1.1;
            let newExtent = [0, chromLength];

            if (disease) {
              const { length, start_at: start } = disease;
              newExtent = createZoomRegion(start, length);
            } else if (band) {
              const { start, end } = bands[band];
              newExtent = [start, end];
            } else if (typeof newPosition === 'number') {
              newExtent = [newPosition - flankingOffset, newPosition + flankingOffset];
            }
            setChrom(chrom);
            setExtent(newExtent, chromLength);
          }}>LOAD</Button>
        </Form.Item>
      </Form>
      <ChromosomeNavigation
        chromosome={globalChrom}
        end={isNaN(transformedExtent[1]) ? 0 : Math.min(transformedExtent[1], chromPosition.end)}
        position={position}
        start={isNaN(transformedExtent[0]) ? 0 : Math.max(transformedExtent[0], chromPosition.start)}
        panLeft={panLeft}
        panRight={panRight}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomReset={zoomReset}
        setExtent={setExtent}
      />
    </div>
  );
}