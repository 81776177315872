// Importing react libs
import React, { useEffect, useState } from 'react';

// Importing Helix components
import SignIn from 'helix/ui/login';

// Importing Helix modules
import { UPDATE_REGION_LIST } from 'helix-modules/awsregions';

// Importing app modules
import AppAPI from 'modules/api';

// Importing container styles
import './index.scss';

// Exporting component
export const Login = (props) => {
  const { onSignedIn: _onSignedIn } = props;
  const [regions, setRegions] = useState();
  const onSendForgotPassword = (email) => {
    return AppAPI.User.recover(email);
  };
  const onSignIn = (credentials) => {
    return AppAPI.User.login(credentials);
  };
  const updateRegion = (value) => {
    const { location: { host, protocol } } = window;
    const subdomain = value ? `${value}.` : '';
    const cleanHost = regions.reduce((cleanHost, { name }) => cleanHost.replace(`${name}.`, ''), host);
    window.location.href = `${protocol}//${subdomain}${cleanHost}`;
    return { error: false };
  };
  const updateRegionList = () => {
    let fresh = window.localStorage.getItem('regions');
    if (fresh) setRegions(JSON.parse(fresh));
  };
  useEffect(() => {
    updateRegionList();
    // subscribe event
    window.addEventListener(UPDATE_REGION_LIST, updateRegionList);
    return () => {
      // unsubscribe event
      window.removeEventListener(UPDATE_REGION_LIST, updateRegionList);
    };
  }, []);
  return (
    <div className="login">
      {regions && (<SignIn
        regions={regions}
        showRegionSelector={true}
        onRegionChange={updateRegion}
        onSendForgotPassword={onSendForgotPassword}
        onSignIn={onSignIn}
        onSignedIn={_onSignedIn}
      />)}
    </div>
  );
};
