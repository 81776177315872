// Importing react libs
import React from 'react';

// Importing antd libs
import { Col, Row, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Settings = ({ analysis, flanking, isV1Array }) => {
  const Language = useLanguage();
  const { reference } = analysis.biomaterialsByDesignation ?? {};
  const { algorithmVersion, scanningHardware, manifestName } = reference.stats ?? {};

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{Language.get('report', 'ANALYSIS_SETTINGS')}</Title>
      </Col>
      <Col offset={1} span={23}>
        <Title level={3}>{Language.get('report', 'ARRAY_TYPE')}: Karyomap v{isV1Array ? '1.0' : '2.0'}</Title>
        <Title level={3}>{Language.get('organization', 'SCANNING_HARDWARE')}: {scanningHardware}</Title>
        <Title level={3}>{Language.get('organization', 'BEAD_ARRAY_FILE')}: {manifestName}</Title>
        <Title level={3}>{Language.get('report', 'FLANKING_SIZE')}: {flanking}MB</Title>
        <Title level={3}>{Language.get('report', 'ALGORITHM_VERSION')}: {algorithmVersion}</Title>
      </Col>
    </Row>
  );
};
