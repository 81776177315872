import { message } from 'antd';
import 'antd/es/message/style/css';

export class Notification {
  static error(lng, res, ctx = 'common') {
    // if multiple contexts given, find the first one that has the translation
    if (Array.isArray(ctx)) {
      ctx = ctx.find(context => lng.exists(context, res.msg));
    }

    message.error(
      `${res.field ? res.field?.toUpperCase() + ': ' : ''} ${lng.get(ctx, res.msg)}`
    );
  }

  static success(lng, ctx = 'common', msg = 'DATA_SAVED_SUCCESFULLY') {
    if (Array.isArray(ctx)) {
      ctx = ctx.find(context => lng.exists(context, msg));
    }

    message.success(lng.get(ctx, msg));
  }

  static fromResponse(lng, res, ctx) {
    res.error ? Notification.error(lng, res, ctx) : Notification.success(lng, ctx, res.msg);
  }
}
