import React from 'react';
import { groupBy, mapValues } from 'lodash-es';
import pako from 'pako';
import * as d3 from 'd3';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

import { Spin } from 'antd';

import Karyomap from 'helix/chart/karyomap';
import GenomePlot from 'helix/chart/genomePlot';
import { RESULT_COLORS } from 'styles/theme/colors';

const bafLogrParesr = (row) => ({
  genomicPosition: Number(row.genomic_position),
  call: row.call,
  logr: Number(row.logr),
  baf: Number(row.baf),
});

const CHART_FILE_URL = {
  'KARYOMAP': ['haploblocks', 'json'],
  'B_ALLELE_CHART': ['baf_logr', 'tsv', bafLogrParesr],
  'LOGR_CHART': ['baf_logr', 'tsv', bafLogrParesr],
  'CNV_CHART': ['cnv', 'tsv'],
  'IDEOGRAM': [null, ''],
};

const fetchFile = async (url, type) => {
  const res = await fetch(url, { credentials: 'include', mode: 'cors' });

  if (type === 'tsv' && url?.endsWith('.gz')) {
    const rawFile = await res.arrayBuffer();
    return pako.ungzip(rawFile, { to: 'string' });
  }

  else if (type === 'json') return res.json();
  else if (type === 'tsv') return res.text();
  else return res.blob();
};

export const getChartQueries = ({ chartFiles, enabledCharts }) => {
  const chartsToRender = enabledCharts.reduce((acc, chartKey) => {
    const [fileKey, fileType, parser] = CHART_FILE_URL[chartKey];
    if (fileKey) {
      Object.entries(chartFiles).forEach(([bpAnalysisId, fileUrlObj]) => {
        if (fileUrlObj[fileKey]) {
          acc.push({
            bpAnalysisId,
            chartKey,
            fileKey,
            fileType,
            fileUrl: fileUrlObj[fileKey],
            parser,
          });
        }
      });
    }
    return acc;
  }, []);

  return chartsToRender.map(chart => ({
    queryKey: [chart.bpAnalysisId, chart.fileKey],
    queryFn: async () => {
      let data = await fetchFile(chart.fileUrl, chart.fileType);

      if (chart.parser) {
        data = d3.tsvParse(data, chart.parser);
      }

      return {
        bpAnalysisId: chart.bpAnalysisId,
        fileKey: chart.fileKey,
        data,
      };
    },
    staleTime: 1000 * 60 * 5, // cache for 5 minutes
  }));
};

const ChartRenderer = ({
  chartFiles,
  chartImages,
  chartQueries,
  enabledCharts,
  geneRegions,
  flanking,
  isFetchingChartData,
}) => {
  const Language = useLanguage();

  if (isFetchingChartData) return (
    <div>
      <Spin />
      <div>{Language.get('report', 'LOADING_CHART_DATA')}</div>
      <div>{Language.get('report', 'CLICK_GENERATE_REPORT')}</div>
      <br />
    </div>
  );

  const chartsData = mapValues(groupBy(chartQueries, query => query.data?.fileKey), val => val.map(query => query.data));

  const regions = geneRegions.map(region => ({
    chrom: { '23': 'X', '24': 'Y' }[region.chromosome] ?? region.chromosome,
    end: region['start_at'] + region.length,
    start: region['start_at'],
    mutationSite: region['mutation_site'],
  }));

  return (
    <div style={{ overflowY: 'visible', position: 'fixed' }}>
      {Object.keys(chartFiles).map(bpAnalysisId => {
        const finder = data => data.bpAnalysisId === bpAnalysisId;
        const karyomapId = `KARYOMAP-${bpAnalysisId}`;
        const bafId = `B_ALLELE_CHART-${bpAnalysisId}`;
        const logrId = `LOGR_CHART-${bpAnalysisId}`;
        return (
          <div key={bpAnalysisId} style={{ width: '1280px', opacity: 0 }}>
            {!chartImages[karyomapId] && enabledCharts.includes('KARYOMAP') && (
              <Karyomap
                blockColors={[
                  RESULT_COLORS.NO_RESULT.fill,
                  RESULT_COLORS.PATERNAL_1.fill,
                  RESULT_COLORS.PATERNAL_2.fill,
                  RESULT_COLORS.MATERNAL_1.fill,
                  RESULT_COLORS.MATERNAL_2.fill,
                ]}
                blockData={chartsData['haploblocks'].find(finder)?.data ?? []}
                geneRegions={regions}
                id={karyomapId}
                flanking={flanking}
              />
            )}
            {!chartImages[logrId] && enabledCharts.includes('LOGR_CHART') && (
              <GenomePlot
                data={chartsData['baf_logr'].find(finder)?.data ?? []}
                diseaseRegions={regions}
                id={logrId}
                yKey="logr"
                yExtent={[-2, 2]}
                flanking={flanking}
                mutationSiteWidth={1}
              />
            )}
            {!chartImages[bafId] && enabledCharts.includes('B_ALLELE_CHART') && (
              <GenomePlot
                data={chartsData['baf_logr'].find(finder)?.data ?? []}
                diseaseRegions={regions}
                id={bafId}
                yKey="baf"
                yExtent={[0, 1]}
                flanking={flanking}
                mutationSiteWidth={1}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ChartRenderer;