// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import antd
import { Button, Collapse } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/collapse/style/css';

// Importing Helix hooks
import Error from 'helix/ui/error';
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing components
import { List } from './list';
import useDataExportFile from 'components/case/useDataExportFile';

// Importing styles
import './raw.scss';

export const RawFileList = () => {
  const Language = useLanguage();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [caseObj, setCaseObj] = useState(null);
  const { id } = useParams();
  const { isLoading, download } = useDataExportFile({ caseIds: [id] });

  useEffect(() => {
    const _loadData = async () => {
      const [bioRes, caseRes] = await Promise.all([
        AppAPI.Biomaterial.list({ filters: { case: id } }),
        AppAPI.Case.get(id),
      ]);
      if (caseRes.error) setError(Language.get('common', caseRes.msg));
      setFiles(bioRes.results.filter(biomaterial => Boolean(biomaterial['file_uri'])));
      setCaseObj(caseRes);
    };
    _loadData();
  }, []);

  if (error) return <Error message={error} />;

  return (
    <Fragment>
      <div className='raw-files-header'>
        <h1>{`${Language.get('file', 'FILES_FOR_CASE_ID')}: ${caseObj?.['external_id'] ?? ''}`}</h1>
        <Button disabled={!caseObj?.analyses?.length > 0} loading={isLoading} type="primary" onClick={download}>
          {Language.get('file', 'DATA_EXPORT_FILE')}
        </Button>
      </div>
      <Collapse bordered={false} className="raw-files" defaultActiveKey={[0]}>
        <List
          files={files}
          title={`${Language.get('file', 'RAW_INPUT_FILES')} - Case ${caseObj?.['external_id'] ?? id}`}
          processFile={async (file) => {
            const res = await fetch(file['file_uri'], {
              mode: 'cors',
              credentials: 'include',
            });
            return {
              content: await res.blob(),
              path: `${file['beadchip_barcode']}_${file['sub_array_id']}.gtc`,
            };
          }}
        />
      </Collapse>
    </Fragment>
  );
};
