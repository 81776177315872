// Importing react libs
import React from 'react';

// Importing antd libs
import { Table, Typography } from 'antd';
import 'antd/es/table/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Snps = ({ snp }) => {
  const Language = useLanguage();

  const data = ['left', 'main', 'right'].map(key => ({
    maternal: snp ? `${snp?.maternal[key]} / ${snp?.total[key]}` : '',
    paternal: snp ? `${snp?.paternal[key]} / ${snp?.total[key]}` : '',
    snps: snp?.total[key],
    title: Language.get('case', `${key.toUpperCase()}_REGION`),
  }));

  const columns = [{
    dataIndex: 'title',
    title: null,
  }, {
    dataIndex: 'snps',
    title: Language.get('case', 'PLATFORM_SNPS'),
  }, {
    dataIndex: 'maternal',
    title: Language.get('case', 'MATERNAL_INFORMATIVE_SNPS'),
  }, {
    dataIndex: 'paternal',
    title: Language.get('case', 'PATERNAL_INFORMATIVE_SNPS'),
  }];

  return (
    <div>
      <Title level={3}>{Language.get('case', 'SNPS_DATA')}</Title>
      <Table bordered columns={columns} dataSource={data} pagination={false} rowKey="title" />
    </div>
  );
};
