// Importing react libs
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing antd libs
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Modal,
  Popconfirm,
  Tooltip,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/message/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/popconfirm/style/css';
import 'antd/es/tooltip/style/css';

// Importing antd icons
import { DeleteOutlined, EyeOutlined, GoldOutlined } from '@ant-design/icons';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix modules
import 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing Helix components
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';
import { getPosition } from 'helix/chart/plate';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { Guide } from './guide';

// Importing component styles
import './list.scss';

// Exporting component
export const List = () => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const [beadchips, setBeadchips] = useState();
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [plate, setPlate] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [wells, setWells] = useState();

  const columns = [{
    align: 'center',
    dataIndex: 'pooled',
    key: 'pooled',
    sorter: true,
    title: Language.get('plate', 'HYBRIDIZED'),
    render: (pooled) => (pooled && <CheckCircleOutlined className="pooled" />)
  }, {
    dataIndex: 'external_id',
    key: 'external_id',
    sorter: true,
    title: Language.get('plate', 'PLATE_ID'),
    render: (value) => <Tooltip title={value}>{value.compact(25)}</Tooltip>,
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'prepared_on',
    key: 'prepared_on',
    sorter: true,
    title: Language.get('plate', 'PREPARATION_DATE'),
    render: (value) => (new Date(value)).format('date'),
    ...tableColumnFilterDate(),
  }, {
    dataIndex: 'beadchips',
    key: 'beadchips',
    title: Language.get('plate', 'BEADCHIPS_DEFINED'),
    render: (beadchips) => {
      const value = beadchips.length ?? 0;
      return (
        <div className={value === 0 ? 'critical-chip' : ''}>
          {value}
        </div>
      );
    },
  }, {
    dataIndex: 'author',
    key: 'author',
    sorter: true,
    title: Language.get('plate', 'CREATED_BY'),
    ...tableColumnFilterSearch(),
  }];

  const _loadPlate = async (id) => {
    const res = await AppAPI.Plate.get(id);
    if (res.error) return message.error(Language.get('plate', res.msg));
    setPlate(res);
  };

  const _setWells = (wells) => {
    wells.sort((a, b) => a['plate_position'].localeCompare(b['plate_position']));
    setWells(wells);
  };

  const reader = async ({ filters, limit, offset, order }) => {
    const mapper = {
      'external_id': 'external_id__icontains',
      'author': 'prepared_by__username__icontains',
    };
    filters = Object.keys(filters).reduce((newFilters, field) => {
      newFilters[mapper[field] || field] = filters[field];
      return newFilters;
    }, {});
    order = order || '-prepared_on';
    return await AppAPI.Plate.list({ filters, limit, offset, order });
  };

  const _delete = (record) => {
    const hasAtLeastOneAnalysis = record.beadchips.some(
      (beadchip) => beadchip.biomaterials.some(
        (biomaterial) => biomaterial.analyses.length > 0
      )
    );
    return (
      <Popconfirm
        cancelText={Language.get('common', 'NO')}
        key="delete"
        okText={Language.get('common', hasAtLeastOneAnalysis ? 'OK' : 'YES')}
        showCancel={!hasAtLeastOneAnalysis}
        title={Language.get('plate', hasAtLeastOneAnalysis ? 'CANNOT_BE_DELETED_HAS_ANALYSIS' : 'CONFIRM_DELETE')}
        onConfirm={async () => {
          if (hasAtLeastOneAnalysis) return;
          const res = await AppAPI.Plate.delete(record.id);
          if (res?.error) return message.error(Language.get('plate', res.msg));
          setListKey((Math.random() + 1).toString(36).substring(2));
        }}
      >
        <Button
          className="icon"
          danger
          icon={<DeleteOutlined />}
          size="small"
        />
      </Popconfirm>
    );
  };
  const _edit = (record) => (
    <Button
      className="icon"
      icon={<GoldOutlined />}
      key="edit"
      size="small"
      onClick={() => navigate(`/planner/${record.id}`)}
    />
  );
  const _preview = (record) => (
    <Button
      className="icon"
      icon={<EyeOutlined />}
      key="preview"
      size="small"
      onClick={() => {
        _loadPlate(record.id);
      }}
    />
  );

  useEffect(() => {
    if (plate?.beadchips) {
      const beadchips = {};
      const wells = plate?.beadchips.reduce((wells, beadchip) => {
        const newWells = beadchip.biomaterials.map((biomaterial) => {
          const { x, y } = getPosition(biomaterial['plate_position']);
          beadchips[x] = beadchips[x] || {};
          beadchips[x].barcode = beadchip.barcode;
          beadchips[x].col = x + 1;
          beadchips[x].metadata = beadchip.metadata;
          beadchips[x][y] = {
            id: biomaterial.id,
            name: biomaterial.name,
            x,
            y,
          };
          return {
            ...biomaterial,
            barcode: beadchip.barcode,
            ...{ x, y },
          };
        });
        return wells.concat(newWells);
      }, []);
      setBeadchips(beadchips);
      _setWells(wells);
    }
  }, [plate]);

  useEffect(() => {
    if (wells) setShowPreviewModal(true);
  }, [wells]);

  return (
    <div className="plate-list-wrapper">
      <HelixList
        actions={[_preview, _edit, _delete]}
        columns={columns}
        context="common"
        dataField="results"
        dataCountField="count"
        defaultSort={{ field: 'prepared_on', order: 'descend' }}
        key={listKey}
        name="plates"
        pageSize={15}
        reader={reader}
        rowKey="id"
      />
      <Modal
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={() => {
            setShowPreviewModal(false);
          }}>
            {Language.get('common', 'OK')}
          </Button>,
        ]}
        height="100"
        style={{ top: 0 }}
        open={showPreviewModal}
        width="100"
        onCancel={() => {
          setShowPreviewModal(false);
        }}
      >
        <Guide beadchips={beadchips} inModal={true} plate={plate} wells={wells} />
      </Modal>
    </div>
  );

};
