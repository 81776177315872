// Importing React and external libs
import React from 'react';
import { Link } from 'react-router-dom';

// Importing antd libs
import { Button } from 'antd';
import 'antd/es/button/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Exporting component
export const Create = ({ biomaterials }) => {
  const Language = useLanguage();
  const state = { selected: Object.values(biomaterials) };
  return (
    <Link to="/report/preview" state={state}>
      <Button type="primary">{Language.get('reportBuilder', 'CREATE_REPORT')}</Button>
    </Link>
  );
};
