// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import Error from 'helix/ui/error';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app helpers
import { Notification } from 'helper/handler/notification';

// Importing app components
import { List as AnalysisList } from 'components/analysis';
import { List as BiomaterialList } from 'components/biomaterial';
import { CaseForm } from 'components/case';
import { List as GeneList } from 'components/gene';

// Importing container styles
import './index.scss';

const generateKey = () => (Math.random() + 1).toString(36).substring(2);

// Exporting component
export const Accessioning = () => {
  const Language = useLanguage();
  const [caseObj, setCaseObj] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [bioListKey, setBioListKey] = useState(generateKey());
  const [geneListKey, setGeneListKey] = useState(generateKey());
  const [analysisListKey, setAnalysisListKey] = useState(generateKey());
  const { id } = useParams();

  let toggleDisableRegionTO = undefined;

  const _loadData = async () => {
    const res = await AppAPI.Case.get(id);
    if (res.error) return setError(res.msg);
    setCaseObj(res);
  };

  const _toogleDisableRegion = (value) => {
    clearTimeout(toggleDisableRegionTO);
    toggleDisableRegionTO = setTimeout(async () => {
      if (caseObj?.['disable_region'] !== value) {
        const res = await AppAPI.Case.save({
          id: caseObj.id,
          'disable_region': value,
        });
        if (res.error) return Notification.error(Language, res, 'case');
        Notification.success(Language);
        setCaseObj(res);
      }
    }, 500);
  };

  useEffect(() => {
    if (id && (!caseObj || id !== caseObj.id)) {
      _loadData();
    } else {
      setCaseObj(undefined);
      setAnalysisListKey(generateKey());
      setBioListKey(generateKey());
      setGeneListKey(generateKey());
    }
  }, [id]);

  return (
    <div className="accessioning">
      {error
        ? <Error message={error} />
        : (
          <Fragment>
            <CaseForm
              case={caseObj}
              onImport={() => setBioListKey(generateKey())}
              onUpdate={(res) => setCaseObj(res)}
            />
            <GeneList
              case={caseObj}
              key={geneListKey}
              onToogleDisableRegion={_toogleDisableRegion}
              onUpdate={() => setAnalysisListKey(generateKey())}
              onDelete={() => setAnalysisListKey(generateKey())}
            />
            <BiomaterialList
              case={caseObj}
              key={bioListKey}
              onUpdate={() => {
                setGeneListKey(generateKey());
                setAnalysisListKey(generateKey());
              }}
            />
            <AnalysisList case={caseObj} key={analysisListKey} onUpdate={() => _loadData()} />
          </Fragment>
        )
      }
    </div>
  );
};
