// Importing app helper
import { reportItem } from './common';

export const regionsSummary = ({ regions, i18n }) => {
  return {
    layout: 'noBorders',
    margin: [0, 10],
    table: {
      body: regions.map((region, index) => [
        reportItem(`${i18n('REGION')} ${index + 1}`, region.name),
        reportItem(
          i18n('LOCATION'),
          `${region.chromosome}:${region['start_at']}-${region.length}`,
        ),
        '',
      ]),
      headerRows: 0,
      widths: ['*', '*', '*'],
    },
  };
};
