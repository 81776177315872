// Importing react libs
import React from 'react';

// Importing antd libs
import { Descriptions } from 'antd';
import 'antd/es/descriptions/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './header.scss';

// Defining constants
const { Item } = Descriptions;

// Exporting component
export const Header = ({ caseID, subjectID }) => {
  const Language = useLanguage();
  return (
    <div className="header">
      <Descriptions>
        <Item label={Language.get('case', 'CASE_ID')}>{caseID}</Item>
        <Item label={Language.get('case', 'MATERNAL_SAMPLE_ID')}>{subjectID}</Item>
      </Descriptions>
    </div>
  );
};
