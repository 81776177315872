const useFlanking = ({ analysisSetting } = {}) => {
  let settings = { ANALYSIS: { FLANKING_REGION: 2 } };
  const orgSetting = window.app.user?.organization?.setting;

  if (typeof analysisSetting?.ANALYSIS?.FLANKING_REGION === 'number') {
    settings = analysisSetting;
  } else if (typeof orgSetting?.ANALYSIS?.FLANKING_REGION === 'number') {
    settings = orgSetting;
  }

  return settings.ANALYSIS.FLANKING_REGION;
};

export default useFlanking;

