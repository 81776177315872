import { useEffect, useState } from 'react';

const fetchJson = async (url, options) => {
  const response = await fetch(url, {
    credentials: 'include',
    mode: 'cors',
    ...options,
  });

  return await response.json();
};

const useJson = ({ url }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let controller;
    controller = new AbortController();
    if (url) {
      setIsLoading(true);
      setData(null);
      fetchJson(url, { signal: controller.signal })
        .then(setData)
        .catch(() => setData(null))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setData(null);
    }
    return () => controller?.abort('');
  }, [url]);

  return { isLoading, data };
};

export default useJson;
