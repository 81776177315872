// Importing react and external libs
import React, { Fragment, useState } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

// Import antd
import {
  Alert,
  Button,
  Collapse,
  Divider,
  message,
  Progress,
} from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/button/style/css';
import 'antd/es/collapse/style/css';
import 'antd/es/icon/style/css';
import 'antd/es/list/style/css';
import 'antd/es/progress/style/css';

// Importing antd icons
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing styles
import './list.scss';

export const List = ({
  files,
  getFileName,
  processFile,
  title,
}) => {
  const Language = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [isZipping, setIsZipping] = useState(false);
  const [progress, setProgress] = useState(0);
  const { Panel } = Collapse;

  const _downloadSection = async (event) => {
    try {
      event.stopPropagation();
      setIsLoading(true);
      const fullZip = new JSZip();
      let completed = 0;
      const filePromises = files.map(async (file) => {
        const processed = await processFile(file);
        if (Array.isArray(processed)) {
          processed.forEach(({ path, content }) => fullZip.file(path, content));
        } else {
          fullZip.file(processed.path, processed.content);
        }
        setProgress(++completed / files.length);
      });
      await Promise.all(filePromises);
      setIsZipping(true);
      const fullZipBlob = await fullZip.generateAsync({ type: 'blob' });
      saveAs(fullZipBlob, `${title}.zip`);
    } catch (error) {
      console.error(error);
      message.error(Language.get('file', 'FILE_DOWNLOAD_FAILED'));
    } finally {
      setProgress(0);
      setIsZipping(false);
      setIsLoading(false);
    }
  };

  const _download = async (file, name) => {
    try {
      setIsLoading(true);
      const processed = await processFile(file);
      if (Array.isArray(processed)) {
        const outZip = new JSZip;
        processed.forEach(({ path, content }) => outZip.file(path, content));
        const zipBlob = await outZip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, name);
      } else {
        saveAs(processed.content, processed.path);
      }
    } catch (error) {
      console.error(error);
      message.error(Language.get('file', 'FILE_DOWNLOAD_FAILED'));
    } finally {
      setIsLoading(false);
    }
  };

  return files && (
    <Collapse bordered={false} className="files-list">
      <Panel
        extra={isLoading ? (
          <div>
            {isZipping
              ? `${Language.get('file', 'ZIPPING')}...`
              : <Progress percent={Math.floor(progress * 100)} steps={5} />}
            {' '}<LoadingOutlined />
          </div>
        ) : null
        }
        header={(
          <Fragment>
            <h3>{title}</h3>
            <DownloadOutlined onClick={_downloadSection} />
          </Fragment>
        )}
      >
        {files?.length > 0 && files.map((file, index) => {
          const { file_uri: path, updated_on: updatedOn } = file;
          const name = getFileName ? getFileName(file) : `${path?.split('/')?.at(-1)}`;
          const size = file?.file_size || 'N/A';
          return (
            <div key={index}>
              <Button
                className="ant-list-item-meta-title"
                icon={<DownloadOutlined />}
                type="link"
                onClick={() => _download(file, name)}
              >
                &nbsp;&nbsp;{name}
              </Button>
              <div className="meta-description">
                {size > 0 && (
                  <span className="meta-data">
                    {Language.get('file', 'FILE_SIZE')}: {size.format({ isByte: true, readable: true, toFixed: 0 })}
                  </span>
                )}
                <span className="meta-data">
                  {Language.get('common', 'LAST_UPDATED')}: {(updatedOn && (new Date(updatedOn)).format()) || 'N/A'}
                </span>
              </div>
              <Divider />
            </div>
          );
        }) || (<Alert banner message={Language.get('file', 'NO_FILES_PRESENT')} type="info" showIcon />)}
      </Panel>
    </Collapse>
  );
};
