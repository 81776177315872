// Importing react libs
import React from 'react';

// Importing antd libs
import { Descriptions, Divider, Space } from 'antd';
import 'antd/es/descriptions/style/css';
import 'antd/es/divider/style/css';
import 'antd/es/space/style/css';
import 'antd/es/typography/style/css';

// Importing helix-hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { Preview as BiomaterialPreview } from 'components/biomaterial/preview';

// Exporting component
const SampleReport = ({ caseObj, analysis }) => {
  const Language = useLanguage();
  const { biomaterialsByDesignation } = analysis;

  const getDescriptionObj = (bio) => ({
    [Language.get('case', 'CASE_ID')]: caseObj['external_id'],
    [Language.get('case', 'SUBJECT_FIRSTNAME')]: bio['biomaterial_firstname'] ?? '',
    [Language.get('report', 'BEADCHIP_BARCODE')]: bio['beadchip_barcode'],
    [Language.get('case', 'SAMPLE_ID')]: bio['biomaterial_name'],
    [Language.get('case', 'SUBJECT_LASTNAME')]: bio['biomaterial_lastname'] ?? '',
    [Language.get('case', 'SUBARRAY')]: bio['biomaterial_subarray'],
  });

  return biomaterialsByDesignation.embryos.map((biomaterial) => (
    <Space key={biomaterial['biomaterial_id']} direction="vertical" size={24} style={{ width: '100%' }}>
      <Divider />
      <Descriptions bordered size="small">
        {Object.entries(getDescriptionObj(biomaterial)).map(([label, value]) => (
          <Descriptions.Item key={label} label={label}>{value}</Descriptions.Item>
        ))}
      </Descriptions>
      <BiomaterialPreview biomaterial={biomaterial} geneRegions={caseObj.geneRegions} />
    </Space>
  ));
};

export default SampleReport;
