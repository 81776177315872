// Importing React and external libs
import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Importing antd libs
import { Col, Row, Space, Typography, message } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/message/style/css';
import 'antd/es/row/style/css';
import 'antd/es/space/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { Create } from 'components/report/create';
import { Biomaterials } from 'components/report/biomaterials';
import { Search } from 'components/report/search';

// Importing styles
import './index.scss';

// Defining constants
const { Title } = Typography;

const key = ({ biomaterial, caseObj, analysisId }) => (
  `${caseObj.id}|${analysisId}|${biomaterial.biomaterial_id}`
);

// Exporting component
export const ReportBuilder = () => {
  const Language = useLanguage();
  const [selectedBiomaterials, setSelectedBiomaterials] = useState({});
  const [cases, setCases] = useState('loading');

  let searchTO = undefined;
  const search = (filters) => {
    clearTimeout(searchTO);
    searchTO = setTimeout(async () => {
      const res = await AppAPI.Case.getSignedBiomaterials({
        filters,
        limit: 100,
        order: '-updated_on',
      });
      if (res.error) {
        setCases([]);
        return message.error(Language.get('common', res.msg));
      }

      const cases = res.results.filter((caseObj) => {
        let biomaterials = [];
        caseObj.analyses.forEach(analysis => biomaterials.push(...analysis.biomaterials));
        return biomaterials.some(biomaterial => biomaterial['biomaterial_designation'] === '');
      });

      setCases(cases);
    }, 1000);
  };

  const searchByCaseId = (value) => search({ 'external_id__icontains': [value] });
  const searchBySubjectId = (value) => search({ 'subject_id': [value] });
  const handleDrop = (item) => setSelectedBiomaterials((prev) => ({ ...prev, [key(item)]: item }));
  const handleRemoveBiomaterial = (itemToRemove) => setSelectedBiomaterials((prev) => {
    const clone = { ...prev };
    delete clone[key(itemToRemove)];
    return clone;
  });
  const isBiomaterialDisabled = (biomaterial, caseObj, analysisId) => Boolean(
    selectedBiomaterials[key({ biomaterial, caseObj, analysisId })]
  );
  const bulkAdd = (biomaterials, caseObj, analysisId) => setSelectedBiomaterials((prev) => ({
    ...prev,
    ...Object.fromEntries(
      biomaterials.map(
        (biomaterial) => [key({ biomaterial, caseObj, analysisId }), { biomaterial, caseObj, analysisId }]
      )
    ),
  }));

  useEffect(() => search(), []);

  return (
    <Space className='report-builder' direction='vertical' size="large">
      <Title level={1}>{Language.get('reportBuilder', 'REPORT_BUILDER')}</Title>
      <DndProvider backend={HTML5Backend}>
        <Row>
          <Col span={6}>
            <Search
              cases={cases}
              isBiomaterialDisabled={isBiomaterialDisabled}
              searchPlaceholder={Language.get('reportBuilder', 'SUBJECT_SEARCH_PLACEHOLDER')}
              title={Language.get('reportBuilder', 'SUBJECT_SEARCH_TITLE')}
              onBulkAdd={bulkAdd}
              onSearch={searchBySubjectId} />
            <Search
              cases={cases}
              isBiomaterialDisabled={isBiomaterialDisabled}
              searchPlaceholder={Language.get('reportBuilder', 'CASE_SEARCH_PLACEHOLDER')}
              title={Language.get('reportBuilder', 'CASE_SEARCH_TITLE')}
              onBulkAdd={bulkAdd}
              onSearch={searchByCaseId} />
          </Col>
          <Col span={12}>
            <Biomaterials
              biomaterials={selectedBiomaterials}
              onBiomaterialDrop={handleDrop}
              onRemoveBiomaterial={handleRemoveBiomaterial}
            />
          </Col>
          <Col offset={2} span={2}>
            <Create biomaterials={selectedBiomaterials} />
          </Col>
        </Row>
      </DndProvider>
    </Space>
  );
};
