// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';

// Importing antd libs
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, message, Upload } from 'antd';
import 'antd/es/avatar/style/css';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/message/style/css';
import 'antd/es/upload/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix components
import Label from 'helix/ui/label';

// Importing component style
import './logoInput.scss';

// Defining const
const FILE_TYPES = ['image/jpeg', 'image/png'];
const { Item } = Form;

export const getBase64FromUrl = async (url) => {
  const res = await fetch(url, { credentials: 'include', mode: 'cors' });
  const blob = await res.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const LogoInput = ({ defaultLogo, onChange, onDelete }) => {
  const Language = useLanguage();
  const [image, setImage] = useState(defaultLogo);
  const form = Form.useFormInstance();

  const handleChange = ({ file }) => {
    const notValidFileType = FILE_TYPES.indexOf(file.type) === -1;
    if (notValidFileType) {
      return message.error(Language.get('report', 'NOT_VALID_LOGO_FILE_TYPE'));
    }
    const isGt2M = file.size / 1024 / 1024 > 2;
    if (isGt2M) {
      return message.error(Language.get('report', 'NOT_VALID_LOGO_FILE_SIZE'));
    }
    setImage(URL.createObjectURL(file));
    onChange?.();
  };

  const handleDelete = () => {
    setImage(null);
    onDelete?.();
  };

  useEffect(() => {
    form.setFieldValue('logo', image);
  }, [image]);

  return (
    <div className="logo-input">
      <Item
        label={<Label name={Language.get('common', 'LOGO')} title={Language.get('common', 'LOGO_INFO')} />}
        name="logo"
      >
        <Fragment>
          <Upload
            accept={FILE_TYPES.join(', ')}
            listType="picture-card"
            showUploadList={false}
            beforeUpload={() => false}
            onChange={handleChange}
          >
            <Avatar icon={<PlusOutlined />} shape="square" size={100} src={image} />
          </Upload>
          {image && (
            <Button onClick={handleDelete} icon={<DeleteOutlined />}>
              {Language.get('report', 'DELETE_LOGO')}
            </Button>
          )}
        </Fragment>
      </Item>
    </div>
  );
};
