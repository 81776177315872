// Importing react libs
import React, { Fragment } from 'react';

// Importing App components
import { Statistics } from 'components/biomaterial/region/statistics';
import { Result } from 'components/biomaterial/result';

// Exporting component
export const Preview = ({ biomaterial, geneRegions }) => {
  const regions = Array.isArray(biomaterial?.stats?.regions) ? biomaterial?.stats?.regions : [];
  regions.sort((a, b) => {
    const aIdx = geneRegions.findIndex(region => region.name === a.name);
    const bIdx = geneRegions.findIndex(region => region.name === b.name);
    return aIdx - bIdx;
  });

  return (
    <div>
      <Result biomaterial={biomaterial} />
      {biomaterial?.stats?.regions.map((statsRegion, index) => (
        <Fragment key={statsRegion.name}>
          <Statistics index={index + 1} statsRegion={statsRegion} />
          <br />
          <br />
        </Fragment>
      ))}
    </div>
  );
};
