// Importing app helper
import { bold } from './common';

// Importing App modules
import AppAPI from 'modules/api';

export const signOffHistory = ({ caseInfo, i18n }) => {
  const prepared = AppAPI.Case.formatSignoffHistory(caseInfo['signoff_history']).split('\n');

  return {
    layout: 'noBorders',
    margin: [0, 20],
    table: {
      body: [
        [
          bold(i18n('SIGNOFF_HISTORY')),
          {
            stack: prepared.length ? prepared : [i18n('NO_HISTORY')],
            style: 'secondary',
          },
        ],
      ],
    },
  };
};
