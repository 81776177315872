// Importing React and external libs
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Importing antd
import { Button, Spin } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/spin/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import pdfMake, { COLORS, DEFAULT_STYLES } from 'helix-modules/pdfMake';
import 'helix-modules/sNumber';
import 'helix-modules/sString';

// Importing Helix components
import { DEFAULT_CFG } from 'helix/chart/plate';

// Importing component helper
import PlatePDF from './helper/pdf';

// Importing CSS
import './guide.scss';

/**
 * Generate pdf report according to format
 * @params {object}
 * plate {object}
 * wells {object}
 * @return {object} promise pdf object that can be manipulated using pdfMake methods
 */
const _getPdfDoc = ({ beadchips, Language, plate, wells }) => {
  const { language } = window.app?.user || { language: 'en' };
  const { REPORT } = window.app?.user?.organization?.setting ?? { REPORT: {} };
  const font = language === 'cn' ? 'Noto Sans SC' : 'Noto Sans';
  const fontPath = font.toLowerCase().replace(' ', '-');

  const { PDF_KEY_1, PDF_KEY_2, PDF_KEY_3 } = REPORT ?? {};

  const pdf = new PlatePDF({
    beadchips,
    plateId: plate?.['external_id'],
    settings: {
      columns: DEFAULT_CFG.plate.cols,
      Language,
      rows: DEFAULT_CFG.plate.rows,
      well: {
        display: {
          fields: [
            { color: COLORS.BLUE, margin: [0, 12, 0, 0], name: PDF_KEY_1 ?? 'case_external_id' },
            { color: COLORS.PURPLE, name: PDF_KEY_2 ?? 'name' },
            { color: COLORS.RED, name: PDF_KEY_3 ?? 'cycle_id' },
          ],
        },
        positionField: 'plate_position',
      },
    },
    wells,
  });
  const docDefinition = {
    content: [
      pdf.header({ showKeys: true }),
      pdf.table(),
      pdf.notch(),
      ...pdf.chips(),
    ],
    defaultStyle: { ...DEFAULT_STYLES, font },
    info: { title: `pooling_guide_plate_${plate?.['external_id']}` },
    footer: pdf.footer.bind(pdf),
    pageMargins: [20, 20, 20, 40],
    pageOrientation: 'landscape',
    pageSize: 'A4',
    styles: {
      ...DEFAULT_STYLES,
      tableCell: {
        alignment: 'center',
        fontSize: 7,
      },
      tableHead: {
        bold: true,
        font: 'Noto Sans',
        fontSize: 11,
      },
      tableHeader: {
        alignment: 'center',
        color: COLORS.GRAY_1,
        fontSize: 14,
      },
    },
  };
  pdfMake.fonts = {
    [font]: {
      normal: `${window.location.origin}/fonts/${fontPath}/regular.ttf`,
      bold: `${window.location.origin}/fonts/${fontPath}/bold.ttf`,
    },
  };
  pdfMake.tableLayouts = {
    ...pdfMake.tableLayouts,
    ...pdf.getBeadchipLayout(),
  };
  return pdfMake.createPdf(docDefinition);
};

// Exporting component
const Guide = (props) => {
  const { inModal, beadchips, plate, wells } = props;
  const Language = useLanguage();
  const navigate = useNavigate();

  /**
   * Generates the pdf report and prompts the browser download
   * @params void
   * @return void
   */
  const _download = () => {
    const pdf = _getPdfDoc({ beadchips, Language, plate, wells });
    pdf.download(`pooling_guide_plate_${plate?.['external_id']}`);
  };

  /**
   * Generates the pdf and appends iframe to #pdf-container
   * @params void
   * @return void
   */
  const _generateReport = () => {
    const pdf = _getPdfDoc({ beadchips, Language, plate, wells });
    pdf.getDataUrl((dataUrl) => {
      const targetElement = document.querySelector('#pdf-container');
      const iframe = document.createElement('iframe');
      iframe.src = dataUrl;
      if (targetElement.firstChild) targetElement.removeChild(targetElement.firstChild);
      targetElement.appendChild(iframe);
    });
  };

  useEffect(() => {
    if (wells) _generateReport();
  }, [wells]);

  return (
    <div className="plate-guide">
      {plate?.['external_id'] && (
        <Fragment>
          <div className="buttons">
            {!inModal && <Button onClick={() => navigate(-1)}>{Language.get('common', 'BACK')}</Button>}
            <Button type="primary" onClick={_download}>
              {Language.get('common', 'DOWNLOAD')}
            </Button>
          </div>
          <div id="pdf-container"></div>
        </Fragment>
      ) || <Spin size="large" />}
    </div>
  );
};

Guide.propTypes = {
  inModal: PropTypes.bool,
};

Guide.defaultProps = {
  inModal: false,
};

export { Guide };
