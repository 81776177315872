// Importing react libs
import React from 'react';

// Importing antd libs
import { Card, Col, Row } from 'antd';
import 'antd/es/card/style/css';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';

// Import Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { List as PlateList, PlateForm } from 'components/plate';

// Importing container styles
import './list.scss';

// Exporting component
export const PlannerList = () => {
  const Language = useLanguage();
  return (
    <div className="planner-list">
      <h1>{Language.get('plate', 'SAMPLE_PREPARATION')}</h1>
      <Row>
        <Col span={16}>
          <Card bordered={false} className="card-title-align" title={<h2>{Language.get('plate', 'AVAILABLE_PLATES')}</h2>}>
            <PlateList />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} className="card-title-align" title={<h2>{Language.get('plate', 'CREATE_NEW')}</h2>}>
            <PlateForm />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
