import Abstract from './abstract';
import Biomaterial from './biomaterial';

import 'helix-modules/sDate';

class Analysis extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'analysis';
    this._updateEndpoint();
  }

  /**
   * Hit GET {this.constants.ENDPOINT}/files
   * to get the analysis files associated with a case
   * @param {Object} filters to filter the files
   * @return {Object} with list of files related to the case
  **/
  async files(filters) {
    const fQuery = Object.keys(filters).reduce((fQuery, param) => {
      if (param === 'tags' && Array.isArray(filters[param]))
        return `${fQuery}${param}=${filters[param].join(',')}&`;
      return filters[param] ? `${fQuery}${param}=${filters[param]}&` : fQuery;
    }, '?');
    const res = await fetch(
      `${this.constants.ENDPOINT}/files/${fQuery}`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'GET',
      }
    );
    return this._handleError(res);
  }

  async filesByAnalysis(filters) {
    const fQuery = Object.keys(filters).reduce((fQuery, param) => {
      if (param === 'tags' && Array.isArray(filters[param]))
        return `${fQuery}${param}=${filters[param].join(',')}&`;
      return filters[param] ? `${fQuery}${param}=${filters[param]}&` : fQuery;
    }, '?');
    const res = await fetch(
      `${this.constants.ENDPOINT}/files_by_analysis/${fQuery}`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'GET',
      }
    );
    return this._handleError(res);
  }

  async reanalyze(id) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/${id}/reanalyze/`,
      {
        method: 'PATCH',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
      }
    );
    return this._handleError(res);
  }

  parseAnalysisForReport(analysis) {
    const biomaterialsWithStats = Array.isArray(analysis.biomaterials) ?
      analysis.biomaterials.map(biomaterial => ({
        ...biomaterial,
        stats: Biomaterial.parseStats(biomaterial['bp_analysis_stats'])
      })) : [];

    const biomaterialsByDesignation = Biomaterial.byDesignation(biomaterialsWithStats);

    const warningsBySample = biomaterialsByDesignation.embryos.reduce(
      (analysisWarnings, embryo) => {
        const { stats, biomaterial_name: name, id } = embryo;

        if (Array.isArray(stats.warnings) && stats.warnings.length > 0) {
          analysisWarnings[`${id}: ${name}`] = stats.warnings;
        }

        return analysisWarnings;
      },
      {}
    );

    return {
      ...analysis,
      biomaterials: biomaterialsWithStats,
      biomaterialsByDesignation,
      warningsBySample,
    };
  }
}

export default new Analysis();
