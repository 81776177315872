// Importing react libs
import React, { useEffect, useRef, useState } from 'react';

// Importing antd libs
import { Button } from 'antd';
import 'antd/es/button/style/css';

// Importing antd icons
import {
  PlusOutlined as PlusOutlinedIcon,
  MinusOutlined as MinusOutlinedIcon,
  LeftOutlined as LeftOutlinedIcon,
  RightOutlined as RightOutlinedIcon,
} from '@ant-design/icons';

// importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing external libs
import Ideogram from 'ideogram';

// Improting component styles
import './style.scss';

const randomString = (prefix) => Math.random().toString(36).replace('0.', prefix || '');

/**
 *
 * @param {Object} props
 * @param {"GRCh38" | "GRCh37"} props.assembly
 */
const ChromosomeNavigation = ({
  assembly = 'GRCh38',
  chromosome = '1',
  end = 0,
  start = 0,
  panLeft,
  panRight,
  zoomIn,
  zoomOut,
  zoomReset,
  setExtent,
}) => {
  const [ideogramId] = useState(randomString('ideo'));
  const ideoRef = useRef();
  const Language = useLanguage();

  useEffect(() => {
    const { clientWidth } = ideoRef.current;
    const chrHeight = (clientWidth ?? 800) - 60;
    const visibleLength = end - start;
    const containerSelector = `#${ideogramId}`;

    if ((visibleLength > 0 && clientWidth > 0)) {

      const brushPos = `chr${chromosome}:${start + 1}-${end ?? 1}`;

      const config = {
        assembly,
        organism: 'human',
        container: containerSelector,
        orientation: 'horizontal',
        chromosome,
        chrHeight,
        brush: brushPos,
        onLoad: function () {
          const ideoElem = document.querySelector(this.selector);
          if (ideoElem) {
            const chromSet = ideoElem.querySelector('.chromosome-set');
            const { height } = chromSet.getBoundingClientRect();
            ideoElem.setAttribute('height', height * 1.7);
          }
        },
        onBrushEnd: function () {
          const { from, to } = this.selectedRegion;
          const diffStart = Math.abs(from - start);
          const diffEnd = Math.abs(to - end);
          if (diffStart > 10 || diffEnd > 10) {
            setExtent([from - 1, to - 1]);
          }
        }
      };

      new Ideogram(config);
    }

  }, [ideoRef, assembly, chromosome, start, end, setExtent, ideogramId]);


  return (
    <div className='chromosome-navigation' ref={ideoRef}>
      <div className="navigation-btn">
        <Button type="primary" onClick={panLeft} icon={<LeftOutlinedIcon />} />
        <div className="navigation-divider"></div>
        <Button type="primary" onClick={zoomIn} icon={<PlusOutlinedIcon />} />
        <Button type="primary" onClick={zoomReset}>{Language.get('common', 'RESET_ZOOM')}</Button>
        <Button type="primary" onClick={zoomOut} icon={<MinusOutlinedIcon />} />
        <div className="navigation-divider"></div>
        <Button type="primary" onClick={panRight} icon={<RightOutlinedIcon />} />
      </div>
      <div id={ideogramId} />
      <div className='position'>
        <span>{chromosome}:{Math.floor(start).toLocaleString()}</span>
        <span>{chromosome}:{Math.ceil(end).toLocaleString()}</span>
      </div>
    </div>
  );

};

export default ChromosomeNavigation;
