// Importing react and external libs
import React, { useState } from 'react';
import { Alert, Col, Row, Select } from 'antd';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import * as d3 from 'd3';
import { useSearchParams } from 'react-router-dom';
import { uniqBy } from 'lodash-es';

// Importing modules
import AppAPI from 'modules/api';
import useLanguage from 'helix-hooks/language';

// Importing local components
import { CONTROL_PROBES } from './control-probes';
import SearchSelect from './SearchSelect';

// Importing styles
import './index.scss';

const CustomTooltip = ({ active, payload }) => {
  const Language = useLanguage();
  const [x] = payload;
  return active ? (
    <div className='metrics-tooltip'>
      <div>{Language.get('biomaterial', 'SAMPLE_ID')}: {x.payload.name}</div>
      <div>{Language.get('biomaterial', 'SUB_ARRAY_ID')}: {x.payload.subArray}</div>
      <div>{Language.get('sample', 'CALL_RATE')}: {x.payload.callRate}</div>
    </div>
  ) : null;
};

const getMetricsBiomaterial = (analysisBiomaterial) => {
  const stats = AppAPI.Biomaterial.parseStats(analysisBiomaterial['bp_analysis_stats']);
  const qcProbes = AppAPI.Biomaterial.parseQCProbes(analysisBiomaterial['qc_probes']);
  if (qcProbes) {
    return {
      id: analysisBiomaterial.id,
      callRate: Number(stats.callRate ?? 0),
      name: analysisBiomaterial['biomaterial_name'],
      qcProbes,
      subArray: analysisBiomaterial['biomaterial_subarray'],
    };
  } else {
    return null;
  }
};

const HELLO = 1;

export function Metrics() {
  if (HELLO === 1) {
    return <Alert message="The BeadChip Array QC tool is under maintenance. Please review the Infinium Controls Dashboard in GenomeStudio software (Illumina) for array QC data. Apologies for any inconvenience." />;
  }

  const Language = useLanguage();
  const [selectedCase, setSelectedCase] = useState();
  const [selectedPlate, setSelectedPlate] = useState();
  const [selectedChip, setSelectedChip] = useState();
  const [priorityCategory, setPriorityCategory] = useState();
  const [sortKey, setSortKey] = useState('subArray');
  const [scaleType, setScaleType] = useState('fixed');
  const [searchParams, setSearchParams] = useSearchParams();
  const plateParam = searchParams.get('plate');
  const beadchipParam = searchParams.get('beadchip');

  const updateParams = (key, value) => setSearchParams(prevParams => {
    const prevParamsObj = Object.fromEntries(prevParams);
    if (value) prevParamsObj[key] = value;
    else delete prevParamsObj[key];
    return prevParamsObj;
  });

  const analysisBiomaterials = [];
  selectedCase?.analyses?.forEach(analysis => {
    analysis?.biomaterials?.forEach(biomaterial => analysisBiomaterials.push(biomaterial));
  });
  const selectedPlateChips = selectedPlate ? selectedPlate.beadchips : [];
  [...selectedPlateChips, selectedChip].forEach(chip => {
    chip?.biomaterials?.forEach(biomaterial => {
      biomaterial?.analyses?.forEach(analysisBiomaterial => {
        const exists = analysisBiomaterials.find(bio => bio.id === analysisBiomaterial.id);
        if (!exists) analysisBiomaterials.push({
          ...analysisBiomaterial,
          'biomaterial_subarray': biomaterial['sub_array_id'],
          'biomaterial_name': biomaterial.name,
        });
      });
    });
  });
  const biomaterials = uniqBy(analysisBiomaterials, 'biomaterial_id');
  const metricsBiomaterials = biomaterials
    .map(getMetricsBiomaterial)
    .sort((a, b) => a?.[sortKey] < b?.[sortKey] ? -1 : 1)
    .filter(Boolean);

  const isWideScreen = window.innerWidth >= 1200;

  const seriesToScatters = (series, extent) => (
    <ResponsiveContainer width="100%" aspect={isWideScreen ? 1.2 : 0.75}>
      <ScatterChart margin={{ bottom: 8 }} >
        <XAxis
          type="number"
          dataKey="x"
          fontSize="0.8em"
          scale="linear"
          label={{ value: Language.get('biomaterial', 'SAMPLE'), position: 'insideBottom', offset: -5 }}
        />
        <YAxis
          type="number"
          dataKey="y"
          fontSize="0.8em"
          label={{ value: 'Intensity', angle: -90, position: 'insideLeft' }}
          domain={extent}
        />
        {isWideScreen ? (
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconSize={10}
            wrapperStyle={{
              padding: 8,
              right: -4,
              border: 'solid 1px',
              fontSize: '0.8em',
              width: `${Math.max(...Object.keys(CONTROL_PROBES).map(key => key.length))}ch`
            }}
          />
        ) : (
          <Legend
            iconSize={10}
            wrapperStyle={{
              padding: 8,
              bottom: '-0.5em',
              border: 'solid 1px',
              fontSize: '0.8em',
            }}
          />
        )}
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid />
        {Object.keys(series).map(serieKey => (
          <Scatter
            key={serieKey}
            name={serieKey}
            fill={series[serieKey]['SeriesColor']}
            data={metricsBiomaterials.map((bio, idx) => ({
              x: idx + 1,
              y: Number(bio.qcProbes[series[serieKey]['ColumnName']]),
              ...bio,
            }))}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );

  return (
    <div className='metrics'>
      <div className='metrics-controls' >
        <SearchSelect
          label={Language.get('case', 'CASE_ID')}
          value={selectedCase}
          onChange={(_, caseObj) => setSelectedCase(caseObj)}
          mapOptions={(cases) => cases.map(caseObj => ({ label: caseObj['external_id'], value: caseObj.id, ...caseObj }))}
          mapQuery={(search) => ['cases', { search, limit: 12, order: '-updated_on' }]}
          queryFn={async ({ queryKey }) => {
            const [, { search, limit, order }] = queryKey;
            const { results } = await AppAPI.Case.list({ filters: { external_id__icontains: search }, limit, order });
            return results;
          }}
        />
        <SearchSelect
          label={Language.get('plate', 'PLATE_ID')}
          initialValue={plateParam}
          onSearchSuccess={(plates) => {
            if (plateParam && !selectedPlate) {
              const found = plates.find(plate => plate['external_id'] === plateParam);
              if (found) setSelectedPlate(found);
            }
          }}
          value={selectedPlate}
          onChange={(_, plate) => {
            setSelectedPlate(plate);
            updateParams('plate', plate?.['external_id']);
          }}
          mapOptions={(plates) => plates.map(plate => ({ label: plate['external_id'], value: plate.id, ...plate }))}
          mapQuery={(search) => ['plates', { search, limit: 12, order: '-updated_on' }]}
          queryFn={async ({ queryKey }) => {
            const [, { search, limit, order }] = queryKey;
            const { results } = await AppAPI.Plate.list({ filters: { external_id__icontains: search }, limit, order });
            return results;
          }}
        />
        <SearchSelect
          label={Language.get('biomaterial', 'BEADCHIP_BARCODE')}
          value={selectedChip}
          initialValue={beadchipParam}
          onSearchSuccess={(beadchips) => {
            if (beadchipParam && !selectedChip) {
              const found = beadchips.find(chip => chip.barcode === beadchipParam);
              if (found) setSelectedChip(found);
            }
          }}
          onChange={(_, chip) => {
            setSelectedChip(chip);
            updateParams('beadchip', chip?.barcode);
          }}
          mapOptions={(beadchips) => beadchips.map(chip => ({ label: chip.barcode, value: chip.id, ...chip }))}
          mapQuery={(search) => ['beadchips', { search, limit: 12, order: '-updated_on' }]}
          queryFn={async ({ queryKey }) => {
            const [, { search, limit, order }] = queryKey;
            const { results } = await AppAPI.Plate.list({ filters: { beadchip_barcode: search }, limit, order });
            const beadchips = (Array.isArray(results) ? results : []).reduce(
              (chips, plate) => [...chips, ...plate.beadchips], []
            );
            return beadchips;
          }}
        />
        <div className='metrics-categories'>
          <h2>{Language.get('common', 'CATEGORIES')}:</h2>
          <ul>
            {Object.keys(CONTROL_PROBES).map(probeKey => (
              <li
                key={probeKey}
                style={{ fontWeight: probeKey === priorityCategory ? 'bold' : 'normal' }}
                onClick={() => setPriorityCategory(probeKey)}
              >
                {probeKey}
              </li>
            ))}
          </ul>
        </div>
        <div className='metrics-sort'>
          <h2>{Language.get('common', 'SORT_BY')}:</h2>
          <br />
          <Select value={sortKey} onChange={setSortKey}>
            <Select.Option value="subArray">{Language.get('biomaterial', 'SUB_ARRAY_ID')}</Select.Option>
            <Select.Option value="callRate">{Language.get('report', 'CALL_RATE')}</Select.Option>
          </Select>
        </div>
        <div className='metrics-scale'>
          <h2>{Language.get('metrics', 'SCALE_TYPE')}:</h2>
          <br />
          <Select value={scaleType} onChange={setScaleType}>
            <Select.Option value="fixed">{Language.get('metrics', 'FIXED_SCALE')}</Select.Option>
            <Select.Option value="auto">{Language.get('metrics', 'AUTO_SCALE')}</Select.Option>
          </Select>
        </div>
      </div>
      <div className='metrics-charts'>
        {Object.keys(CONTROL_PROBES).sort((a, b) => b === priorityCategory ? 1 : -1).map(((category) => {
          const seriesGreen = CONTROL_PROBES[category][`${category} Green`];
          const seriesRed = CONTROL_PROBES[category][`${category} Red`];
          const allValues = [];
          [...Object.values(seriesRed), ...Object.values(seriesGreen)].forEach(
            (serie) => allValues.push(...metricsBiomaterials.map(bio => Number(bio.qcProbes[serie['ColumnName']])))
          );
          const extent = scaleType === 'fixed' ? d3.nice(0, d3.max(allValues), 10) : undefined;
          return (
            <Row className='metrics-chart-cateogry' gutter={16} key={category} justify="space-around" wrap={false}>
              <Col flex="1">
                <h2 className='metrics-chart-title'>{category} Red</h2>
                {seriesToScatters(seriesRed, extent)}
              </Col>
              <Col flex="1">
                <h2 className='metrics-chart-title'>{category} Green</h2>
                {seriesToScatters(seriesGreen, extent)}
              </Col>
            </Row>
          );
        }))}
      </div>
    </div>
  );
}