// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';

// Importing momentjs
import moment from 'moment';

// Importing antd libs
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/date-picker/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/input-number/style/css';
import 'antd/es/message/style/css';
import 'antd/es/row/style/css';
import 'antd/es/select/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { MChromosomeDictionary } from 'helix-modules/chromosome';
import { BACKEND_DATE_FORMAT } from 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing Helix components
import Label from 'helix/ui/label';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { BiomaterialGeneRegionList } from 'components/biomaterial';
import { Notification } from 'helper/handler/notification';
import { alphanumericValidator } from 'helper/handler/alphanumericValidator';

// Importing component style
import './form.scss';

// Defining constants
const { Item, useWatch } = Form;
const DOB_FORMAT = 'DD-MMM-YYYY';
const MAX_LENGTH = 50;

// Exporting component
export const BiomaterialForm = (props) => {
  const { case: caseObj, form: modalForm, biomaterial, onUpdate } = props;
  const Language = useLanguage();
  const [form] = modalForm ? [modalForm] : Form.useForm();
  const [chromList, setChromList] = useState();
  const [genes, setGenes] = useState([]);
  const [refCounter, setRefCounter] = useState({});
  const designation = useWatch('designation', form);
  const pedegree = useWatch('pedegree', form);
  const sex = useWatch('sex', form);
  const namesDisabled = !['father', 'mother'].includes(designation);
  const cycleIdDisabled = !['sample', 'sample_reference'].includes(pedegree);

  const onFinish = async (values) => {
    // set the case id
    if (caseObj?.id) values['case'] = caseObj.id;
    // set id if gene is already defined
    if (biomaterial?.id) values['id'] = biomaterial.id;
    // format dob
    if (values['dob']) values['dob'] = values['dob'].format(BACKEND_DATE_FORMAT);
    // set gene status relationship
    values['gene_regions'] = genes.map((gene) => ({
      id: gene.id,
      status: gene.status,
    }));
    // set metadata
    ['260_230', '260_280', 'concentration_ngul', 'volume_ul'].forEach((field) => {
      values['metadata'] = values['metadata'] || {};
      values['metadata'][field] = values[field];
      delete (values[field]);
    });
    const res = await AppAPI.Biomaterial.save(values);
    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);
    return onUpdate(res);
  };

  const _countReference = async () => {
    if (caseObj) {
      const references = ['father', 'mother', 'reference'];
      const promises = references.map((designation) => AppAPI.Biomaterial.list({
        filters: { designation, 'case__exact': [caseObj?.id] },
      }));
      const responses = await Promise.all(promises);
      const refCounter = references.reduce((refCounter, ref, index) => {
        refCounter[ref] = (responses[index]?.count || 0) + 1;
        return refCounter;
      }, {});
      setRefCounter(refCounter);
    }
  };

  const _loadGenes = async () => {
    const res = await AppAPI.GeneRegion.list({ filters: { 'case': [caseObj?.id] } });
    if (res.error) return message.error(Language.get('common', res.msg));
    const genes = res.results.map((gene) => {
      // calculate cytoband
      gene.cytoband = 'N/A';
      // console.log(chromList);
      if (chromList) {
        gene.cytoband = MChromosomeDictionary.getCytoband({
          chromosome: chromList.find(({ value }) => `${value}` === `${gene.chromosome}`),
          position: gene['start_at'] + (gene.length / 2),
        });
      }
      // calculate status
      let status = undefined;
      if (biomaterial?.id) {
        const geneRegion = gene['biomaterials'].find((item) => item['biomaterial_id'] === biomaterial.id);
        status = geneRegion?.status || status;
      }
      return {
        chromosome: gene.chromosome,
        cytoband: gene.cytoband,
        id: gene.id,
        name: gene.name,
        status,
        type: gene['gene_type']
      };
    }, {});

    const geneStatuses = genes.reduce(
      (statuses, gene) => ({ ...statuses, [`${gene.name}_status`]: gene.status }), {}
    );

    setGenes(genes);
    form.setFieldsValue(geneStatuses);
  };

  const _loadChromosomeDictionary = async () => {
    // Define hg based on case is_v1_array
    if (caseObj) {
      const hg = caseObj?.is_v1_array ? '19' : '38';
      setChromList(await MChromosomeDictionary.load({
        bandsUrl: `/bioinfo/hg${hg}_cytobands.tsv`,
        dictUrl: `/bioinfo/hg${hg}_fasta.dict`,
      }));
    }
  };

  const _preFill = (value) => {
    const preFillRules = {
      'father': {
        bioType: 'gdna',
        designation: 'father',
        sex: 'm'
      },
      'mother': {
        bioType: 'gdna',
        designation: 'mother',
        sex: 'f'
      },
      'sibling': {
        bioType: 'gdna',
        designation: 'reference',
        sex: null,
      },
      'matern_aunt|matern_grand_mother|patern_aunt|patern_grand_mother': {
        bioType: 'gdna',
        designation: 'reference',
        sex: 'f'
      },
      'matern_uncle|matern_grand_father|patern_uncle|patern_grand_father': {
        bioType: 'gdna',
        designation: 'reference',
        sex: 'm'
      },
      'sample': {
        bioType: 'trophectoderm',
        designation: '',
        sex: 'u'
      },
      'sample_reference': {
        bioType: 'trophectoderm',
        designation: 'reference',
        sex: null,
      }
    };
    const matchingKey = Object.keys(preFillRules).find(key => new RegExp(`^(${key})$`).test(value));
    const { bioType, designation, sex } = preFillRules[matchingKey];

    form.setFieldsValue({ 'bio_type': bioType, designation, sex });

    if (!['father', 'mother'].includes(designation)) {
      form.setFieldsValue({ firstname: null, lastname: null, dob: null });
    }

    // clear cycle id if disabled
    if (!['sample', 'sample_reference'].includes(value)) {
      form.setFieldsValue({ cycle_id: null });
    }

    if (designation.length > 0) {
      _setName(designation);
    }
  };

  const _setName = (value) => {
    if (value) {
      const current = (form.getFieldValue('name') || '').toLowerCase().slice(0, -1);
      if (!current || AppAPI.Biomaterial.DESIGNATION_VALUES.indexOf(current) !== -1) {
        form.setFieldsValue({ name: `${value.ftuc()}${refCounter[value] || 1}` });
      }
    }
  };

  const _updateBiomaterialRegionStatus = ({ index, value }) => {
    genes[index].status = value;
    setGenes(genes);
  };

  useEffect(() => {
    _countReference();
    _loadChromosomeDictionary();
  }, [biomaterial, caseObj]);

  useEffect(() => {
    _loadGenes();
  }, [chromList]);

  useEffect(() => {
    if (biomaterial?.id) {
      form.setFieldsValue({
        ...biomaterial,
        '260_230': biomaterial['metadata']?.['260_230'],
        '260_280': biomaterial['metadata']?.['260_280'],
        'concentration_ngul': biomaterial['metadata']?.['concentration_ngul'],
        'volume_ul': biomaterial['metadata']?.['volume_ul'],
        dob: biomaterial.dob
          ? moment(biomaterial.dob)
          : undefined,
      });
    }
  }, [genes]);

  return (
    <div className="biomaterial-form">
      <Form
        form={form}
        initialValues={{
          designation: '',
          pedegree: '',
          sex: '',
          'bio_type': '',
          requisition: '',
        }}
        name="biomaterial-form"
        validateMessages={{
          required: Language.get('common', 'NOT_VALID_EMPTY_VALUE'),
        }}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Item label={Language.get('case', 'CASE_NAME')}>
              {caseObj['external_id']} {pedegree ? `(${Language.get('biomaterial', designation || 'SAMPLE')})` : ''}
            </Item>
          </Col>
          <Col span={8}>
            <Item name="designation" style={{ display: 'none' }} >
              <Select
                options={AppAPI.Biomaterial.DESIGNATION_VALUES.map(
                  (designation) => ({
                    label: Language.get('biomaterial', designation === '' ? 'SAMPLE' : designation),
                    value: designation,
                  })
                )}
              />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'PEDEGREE')} title={Language.get('biomaterial', 'PEDEGREE_INFO')} />)}
              name="pedegree"
              rules={[{ required: true }]}
            >
              <Select
                optionFilterProp="label"
                options={AppAPI.Biomaterial.PEDEGREE_VALUES.map(
                  (pedegree) => ({
                    label: pedegree === '' ? pedegree : Language.get('biomaterial', pedegree),
                    value: pedegree,
                  })
                )}
                showSearch
                onChange={_preFill}
              />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'SEX')} title={Language.get('biomaterial', 'SEX_INFO')} />)}
              name="sex"
              rules={[{ required: true }]}
            >
              <Select
                disabled={!pedegree}
                options={[
                  'female',
                  'male',
                  'unknown',
                ].map(
                  (sex) => ({
                    label: Language.get('biomaterial', sex),
                    value: sex.at(0),
                  })
                )}
              />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'TYPE')} title={Language.get('biomaterial', 'TYPE_INFO')} />)}
              name="bio_type"
              rules={[{ required: true }]}
            >
              <Select
                disabled={!pedegree}
                optionFilterProp="label"
                options={AppAPI.Biomaterial.BIO_TYPE_VALUES.map(
                  (bioType) => ({
                    label: Language.get('biomaterial', bioType),
                    value: bioType !== 'blank' ? bioType : '',
                  })
                )}
                showSearch
              />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'SAMPLE_ID')} title={Language.get('biomaterial', 'SAMPLE_ID_INFO')} />)}
              name="name"
              rules={[
                { required: true },
                { max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` },
                alphanumericValidator(Language)
              ]}
            >
              <Input disabled={!pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'TUBE_ID')} title={Language.get('biomaterial', 'TUBE_ID_INFO')} />)}
              name="tube_id"
              rules={[
                { required: true },
                { max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` },
                alphanumericValidator(Language)
              ]}>
              <Input disabled={!pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'BARCODE')} title={Language.get('biomaterial', 'BARCODE_INFO')} />)}
              name="barcode"
              rules={[
                { max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` },
                alphanumericValidator(Language)
              ]}>
              <Input disabled={!pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
            <Item
              label={(<Label name={Language.get('plate', 'REQUISITION')} title={Language.get('biomaterial', 'REQUISITION_INFO')} />)}
              name="requisition"
              rules={[
                { max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` },
                {
                  message: Language.get('common', 'NOT_VALID_ALPHANUMERIC'),
                  validator: (_, value) => /[^a-z0-9-]+/i.test(value) ? Promise.reject() : Promise.resolve()
                }
              ]}>
              <Input disabled={!pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              label={(<Label name={Language.get('biomaterial', 'LASTNAME')} title={Language.get('biomaterial', 'LASTNAME_INFO')} />)}
              name="lastname"
              rules={[{ max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` }]}
            >
              <Input disabled={namesDisabled || !pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'FIRSTNAME')} title={Language.get('biomaterial', 'FIRSTNAME_INFO')} />)}
              name="firstname"
              rules={[{ max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` }]}
            >
              <Input disabled={namesDisabled || !pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'DOB')} title={Language.get('biomaterial', 'DOB_INFO')} />)}
              name="dob"
            >
              <DatePicker
                disabled={namesDisabled || !pedegree}
                defaultPickerValue={moment(new Date()).add(-35, 'years')}
                disabledDate={(date) => !date || date.isAfter(moment())}
                format={DOB_FORMAT}
              />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'BEADCHIP_BARCODE')} title={Language.get('biomaterial', 'BEADCHIP_BARCODE_INFO')} />)}
              name="beadchip_barcode"
            >
              {biomaterial?.['beadchip_barcode']}
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'SUB_ARRAY_ID')} title={Language.get('biomaterial', 'SUB_ARRAY_ID_INFO')} />)}
              name="sub_array_id"
            >
              {biomaterial?.['sub_array_id']}
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'CYCLE_ID')} title={Language.get('biomaterial', 'CYCLE_ID_INFO')} />)}
              name="cycle_id"
              rules={[
                { max: MAX_LENGTH, message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}` },
                alphanumericValidator(Language)
              ]}>
              <Input disabled={cycleIdDisabled || !pedegree} maxLength={MAX_LENGTH} showCount />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              label={(<Label name={Language.get('biomaterial', 'VOLUME')} title={Language.get('biomaterial', 'VOLUME_INFO')} />)}
              name="volume_ul"
            >
              <InputNumber disabled={!pedegree} max={20} min={0} step={0.1} />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', 'CONCENTRATION')} title={Language.get('biomaterial', 'CONCENTRATION_INFO')} />)}
              name="concentration_ngul"
            >
              <InputNumber disabled={!pedegree} max={100} min={0} step={5} />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', '260_280')} title={Language.get('biomaterial', '260_280_INFO')} />)}
              name="260_280"
            >
              <InputNumber disabled={!pedegree} max={5} min={0} step={0.1} />
            </Item>
            <Item
              label={(<Label name={Language.get('biomaterial', '260_230')} title={Language.get('biomaterial', '260_230_INFO')} />)}
              name="260_230"
            >
              <InputNumber disabled={!pedegree} max={5} min={0} step={0.1} />
            </Item>
          </Col>
        </Row>
        {['mother', 'father', 'reference'].includes(designation) && (
          <Fragment>
            <h4>{Language.get('biomaterial', 'REGIONS')}</h4>
            <BiomaterialGeneRegionList
              pedegree={pedegree}
              genes={genes}
              sex={sex}
              onUpdate={_updateBiomaterialRegionStatus}
            />
          </Fragment>
        )}
      </Form>
    </div>
  );
};
