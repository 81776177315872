// Importing react libs
import React from 'react';

// Importing antd libs
import {
  Descriptions,
  Modal,
  Table,
} from 'antd';
import 'antd/es/descriptions/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/table/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './caImportConfirm.scss';

// Defining constants
const { Item } = Descriptions;

export const CAImportConfirm = ({ data, open, onCancel, onConfirm }) => {
  const { biomaterials, case: caseObj, genes } = data || {};
  const Language = useLanguage();

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      title={Language.get('case', 'CASE_IMPORT_SUMMARY')}
      width={1200}
      onCancel={onCancel}
      onOk={onConfirm}
    >
      <div className="ca-import-confirm-wrapper">
        <h2>{Language.get('case', 'CASE_IMPORT_SUMMARY_CONFIRM')}</h2>

        <h3>{Language.get('case', 'CASE_SUMMARY')}</h3>
        {caseObj && (
          <Descriptions column={2}>
            <Item label={Language.get('case', 'CASE_ID')}>
              {caseObj?.['external_id']}
            </Item>
            <Item label={Language.get('case', 'CONSULTANT')}>
              {caseObj?.consultant}
            </Item>
            <Item label={Language.get('case', 'REFERRING_CENTRE')}>
              {caseObj?.centre}
            </Item>
            <Item label={Language.get('case', 'NOTES')}>
              {caseObj?.notes}
            </Item>
          </Descriptions>
        )}

        <h3>{Language.get('gene', 'GENES_SUMMARY')}</h3>
        {genes && (
          <Table
            columns={[{
              dataIndex: 'name',
              title: Language.get('gene', 'NAME'),
            }, {
              dataIndex: 'chromosome',
              title: Language.get('gene', 'CHROM'),
            }, {
              dataIndex: 'start_at',
              title: Language.get('gene', 'START'),
            }, {
              dataIndex: 'length',
              title: Language.get('gene', 'END'),
              render: (length, record) => record['start_at'] + length,
            }, {
              dataIndex: 'gene_type',
              title: Language.get('gene', 'TYPE'),
              render: (value) => Language.get('gene', value),
            }, {
              dataIndex: 'mutation_site',
              title: Language.get('gene', 'MUTATION_SITE'),
            }, {
              dataIndex: 'biomaterials',
              key: 'mother_status',
              title: Language.get('gene', 'MOTHER'),
              render: (biomaterials) => {
                const mother = biomaterials.find(
                  (biomaterial) => biomaterial?.field === 'mother_status'
                );
                return Language.get('gene', mother?.status || 'NA');
              },
            }, {
              dataIndex: 'biomaterials',
              key: 'father_status',
              title: Language.get('gene', 'FATHER'),
              render: (biomaterials) => {
                const father = biomaterials.find(
                  (biomaterial) => biomaterial?.field === 'father_status'
                );
                return Language.get('gene', father?.status || 'NA');
              },
            }, {
              dataIndex: 'biomaterials',
              key: 'reference1_status',
              title: `${Language.get('gene', 'REFERENCE')} 1`,
              render: (biomaterials) => {
                const reference = biomaterials.find(
                  (biomaterial) => biomaterial?.field === 'reference1_status'
                );
                return Language.get('gene', reference?.status || 'NA');
              },
            }, {
              dataIndex: 'biomaterials',
              key: 'reference2_status',
              title: `${Language.get('gene', 'REFERENCE')} 2`,
              render: (biomaterials) => {
                const reference = biomaterials.find(
                  (biomaterial) => biomaterial?.field === 'reference2_status'
                );
                return Language.get('gene', reference?.status || 'NA');
              },
            }]}
            dataSource={genes}
            pagination={false}
            rowKey="name"
            size="small"
          />
        )}

        <h3>{Language.get('biomaterial', 'BIOMATERIALS_SUMMARY')}</h3>
        {biomaterials && (
          <Table
            columns={[{
              dataIndex: 'tube_id',
              title: Language.get('biomaterial', 'TUBE_ID'),
            }, {
              dataIndex: 'cycle_id',
              title: Language.get('biomaterial', 'CYCLE_ID'),
            }, {
              dataIndex: 'name',
              title: Language.get('biomaterial', 'SAMPLE_ID'),
            }, {
              dataIndex: 'barcode',
              title: Language.get('biomaterial', 'BARCODE'),
            }, {
              dataIndex: 'bio_type',
              title: Language.get('biomaterial', 'BIO_TYPE'),
              render: (value) => Language.get('biomaterial', value),
            }]}
            dataSource={data.biomaterials}
            pagination={false}
            rowKey="name"
            size="small"
          />
        )}
      </div>
    </Modal>
  );
};
