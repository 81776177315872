// Importing Helix modules
import 'helix-modules/sDate';

// Importing app helpers
import { COLORS } from './styles';

export const footer = ({ data, i18n }) => (currentPage, pageCount) => {
  const date = (new Date()).format();
  const page = `${i18n('PAGE')} ${currentPage} ${i18n('OF')} ${pageCount}`;
  const { username } = window.app.user;
  return {
    margin: [0, 7, 0, 0],
    table: {
      body: [
        [{
          alignment: 'center',
          colSPan: 3,
          fillColor: COLORS.VL_GRAY_3,
          style: 'xxsmall',
          text: data.info.disclaimer,
        }],
        [{
          columns: [
            { alignment: 'left', text: page, width: '15%' },
            { alignment: 'center', text: i18n('FOR_RESEARCH_ONLY'), width: '*' },
            { alignment: 'right', text: `printed on ${date} ${username}`, width: '*' },
          ],
          fillColor: COLORS.VL_GRAY_3,
          style: 'xxsmall',
        }],
      ],
      widths: ['*'],
    },
  };
};
