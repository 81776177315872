import { validateStatusForInheritanceType } from 'components/gene/rules';

export const statusValidator = ({ i18n, inheritanceType, pedegree, sex }) => (_, value) => {
  if (!value) return Promise.reject(new Error(i18n('NOT_VALID_EMPTY_VALUE')));
  const isValid = validateStatusForInheritanceType({
    inheritanceType,
    pedegree,
    sex,
    status: value,
  });
  if (isValid) return Promise.resolve();
  else return Promise.reject(new Error(i18n('INVALID_STATUS')));
};
