// Importing external libs
import { useQuery } from '@tanstack/react-query';

// Importing app modules
import AppAPI from 'modules/api';

/**
 *
 * @param {Object} queryObject
 * @param {Array<string>} queryObject.queryKey
 * @returns {Promise<Array<{bp_analysis_id: number, }} array of
 */
const getFileList = async ({ queryKey }) => {
  const analysisId = queryKey[1];
  const analyses = await AppAPI.Analysis.files({
    'analysis_id': analysisId,
  });

  const analysisUiFiles = Object.values(analyses).reduce((analysisUiFilesMap, analysis) => {
    const uiFiles = analysis.files.filter(
      file => ['ui_file_list.json', 'generate_trio_data/ui'].some(path => file['file_uri'].endsWith(path))
    );
    uiFiles.forEach(file => {
      analysisUiFilesMap[file['bp_analysis_id']] = file['file_uri'];
    });
    return analysisUiFilesMap;
  }, {});

  const uiFileBaseUrlRx = /http.*analyses(\/\d+)*/g;

  const chartFiles = {};

  const analysisUiFilesPromises = Object.entries(analysisUiFiles).map(
    async ([bpAnalysisId, uiFileListUrl]) => {
      let uiFilesUrls = {};

      if (uiFileListUrl.endsWith('ui_file_list.json')) {
        // initailize a map to hold the URLs of all UI files by key
        let uiFilesPaths = {};
        try {
          // get the JSON object which contains path of each ui file by key
          const fileResponse = await fetch(uiFileListUrl, {
            credentials: 'include',
            mode: 'cors'
          });
          uiFilesPaths = await fileResponse.json();
        } catch (error) {
          uiFilesPaths = {};
        }

        const [baseUrl] = uiFileListUrl.match(uiFileBaseUrlRx);

        for (const uiFileKey in uiFilesPaths) {
          uiFilesUrls[uiFileKey] = `${baseUrl}/${uiFilesPaths[uiFileKey]}`;
        }
      } else if (uiFileListUrl.endsWith('generate_trio_data/ui')) {
        uiFilesUrls['father_baf_logr'] = `${uiFileListUrl}/father.baf_logr.tsv.gz`;
        uiFilesUrls['father_haploblocks'] = `${uiFileListUrl}/father.haploblocks.json`;
        uiFilesUrls['mother_baf_logr'] = `${uiFileListUrl}/mother.baf_logr.tsv.gz`;
        uiFilesUrls['mother_haploblocks'] = `${uiFileListUrl}/mother.haploblocks.json`;
        uiFilesUrls['reference_baf_logr'] = `${uiFileListUrl}/reference.baf_logr.tsv.gz`;
        uiFilesUrls['reference_haploblocks'] = `${uiFileListUrl}/reference.haploblocks.json`;
      }

      chartFiles[bpAnalysisId] = uiFilesUrls;

      return chartFiles;
    }
  );

  await Promise.all(analysisUiFilesPromises);

  return chartFiles;
};

export default function useChartFiles(analysisId, options) {
  const query = useQuery({
    enabled: Boolean(analysisId),
    queryKey: ['/analysis/files', analysisId],
    queryFn: getFileList,
    refetchInterval: false,
    staleTime: 1000 * 60 * 5,
    ...options,
  });

  return query;
}
