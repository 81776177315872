// Importing react libs
import React from 'react';

// Importing antd libs
import { Form, Select, Table } from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/select/style/css';
import 'antd/es/table/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { getStatusForInheritanceType } from 'components/gene/rules';
import { statusValidator } from 'components/gene/validators';

// Importing component style
import './biomaterialList.scss';

// Exporting component
export const BiomaterialGeneRegionList = ({ biomaterials, inheritanceType, onUpdate }) => {
  const Language = useLanguage();
  const i18n = (key) => Language.get('gene', key);
  const getStatusOptions = getStatusForInheritanceType(inheritanceType);

  const columns = [{
    dataIndex: 'designation',
    key: 'designation',
    title: Language.get('biomaterial', 'DESIGNATION'),
  }, {
    dataIndex: 'name',
    key: 'name',
    title: Language.get('biomaterial', 'SAMPLE_ID'),
  }, {
    dataIndex: 'pedegree',
    key: 'pedegree',
    title: Language.get('biomaterial', 'PEDEGREE'),
    render: (value) => Language.get('biomaterial', value),
  }, {
    dataIndex: 'status',
    key: 'status',
    title: Language.get('biomaterial', 'Status'),
    render: (_, record, index) => (
      <Form.Item
        name={`${record.name}_status`}
        rules={[{
          validator: statusValidator({ i18n, inheritanceType, pedegree: record.pedegree, sex: record.sex })
        }]}>
        <Select
          placeholder={i18n('TYPE')}
          options={getStatusOptions(record).map((option) => ({ label: i18n(option), value: option }))}
          onChange={(newValue) => onUpdate({ id: record.id, index, value: newValue })}
        />
      </Form.Item>
    ),
  }];

  return (
    <div className="biomaterial-gene-region-list-wrapper">
      <Table
        columns={columns}
        dataSource={Object.values(biomaterials)}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </div>
  );
};
