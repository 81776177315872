import Abstract from './abstract';


class GeneRegion extends Abstract {
  constructor() {
    super();
    this.resource = 'gene_region';
    this._updateEndpoint();

    this.BIO_STATUSES = [
      'hemizygous_affected',
      'hemizygous_affected_maternal',
      'hemizygous_normal',
      'heterozygous_affected',
      'heterozygous_affected_maternal',
      'heterozygous_affected_paternal',
      'heterozygous_carrier',
      'heterozygous_carrier_maternal',
      'heterozygous_carrier_paternal',
      'heterozygous',
      'homozygous_affected',
      'homozygous_normal',
      'unaffected',
      'unknown',
    ];

    this.TYPE_VALUES = [
      'autosomal_recessive',
      'autosomal_dominant',
      'x_linked_recessive',
      'x_linked_dominant',
      'unknown'
    ];

    this.NON_X_TYPE_VALUES = [
      'autosomal_recessive',
      'autosomal_dominant',
      'unknown'
    ];

    this.X_TYPE_VALUES = [
      'x_linked_recessive',
      'x_linked_dominant',
      'unknown'
    ];
  }
}

export default new GeneRegion();
