// Importing react libs
import React from 'react';

// Importing antd libs
import { Row, Col, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/typography/style/css';

// Importing App components
import { Phase } from 'components/biomaterial/region/phase';
import { Snps } from 'components/biomaterial/region/snps';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Statistics = ({ statsRegion, index }) => {
  const Language = useLanguage();
  const { nameAndLocation, snpP1, snpP2, snpM1, snpM2 } = statsRegion;

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{Language.get('case', 'REGION')} {index}: {nameAndLocation}</Title>
      </Col>
      <Col offset={1} span={23}>
        <Row gutter={32}>
          <Col span={8}>
            <Phase region={statsRegion} />
          </Col>
          <Col span={8}>
            <Snps kind="PATERNAL" snps={[snpP1, snpP2]} />
          </Col>
          <Col span={8}>
            <Snps kind="MATERNAL" snps={[snpM1, snpM2]} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
