// Importing React and external libs
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing Antd
import { Button } from 'antd';
import 'antd/es/button/style/css';

// Importing antd icons
import { PlusOutlined } from '@ant-design/icons';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix modules
import 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing helix components
import BtnConfirm from 'helix/ui/btnConfirm';
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';

// Importing modules
import AppAPI from 'modules/api';

// Import styles
import './list.scss';

export const List = () => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [{
    dataIndex: 'id',
    sorter: true,
    title: Language.get('common', 'ID'),
    width: 100,
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'name',
    sorter: true,
    title: Language.get('common', 'NAME'),
    width: 900,
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'updated_on',
    defaultSortOrder: 'descend',
    sorter: true,
    title: Language.get('common', 'LAST_UPDATE'),
    width: 200,
    render: (value) => value ? (new Date(value)).format() : ' - ',
    ...tableColumnFilterDate(),
  }];

  const _deleteById = async () => {
    const promises = selectedRowKeys.map((item) => AppAPI.Organization.delete(item.id));
    await Promise.all(promises);
    window.location.reload(false);
  };
  const onEdit = async (item) => navigate(`/organization/${item.id}`);
  const reader = async ({ filters, limit, offset, order }) => {
    const mapper = {
      name: 'name__icontains',
    };
    filters = Object.keys(filters).reduce((newFilters, field) => {
      newFilters[mapper[field] || field] = filters[field];
      return newFilters;
    }, {});
    order = order || '-updated_on';
    return await AppAPI.Organization.list({ filters, limit, offset, order });
  };

  return (
    <div className="organization-list">
      <h1>{Language.get('organization', 'ORGANIZATIONS')}</h1>
      <div className="actions-buttons">
        <Button
          ghost
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate('/organization/new')}
        >
          {Language.get('common', 'NEW')}
        </Button>
        <BtnConfirm
          disabled={selectedRowKeys.length === 0}
          name={Language.get('common', 'DELETE')}
          onConfirm={_deleteById}
        />
      </div>
      <div className="organization-table">
        <HelixList
          border={false}
          context="common"
          columns={columns}
          defaultSort={{ field: 'updated_on', order: 'descend' }}
          pagination={true}
          pageSize={20}
          rowKey="id"
          size="small"
          onEdit={onEdit}
          onSelectedRows={(selected) => setSelectedRowKeys(selected)}
          reader={reader}
        />
      </div>
    </div>
  );
};
