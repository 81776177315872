import Abstract from './abstract';

class Role extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'role';
    this._updateEndpoint();
  }

  /**
   * Get host settings
   * @param {void}
   * @returns {Object}
  **/
  list() {
    return { 'results': [{ 'id': 1,  'name': 'Level_1' }, { 'id': 2, 'name': 'Level_2' }] };
  }
}

export default new Role();
