// Importing react libs
import React from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

// Importing antd libs
import { Button, DatePicker, Form, Input } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/date-picker/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { BACKEND_DATE_FORMAT } from 'helix-modules/sDate';

// Importing app modules
import AppAPI from 'modules/api';

// Importing local components
import { idFormValidator } from 'components/common/validators';
import FAImport from './import/faImport';

// Importing component style
import './form.scss';

// Defining constants
const { Item } = Form;
const MAX_LENGTH = 50;

// Exporting component
export const PlateForm = () => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    values['prepared_on'] = values['prepared_on']?.format(BACKEND_DATE_FORMAT);
    return navigate(
      `/planner/new?external_id=${values['external_id']}&prepared_on=${values['prepared_on']}`
    );
  };

  const uniqueIdValidator = async (_, value) => {
    if (!value) return;
    const res = await AppAPI.Plate.list({ filters: { 'external_id': value } });
    if (res.count > 0) throw new Error('PLATE_ID_ALREADY_EXIST');
  };

  return (
    <div className="plate-form">
      <Form
        className="plate-id"
        form={form}
        layout="vertical"
        name="plate-form"
        validateMessages={{
          required: Language.get('common', 'NOT_VALID_EMPTY_VALUE'),
        }}
        onFinish={onFinish}
      >
        <Item
          label={Language.get('plate', 'PLATE_ID')}
          name="external_id"
          rules={[{ required: true }, {
            max: MAX_LENGTH,
            message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH}`,
          }, {
            validator: uniqueIdValidator,
            message: Language.get('plate', 'PLATE_ID_ALREADY_EXIST')
          }, {
            validator: (_, value) => value
              ? idFormValidator(Language.get('accessioning', 'NOT_VALID_ID', true)).validator(_, value)
              : Promise.resolve()
          }]}
        >
          <Input maxLength={MAX_LENGTH} showCount />
        </Item>
        <Item
          label={Language.get('plate', 'PREPARATION_DATE')}
          name="prepared_on"
          rules={[{ required: true }]}
        >
          <DatePicker
            className="preparation-on"
            disabledDate={(current) => current && current < moment('2023-01-01')}
          />
        </Item>
        <div className="preparation-btns">
          <FAImport onSuccess={() => navigate('/dashborad')} />
          <Button
            className="next-button"
            htmlType="submit"
            type="primary"
          >
            {Language.get('common', 'NEXT')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
