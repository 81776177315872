import { reportItem } from './common';

export const analysisSettings = ({ caseInfo, flanking, i18n, reference }) => {
  const version = caseInfo['is_v1_array'] ? 'v1' : 'v2';
  const { algorithmVersion, scanningHardware, manifestName } = reference.stats ?? {};

  return [
    reportItem(i18n('ARRAY_TYPE'), `Karyomap ${version}`),
    reportItem(i18n('SCANNING_HARDWARE', 'organization'), scanningHardware),
    reportItem(i18n('BEAD_ARRAY_FILE', 'organization'), manifestName),
    reportItem(i18n('FLANKING_SIZE'), `${flanking}MB`),
    reportItem(i18n('ALGORITHM_VERSION'), algorithmVersion),
  ];
};
