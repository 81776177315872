import Abstract from './abstract';

class Case extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'case';
    this._updateEndpoint();
  }

  /**
   * Hit GET {this.constants.ENDPOINT}/biomaterials to get list
   * @param {object}
   * filters {object}
   * limit {number}
   * offset {number}
   * order {string}
   * @return {object}
  **/
  biomaterials({ filters, limit = 10, offset = 0, order = '' }) {
    return this.list(
      { filters, limit, offset, order },
      `${this.constants.ENDPOINT}/biomaterials`,
    );
  }

  /**
   * Hit GET {this.constants.ENDPOINT}/biomaterials_for_report to get list
   * @param {object}
   * filters {object}
   * limit {number}
   * offset {number}
   * order {string}
   * @return {object}
  **/
  getSignedBiomaterials({ filters = {}, limit = 10, offset = 0, order = '' }) {
    return this.list(
      { filters, limit, offset, order },
      `${this.constants.ENDPOINT}/biomaterials_for_report`,
    );
  }

  /**
   * Hit POST {this.constants.ENDPOINT}/ca_import to import CA file content
   * @param {object} payload
   * @return {object}
  **/
  async import(payload) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/ca_import/`,
      {
        method: 'POST',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(payload)
      }
    );
    return this._handleError(res);
  }

  /**
   * Hit POST {this.constants.ENDPOINT}/ca_import to import CA file content
   * @param {object} payload
   * @return {object}
  **/
  async faImport(payload) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/fa_import/`,
      {
        method: 'POST',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(payload)
      }
    );
    return this._handleError(res);
  }

  async signoff(id, payload) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/${id}/signoff/`,
      {
        method: 'POST',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      }
    );
    return this._handleError(res);
  }

  async cancelSignoff(id) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/${id}/cancel_signoff/`,
      {
        method: 'PATCH',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
      }
    );
    return this._handleError(res);
  }

  formatSignoffHistory(history) {
    return history.reduce((chain, record) => {
      const { notes, signed_on: signedOn, signing_user_detail: user } = record;
      return `${chain}${new Date(signedOn).format('date')}: ${user.username} - ${notes}\n`;
    }, '');
  }

}

export default new Case();
