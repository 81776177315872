import React, { useRef } from 'react';
import { Markup } from './markup';

export const IconRenderer = ({ cases, children }) => {
  const icons = useRef({});

  return (
    <div>
      {cases.map((caseInfo) =>
        caseInfo.analyses.map((analysis) =>
          analysis.biomaterials.map((bio) => (
            <Markup
              bio={bio}
              key={bio.id}
              onRender={(str) => {
                icons.current[bio.id] = str;
              }}
            />
          ))
        )
      )}
      {children(icons.current)}
    </div>
  );
};
