// Importing react libs
import React, { useState, useEffect, useRef } from 'react';

// Importing Helix components
import Haploblock from 'helix/chart/haploblock';
import Reference from 'helix/chart/haploblock/reference';

// Importing app hooks
import useTsv from 'hooks/useTsv';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

// Importing component CSS
import './style.scss';

const defaultBlock = {
  paternal: { 'initial_color': 0, positions: [] },
  maternal: { 'initial_color': 0, positions: [] },
};

/**
 *
 * @param {Object}  props component props
 * @param {string}  props.chrom label of selected chromosome
 * @param {Array<number>}  props.chromExtent start and end position of chrom
 * @param {boolean} props.enableSnps whether to show snps or not
 * @param {string}  props.haploblocksFile URL of file to load haploblocks
 * @param {boolean} props.showReference whether to show reference or not
 * @param {string}  props.snpsFile URL of file to load snps data (specific to chromosome)
 * @param {Array<number>}  props.transformedExtent start and end position of visible area of chrom
 */
const CaseViewHaploblock = ({
  chrom,
  chromExtent,
  diseaseRegions,
  enableSnps = false,
  flanking,
  haploblocksFile,
  showReference = false,
  snpsFile,
  transformedExtent,
}) => {
  const [haploblocks, setHaploblocks] = useState({});
  const ref = useRef();
  const onScreen = useIntersectionObserver(ref, { threshold: 0.6, rootMargin: '-300px 0px 0px 0px' });

  useEffect(() => {
    fetch(haploblocksFile, {
      credentials: 'include',
      mode: 'cors'
    })
      .then(response => response.json().then(setHaploblocks))
      .catch({});
  }, [haploblocksFile]);

  const blocksData = haploblocks[`chr${chrom}`] ?? defaultBlock;

  const { data: snpsData } = useTsv({
    url: snpsFile,
    parser: (row) => ({
      call: row.call,
      snp: row.snp,
      position: Number(row.position),
      snp_type: Number(row.snp_type),
      snp_phase: Number(row.snp_phase),
      block_phase: Number(row.block_phase),
    }),
  });

  const usefulSnps = snpsData.filter(d => d.snp_type > 0);

  return (
    <div ref={ref} className='case-view-haploblock' style={{ minHeight: enableSnps ? 180 : 80 }} >
      {onScreen && (
        <Haploblock
          blocks={{
            father: {
              initial: Math.max(0, blocksData.paternal?.['initial_color']),
              positions: blocksData.paternal?.positions ?? [],
            },
            mother: {
              initial: Math.max(0, blocksData.maternal?.['initial_color']),
              positions: blocksData.maternal?.positions ?? [],
            },
          }}
          chrom={chrom}
          domain={transformedExtent}
          diseaseRegions={diseaseRegions}
          enableSnps={enableSnps}
          extent={chromExtent}
          flanking={flanking}
          snps={usefulSnps}
          snpYOffset={18}
        />
      )}
      {showReference ? (
        <div className='reference-wrapper'>
          <Reference refType="MATERNAL" />
        </div>
      ) : null}
    </div>
  );
};

export default CaseViewHaploblock;