export const RESULT_COLORS = {
  PATERNAL_1: { fill: '#085cfc', stroke: '#0000ac' },
  PATERNAL_2: { fill: '#ff0404', stroke: '#c00000' },
  MATERNAL_1: { fill: '#f8a004', stroke: '#a26800' },
  MATERNAL_2: { fill: '#027d03', stroke: '#385723' },
  NO_RESULT: { fill: '#bbbbbb', stroke: '#7f7f7f' },
  FLANKING_REGION: { fill: '#ececec', stroke: '#7f7f7f' },
  DISEASE_REGION: { fill: '#b3c6df', stroke: '#0000ac' },
  MUTATION: { stroke: '#000000' },
};

// Vitrolife colors, should be moved to config in future
const BLUE = '#004a9d';
const GREEN = '#23bb6c';
const GRAY = '#b5b5b5';
const ORANGE = '#ffa500';
const RED = '#be1d13';

export const ANALYSIS_STATUS_COLORS = {
  'completed': GREEN,
  'completed_trio': GREEN,
  'failed_trio': RED,
  'failed_embryos': ORANGE,
  'processing_embryos': BLUE,
  'processing_trio': BLUE,
  'waiting': GRAY,
};