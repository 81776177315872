export const ID_REGEX = /^[a-z0-9]+[a-z0-9-_.]*$/i;
export const MAX_LENGTH_NAME = 50;
export const MAX_LENGTH_NOTES = 500;

export const isIdValid = (str) => {
  return ID_REGEX.test(str ?? '') && str.length <= 50;
};

/**
 *
 * @param {string} failureMessage reason for promise rejection when validation fails
 */
export const idFormValidator = (failureMessage) => ({
  validator: (_, value) => ID_REGEX.test(value) ? Promise.resolve() : Promise.reject(
    failureMessage
  )
});

