// Importing react libs
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Importing antd libs
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Skeleton,
  Typography,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/checkbox/style/css';
import 'antd/es/col/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/row/style/css';
import 'antd/es/select/style/css';
import 'antd/es/spin/style/css';
import 'antd/es/skeleton/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app helpers
import { filterCases } from './helpers/case';
import { IconRenderer } from './iconRenderer';
import ChartRenderer, { getChartQueries } from './chartRenderer';

// Importing app components
import { AnalysesPicker } from 'components/report/analysesPicker';
import { LogoInput, getBase64FromUrl } from 'components/report/logoInput';
import useChartFiles from 'components/case/view/useChartFiles';

// Importing app hooks
import useFlanking from 'hooks/useFlanking';
import useRegions from 'hooks/useRegions';

// Importing component style
import './preview.scss';
import { generateReport, getChartDataUrl, svgElMap } from 'modules/pdf';
import { useQueries } from '@tanstack/react-query';

// Defining constants
const { Item } = Form;
const { TextArea } = Input;
const { Title } = Typography;

function downloadReport(blob, filename) {
  var a = document.createElement('a');
  a.download = filename;
  a.style.display = 'none';
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const generateChartDataUrls = async ({ analysis, enabledCharts, existingImages }) => {
  const chartDataUrls = {};

  for await (const chartKey of enabledCharts) {
    for await (const biomaterial of analysis.biomaterials) {
      const bpAnalysisId = biomaterial['bp_analysis_id'];
      const chartId = `${chartKey}-${bpAnalysisId}`;
      if (bpAnalysisId && !existingImages[chartId]) {
        const t0 = performance.now();
        const dataUrl = await getChartDataUrl(
          `#${chartId}`,
          svgElMap[chartKey]
        );
        const t1 = performance.now();
        console.log(`Call to render ${chartId} took ${t1 - t0} milliseconds.`);
        chartDataUrls[chartId] = dataUrl;
      }
    }
  }
  return chartDataUrls;
};

export const ReportPreview = () => {
  const Language = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const [pdfBlob, setPdfBlob] = useState(false);
  const [form] = Form.useForm();
  const [pickedData, setPickedData] = useState(null);
  const organization = window.app?.user?.organization;
  const reportSettings = organization?.setting?.REPORT ?? {};
  const [includedCharts, setIncludedCharts] = useState(reportSettings['INCLUDED_CHARTS'] ?? []);
  const [isGenerating, setIsGenerating] = useState(false);
  const [chartImages, setChartImages] = useState(null);
  const i18n = (key, context) => key ? Language.get(context ?? 'report', key) : '';

  const selected = location.state?.selected ?? [];
  const biomaterials = selected.map(selectedObj => selectedObj.biomaterial);
  const biomaterialIds = biomaterials.map(biomaterial => biomaterial.id);

  const filteredCases = filterCases(selected);

  const geneRegions = useRegions({
    caseId: pickedData?.caseInfo?.id,
    isV1Array: pickedData?.caseInfo?.['is_v1_array'] ?? false
  });

  const flanking = useFlanking({ analysisSetting: pickedData?.analysis?.setting });

  useEffect(() => {
    if (!filteredCases.length) {
      navigate('/report/builder');
    }
  }, filteredCases);

  const handleReportGeneration = async ({
    icons,
    options,
    data,
    geneRegions,
  }) => {
    setPdfBlob(null);
    setIsGenerating(true);
    console.time('report-gen');
    const dataUrls = await generateChartDataUrls({
      analysis: pickedData.analysis,
      enabledCharts: includedCharts,
      existingImages: chartImages ?? {},
    });
    console.timeEnd('report-gen');
    console.time('pdf-gen');
    const { language } = window.app?.user || { language: 'en' };
    const { pageSize, margin, ...info } = options;
    const extendedData = {
      ...data,
      regions: geneRegions,
      icons,
    };
    if (info.logo) {
      info.logo = await getBase64FromUrl(info.logo);
    }
    const updatedChartImages = { ...chartImages, ...dataUrls };
    const pdfBlob = await generateReport(
      { language, pageSize, margin, i18n },
      { ...extendedData, info, chartImages: updatedChartImages },
    );
    const objUrl = URL.createObjectURL(pdfBlob);
    console.timeEnd('pdf-gen');
    setChartImages(updatedChartImages);
    setIsGenerating(false);
    setPdfBlob(objUrl);
  };

  const casesMap = filteredCases.reduce((acc, caseObj) => {
    acc[caseObj.id] = caseObj;

    return acc;
  }, {});

  const analyses = filteredCases.reduce((acc, caseObj) => {
    const pickedIds = biomaterials.map(analysisBiomaterial => analysisBiomaterial.id);
    const analyses = caseObj.analyses.filter(analysis => analysis.biomaterials.some(
      analysisBio => pickedIds.indexOf(analysisBio.id) !== -1)
    );
    acc.push(...analyses);
    return acc;
  }, []);

  const initialValues = {
    samples: biomaterials.map(
      (biomaterial) => `${biomaterial['analysis_id']}: ${biomaterial['biomaterial_name']}`
    ),
    address: organization?.address ?? '',
    disclaimer: reportSettings['DISCLAIMER'] ?? '',
    introduction: reportSettings['SAMPLE_SUMMARY'] ?? '',
    margin: 'small',
    pageSize: 'a4',
    charts: reportSettings['INCLUDED_CHARTS'] ?? [],
  };

  const handleOnChange = (analysis) => {
    const caseInfo = { ...casesMap[analysis.case] };
    delete (caseInfo.analyses);
    const analysisWithSelectedBiomaterials = {
      ...analysis,
      biomaterials: analysis.biomaterials.filter(
        (bio) => bio['biomaterial_designation'] !== '' || biomaterialIds.indexOf(bio.id) !== -1
      )
    };
    setPickedData({
      caseInfo,
      analysis: AppAPI.Analysis.parseAnalysisForReport(analysisWithSelectedBiomaterials),
    });
    setIsGenerating(false);
    setPdfBlob(null);
  };

  const { data: chartFiles = {}, isFetching: isLoadingChartFileList } = useChartFiles(
    includedCharts.length > 0 ? pickedData?.analysis?.id : null
  );

  const embryoChartFiles = pickedData?.analysis
    ? pickedData?.analysis.biomaterialsByDesignation.embryos.reduce((files, sample) => {
      const bpAnalysisId = sample['bp_analysis_id'];
      if (bpAnalysisId && chartFiles[bpAnalysisId]) {
        files[bpAnalysisId] = chartFiles[bpAnalysisId];
      }
      return files;
    }, {})
    : {};

  const chartQueries = useQueries({ queries: getChartQueries({ chartFiles, enabledCharts: includedCharts }) });
  const isLoadingChartData = chartQueries.some(query => query.isFetching);
  const isWaitingForChart = isLoadingChartData || isLoadingChartFileList;
  const { analysis, caseInfo } = pickedData ?? {};
  const fileName = `${caseInfo?.['external_id']}-${analysis?.name}.kmap_report.pdf`;
  const { logo } = organization;

  const logoWithId = useMemo(() => `${logo}?id=${(Math.random() + 1).toString(36).substring(2)}`, [logo]);

  return (
    <div className="report-preview">
      <Title>{Language.get('report', 'REPORT_SETTINGS')}</Title>
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={() => setPdfBlob(null)}
      >
        <Row>
          <Col span={12}>
            <LogoInput
              defaultLogo={logo ? logoWithId : null}
              onChange={() => setPdfBlob(null)}
              onDelete={() => setPdfBlob(null)}
            />
            <Item label={Language.get('report', 'SAMPLES')} name="samples">
              <Select
                className="sample-select"
                disabled
                mode="multiple"
              />
            </Item>
            <Item label={Language.get('report', 'INCLUDE_CHARTS')} name="charts">
              <Checkbox.Group
                onChange={(value) => {
                  setIncludedCharts(value);
                  setPickedData(null);
                  setPdfBlob(null);
                }}
                options={AppAPI.Organization.getSupportedCharts().map(chartKey => ({
                  label: Language.get('chart', chartKey),
                  value: chartKey,
                }))}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item label={Language.get('report', 'ADDRESS')} name="address">
              <TextArea rows={6}></TextArea>
            </Item>
            <Item label={Language.get('report', 'DISCLAIMER')} name="disclaimer">
              <Input />
            </Item>
            <Item label={Language.get('report', 'REPORT_INTRODUCTION')} name="introduction">
              <TextArea rows={10}></TextArea>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Title className="report-preview-title">{Language.get('report', 'REPORT_PREVIEW')}</Title>
          </Col>
          <Col span={8}>
            <Item label={Language.get('report', 'PAGE_MARGIN')} name="margin">
              <Select
                options={[
                  { label: 'Small', value: 'small' },
                  { label: 'Normal', value: 'normal' },
                ]}
              />
            </Item>
          </Col>
          <Col span={8}>
            <Item label={Language.get('report', 'PAGE_SIZE')} name="pageSize">
              <Select
                options={[
                  { label: 'A4', value: 'a4' },
                  { label: 'Legal', value: 'legal' },
                  { label: 'Letter', value: 'letter' },
                ]}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <AnalysesPicker
              analyses={analyses}
              value={pickedData?.analysis?.id}
              onChange={handleOnChange}
            />
          </Col>
          <Col>
            <IconRenderer cases={filteredCases}>
              {(icons) => (
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => pdfBlob
                    ? downloadReport(pdfBlob, fileName)
                    : handleReportGeneration({
                      icons,
                      options: form.getFieldsValue(),
                      data: pickedData,
                      geneRegions,
                    })}
                  disabled={isWaitingForChart || !pickedData}
                  loading={isGenerating}
                >
                  {Language.get('report', pdfBlob ? 'DOWNLOAD_REPORT' : 'GENERATE_REPORT')}
                </Button>
              )}
            </IconRenderer>
          </Col>
        </Row>
      </Form>
      {isGenerating ? (
        <Spin>
          <br />
          <Skeleton active />
          <br />
        </Spin>
      ) : pickedData && pdfBlob && (
        <div className="report-pdf-preview">
          <embed
            height="1100px"
            src={pdfBlob}
            type="application/pdf"
            width="100%"
          />
        </div>
      )}
      <br />
      {includedCharts.length > 0 ?
        <ChartRenderer
          chartFiles={embryoChartFiles}
          chartImages={chartImages ?? {}}
          chartQueries={chartQueries}
          enabledCharts={includedCharts}
          geneRegions={geneRegions}
          flanking={flanking}
          isFetchingChartData={isWaitingForChart}
        />
        : null
      }
    </div>
  );
};
