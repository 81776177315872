// Importing react libs
import React from 'react';

// Importing antd libs
import { Col, Row, Table, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/table/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import AppAPI from 'modules/api';
import { Icon } from 'components/biomaterial/icon';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Result = ({ biomaterial }) => {
  const Language = useLanguage();
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');

  const columns = [{
    dataIndex: ['stats', 'callRate'],
    title: Language.get('report', 'CALL_RATE'),
  }, {
    dataIndex: ['stats', 'aaRate'],
    title: Language.get('report', 'AA'),
  }, {
    dataIndex: ['stats', 'abRate'],
    title: Language.get('report', 'AB'),
  }, {
    dataIndex: ['stats', 'bbRate'],
    title: Language.get('report', 'BB'),
  }, {
    dataIndex: ['stats', 'adoRate'],
    title: Language.get('report', 'ADO'),
  }, {
    dataIndex: ['stats', 'misCallRate'],
    title: Language.get('report', 'MIS_CALL'),
  }, {
    dataIndex: ['stats', 'xHetRate'],
    title: Language.get('report', 'X_HET_RATE'),
  }, {
    dataIndex: ['stats', 'yCallRate'],
    title: Language.get('report', 'Y_CALL_RATE'),
  }, ...(isCnvEnabled ? [{
    dataIndex: ['stats', 'aneuploidyStatus'],
    title: Language.get('report', 'ANEUPLOIDY_STATUS'),
  }] : []), {
    key: 'result',
    title: Language.get('report', 'RESULT'),
    render: (biomaterial) => biomaterial && <Icon biomaterial={biomaterial} tooltip={false} />
  }];

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{Language.get('report', 'SAMPLE_RESULT')}</Title>
      </Col>
      <Col offset={1} span={23}>
        <Table
          bordered
          columns={columns}
          dataSource={[biomaterial]}
          pagination={false}
          rowKey="biomaterial_id"
        />
      </Col>
    </Row>
  );
};
