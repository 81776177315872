// Importing React and external libs
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing Antd
import { Button } from 'antd';
import 'antd/es/button/style/css';

// Importing antd icons
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing helix modules
import 'helix-modules/sDate';
import 'helix-modules/sString';

// Importing helix components
import BtnConfirm from 'helix/ui/btnConfirm';
import { default as HelixList, tableColumnFilterDate, tableColumnFilterSearch } from 'helix/ui/list';

// Importing the modules
import AppAPI from 'modules/api';
import Permission, { ROLE_MAPPER } from 'modules/permission';

// Import style
import './list.scss';

export const List = () => {
  const Language = useLanguage();
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [{
    dataIndex: 'id',
    sorter: true,
    title: Language.get('common', 'ID'),
    width: 100,
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'username',
    sorter: true,
    title: Language.get('login', 'EMAIL'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'first_name',
    sorter: true,
    title: Language.get('user', 'FIRST_NAME'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'last_name',
    sorter: true,
    title: Language.get('user', 'LAST_NAME'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'role',
    title: Language.get('user', 'ROLE'),
    sorter: true,
    render: (value) => value && Language.get('user', ROLE_MAPPER[value]),
    filters: [
      { text: 'Level 1', value: 'level_1' },
      { text: 'Level 2', value: 'level_2' }
    ],
    filterIcon: (filtered) => <FilterOutlined className={filtered ? 'filtered' : ''} />
  }, {
    dataIndex: 'updated_on',
    defaultSortOrder: 'descend',
    sorter: true,
    title: Language.get('user', 'LAST_LOGIN'),
    width: 180,
    render: (value) => value ? (new Date(value)).format() : ' - ',
    ...tableColumnFilterDate(),
  }];

  const _deleteById = async () => {
    const promises = selectedRowKeys.map((item) => AppAPI.User.delete(item.id));
    await Promise.all(promises);
    window.location.reload(false);
  };
  const onEdit = async (item) => navigate(`/user/${item.id}`);

  const reader = async ({ filters, limit, offset, order }) => {
    const mapper = {
      username: 'username__icontains',
      first_name: 'first_name__icontains',
      last_name: 'last_name__icontains',
      role: 'role__in',
    };
    filters = Object.keys(filters).reduce((newFilters, field) => {
      newFilters[mapper[field] || field] = filters[field];
      return newFilters;
    }, {});
    order = (order || '').replace('organization_name', 'organization__name') || '-updated_on';
    return await AppAPI.User.list({ filters, limit, offset, order });
  };

  if (Permission.isHostAdmin()) {
    columns.splice(2, 0, {
      dataIndex: 'organization_name',
      sorter: true,
      title: Language.get('organization', 'ORGANIZATION'),
    });
  }

  return (
    <div className="user-list">
      <h1>{Language.get('user', 'USERS')}</h1>
      <div className="actions-buttons">
        <Button
          ghost
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate('/user/new')}
        >
          {Language.get('common', 'NEW')}
        </Button>
        <BtnConfirm
          disabled={selectedRowKeys.length === 0}
          name={Language.get('common', 'DELETE')}
          onConfirm={_deleteById}
        />
      </div>
      <div className="user-table">
        <HelixList
          context="common"
          border={false}
          columns={columns}
          pageSize={20}
          rowKey="id"
          size="small"
          onEdit={onEdit}
          onSelectedRows={(selected) => setSelectedRowKeys(selected)}
          reader={reader}
        />
      </div>
    </div>
  );
};
