// Importing react libs
import React from 'react';

// Importing antd libs
import { Col, Row } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { Table } from 'components/analysis/trio/table';

// Exporting component
export const Detail = ({ mother, father, reference }) => {
  const Language = useLanguage();
  return (
    <Row gutter={16}>
      <Col span={8} >
        <Table biomaterial={mother} title={Language.get('case', 'MATERNAL_DETAILS')} />
      </Col>
      <Col span={8}>
        <Table biomaterial={father} title={Language.get('case', 'PATERNAL_DETAILS')} />
      </Col>
      <Col span={8}>
        <Table biomaterial={reference} title={Language.get('case', 'REFERENCE_DETAILS')} isReference />
      </Col>
    </Row>
  );
};
