// Importing React and react-hooks
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Importing Antd
import { Spin } from 'antd';
import 'antd/es/spin/style/css';

// Importing components
import { SettingForm } from './form';

export const Setting = () => {
  const organization = window.app?.user?.organization;
  const navigate = useNavigate();

  /**
   * Handle on after organization setting save
   * @returns {void}
  **/
  const _handleOnAfterSave = (organization) => {
    window.app.user.organization = {
      ...window.app.user.organization,
      ...organization,
    };
    navigate('/organization/setting');
  };

  return organization
    ? (<SettingForm organization={organization} onAfterSave={(organization) => _handleOnAfterSave(organization)} />)
    : (<Spin size="large" />);
};
