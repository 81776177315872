// Importing react libs
import React from 'react';

// Importing antd libs
import { Descriptions, Typography } from 'antd';
import 'antd/es/descriptions/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './table.scss';

// Defining constants
const { Item } = Descriptions;
const { Title } = Typography;

// Exporting component
export const Table = ({ title, biomaterial, isReference }) => {
  const Language = useLanguage();
  const {
    biomaterial_name: sampleId,
    biomaterial_firstname: firstName,
    biomaterial_lastname: lastName,
    biomaterial_sex: sex,
    biomaterial_pedegree: pedegree,
  } = biomaterial;

  const GENDER_MAP = {
    'm': Language.get('biomaterial', 'MALE'),
    'f': Language.get('biomaterial', 'FEMALE'),
    'u': Language.get('biomaterial', 'UNKNOWN'),
  };

  return (
    <div>
      <Title level={3}>{title}</Title>
      <Descriptions bordered className="trio-table-info" column={1} contentStyle={{ minWidth: 150 }} size="small">
        <Item label={Language.get('case', 'SAMPLE_ID')}>{sampleId}</Item>
        {!isReference && <Item label={Language.get('case', 'FIRST_NAME')}>{firstName}</Item>}
        {!isReference && <Item label={Language.get('case', 'LAST_NAME')}>{lastName}</Item>}
        {isReference && <Item label={Language.get('biomaterial', 'PEDEGREE')}>{Language.get('biomaterial', pedegree)}</Item>}
        {isReference && <Item label={Language.get('biomaterial', 'SEX')}>{GENDER_MAP[sex]}</Item>}
      </Descriptions>
    </div>
  );
};
