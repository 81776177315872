import Abstract from './abstract';

class Host extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'host';
    this._updateEndpoint();
  }

  /**
   * Get host settings
   * @param {void}
   * @returns {Object}
  **/
  getSetting() {
    const { setting } = window.app?.host ?? {
      setting: {
        DASHBOARD: [],
        FEATURES: [],
        LAYOUT: 'default',
        LANGUAGES: ['en'],
        QC_FIELDS_TO_DISPLAY: [],
        REPORT_TEMPLATE: 'standard',
        RESOURCES: [],
        SVP_FIELDS_TO_DISPLAY: [],
        THEME: 'default'
      }
    };
    return setting;
  }

}

export default new Host();
