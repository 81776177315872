// Importing app helper
import { keyValueTable } from './common';

export const trioInfo = ({ father, mother, reference, i18n }) => {
  const GENDER_MAP = {
    'f': i18n('FEMALE', 'biomaterial'),
    'm': i18n('MALE', 'biomaterial'),
    'u': i18n('UNKNOWN', 'biomaterial'),
  };

  return {
    layout: 'noBorders',
    margin: [0, 5],
    table: {
      body: [
        [
          mother
            ? keyValueTable(i18n('MATERNAL_DETAILS'), {
              [i18n('SAMPLE_ID')]: mother['biomaterial_name'],
              [i18n('FIRST_NAME')]: mother['biomaterial_firstname'],
              [i18n('LAST_NAME')]: mother['biomaterial_lastname'],
            })
            : '',
          '',
          father
            ? keyValueTable(i18n('PATERNAL_DETAILS'), {
              [i18n('SAMPLE_ID')]: father['biomaterial_name'],
              [i18n('FIRST_NAME')]: father['biomaterial_firstname'],
              [i18n('LAST_NAME')]: father['biomaterial_lastname'],
            })
            : '',
          '',
          reference
            ? keyValueTable(i18n('REFERENCE_DETAILS'), {
              [i18n('SAMPLE_ID')]: reference['biomaterial_name'],
              [i18n('GENDER')]: GENDER_MAP[reference['biomaterial_sex']],
              [i18n('PEDIGREE')]: i18n(reference['biomaterial_pedegree'], 'biomaterial'),
            })
            : '',
        ],
      ],
      headerRows: 0,
      widths: ['*', 'auto', '*', 'auto', '*'],
    },
  };
};
