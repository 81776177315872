// Importing react libs
import React, { useEffect, useMemo } from 'react';

// Importing antd libs
import {
  Collapse,
  Spin,
} from 'antd';
import 'antd/es/collapse/style/css';
import 'antd/es/spin/style/css';

// Importing Helix hooks
import useChartZoom from 'helix-hooks/chart/useChartZoom';
import useGenomePositions from 'helix-hooks/chart/useGenomePositions';
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import GenomePlot from 'helix/chart/genomePlot';
import Haploblock from 'helix/chart/haploblock';
import Karyomap from 'helix/chart/karyomap';
import Reference from 'helix/chart/haploblock/reference';

// Importing local components
import ChromosomeNavigation from 'components/case/view/navigation/chromosome';
import { EXPANDED_CHARTS_OPTIONS } from 'components/user/profile';
import { RESULT_COLORS } from 'styles/theme/colors';

// Importing app hooks
import useFlanking from 'hooks/useFlanking';
import useJson from 'hooks/useJson';
import useTsv from 'hooks/useTsv';

// Importing app modules
import AppAPI from 'modules/api';

// Importing component style
import './style.scss';

const { Panel } = Collapse;

const MemoizedGenomePlot = React.memo(GenomePlot, (prevProps, nextProps) => {
  const [prevStart, prevEnd] = prevProps.xExtent ?? [0, 0];
  const [nextStart, nextEnd] = nextProps.xExtent ?? [0, 0];
  return prevProps.data === nextProps.data && prevStart === nextStart && prevEnd === nextEnd;
});

const defaultBlock = {
  paternal: { 'initial_color': 0, positions: [] },
  maternal: { 'initial_color': 0, positions: [] },
};

const FLANKING_PADDING = 1.1;

const CaseViewSampleDetails = ({ caseObj, chrom, chromNav, isFullGenome, reference, sample, sampleChartFiles }) => {
  const Language = useLanguage();
  const geneRegions = Array.isArray(caseObj?.geneRegions)
    ? caseObj.geneRegions.map(region => ({
      chrom: { '23': 'X', '24': 'Y' }[region.chromosome] ?? region.chromosome,
      end: region['start_at'] + region.length,
      start: region['start_at'],
      mutationSite: region['mutation_site'],
    })) : [];
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');

  const analysis = caseObj.analyses.find(analysis => analysis.id === sample['analysis_id']);
  const flanking = useFlanking({ analysisSetting: analysis?.setting });

  const haploblocksFileUri = sampleChartFiles['haploblocks'];

  const { data: haploblocksData } = useJson({ url: haploblocksFileUri });
  const haploblocks = haploblocksData ?? {};

  const { chromPositions, isLoading, genomeExtent: fullGenomeExtent } = useGenomePositions('hg38');

  const { data: bafLogRData, isLoading: isLoadingBafLogR } = useTsv({
    url: sampleChartFiles['baf_logr'],
    parser: (row) => ({
      baf: Number(row.baf),
      call: row.call,
      genomicPosition: Number(row.genomic_position),
      logr: Number(row.logr),
      position: Number(row.position),
      snp: row.snp,
    })
  });

  const { data: snpsData } = useTsv({
    url: sampleChartFiles[`chr${chrom}`],
    parser: (row) => ({
      call: row.call,
      snp: row.snp,
      position: Number(row.position),
      snp_type: Number(row.snp_type),
      snp_phase: Number(row.snp_phase),
      block_phase: Number(row.block_phase),
    }),
  });

  const chromPosition = {
    start: chromPositions[chrom]?.start,
    end: chromPositions[chrom]?.end
  };
  const chromExtent = [chromPosition.start ?? 0, chromPosition.end ?? 0];

  const {
    position,
    zoomIn,
    zoomOut,
    panLeft,
    panRight,
    transformedExtent,
    reset: zoomReset,
    setExtent,
  } = useChartZoom(chromExtent);

  const usefulSnps = useMemo(() => snpsData.filter(e => e.snp_type > 0), [snpsData]);

  useEffect(() => {
    const diseaseRegion = geneRegions.find(region => region.chrom === chrom);
    if (diseaseRegion) {
      const flankingOffset = flanking * 1e6 * FLANKING_PADDING;
      setExtent([diseaseRegion.start - flankingOffset, diseaseRegion.end + flankingOffset]);
    }
  }, [chrom, isLoading]);

  if (isLoading) return <Spin />;

  const chromBlocksData = haploblocks[`chr${chrom}`] ?? defaultBlock;

  const pedegree = reference['biomaterial_pedegree'];
  const [refUpperColor, refLowerColor] = AppAPI.Biomaterial.PEDEGREE_TO_COLOR[pedegree];

  const genomeStart = chromPositions[chrom]?.genomeStart ?? 0;
  const genomeExtent = isFullGenome
    ? fullGenomeExtent
    : [genomeStart + transformedExtent[0], genomeStart + transformedExtent[1]];
  const visibleLength = genomeExtent[1] - genomeExtent[0];
  const userExpandedCharts = window.app.user?.preferences?.['expanded_charts'] ?? [
    EXPANDED_CHARTS_OPTIONS.DETAILED_HAPLOBLOCK_CHART,
  ];

  const isEmbryo = !sample['biomaterial_designation'];

  return (
    <section className='case-sample-details'>
      <div className='case-navigation'>
        {chromNav}
        {!isFullGenome && (
          <ChromosomeNavigation
            chromosome={chrom}
            end={isNaN(transformedExtent[1]) ? 0 : Math.min(transformedExtent[1], chromPosition.end)}
            position={position}
            start={isNaN(transformedExtent[0]) ? 0 : Math.max(transformedExtent[0], chromPosition.start)}
            panLeft={panLeft}
            panRight={panRight}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomReset={zoomReset}
            setExtent={setExtent}
          />
        )}
      </div>
      <Collapse defaultActiveKey={userExpandedCharts} destroyInactivePanel>
        <Panel header={Language.get('chart', 'KARYOMAP')} key={EXPANDED_CHARTS_OPTIONS.KARYOMAP}>
          <Karyomap
            blockColors={[
              RESULT_COLORS.NO_RESULT.fill,
              RESULT_COLORS.PATERNAL_1.fill,
              RESULT_COLORS.PATERNAL_2.fill,
              RESULT_COLORS.MATERNAL_1.fill,
              RESULT_COLORS.MATERNAL_2.fill,
            ]}
            blockData={haploblocks}
            flanking={flanking}
            geneRegions={geneRegions}
            visibleRegion={isFullGenome ? null : {
              chrom,
              start: transformedExtent[0],
              end: transformedExtent[1]
            }}
            useDevicePixelRatio={true}
          />
        </Panel>
        <Panel
          header={isEmbryo && !isFullGenome ? Language.get('chart', 'DETAILED_HAPLOBLOCK_CHART') : Language.get('chart', 'HAPLOBLOCK_REFERENCE')}
          key={EXPANDED_CHARTS_OPTIONS.DETAILED_HAPLOBLOCK_CHART}
        >
          {isEmbryo && !isFullGenome && (
            <Haploblock
              blocks={{
                father: {
                  initial: Math.max(0, chromBlocksData.paternal?.['initial_color']),
                  positions: chromBlocksData.paternal?.positions ?? [],
                },
                mother: {
                  initial: Math.max(0, chromBlocksData.maternal?.['initial_color']),
                  positions: chromBlocksData.maternal?.positions ?? [],
                },
              }}
              chrom={`${chrom}`}
              diseaseRegions={geneRegions.filter(region => region.chrom === chrom)}
              domain={transformedExtent}
              enableSnps={true}
              extent={chromExtent}
              flanking={flanking}
              snps={usefulSnps}
              useDevicePixelRatio={true}
              snpYOffset={18}
            />
          )}
          <div style={{ padding: '1em' }}>
            <Reference lowerColor={refLowerColor} upperColor={refUpperColor} />
          </div>
        </Panel>
        {isCnvEnabled && (
          <Panel header={Language.get('chart', 'CNV_CHART')} key={EXPANDED_CHARTS_OPTIONS.CNV_CHART}>
            CNV Chart
          </Panel>
        )}
        <Panel header={Language.get('chart', 'LOGR_CHART')} key={EXPANDED_CHARTS_OPTIONS.LOGR_CHART}>
          {isLoadingBafLogR ? <Spin /> :
            <MemoizedGenomePlot
              data={bafLogRData}
              diseaseRegions={geneRegions}
              flanking={flanking}
              gradientColors={!isFullGenome && ['#ffffff']}
              pointSize={visibleLength < (flanking * 1e6) ? 20 : 4}
              xExtent={genomeExtent}
              yKey="logr"
              yExtent={[-2, 2]}
              useDevicePixelRatio={true}
            />
          }
        </Panel>
        <Panel header={Language.get('chart', 'B_ALLELE_CHART')} key={EXPANDED_CHARTS_OPTIONS.B_ALLELE_CHART}>
          {isLoadingBafLogR ? <Spin /> :
            <MemoizedGenomePlot
              data={bafLogRData}
              diseaseRegions={geneRegions}
              flanking={flanking}
              gradientColors={!isFullGenome && ['#ffffff']}
              pointSize={visibleLength < (flanking * 1e6) ? 20 : 4}
              yKey="baf"
              xExtent={genomeExtent}
              yExtent={[0, 1]}
              useDevicePixelRatio={true}
            />
          }
        </Panel>
      </Collapse>
    </section>
  );
};

export default CaseViewSampleDetails;
