// Importing react libs
import React, { useEffect, useState } from 'react';

// Importing antd libs
import { CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  message,
  Modal,
  Tooltip,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/message/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/tooltip/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import { default as HelixList, tableColumnFilterSearch } from 'helix/ui/list';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app components
import { BiomaterialForm } from 'components/biomaterial';

// Importing component style
import './list.scss';

// Exporting component
export const List = (props) => {
  const { case: caseObj, onUpdate } = props;
  const Language = useLanguage();
  const [form] = Form.useForm();
  const [biomaterial, setBiomaterial] = useState();
  const [hasAnalysis, setHasAnalysis] = useState(false);
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const columns = [{
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    title: Language.get('biomaterial', 'SAMPLE_ID'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'designation',
    key: 'designation',
    sorter: true,
    title: Language.get('biomaterial', 'DESIGNATION'),
    render: (value) => typeof value === 'string' && Language.get('biomaterial', value || 'SAMPLE'),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'pedegree',
    key: 'pedegree',
    sorter: true,
    title: Language.get('biomaterial', 'PEDEGREE'),
    render: (value) => Language.get('biomaterial', value),
    ...tableColumnFilterSearch(),
  }, {
    dataIndex: 'barcode',
    key: 'barcode',
    sorter: true,
    title: Language.get('biomaterial', 'BARCODE'),
  }, {
    dataIndex: 'bio_type',
    key: 'bio_type',
    sorter: true,
    title: Language.get('biomaterial', 'TYPE'),
    render: (value) => Language.get('biomaterial', value),
  }, {
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    title: Language.get('biomaterial', 'STATUS'),
    ...tableColumnFilterSearch(),
  }];

  const _clone = async (id) => {
    const res = await AppAPI.Biomaterial.clone(id);
    if (res.error) return message.error(Language.get('biomaterial', res.msg));
    message.success(Language.get('common', 'DATA_SAVED_SUCCESFULLY'));
    onUpdate(); // propagate updates to reload gene list
    setListKey((Math.random() + 1).toString(36).substring(2));
  };
  const onDelete = async (item) => {
    const res = await AppAPI.Biomaterial.delete(item.id);
    if (res.error) {
      res.msg = Language.get('biomaterial', res.msg);
      return res;
    }
    onUpdate(); // propagate updates to reload gene list
  };
  const onEdit = async (item) => { setBiomaterial(item); setShowAddEditModal(true); };
  const reader = async ({ filters, limit, offset, order }) => {
    const caseId = caseObj?.id;
    if (caseId) {
      const mapper = {
        name: 'name__icontains',
        barcode: 'barcode__icontains',
        pedegree: 'pedegree__icontains',
        requision: 'requision__icontains',
        status: 'status__icontains',
        'bio_type': 'bio_type__icontains',
      };
      filters = Object.keys(filters).reduce((newFilters, field) => {
        newFilters[mapper[field] || field] = filters[field];
        return newFilters;
      }, {});
      filters['case'] = [caseId];
      return await AppAPI.Biomaterial.list({ filters, limit, offset, order });
    }
    return { result: [] };
  };

  useEffect(() => {
    if (caseObj) {
      setHasAnalysis(caseObj.analyses.length > 0);
      setListKey((Math.random() + 1).toString(36).substring(2));
    }
  }, [caseObj]);

  return (
    <div className="biomaterial-list-wrapper">
      <div className="title-with-action">
        <h2>{Language.get('biomaterial', 'SAMPLES')}</h2>
        <Button
          disabled={!caseObj?.id}
          size="small"
          type="primary"
          onClick={() => { form.resetFields(); setBiomaterial(undefined); setShowAddEditModal(true); }}
        >
          {Language.get('common', 'ADD')}
        </Button>
      </div>
      <HelixList
        actions={[
          (record) => (
            <Tooltip title={Language.get('common', 'CLONE_HOVER_INFO')}>
              <Button
                className="icon"
                icon={<CopyOutlined />}
                key="clone"
                size="small"
                onClick={() => _clone(record.id)}
              />
            </Tooltip>
          )
        ]}
        columns={columns}
        context="common"
        dataField="results"
        dataCountField="count"
        disableDelete={hasAnalysis}
        key={listKey}
        name="biomaterials"
        reader={reader}
        rowKey="id"
        showPagination={false}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        open={showAddEditModal}
        title={Language.get('common', biomaterial ? 'EDIT' : 'ADD')}
        width={1200}
        onOk={() => form.submit()}
        onCancel={() => Modal.confirm({
          content: Language.get('common', 'ARE_YOU_SURE_CANCEL_EDITION'),
          cancelText: Language.get('common', 'NO'),
          icon: <ExclamationCircleOutlined />,
          okText: Language.get('common', 'YES'),
          onOk: () => {
            form.resetFields();
            setBiomaterial(undefined);
            setShowAddEditModal(false);
          },
        })}
      >
        <BiomaterialForm
          biomaterial={biomaterial}
          case={caseObj}
          form={form}
          onUpdate={() => {
            setListKey((Math.random() + 1).toString(36).substring(2));
            setShowAddEditModal(false);
            onUpdate(); // propagate updates to reload gene list
          }}
        />
      </Modal>
    </div>
  );
};
