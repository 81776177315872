// Importing react libs
import React from 'react';

// Importing recharts components
import {
  CartesianGrid,
  Legend,
  LineChart,
  Line as RechartsLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './line.scss';

// Exporting component
export const Line = (props) => {
  const {
    context,
    data,
    dataset,
    height,
    id,
    title,
    width,
  } = props;
  const Language = useLanguage();
  const i18n = (key) => key && Language.get(context || 'line', key);
  return (
    <div
      className="line-chart"
      id={id}
    >
      <LineChart
        width={width}
        height={height}
        data={data}
      >
        {title && <h2>{title}</h2>}
        <CartesianGrid strokeDasharray="3 3" />
        {dataset.map((line) => (
          <RechartsLine
            activeDot={line.dot}
            dataKey={line.key}
            key={line.key}
            stroke={line.stroke}
            strokeWidth={line.width}
            type="linear"
          />
        ))}
        <XAxis dataKey="domain" />
        <YAxis />
        <Legend
          align='right'
          formatter={i18n}
          height={36}
          iconType='square'
          layout='vertical'
          verticalAlign="top"
          wrapperStyle={{ right: 0 }}
        />
        <Tooltip formatter={(value, name) => [value, i18n(name)]} />
      </LineChart>
    </div>
  );
};
