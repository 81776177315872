// Importing react libs
import React from 'react';

// Importing antd libs
import { Col, Row, Typography } from 'antd';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import { Description } from 'components/case/description';
import { Detail } from 'components/analysis/trio/detail';
import { Summary as BiomaterialSummary } from 'components/biomaterial/summary';
import { Summary as SignoffHistory } from 'components/analysis/signoff/summary';

// Defining constants
const { Title } = Typography;

export const Summary = (props) => {
  const {
    analysis,
    biomaterials,
    caseObj,
    regions,
  } = props;
  const Language = useLanguage();
  return (
    <Row>
      <Col span={24}>
        <Title level={2}>{Language.get('case', 'CASE_SUMMARY')}</Title>
      </Col>
      <Col offset={1} span={23}>
        <Row>
          <Col span={24}>
            <Description
              analysisExternalId={analysis['analysis_external_id']}
              caseId={caseObj['external_id']}
              centre={caseObj.centre}
              consultant={caseObj.consultant}
              regions={regions}
            />
          </Col>
          <Col span={24}>
            <Detail
              mother={biomaterials.mother}
              father={biomaterials.father}
              reference={biomaterials.reference}
            />
          </Col>
          <Col span={24}>
            <BiomaterialSummary biomaterials={biomaterials.embryos} />
          </Col>
          <Col span={24}>
            <SignoffHistory history={caseObj['signoff_history']} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
