// Importing react libs
import React, { Fragment } from 'react';

// Importing helix-hooks
import useLanguage from 'helix-hooks/language';

// Importing components
import { RecentBeadchipsList } from 'components/plate/recentBeadchipList';

export const Beadchip = () => {
  const Language = useLanguage();
  return (
    <Fragment>
      <h1>{Language.get('header', 'BEAD_ARRAYS')}</h1>
      <RecentBeadchipsList />
    </Fragment>
  );
};
