// Importing JsBarcode
import JsBarcode from 'jsbarcode';

// Importing Helix modules
import { COLORS } from 'helix-modules/pdfMake';
import 'helix-modules/sString';

// Importing Helix components
import { DEFAULT_CFG, getCoord } from 'helix/chart/plate';

// Constants
const MAX_KEY_VALUE_LENGTH = 13;

COLORS.VL_GRAY_3 = '#ececec';
COLORS.VL_LIGHT_BLUE = '#9fcdf0';

export const beadchipCoord = ({ y }) => `R0${y + 1}C01`;

export default class PlatePDF {

  constructor({ beadchips, plateId, settings, wells }) {
    this.beadchips = beadchips;
    this.plateId = plateId;
    this.settings = settings;
    this.wells = wells || [];
  }

  /**
   * Generate the beadchips for lab planner and pooling guide
   * @returns {object} the title and key of the plate preview table
   **/
  chips() {
    const emptyPage = () => ({
      columns: [],
      margin: [0, 30, 0, 0],
    });
    const columns = Object.keys(this.beadchips);
    const beadchips = columns.reduce((items, x, index) => {
      const { barcode } = this.beadchips[x];
      const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      JsBarcode(svgNode, barcode, {
        displayValue: false,
        height: 20,
        marginLeft: 0,
        marginRight: 0,
        width: 1,
        xmlDocument: document,
      });
      const page = items[items.length - 1];
      page.columns.push({
        layout: 'beadchip',
        table: {
          body: [
            ...Array(DEFAULT_CFG.plate.rows).fill(DEFAULT_CFG.plate.rows).map(
              (_, y) => this._beadchipCell(x, y)
            ),
            [{ border: [0, 0, 1, 0], text: ' ' }, { alignment: 'center', fit: [120, 20], svg: svgNode.outerHTML }],
            [{ border: [0, 0, 0, 0], text: ' ' }, { alignment: 'center', border: [0, 0, 0, 0], text: barcode }],
          ],
          margin: [0, 0, 0, 0],
          heights: Array(10).fill(20),
          widths: [45, 120],
        },
      });
      // new page every 4 beadchips
      if ((index + 1) % 4 === 0) {
        items.push({ pageBreak: 'before', text: '' });
        items.push(this.header({}));
        items.push(emptyPage());
      }
      return items;
    }, [{ pageBreak: 'before', text: '' }, this.header({}), emptyPage()]);
    return beadchips;
  }

  /**
   * @param {number} currentPage - current page number
   * @param {number} pageCount - total no. of pages
   * @returns {object} pdfMake doc definition for footer table
   */
  footer(currentPage, pageCount) {
    const { Language } = this.settings;
    return {
      table: {
        body: [
          [{ alignment: 'center', fillColor: COLORS.VL_GRAY_3, text: new Date().format() }],
          [{
            columnGap: 2,
            columns: [{
              alignment: 'left',
              text: `${Language.get('plate', 'PAGE')} ${currentPage} ${Language.get('common', 'OF')} ${pageCount}`,
              width: 'auto',
            }, {
              alignment: 'right',
              text: `${Language.get('common', 'VITROLIFE_TRINITY')}, ${Language.get('common', 'RESEARCH_USE_ONLY')}`,
              width: '*',
            }],
            fillColor: COLORS.VL_LIGHT_BLUE,
            style: 'xxsmall',
          }],
        ],
        headerRows: 0,
        widths: ['*'],
      }
    };
  }

  /**
   * Generate the beadchip layout
   * @params {void}
   * @returns {object} the beadchip layout
   **/
  getBeadchipLayout() {
    return {
      beadchip: {
        hLineWidth: (index, node) => (index === 0 || index === node.table.body.length - 1) ? 1 : 0,
        vLineWidth: () => 1,
        hLineColor: () => COLORS.BLUE,
        vLineColor: () => COLORS.BLUE,
        paddingBottom: (index, node) => index === node.table.body.length ? 4 : 2,
        paddingLeft: () => 0,
        paddingRight: () => 0,
        paddingTop: (index) => index === 0 ? 4 : 2,
      },
    };
  }

  /**
   * Generate the header of lab planner and pooling guide
   * @params {object}
   * showKeys {boolean} to display or not key references in the header
   * @returns {object} the title and key of the plate preview table
   **/
  header({ showKeys }) {
    const { Language, well: { display: { fields } } } = this.settings
      || { well: { display: { fields: [] } } };
    const stack = fields.filter(field => field.name !== 'blank').map((field) => ({
      alignment: 'left',
      color: field.color,
      margin: field.margin || [0, 2, 0, 0],
      style: 'tableCell',
      text: Language.get('plate', field.name),
    }));
    const response = {
      columnGap: 10,
      columns: [],
      margin: [0, 0, 0, 0]
    };

    // title
    response.columns.push({
      stack: [
        {
          style: 'h2',
          text: Language.get('plate', 'KMAP_POOLING_GUIDE'),
        },
        { style: 'secondary', text: `${Language.get('plate', 'PLATE_ID')}: ${this.plateId}` },
      ],
      width: '*',
    });

    // adding keys
    if (showKeys) {
      response.columns.push({ alignment: 'right', bold: true, text: `${Language.get('plate', 'KEY')}: `, width: 'auto' });
      response.columns.push({ alignment: 'right', stack, width: 'auto' });
    }
    return response;
  }

  /**
   * Generate the plate notch
   * @params {void}
   * @returns {object} pdfMake table definition
   **/
  notch() {
    return {
      fit: [30, 30],
      svg: `
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon fill="${COLORS.GRAY_1}" points="0 0, 0 100, 100 100"></polygon>
        </svg>
      `
    };
  }

  /**
   * Generate the plate layout
   * @params {void}
   * @returns {object} pdfMake table definition for the plate planner
   **/
  table() {
    const { columns, rows } = this.settings;
    const body = [Array.from(Array(columns + 1), (_, index) => ({ text: `${index || ''}`, style: 'tableHeader' }))];
    for (let y = 0; y < rows; y++) {
      const cells = [{ text: y.toAlphabet(), style: 'tableHeader', margin: [0, 8, 0, 0] }];
      for (let x = 0; x < columns; x++) {
        cells.push(this._plateCell(x, y));
      }
      body.push(cells);
    }
    return {
      layout: 'noBorders',
      table: {
        body,
        heights: Array.from(body, (_, index) => index === 0 ? 20 : 50),
      }
    };
  }

  _beadchipCell(x, y) {
    const alignment = [0, 1, 4, 5].includes(y) ? 'left' : 'right';
    const cellTypeX = { left: 0, right: 115 }[alignment];
    const cellType = `<rect fill="${COLORS.BLUE}" height="20" stroke="${COLORS.BLUE}" width="5" x="${cellTypeX}" y="1"></rect>`;
    const { name } = this.beadchips[x][y] || {};
    const coord = getCoord({ x: Number(x), y });
    const text = ((name ? `${coord} (${name})` : '')).compact(MAX_KEY_VALUE_LENGTH);
    return [{ border: [0, 0, 1, 0], text: beadchipCoord({ y }) }, {
      alignment,
      stack: [{
        relativePosition: { x: 0, y: 0 },
        svg: `
          <svg viewBox="0 0 120 22">
            <rect
              fill="${text ? COLORS.GREEN : COLORS.WHITE}"
              height="20"
              rx="5"
              ry="5"
              stroke="${COLORS.BLUE}"
              stroke-width="1"
              width="113"
              x="${{ left: 1, right: 6 }[alignment]}"
              y="1"
            ></rect>
            ${cellType}
            <text x="50%" y="50%" font-size="0.7em" dominant-baseline="middle" text-anchor="middle">${text}</text>
          </svg>
        `,
      }]
    }];
  }

  /**
   * Helper to generate a the plate cell
   * @param {number} x horizontal index
   * @param {number} y vertical index
   * @returns {object} pdfMake table definition
   **/
  _plateCell(x, y) {
    const { display: { fields }, positionField } = this.settings?.well
      || { display: { fields: [] }, positionField: '' };
    const coord = getCoord({ x, y });
    const well = this.wells.find((well) => well[positionField] === coord);
    return {
      alignment: 'center',
      stack: [
        {
          fit: [56, 60],
          relativePosition: { x: 0, y: 0 },
          svg: this._plateCellSVG(
            x % 2 === 0 ? COLORS.GRAY_4 : COLORS.WHITE,
            well ? coord : '',
          ),
        },
        ...fields.filter(field => field.name !== 'blank').map((field) => ({
          color: field.color,
          margin: field.margin || [0, 2, 0, 0],
          style: 'tableCell',
          text: `${well ? well[field.name] ?? '-' : ''}`.compact(MAX_KEY_VALUE_LENGTH),
        })),
      ],
    };
  }

  /**
   * Helper to generate the border of a plate cell
   * @param {string} fillColor - background color of svg
   * @param {number} cellIndex - index of the cell
   * @returns {string} string for an svg that creates a rounded rectangle
   **/
  _plateCellSVG(fillColor = COLORS.WHITE, cellIndex) {
    return (
      `<svg width="123" height="113" viewBox="0 0 123 113" xmlns="http://www.w3.org/2000/svg">
        <rect
          fill="${fillColor}"
          height="100"
          rx="12"
          stroke="${COLORS.BLUE}"
          stroke-width="2"
          width="120"
          x="2"
          y="12"
        />
        ${cellIndex ? `
            <rect fill="${fillColor}" height="3" x="12" y="10.5" width="80" />
            <text text-anchor="middle" x="50%" y="20">${cellIndex}</text>`
        : ''}
      </svg>`
    );
  }

}
