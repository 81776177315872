import Analysis from './analysis';
import Biomaterial from './biomaterial';
import Case from './case';
import Context from './context';
import Host from './host';
import DiseaseDictionary from './diseaseDictionary';
import GeneRegion from './geneRegion';
import Language from './language';
import Log from './log';
import Role from './role';
import Organization from './organization';
import Plate from './plate';
import Subject from './subject';
import User from './user';

const AppAPI = {
  Analysis,
  Biomaterial,
  Case,
  Context,
  DiseaseDictionary,
  GeneRegion,
  Host,
  Language,
  Log,
  Organization,
  Plate,
  Role,
  Subject,
  User,
};
export default AppAPI;
