/*global RELEASE_VERSION*/

// Importing React and external libs
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing CSS
import './footer.scss';

export const Footer = () => {
  const Language = useLanguage();

  // Update the footer height css variable
  useEffect(() => {
    const footer = document.querySelector('footer.footer-content');
    if (footer) {
      const rootEl = document.getElementById('root');
      rootEl.style.setProperty('--footer-height', `${footer.clientHeight}px`);
    }
  }, []);

  const chain = ` | ${Language.get('common', 'RESEARCH_USE_ONLY')}` +
    ` | ${Language.get('common', 'COPYRIGHT')}` +
    ` | ${Language.get('common', 'RELEASE')} v${RELEASE_VERSION}`;
  return (
    <footer className="footer-content">
      <div className="image-logo"></div>
      <div className="footer-text">
        <Link target="_blank" to="/terms">{Language.get('common', 'TERMS')}</Link>{chain}
      </div>
    </footer>
  );
};
