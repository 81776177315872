import Abstract from './abstract';

class Subject extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'biomaterial/subject';
    this._updateEndpoint();
  }
}

export default new Subject();
