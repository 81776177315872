// Importing react libs
import React from 'react';

// Importing antd libs
import { Form, Input } from 'antd';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components
import AppAPI from 'modules/api';

// Importing component style
import './form.scss';

// Defining constants
const { Item } = Form;
const { TextArea } = Input;

const MAX_LENGTH_NOTES = 500;

// Exporting component
export const SignoffForm = ({ caseObj, form }) => {
  const Language = useLanguage();

  const history = AppAPI.Case.formatSignoffHistory(caseObj['signoff_history']);

  return (
    <div className="signoff-form">
      <Form
        autoComplete="off"
        form={form}
        initialValues={{ notes: '', 'signoff_history': history }}
        layout="vertical"
        name="signoff-form"
        validateMessages={{ required: Language.get('common', 'NOT_VALID_EMPTY_VALUE') }}
      >
        <Item
          label={Language.get('common', 'NOTES')}
          name="notes"
          rules={[{
            max: MAX_LENGTH_NOTES,
            message: `${Language.get('common', 'CANNOT_BE_LARGER_THAN')} ${MAX_LENGTH_NOTES}`,
          }]}
        >
          <TextArea className="signoff-form-notes" rows={4} showCount />
        </Item>
        <Item label={Language.get('signoff', 'HISTORY')} name="signoff_history">
          <TextArea className="signoff-form-history" disabled={true} rows={4} />
        </Item>
        <Item label={Language.get('login', 'USERNAME')} name="username" rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label={Language.get('login', 'PASSWORD')} name="password" rules={[{ required: true }]}>
          <Input type="password" />
        </Item>
      </Form>
    </div>
  );
};
