// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Importing antd libs
import {
  Alert,
  Button,
  message,
  Popconfirm,
  Radio,
  Select,
  Table
} from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/button/style/css';
import 'antd/es/message/style/css';
import 'antd/es/popconfirm/style/css';
import 'antd/es/radio/style/css';
import 'antd/es/select/style/css';
import 'antd/es/table/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components
import Plate, { DEFAULT_CFG, WELLS_COLOR, getCoord, getEmptyMatrix } from 'helix/chart/plate';
import { DND_ITEM_TYPE_WELL } from 'helix/chart/plate/model/well';

// Importing component style
import './builder.scss';

// Exporting component
const Builder = (props) => {
  const { plate, wells, startingColumn, onSetStartingColumn, onUpdate } = props;
  const Language = useLanguage();
  const [data, setData] = useState();
  const [listKey, setListKey] = useState((Math.random() + 1).toString(36).substring(2));
  const [showNoBloodTypeInRowAWarning, setShowNoBloodTypeInRowAWarning] = useState(false);
  const [view, setView] = useState('PLATE_GRAPHIC');

  const columns = [
    { dataIndex: 'case_external_id', title: Language.get('case', 'CASE_ID') },
    { dataIndex: 'name', title: Language.get('biomaterial', 'SAMPLE_ID') },
    { dataIndex: 'barcode', title: Language.get('plate', 'BARCODE') },
    { dataIndex: 'requisition', title: Language.get('plate', 'REQUISITION') },
    { dataIndex: 'tube_id', title: Language.get('biomaterial', 'TUBE_ID') },
    { dataIndex: 'bio_type', title: Language.get('biomaterial', 'BIO_TYPE'), render: (value) => Language.get('biomaterial', value) },
    { dataIndex: 'plate_position', title: Language.get('plate', 'WELL_POSITION') },
  ];

  const _inPlate = (item) => wells.find(
    (inPlate) => inPlate.x === item.x && inPlate.y === item.y
  );

  const _setView = (event) => {
    setView(event.target.value);
  };

  useEffect(() => {
    if (plate && wells) {
      const data = getEmptyMatrix({}).map((item) => {
        const disable = plate.pooled || item.fixed;
        const well = _inPlate(item);
        if (well) {
          item.color = disable ? WELLS_COLOR.unavailable : WELLS_COLOR.used;
          item.label = `${getCoord(item)} - ${well['case_external_id']} - ${well.name}`;
          item.id = well.id;
        }
        if (disable) return item;
        return {
          ...item,
          onClick: () => {
            const state = 'available';
            item.color = WELLS_COLOR[state];
            item.label = `${getCoord(item)} - ${state}`;
            onUpdate(wells.filter((well) => well.id !== item.id));
          },
          onDrop: (obj) => {
            if (item.type === DND_ITEM_TYPE_WELL) {
              if (item.state === 'unavailable') {
                return message.error(Language.get('plate', 'WELL_NOT_AVAILABLE'));
              }
              if (_inPlate(item)) {
                return message.error(Language.get('plate', 'WELL_IN_USE'));
              }
              if (obj.id) {
                if (wells.some((well) => well.name === obj.name)) {
                  message.error(Language.get('plate', 'NOT_VALID_DUPLICATED_SAMPLE_NAME'));
                  return;
                }
                obj['plate_position'] = getCoord(item);
                onUpdate([...wells, { ...item, ...obj }]);
              }
            }
          },
        };
      });
      setData(data);
      setListKey((Math.random() + 1).toString(36).substring(2));
    }
  }, [plate, wells]);

  useEffect(() => {
    if (wells) {
      setShowNoBloodTypeInRowAWarning(
        wells.some(
          (well) => well.y === 0 && well.id && !/gdna/i.test(well['bio_type'])
        )
      );
    }
  }, [wells]);

  return (
    <div className="plate-builder">
      <div className="actions">
        <Radio.Group buttonStyle="solid" defaultValue="PLATE_GRAPHIC" onChange={_setView}>
          <Radio.Button value="PLATE_GRAPHIC">{Language.get('plate', 'PLATE_GRAPHIC')}</Radio.Button>
          <Radio.Button value="TABLE_BY_WELL">{Language.get('plate', 'TABLE_BY_WELL')}</Radio.Button>
        </Radio.Group>

        {!plate?.pooled && (
          <Fragment>
            <div className="column-selector">
              <span className="label">{Language.get('plate', 'STARTING_COLUMN')}</span>
              <Select defaultValue={startingColumn ?? 1} showSearch onChange={onSetStartingColumn}>
                {[...Array(DEFAULT_CFG.plate.cols)].map((_, index) => {
                  const value = index + 1;
                  return <Select.Option key={value}>{value}</Select.Option>;
                })}
              </Select>
            </div>

            <div className="clear-plate">
              <Popconfirm
                cancelText={Language.get('common', 'NO')}
                okText={Language.get('common', 'YES')}
                title={Language.get('plate', 'CONFIRM_CLEAR_PLATE')}
                onConfirm={() => onUpdate([])}
              >
                <Button type="primary">{Language.get('plate', 'CLEAR')}</Button>
              </Popconfirm>
            </div>
          </Fragment>
        )}
      </div>
      <div className="plate-view">
        {view === 'PLATE_GRAPHIC' && data && (
          <Plate data={data} height={300} id="plate-builder" width={400} />
        )}
        {view === 'TABLE_BY_WELL' && (
          <Table
            columns={columns}
            dataSource={wells}
            key={listKey}
            pagination={false}
            rowKey="id"
            size="small"
          />
        )}
      </div>
      {showNoBloodTypeInRowAWarning && (
        <Alert message={Language.get('plate', 'ROW_A_NO_BLOOD_TYPE')} showIcon type="warning" />
      )}
    </div>
  );
};

Builder.propTypes = {
  wells: PropTypes.array,
  onSetStartingColumn: PropTypes.func,
  onUpdate: PropTypes.func,
};

Builder.defaultProps = {
  wells: [],
  onSetStartingColumn: () => { },
  onUpdate: () => { },
};

export { Builder };
