// Importing app helper
import { reportItem } from './common';

export const caseSummary = ({ analysisInfo, caseInfo, i18n }) => {
  return [{
    layout: 'noBorders',
    table: {
      body: [
        [
          reportItem(i18n('CASE_ID'), caseInfo['external_id']),
          reportItem(i18n('CONSULTANT'), caseInfo.consultant),
        ],
        [
          reportItem(i18n('ANALYSIS_ID'), analysisInfo['analysis_external_id']),
          reportItem(i18n('REFERRING_CENTRE'), caseInfo.centre),
        ],
      ],
      headerRows: 0,
      widths: ['*', '*', '*'],
    },
  }];
};
