// Importing React and external libs
import React from 'react';
import { useDrag } from 'react-dnd';

// Importing antd libs
import { Input, Button, Spin, Tree , Typography } from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/input/style/css';
import 'antd/es/spin/style/css';
import 'antd/es/tree/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing antd icons
import { HolderOutlined, ExportOutlined } from '@ant-design/icons';

// Importing component style
import './search.scss';

// Exporting DND drop type
export const DND_ITEM_TYPE_BIOMATERIAL = 'BIOMATERIAL';

// Defining constants
const { Title } = Typography;

const Biomaterial = (props) => {
  const { analysisId, caseObj, disabled, biomaterial } = props;
  const Language = useLanguage();
  const [{ dragging }, drag] = useDrag({
    canDrag: !disabled,
    item: { biomaterial, caseObj, analysisId },
    type: DND_ITEM_TYPE_BIOMATERIAL,
    collect: (monitor) => ({
      dragging: monitor.isDragging() ? 'dragging' : '',
    }),
  });

  return (
    <div className={`draggable-source ${disabled && 'disabled'}`}>
      <div className={dragging} ref={drag}>
        <HolderOutlined />{Language.get('biomaterial', 'SAMPLE')} - {biomaterial['biomaterial_name']}
      </div>
    </div>
  );
};

const BulkAddButton = (props) => {
  const { analysis, caseObj, onBulkAdd } = props;
  const handleClick = (event) => {
    event.stopPropagation();
    const biomaterials = analysis.biomaterials.filter(
      (biomaterial) => biomaterial['biomaterial_designation'] === ''
    );
    onBulkAdd(biomaterials, caseObj, analysis.id);
    return false;
  };

  return <Button size="small" icon={<ExportOutlined />} onClick={handleClick}/>;
};

const { DirectoryTree } = Tree;

// Exporting component
export const Search = (props) => {
  const {
    cases,
    isBiomaterialDisabled,
    searchPlaceholder,
    title,
    onBulkAdd,
    onSearch,
  } = props;
  const Language = useLanguage();

  const treeData = cases !== 'loading' && cases.map((caseObj) => ({
    children: (caseObj.analyses || []).map((analysis) => ({
      children: (analysis.biomaterials || []).reduce((acc, biomaterial) => {
        if (biomaterial['biomaterial_designation'] === '') {
          return [
            ...acc,
            {
              isLeaf: true,
              key: `case_${caseObj['external_id']}_analysis_${analysis.id}_biomaterial_${biomaterial.biomaterial_id}`,
              selectable: false,
              title: (
                <Biomaterial
                  analysisId={analysis.id}
                  biomaterial={biomaterial}
                  caseObj={caseObj}
                  disabled={isBiomaterialDisabled(biomaterial, caseObj, analysis.id)}
                />
              ),
              icon: () => { },
            },
          ];
        }
        return acc;
      }, []),
      key: `case_${caseObj['external_id']}_analysis_${analysis.id}`,
      selectable: false,
      title: (
        <div className='tree-parent'>
          {Language.get('analysis', 'ANALYSIS_ID')} - {analysis.id}
          <BulkAddButton analysis={analysis} caseObj={caseObj} onBulkAdd={onBulkAdd} />
        </div>
      ),
    })),
    key: `case_${caseObj['external_id']}`,
    selectable: false,
    title: `${caseObj['external_id']} - (${caseObj['subject_id']})`,
  }));

  return (
    <div className="case-selector">
      <Title level={2}>{title}</Title>
      <Input placeholder={searchPlaceholder} onChange={(event) => onSearch(event.target.value)} />
      {cases === 'loading'
        ? <Spin />
        : cases.length > 0 && (
          <DirectoryTree
            autoExpandParent={true}
            defaultExpandedKeys={[treeData[0]?.key]}
            defaultExpandParent={true}
            treeData={treeData}
          />
        )
      }
    </div>
  );
};
