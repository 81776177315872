import { Icon } from 'components/biomaterial';
import React, { useEffect, useRef } from 'react';

export const Markup = ({ bio, onRender }) => {
  const ref = useRef();
  useEffect(() => {
    const div = ref.current;
    const icon = div?.querySelector('svg');
    if (icon) {
      computedStyleToInline(icon, [
        'fill',
        'stroke',
        'stroke-width',
        'stroke-dasharray',
        'opacity'
      ]);

      onRender(icon.parentElement.innerHTML);
    }
  });

  return (
    <div ref={ref} style={{ display: 'none' }}>
      <Icon biomaterial={bio} />
    </div>
  );
};

function computedStyleToInline(node, properties) {
  if (node) {
    const computedStyle = getComputedStyle(node);
    properties.forEach((property) => {
      node.style[property] = computedStyle.getPropertyValue(property);
    });

    [...node.children].forEach((child) =>
      computedStyleToInline(child, properties)
    );
  }
}
