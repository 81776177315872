window.BASE_API_URL = window.location.origin.replace(':3000', '');

const OAuth = new (class OAuth {
  EXPIRES_AT_KEY = 'oauth_expires_at';

  /**
   * Getter stored oauth data
   * @param {string} field
   * @return {string}
  **/
  get(field) {
    const oauth = JSON.parse(window.localStorage.getItem('oauth')) ?? {};
    return field ? oauth[field] : oauth;
  }

  /**
   * Setter stored oauth data
   * @param {object} oauth
   * @return {void}
  **/
  set(oauth) {
    window.localStorage.setItem('oauth', JSON.stringify(oauth));
    if (oauth['expires_in']) {
      const nowInMilliseconds = new Date().getTime();
      const expiresInMilliseconds = oauth['expires_in'] * 1000;
      window.localStorage.setItem(this.EXPIRES_AT_KEY, nowInMilliseconds + expiresInMilliseconds);
    } else {
      window.localStorage.removeItem(this.EXPIRES_AT_KEY);
    }
  }

  clear() {
    window.localStorage.removeItem('oauth');
    window.localStorage.removeItem(this.EXPIRES_AT_KEY);
  }
})();

export default class Abstract {
  /**
   * Constructor
  **/
  constructor() {
    const err = {
      100: 'Cannot instantiate an abstract class.',
    };

    if (new.target === Abstract) {
      throw new TypeError(err[100]);
    }

    this.constants = {
      API_URL: `${window.BASE_API_URL}/api`,
      VERSION: 'v1',
    };

    this.OAuth = OAuth;
  }

  /**
   * Hit DELETE {this.constants.ENDPOINT}/{id}
   * @param {integer} id of the entity
   * @return {object}
  **/
  async delete(id) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/${id}/`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'DELETE',
      },
    );
    return this._handleError(res);
  }

  /**
   * Hit GET {this.constants.ENDPOINT}/id to get obj detail
   * @param {string} id of the obj
   * @param {object} extras
   * @return {object}
  **/
  async get(id, extras = {}) {
    const eQuery = Object.keys(extras).reduce(
      (eQuery, param) => eQuery = `${eQuery}&${param}=${extras[param]}`,
      '?',
    );
    const res = await fetch(
      `${this.constants.ENDPOINT}/${id}/${eQuery}`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'GET',
      },
    );
    return this._handleError(res);
  }

  /**
   * helper to calculate the basic oauth header
   * @param {void}
   * @return {void}
  **/
  header() {
    return {
      'Authorization': `${this.OAuth.get('token_type')} ${this.OAuth.get('access_token')}`,
    };
  }

  /**
   * Hit GET {this.constants.ENDPOINT}/ to get list
   * @param {string} id of the obj
   * @param {object}
   * filters {object}
   * limit {number}
   * @return {object}
  **/
  async list({ filters, limit = 10, offset = 0, order = '' }, path) {
    const fQuery = Object.keys(filters).reduce(
      (fQuery, param) => {
        if (filters[param] && this._isDateField(param)) {
          fQuery = `${fQuery}&${param}__gte=${filters[param][0].format('YYYY-MM-DD')}`;
          if (filters[param][1]) {
            fQuery = `${fQuery}&${param}__lte=${filters[param][1].format('YYYY-MM-DD')} 23:59:59`;
          }
          return fQuery;
        }
        return filters[param] ? `${fQuery}&${param}=${filters[param]}` : fQuery;
      },
      `?limit=${limit}&offset=${offset}&ordering=${order}`,
    );
    const res = await fetch(
      `${path || this.constants.ENDPOINT}/${fQuery}`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'GET'
      },
    );
    return this._handleError(res);
  }


  /**
   * Hit PUT/POST {this.constants.ENDPOINT}/{id}
   * to save obj detail
   * @param {object} obj detail
   * @return {object}
  **/
  async save(obj) {
    const objectId = obj.id;
    delete (obj.id);
    const hasId = /^\d+$/.test(objectId);
    const res = await fetch(
      `${this.constants.ENDPOINT}/${hasId ? objectId + '/' : ''}`,
      {
        body: JSON.stringify(obj),
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: hasId ? 'PATCH' : 'POST',
      }
    );
    return this._handleError(res);
  }

  /**
   * Format the id to the proper uri
   * @param {number} id
   * @return {string}
  **/
  uri(id) {
    return `${this.constants.ENDPOINT}/${id}`;
  }

  /**
   * Get id from uri
   * @param {string} uri
   * @return {number}
  **/
  uriToId(uri) {
    return Number(uri.replace(`${this.constants.ENDPOINT}/`, ''));
  }

  /**
   * Protected function to handle any error response
   * @param {object} res from an api request
   * @return {object}
  **/
  async _handleError(res) {
    const errorMsgs = {
      401: 'ERROR_401',
      404: 'ERROR_404',
      500: 'ERROR_500',
    };

    if (res?.detail === 'AUTHENTICATION_CREDENTIALS_WERE_NOT_PROVIDED') {
      dispatchEvent(new CustomEvent('sessionExpired'));
      throw new Error('SESSION_EXPIRED');
    }

    if ([200, 201, 202, 204].indexOf(res.status) === -1) {
      let original;
      try {
        original = await res.json() || {};
      } catch (e) {
        original = {};
      }
      // check if it is bulk response error
      if (Array.isArray(original) && typeof original[0] !== 'string') {
        // wer process only the first one
        original = original[0];
      }
      // is field error
      const fields = Object.keys(original);
      if (fields.length > 0) {
        // return the first detected field error
        return {
          error: true,
          field: Array.isArray(original[fields[0]]) && fields[0] !== 'non_field_errors' && fields[0],
          msg: Array.isArray(original[fields[0]]) ? original[fields[0]][0] : original[fields[0]],
        };
      }
      return {
        error: true,
        msg: errorMsgs[res.status] || errorMsgs[500],
      };
    }

    const contentType = res.headers.get('content-type');
    const isJson = (contentType && contentType.indexOf('application/json') !== -1)
      && ['No Content', 'Not Found'].indexOf(res.statusText) === -1;
    return isJson ? await res.json() : res;
  }

  /**
   * Helper to check if field is date type
   * @param {string} field
   * @return {bool} true if a date field
  **/
  _isDateField(field) {
    return /(_on|date|time)/.test(field);
  }

  /**
   * Helper to update the endpoint if base api url changed
   * @param {void}
   * @return {void}
  **/
  _updateEndpoint() {
    this.constants.ENDPOINT = `${this.constants.API_URL}/${this.constants.VERSION}/${this.resource}`;
  }
}
