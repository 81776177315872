// Importing react libs
import React from 'react';

// Importing antd libs
import { Input, Typography } from 'antd';
import 'antd/es/input/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing component style
import './summary.scss';

// Defining constants
const { TextArea } = Input;
const { Title } = Typography;

// Exporting component
export const Summary = ({ history }) => {
  const Language = useLanguage();
  const formatted = AppAPI.Case.formatSignoffHistory(history);
  return (
    <div className='signoff-history'>
      <Title level={3}>{Language.get('signoff', 'HISTORY')}</Title>
      <TextArea disabled={true} rows={4} style={{ cursor: 'default', resize: 'none' }} value={formatted} />
    </div>
  );
};
