
// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';

// Importing antd libs
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Upload,
} from 'antd';
import 'antd/es/button/style/css';
import 'antd/es/message/style/css';
import 'antd/es/upload/style/css';

// Importing helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing app helper
import { Notification } from 'helper/handler/notification';

// Importing component helper
import { Parser, Validator } from 'components/case/import/helper/import';

// Importing local component
import { FAImportConfirm } from './faImportConfirm';

// Importing styles
import './faImport.scss';

// Defining constants
const ACCEPT_FILE_TYPE = 'text/plain, text/tsv';



// Exporting component
export default function FAImport({ onSuccess }) {
  const Language = useLanguage();
  const [importPayload, setImportPayload] = useState();
  const [loading, setLoading] = useState(false);
  const [showImportSummary, setShowImportSummary] = useState(false);
  const i18n = (key, context = 'plate') => Language.get(context, key);

  const Import = {
    fa: async (content) => {
      // validate case accessioning file
      await Validator.caseAccessioningData(content, 'fa');

      const biomaterials = [...content.trio, ...content.data];

      await Validator.biomaterials(biomaterials);

      await Validator.beadchipsAndPlates(biomaterials, i18n);

      // validate combination of gene regions and trio
      Validator.geneTrio(content);

      // parse content to set proper payload for the API call
      Parser.trio(content);
      const faImportContent = Parser.mapForImport(content, 'fa');
      setImportPayload(faImportContent);
    },
  };

  /**
   * On import ca confirm
   * @params {void}
   * @return {void} state change
  **/
  const _onConfirmImport = async () => {
    const res = await AppAPI.Case.faImport(importPayload);
    if (res.error) return Notification.error(Language, res, 'accessioning');
    Notification.success(Language);
    setImportPayload(undefined);
    onSuccess?.(res);
  };

  /**
   * On import btn clicked
   * @params {object} file last added
   * @params {array} fileList list of file added
   * @return {bool} return false to prevent automatically upload files
  **/
  const _onImport = async (file) => {
    try {
      setLoading(true);
      const text = await file.text();

      // validate encode
      if (/�/gi.test(text)) {
        return message.error(Language.get('accessioning', 'NOT_VALID_ENCODE_FOR_IMPORT_FILE'));
      }

      // Parsing file content
      const content = Parser.read(text);

      if (content) {
        if (!Import[content.header['file_type']]) throw new Error('NOT_VALID_FILE_TYPE');
        await Import[content.header['file_type'].toLowerCase()](content);
      }
    } catch (error) {
      console.error(error);
      message.error(
        <Fragment>
          {Language.get(error?.context ?? 'accessioning', error.message.toUpperCase())}
          {error.ref && (<pre>{Language.get('common', 'ROW')}: {error.ref}</pre>)}
          {error.detail && (<pre>{error.detail}</pre>)}
        </Fragment>
      );
    } finally {
      setLoading(false);
    }
    return false;
  };

  useEffect(() => {
    setShowImportSummary(Boolean(importPayload));
  }, [importPayload]);

  return (
    <div className="fast-track-import">
      <Upload
        accept={ACCEPT_FILE_TYPE}
        multiple={false}
        showUploadList={false}
        beforeUpload={_onImport}
      >
        <Button type="primary" disabled={loading}>
          {loading && <LoadingOutlined />}
          {Language.get('plate', 'FAST_TRACK')}
        </Button>
      </Upload>

      <FAImportConfirm
        data={importPayload}
        open={showImportSummary}
        onCancel={() => setImportPayload(undefined)}
        onConfirm={_onConfirmImport}
      />
    </div>
  );
}

