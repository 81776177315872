// Importing external libs
import { tsvParse } from 'd3-dsv';

// Importing app components
import { RESULT_COLORS } from 'styles/theme/colors';

import Abstract from './abstract';

class Biomaterial extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'biomaterial';
    this._updateEndpoint();

    this.BIO_TYPE_VALUES = [
      'blastomere',
      'gdna',
      'other',
      'trophectoderm',
    ];

    this.DESIGNATIONS = {
      BLANK: '',
      MOTHER: 'mother',
      FATHER: 'father',
      REFERENCE: 'reference',
    };

    this.DESIGNATION_VALUES = Object.values(this.DESIGNATIONS).map((value) => value.toLowerCase());

    this.PEDEGREE_VALUES = [
      'father',
      'mother',
      'matern_aunt',
      'matern_grand_father',
      'matern_grand_mother',
      'matern_uncle',
      'patern_aunt',
      'patern_grand_father',
      'patern_grand_mother',
      'patern_uncle',
      'sample',
      'sample_reference',
      'sibling',
    ];

    const [p1, p2, m1, m2, nr] = [
      RESULT_COLORS.PATERNAL_1.fill,
      RESULT_COLORS.PATERNAL_2.fill,
      RESULT_COLORS.MATERNAL_1.fill,
      RESULT_COLORS.MATERNAL_2.fill,
      RESULT_COLORS.NO_RESULT.fill,
    ];

    this.PEDEGREE_TO_COLOR = {
      '': [p1, m1],
      'father': [p1, p2],
      'mother': [m1, m2],
      'matern_aunt': [nr, m1],
      'matern_grand_father': [nr, m1],
      'matern_grand_mother': [nr, m1],
      'matern_uncle': [nr, m1],
      'patern_aunt': [p1, nr],
      'patern_grand_father': [p1, nr],
      'patern_grand_mother': [p1, nr],
      'patern_uncle': [p1, nr],
      'sample': [p1, m1],
      'sample_reference': [p1, m1],
      'sibling': [p1, m1],
    };
  }

  byDesignation(biomaterials = []) {
    const { FATHER, MOTHER, REFERENCE } = this.DESIGNATIONS;
    const _get = (list, designation, field = 'biomaterial_designation') => (
      list.find((bio) => bio[field] === designation)
    );
    return {
      embryos: biomaterials.filter(
        (bio) => [FATHER, MOTHER, REFERENCE].indexOf(bio['biomaterial_designation']) === -1
      ).sort((a, b) => a['biomaterial_id'] - b['biomaterial_id']),
      father: _get(biomaterials, FATHER),
      mother: _get(biomaterials, MOTHER),
      reference: _get(biomaterials, REFERENCE),
    };
  }

  async clone(id) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/clone/?id=${id}`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    );
    return this._handleError(res);
  }

  /**
   *
   * @param {Object} rawStats stats parsed from csv file
   */
  parseStats(rawStats) {
    if (Object.keys(rawStats ?? {}).length === 0) {
      return {
        regions: [],
        warnings: [],
      };
    }

    const regionKeys = {};

    Object.keys(rawStats).forEach(key => {
      if (key.startsWith('Region')) {
        regionKeys[key.split('_')[0]] = true;
      }
    });

    const parseEvidence = (rawEvidence) => rawEvidence.split(',').map(pair => {
      const [region, snps] = pair.split(':');
      return `${snps} Key SNPs support ${region}`;
    });

    const parseRegion = (rawRegion) => {
      const [key, nonKey] = rawRegion.split(',');
      const [leftKey, mainKey, rightKey] = key.split(':');
      const [leftNonKey, mainNonKey, rightNonKey] = nonKey.split(':');

      return {
        key: {
          left: leftKey,
          main: mainKey,
          right: rightKey,
        },
        nonKey: {
          left: leftNonKey,
          main: mainNonKey,
          right: rightNonKey,
        }
      };
    };

    const regions = Object.keys(regionKeys).map(regionKey => ({
      name: rawStats[`${regionKey}_Name`].split('__')[0],
      nameAndLocation: rawStats[`${regionKey}_Name`].replace('__', ' '),
      nonSupportive: parseEvidence(rawStats[`${regionKey}_NonSupp`]),
      predictedPhases: rawStats[`${regionKey}_Predicted_Phases`].split(','),
      predictedStatus: rawStats[`${regionKey}_Predicted_Status`] ?? '',
      snpP1: parseRegion(rawStats[`${regionKey}_SNP_P1`]),
      snpP2: parseRegion(rawStats[`${regionKey}_SNP_P2`]),
      snpM1: parseRegion(rawStats[`${regionKey}_SNP_M1`]),
      snpM2: parseRegion(rawStats[`${regionKey}_SNP_M2`]),
      supportive: parseEvidence(rawStats[`${regionKey}_Supp`]),
    }));

    const trioSnps = Object.keys(rawStats['trio_snps'] ?? {}).reduce((snps, key) => {
      if (key.startsWith('region')) {
        snps.push(rawStats['trio_snps'][key]);
      }
      return snps;
    }, []);

    const roundOff = (value, digits = 2) => {
      const num = Number(value);
      return isNaN(num) ? '' : num.toFixed(digits);
    };

    return {
      aaCalls: roundOff(rawStats['AA-Calls']),
      aaRate: roundOff(rawStats['Frequency(AA)']),
      abCalls: roundOff(rawStats['AB-Calls']),
      abRate: roundOff(rawStats['Frequency(AB)']),
      adoRate: roundOff(rawStats['ADO-Rate']),
      algorithmVersion: rawStats['Algorithm_Version'] ?? '',
      aneuploidyStatus: rawStats['Aneuploidy_Status'],
      assignedSex: rawStats['Assigned Sex'],
      bbCalls: roundOff(rawStats['BB-Calls']),
      bbRate: roundOff(rawStats['Frequency(BB)']),
      callRate: roundOff(rawStats['Call-Rate']),
      case: rawStats['Case'],
      copyNumberChanges: rawStats['Copy-Number_Changes'],
      fileName: rawStats['File_Name'],
      flankSetting: rawStats['Flank-Setting'],
      manifestName: rawStats['Manifest_Name'],
      medianLogR: roundOff(rawStats['Median-LogR']),
      misCallRate: roundOff(rawStats['Miscall-Rate']),
      noCalls: roundOff(rawStats['No-Calls']),
      overallStatus: rawStats['Overall_Status'],
      pedigree: rawStats['Pedigree'],
      qc: rawStats['QC'],
      regions,
      sampleName: rawStats['Sample_Name'],
      sampleType: rawStats['Sample_Type'],
      scanDate: rawStats['Scan_Date'],
      scanningHardware: rawStats['Scanner_Type'],
      stdLogR: roundOff(rawStats['Std-LogR']),
      totalCalls: roundOff(rawStats['Total_Calls']),
      trioSnps,
      warnings: (rawStats['Warnings'] ?? '').split(';')
        .map(warning => warning.trim())
        .filter(warning => warning.length > 0),
      xHetRate: roundOff(rawStats['X_Het-Rate']),
      yCallRate: roundOff(rawStats['Y_Call-Rate']),
    };
  }

  /**
   *
   * @param {Object} qcProbes qc probes text (tsv)
   */
  parseQCProbes(qcProbes) {
    if (!qcProbes) return null;
    const parsed = tsvParse(qcProbes);
    return parsed.length > 0 ? parsed[0] : null;
  }
}

export default new Biomaterial();
