// Importing app helper
import { h1 } from './common';

export const pageHeader = ({ address, image, title }) => {
  return {
    margin: [0, 0, 0, 10],
    stack: [{
      columnGap: 5,
      columns: [
        { ...h1(title), width: '*' },
        ...(image
          ? [{ alignment: 'right', fit: [60, 60], image, width: 60 }]
          : [{ text: '', width: 60 }]),
      ],
    }, {
      alignment: 'right',
      margin: 5,
      style: 'secondary',
      text: address || '',
    }],
  };
};
