import Abstract from './abstract';

class DiseaseDictionary extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'disease_dictionary';
    this._updateEndpoint();
  }
}

export default new DiseaseDictionary();
